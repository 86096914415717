import { createAction } from '@ngrx/store';

export const ACTIONS_SCOPE = '[Domain][Shared Subscription Plans]';

export const loadAll = createAction(
	`${ ACTIONS_SCOPE } Load`,
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);
