import { NgModule } from '@angular/core';

import { MatMenuTriggerObserverDirective } from './mat-menu-trigger-observer.directive';

const EXPOSED = [
	MatMenuTriggerObserverDirective,
];

@NgModule({
	imports: [],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesMatMenuTriggerObserverModule { }
