import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { IIdentity } from '@bp/shared/domains/jwt-session';
import { Permission } from '@bp/shared/domains/permissions';

import { IdentityFacade } from '../state';

@Injectable({
	providedIn: 'root',
})
export class FeaturePermissionsService {

	private get _userPermissions(): IIdentity['featurePermissions'] | undefined {
		return this.__identityFacade.user?.featurePermissions;
	}

	private get _user$(): Observable<IIdentity | null> {
		return this.__identityFacade.user$;
	}

	constructor(private readonly __identityFacade: IdentityFacade<IIdentity, any>) {}

	hasAccess(permission: Permission): boolean {
		if (this.__identityFacade.hiddenOrganizationPermissions.has(permission))
			return false;

		return !!this._userPermissions?.has(permission) || this.__identityFacade.organizationPermissions.has(permission);
	}

	hasAccess$(permission: Permission): Observable<boolean> {
		return combineLatest([
			this._user$,
			this.__identityFacade.organizationPermissions$,
			this.__identityFacade.hiddenOrganizationPermissions$,
		])
			.pipe(map(([ user ]) => (user ? this.hasAccess(permission) : false)));
	}
}
