import type { DTO, PropertyMetadata } from '@bp/shared/models/metadata';
import { MetadataEntity, MapFromDTO, Mapper, titleCase, Default, Unserializable } from '@bp/shared/models/metadata';

import { ApiPropertyMetadata } from './api-property-metadata';
import { ApiPropertiesMetadataArray } from './api-properties-metadata-array';

export class ApiPropertiesMetadataSection extends MetadataEntity {

	@MapFromDTO()
	name!: string;

	@Mapper(titleCase)
	title!: string | null;

	@Mapper(ApiPropertyMetadata)
	fields!: ApiPropertyMetadata[];

	@Mapper(ApiPropertiesMetadataArray)
	@Default([])
	arrays!: ApiPropertiesMetadataArray[];

	@Unserializable()
	readonly $$fields: PropertyMetadata[];

	get isStatic(): boolean {
		return this.name === 'static';
	}

	constructor(dto?: DTO<ApiPropertiesMetadataSection>) {
		super(dto);

		this.$$fields = this.fields.map(field => field.propertyMetadata);

		this.title = this.isStatic ? null : this.title;
	}
}
