import { Moment } from 'moment';

import { DTO, Mapper, MetadataEntity, momentMapper, Validator } from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';
import { SupportRequestStatus } from '@bp/shared/domains/support-requests';

export class SupportRequestPatchModel extends MetadataEntity {

	@Mapper(momentMapper)
	@Validator(Validators.afterNow<unknown>())
	expiresAt?: Moment;

	@Mapper(SupportRequestStatus)
	@Validator(Validators.inList<unknown>([ SupportRequestStatus.supportRequestCompleted ]))
	status?: SupportRequestStatus;

	constructor(dto?: DTO<SupportRequestPatchModel>) {
		super(dto);
	}

}
