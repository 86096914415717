import { isString } from 'lodash-es';

import type { TemplateRef } from '@angular/core';
import {
	ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2
} from '@angular/core';

import { Enumeration } from '@bp/shared/models/core/enum';
import { FieldViewType, PropertyMetadata, PropertyMetadataTable } from '@bp/shared/models/metadata';
import { LONG_MOMENT_FORMAT } from '@bp/shared/models/core';
import { hasMaskedChars, replaceMaskSourceChars, getDisplayName } from '@bp/shared/utilities/core';

import { SimpleChanges, OnChanges, SimpleChange } from '@bp/frontend/models/core';

import type { PropertyMetadataCustomValueViewDirective } from './property-metadata-custom-view.directive';

@Component({
	selector: 'bp-property-metadata-view',
	templateUrl: './property-metadata-view.component.html',
	styleUrls: [ './property-metadata-view.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyMetadataViewComponent<TEntity> implements OnChanges {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	FieldViewType = FieldViewType;

	@Input() label = true;

	@Input() compact = false;

	@Input() metadata!: PropertyMetadata;

	@Input() table?: PropertyMetadataTable | null;

	@Input() value: any;

	@Input() tooltip?: string | null;

	@Input() customValueTemplate?: TemplateRef<any> | null;

	@Input() customValueView?: PropertyMetadataCustomValueViewDirective<TEntity> | null;

	readonly defaultMomentFormat = LONG_MOMENT_FORMAT;

	get showLabel(): boolean {
		return this.customValueView
			? !!this.customValueView.showLabel
			: this.label;
	}

	get viewType(): FieldViewType {
		const viewType = this.table?.viewType ?? this.metadata.viewType;

		if (viewType === FieldViewType.textarea && this.table)
			return FieldViewType.text;

		return viewType;
	}

	get showGeneralTooltip(): boolean {
		return this.__generalTooltipViewTypes.has(this.viewType);
	}

	get viewFormatter(): ((propertyValue: any) => any) | null {
		return this.table?.viewFormatter ?? this.metadata.viewFormatter;
	}

	get viewEmptyValue(): number | string | null {
		return this.table?.viewEmptyValue ?? this.metadata.viewEmptyValue;
	}

	get isCompact(): boolean {
		return !!this.table || !!this.compact;
	}

	// @Input() color: ThemePalette;

	get booleanIcon(): string {
		return this.value ? 'check' : 'close';
	}

	displayValue: any;

	viewFormattedValue: any;

	protected _displayValueHasMaskedChars = false;

	private readonly __generalTooltipViewTypes = new Set([
		FieldViewType.text,
		FieldViewType.currency,
		FieldViewType.currencyCode,
		FieldViewType.percent,
		FieldViewType.link,
		FieldViewType.email,
		FieldViewType.paymentOptionBrand,
	]);

	constructor(
		private readonly _renderer: Renderer2,
		private readonly _host: ElementRef,
	) { }

	ngOnChanges({ metadata, value, table }: SimpleChanges<this>): void {
		metadata && this.__setHostClass(metadata);

		if (metadata || value || table) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.viewFormattedValue = this.__buildViewFormattedValue();

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.displayValue = this.__buildDisplayValue();

			this._displayValueHasMaskedChars = isString(this.displayValue) && hasMaskedChars(this.displayValue);
		}
	}

	isEnumeration(value: unknown): value is Enumeration {
		return value instanceof Enumeration;
	}

	private __setHostClass({ previousValue: previous, currentValue: current }: SimpleChange<PropertyMetadata>): void {
		previous && this._renderer.removeClass(this._host.nativeElement, this.__getHostClass(previous));

		current && this._renderer.addClass(this._host.nativeElement, this.__getHostClass(current));
	}

	private __getHostClass(md: PropertyMetadata): string {
		return `view-type-${ md.viewType.cssClass }`;
	}

	private __buildViewFormattedValue(): any {
		return this.viewFormatter?.(this.value) ?? this.value;
	}

	private __buildDisplayValue(): any {
		if (this.viewFormatter)
			return this.viewFormatter(this.value);

		const displayValue = getDisplayName(this.value);

		return this.metadata.isSecret
			? this.__whenStringReplaceMaskSourceChars(displayValue)
			: displayValue;
	}

	private __whenStringReplaceMaskSourceChars<T>(value: T): T | string {
		// Note displayValue pipe actually returns `any` value, so need to have this explicit check.
		return isString(value)
			? replaceMaskSourceChars(value)
			: value;
	}

}
