import { Enumeration } from '@bp/shared/models/core/enum';
import { SECONDS_IN_DAY, SECONDS_IN_HOUR } from '@bp/shared/utilities/core';

export class SupportRequestDurationChange extends Enumeration {

	static minusDay = new SupportRequestDurationChange('- 1 day', -SECONDS_IN_DAY);

	static minusHour = new SupportRequestDurationChange('- 1 hour', -SECONDS_IN_HOUR);

	static plusHour = new SupportRequestDurationChange('+ 1 hour', SECONDS_IN_HOUR);

	static plusDay = new SupportRequestDurationChange('+ 1 day', SECONDS_IN_DAY);

	constructor(
		displayName: string,
		public readonly seconds: number,
	) {
		super(displayName);
	}

}
