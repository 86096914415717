import { GtagEvents, IAnalyticsObserver } from '@bp/frontend/features/analytics/models';

export class LinkedInAnalyticsObserverService implements IAnalyticsObserver {

	private readonly __eventConversionIdMap: {[eventName in keyof GtagEvents.IEventPayloadMap]?: number } = {
		/* eslint-disable @typescript-eslint/naming-convention */
		generate_lead: 8_453_849,
		lead_updated: 8_453_857,
		demo_booked: 8_453_865,
		new_free_client: 8_453_889,
		login: 8_453_897,
		begin_checkout: 8_453_873,
		purchase: 8_453_881,
		/* eslint-enable @typescript-eslint/naming-convention */
	};

	dispatchEvent<TEventName extends keyof GtagEvents.IEventPayloadMap>(
		event: TEventName,
		_payload?: GtagEvents.IEventPayloadMap[TEventName],
	): void {
		const conversionId = this.__eventConversionIdMap[event];

		if (conversionId)
			this.__trackEvent(conversionId);
	}

	private __trackEvent(conversionId: number): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		window.lintrk?.('track', { conversion_id: conversionId });
	}
}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		lintrk?: (track: 'track', options: { conversion_id: number }) => void;
	}
}
