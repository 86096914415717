import { Control, DTO, FieldControlType, Mapper, MetadataEntity } from '@bp/shared/models/metadata';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { NumberMaskConfig, TextMaskConfig } from '@bp/shared/features/text-mask';

export class CurrencyMarkupTarget extends MetadataEntity {

	@Mapper(FiatCurrency)
	@Control(FieldControlType.autocomplete, {
		list: FiatCurrency.list,
		required: true,
	})
	targetCurrency!: FiatCurrency;

	@Mapper(Number)
	@Control(FieldControlType.number, {
		required: true,
		typeControlOptions: {
			textMask: new NumberMaskConfig({
				placeholderChar: TextMaskConfig.whitespace,
				allowDecimal: true,
				decimalLimit: 3,
				guide: true,
				maskOnFocus: true,
				suffix: '%',
			}),
		},
	})
	exchangeRateMarkup!: number;

	constructor(dto: DTO<CurrencyMarkupTarget>) {
		super(dto);
	}

}
