import { BehaviorSubject, MonoTypeOperatorFunction, Observable, Subject, firstValueFrom, retry } from 'rxjs';

import { Injectable, Injector, inject } from '@angular/core';
import { MatLegacyDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { AsyncVoidSubject, ZoneService, observeInsideNgZone } from '@bp/frontend/rxjs';
import { $ } from '@bp/frontend/utilities/dom';
import { LocalBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { ITurnstileOptions } from './models';
import { TurnstileDialogComponent } from './components';

@Injectable({
	providedIn: 'root',
})
export class TurnstileService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __injector = inject(Injector);

	private readonly __zoneService = inject(ZoneService);

	/**
	 * We cannot directly inject MatLegacyDialog since it causes circular dependency in DI
	 * https://github.com/angular/components/issues/10304
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private __dialog__: MatLegacyDialog | undefined;

	private get __dialog(): MatLegacyDialog {
		return (this.__dialog__ ??= this.__injector.get(MatLegacyDialog));
	}

	private readonly __turnstileScriptLoaded$ = new AsyncVoidSubject();

	private readonly __token$ = new BehaviorSubject<string | null>(null);

	private readonly __obtainedClearance$ = new Subject<void>();

	readonly token$ = this.__token$.pipe(observeInsideNgZone());

	get token(): string | null {
		return this.__token$.value;
	}

	private readonly __visitorChallenged$ = new BehaviorSubject<boolean>(false);

	readonly visitorChallenged$ = this.__visitorChallenged$.pipe(observeInsideNgZone());

	readonly enabled = !!this.__environment.cloudflareTurnstileSiteKey && !LocalBackendState.isActive;

	private __$currentWidgetHost: HTMLElement | null = null;

	constructor() {
		if (!this.enabled)
			return;

		this.__injectCloudflareTurnstileScript();

		this.__visitorChallenged$
			.subscribe(state => void this.__toggleHostVisitorChallengedClass(state));
	}

	/**
	 * On cloudflare admin we can setup which endpoints require visitor's human verification for a given time period
	 */
	whenRequestChallengedShowChallengeDialog<T>(): MonoTypeOperatorFunction<T> {
		return (source$: Observable<T>) => source$.pipe(
			retry({
				count: 1,
				// Should be false, as response interceptor processes all events, thus resetting it every time
				resetOnSuccess: false,
				delay: async (error: unknown) => {
					if (this.__checkIfRequestChallenged(error)) {
						await this.__challengeVisitor();

						return;
					}

					// exit without retrying and challenging
					throw error;
				},
			}),
		);
	}

	async renderChallenge($host: HTMLElement, action?: string): Promise<void> {
		await this.__scriptLoaded();

		this.__$currentWidgetHost = $host;

		this.__telemetry.log('[turnstile] render challenge', action);

		this.__zoneService.runOutsideAngular(() => window.turnstile!.render(
			$host,
			{
				action,
				theme: 'light',
				appearance: 'always',
				sitekey: this.__environment.cloudflareTurnstileSiteKey!,
				callback: token => {
					this.__telemetry.log('[turnstile] token issued');

					void this.__visitorChallenged$.next(false);

					void this.__token$.next(token);
				},
				'before-interactive-callback': () => void this.__visitorChallenged$.next(true),
				'expired-callback': () => void this.__telemetry.log('[turnstile] token expired'),
				'error-callback': error => void this.__telemetry.captureMessage(`[turnstile] error: ${ error }`),
			},
		));
	}

	resetChallenge($host: HTMLElement): void {
		this.__telemetry.log('[turnstile] reset challenge');

		this.__zoneService.runOutsideAngular(() => window.turnstile?.reset($host));
	}

	removeChallenge($host: HTMLElement): void {
		this.__telemetry.log('[turnstile] remove challenge');

		this.__token$.next(null);

		window.turnstile?.remove($host);

		this.__$currentWidgetHost = null;
	}

	private __toggleHostVisitorChallengedClass(state: boolean): void {
		this.__$currentWidgetHost?.classList.toggle('visitor-challenged', state);
	}

	private async __renderChallengeAndAwaitClearance($host: HTMLElement): Promise<void> {
		await this.__scriptLoaded();

		this.__telemetry.log('[turnstile] render challenge and await clearance');

		this.__$currentWidgetHost = $host;

		return new Promise((resolve, reject) => this.__zoneService.runOutsideAngular(() => window.turnstile!.render(
			$host,
			{
				theme: 'light',
				appearance: 'always',
				sitekey: this.__environment.cloudflareTurnstileSiteKey!,
				callback: (token, preClearanceObtained) => {
					this.__telemetry.log(`[turnstile] token issued; preClearanceObtained: ${ preClearanceObtained }`);

					window.turnstile!.remove($host);

					this.__visitorChallenged$.next(false);

					if (preClearanceObtained)
						this.__obtainedClearance$.next();

					this.__token$.next(token);

					preClearanceObtained || this.__environment.isLocal
						? void resolve()
						: void reject(new Error('Unable to obtain pre-clearance'));
				},
				'before-interactive-callback': () => void this.__visitorChallenged$.next(true),
				'expired-callback': () => void this.__telemetry.log('[turnstile] token expired'),
				'error-callback': error => void this.__telemetry.captureMessage(`[turnstile] error: ${ error }`),
			},
		)));
	}

	private __checkIfRequestChallenged(error: unknown): boolean {
		return error instanceof HttpErrorResponse
			&& error.headers.has('cf-mitigated')
			&& error.headers.get('cf-mitigated') === 'challenge';
	}

	private async __challengeVisitor(): Promise<void> {
		if (this.__$currentWidgetHost) {
			this.__telemetry.log('[turnstile] visitor already being challenged, awaiting clearance');

			return firstValueFrom(this.__obtainedClearance$);
		}

		this.__telemetry.log('[turnstile] challenge visitor with clearance');

		const [ dialog, $challengeHost ] = this.__openTurnstileDialog();

		await this.__renderChallengeAndAwaitClearance($challengeHost);

		dialog.close();

		await firstValueFrom(dialog.afterClosed());

		this.removeChallenge($challengeHost);
	}

	private __openTurnstileDialog(): [dialog: MatLegacyDialogRef<any>, challengeHost: HTMLElement ] {
		const dialog = this.__dialog
			.open<TurnstileDialogComponent>(TurnstileDialogComponent, {
			disableClose: true,
		});

		const $dialogContent: HTMLElement = (<HTMLElement>dialog.componentRef!.location.nativeElement).querySelector('mat-dialog-content')!;

		return [ dialog, $dialogContent ];
	}

	private __injectCloudflareTurnstileScript(): void {
		const $script = $.buildAsyncScriptElement({
			src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
		});

		$script.addEventListener(
			'load',
			() => void this.__turnstileScriptLoaded$.complete(),
		);

		document.body.append($script);
	}

	private async __scriptLoaded(): Promise<void> {
		await firstValueFrom(this.__turnstileScriptLoaded$);
	}

}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		turnstile?: {
			render: (
				idOrContainer: HTMLElement | string,
				options: ITurnstileOptions
			) => string;
			reset: (widgetIdOrContainer: HTMLElement | string) => void;
			getResponse: (
				widgetIdOrContainer: HTMLElement | string
			) => string | undefined;
			remove: (widgetIdOrContainer: HTMLElement | string) => void;
		};
	}
}
