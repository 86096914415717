import { isNil, omitBy } from 'lodash-es';

import { Country, countryMapper, State, stateMapper } from '@bp/shared/models/countries';
import { booleanMapper, Default, DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';
import { toPlainObject } from '@bp/shared/utilities/core';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

export type GeolocationInfoKeys = NonFunctionPropertyNames<GeolocationInfo>;
export type GeolocationInfoDTO = DTO<GeolocationInfo>;

export class GeolocationInfo extends MetadataEntity {
	@Default(null)
	ip!: string | null;

	/**
	 * The [ISO 3166-1 Alpha 2](https://www.iso.org/iso-3166-country-codes.html) country code the request originated from.
	 *
	 * If Cloudflare is unable to determine where the request originated this property is omitted.
	 *
	 * @example "GB"
	 */
	@Mapper(countryMapper)
	@Default(null)
	country!: Country | null;

	/**
	 * If known, the ISO 3166-2 code for the first-level region associated with
	 * the IP address of the incoming request
	 *
	 * @example "TX"
	 */
	@Mapper(stateMapper)
	@Default(null)
	state!: State | null;

	/**
	 * The city the request originated from
	 *
	 * @example "Austin"
	 */
	@Default(null)
	city!: string | null;

	/**
	 * A two-letter code indicating the continent the request originated from.
	 *
	 * @example "AN"
	 */
	@Default(null)
	continent!: string | null;

	/**
	 * The latitude of the request
	 *
	 * @example "30.27130"
	 */
	@Default(null)
	latitude!: string | null;

	/**
	 * The longitude of the request
	 *
	 * @example "-97.74260"
	 */
	@Default(null)
	longitude!: string | null;

	/**
	 * The postal code the request originated from
	 *
	 * @example "78701"
	 */
	@Default(null)
	postalCode!: string | null;

	/**
	 * Timezone of the incoming request
	 *
	 * @example "America/Chicago"
	 */
	@Default(null)
	timezone!: string | null;

	 /**
	 * If known, the ISO 3166-2 name for the first level region associated with
	 * the IP address of the incoming request
	 *
	 * @example "Texas"
	 */
	@Default(null)
	region!: string | null;

	/**
	 * If known, the ISO 3166-2 code for the first-level region associated with
	 * the IP address of the incoming request
	 *
	 * @example "TX"
	 */
	@Default(null)
	regionCode!: string | null;

	/**
	 * Whether the country the request originated from is in the EU
	 *
	 * @example true
	 */
	@Mapper(booleanMapper)
	@Default(false)
	isEUCountry!: boolean;

	constructor(dto?: GeolocationInfoDTO) {
		super(dto);
	}

	getTelemetryContext(): Record<string, string> {
		return <Record<string, string>>omitBy(
			toPlainObject(this),
			(value, key) => isNil(value) || (<GeolocationInfoKeys[]>[ 'longitude', 'latitude', 'ip' ]).includes(<GeolocationInfoKeys>key),
		);
	}
}
