import { map, merge, shareReplay, startWith } from 'rxjs';

import { Directive, inject } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Directive({
	selector: '[bpMatMenuTriggerObserver]',
	exportAs: 'bpMatMenuTriggerObserver',
})
export class MatMenuTriggerObserverDirective {

	private readonly _matMenuTrigger = inject(MatMenuTrigger, { self: true })!;

	opened$ = merge(
		this._matMenuTrigger.menuOpened.pipe(map(() => true)),
		this._matMenuTrigger.menuClosed.pipe(map(() => false)),
	)
		.pipe(
			startWith(this._matMenuTrigger.menuOpen),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
}
