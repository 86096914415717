import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { TextMaskDirective } from '@bp/frontend/features/text-mask';

import {
	NoopPermissionBasedNavigationService, PermissionBasedNavigationService, SharedDomainsIdentityModule
} from '@bp/frontend-domains-identity';

import {
	IdentitySessionIsAboutToExpireDialogComponent,
	OtpCodeInputComponent,
	OtpGenerateCodeButtonComponent,
	OtpProviderMessageComponent
} from './components';

const EXPOSED_MODULES = [ SharedDomainsIdentityModule ];

const EXPOSED_COMPONENTS = [
	OtpCodeInputComponent,
	OtpGenerateCodeButtonComponent,
	OtpProviderMessageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		TextMaskDirective,
		EXPOSED_MODULES,
	],
	exports: [ EXPOSED_MODULES, EXPOSED_COMPONENTS ],
	declarations: [ EXPOSED_COMPONENTS, IdentitySessionIsAboutToExpireDialogComponent ],
	providers: [
		{
			provide: PermissionBasedNavigationService,
			useClass: NoopPermissionBasedNavigationService,
		},
	],
})
export class AdminsSharedDomainsIdentityModule {}
