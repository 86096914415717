import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { filterPresent } from '@bp/frontend/rxjs';

import { loadAll, resetState } from './bridger-psps-shared.actions';
import {
	selectAllBridgerPsps, selectAllBridgerPspsByPspNameMap, selectError, selectPending, selectAllBridgerPspsInternalNames,
	selectAllBridgerPspsByPaymentOptionTypeMap
} from './bridger-psps-shared.selectors';

@Injectable({
	providedIn: 'root',
})
export class BridgerPspsSharedFacade {

	all$ = this._store$
		.select(selectAllBridgerPsps)
		.pipe(filterPresent);

	allByPspNameMap$ = this._store$
		.select(selectAllBridgerPspsByPspNameMap)
		.pipe(filterPresent);

	allInternalNames$ = this._store$
		.select(selectAllBridgerPspsInternalNames)
		.pipe(filterPresent);

	allByPaymentOptionTypeMap$ = this._store$
		.select(selectAllBridgerPspsByPaymentOptionTypeMap)
		.pipe(filterPresent);

	pending$ = this._store$.select(selectPending);

	error$ = this._store$.select(selectError);

	constructor(
		private readonly _store$: Store,
	) { }

	loadAll(): void {
		this._store$.dispatch(loadAll());
	}

	resetState(): void {
		this._store$.dispatch(resetState());
	}

}
