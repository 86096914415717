import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';

import { IDENTITY_GET_ACCESS_DIALOG_GUARDS, PermissionBasedNavigationService } from '@bp/frontend-domains-identity';
import { MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionModule } from '@bp/merchant-admin/frontend/domains/current-organization-subscription';

import {
	PaywallBadgeComponent,
	PaywallButtonComponent,
	ResourceAccessOtpVerificationDialogComponent,
	ResourceAccessOtpVerificationFormComponent,
	ResourcePaywallDialogComponent
} from './components';
import { IdentityGetAccessDialogGuard } from './guards';
import { ButtonIdentityGetAccessDialogGuardDirective, BehindPaywallStructuralDirective } from './directives';
import {
	HttpResponsePaywallGuardInterceptor,
	HttpPostRequestIdentitySessionRefreshInterceptor,
	MerchantAdminPermissionBasedNavigationService
} from './services';

const EXPOSED_COMPONENTS = [
	ButtonIdentityGetAccessDialogGuardDirective,
	PaywallBadgeComponent,
	PaywallButtonComponent,
	BehindPaywallStructuralDirective,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		MatDialogModule,
		MatProgressBarModule,
		MatButtonModule,

		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedDirectivesCoreModule,

		AdminsSharedDomainsIdentityModule,
		MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionModule,
	],
	exports: [ EXPOSED_COMPONENTS ],
	declarations: [
		EXPOSED_COMPONENTS,
		ResourcePaywallDialogComponent,
		ResourceAccessOtpVerificationDialogComponent,
		ResourceAccessOtpVerificationFormComponent,
	],
	providers: [
		{
			provide: PermissionBasedNavigationService,
			useClass: MerchantAdminPermissionBasedNavigationService,
		},
		{ provide: IDENTITY_GET_ACCESS_DIALOG_GUARDS, useClass: IdentityGetAccessDialogGuard, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpResponsePaywallGuardInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpPostRequestIdentitySessionRefreshInterceptor, multi: true },
	],
})
export class MerchantAdminFrontendDomainsIdentityModule {}
