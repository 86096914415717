/* eslint-disable max-classes-per-file */
import { Label } from '@bp/shared/models/metadata';

import {
	CommonFeatureActions, EditAndAccessFeatureActions, FeatureAction, FeatureActionSymbol
} from '../feature-actions';

export class TransactionsFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	refund!: FeatureAction;

	@FeatureActionSymbol
	checkStatus!: FeatureAction;

	@FeatureActionSymbol
	resendNotification!: FeatureAction;

	@FeatureActionSymbol
	void!: FeatureAction;

	@FeatureActionSymbol
	capture!: FeatureAction;
}

export class PayoutsFeatureActions extends EditAndAccessFeatureActions {
	@FeatureActionSymbol
	refund!: FeatureAction;

	@FeatureActionSymbol
	checkStatus!: FeatureAction;

	@FeatureActionSymbol
	resendNotification!: FeatureAction;

	@FeatureActionSymbol
	chargeback!: FeatureAction;
}

export class ReportsFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	preview!: FeatureAction;

	@FeatureActionSymbol
	export!: FeatureAction;
}

export class MembersFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	invite!: FeatureAction;

	@FeatureActionSymbol
	edit!: FeatureAction;
}

export class CheckoutsFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	paymentLink!: FeatureAction;

	@FeatureActionSymbol
	paywith!: FeatureAction;

	@FeatureActionSymbol
	blox!: FeatureAction;

	@FeatureActionSymbol
	paymentCardToken!: FeatureAction;

	@FeatureActionSymbol
	external!: FeatureAction;

}

export class PaymentCardTokensFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	@Label('sneak peek payment card token')
	sneakPeek!: FeatureAction;

}
