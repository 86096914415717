import { MetadataEntity } from '@bp/shared/models/metadata';

import type { PspPaymentOptionType } from '@bp/frontend/models/business';

export abstract class BridgerPspPaymentOptionBaseSummary extends MetadataEntity {

	static readonly type: PspPaymentOptionType;

	abstract readonly type: PspPaymentOptionType;

}
