import { createAction } from '@ngrx/store';

import { createVerboseAction } from '@bp/frontend/ngrx';

export const ACTIONS_SCOPE = '[Domain][Current Organization]';

export const load = createAction(`${ ACTIONS_SCOPE } Load`);

export const refresh = createVerboseAction(`${ ACTIONS_SCOPE } Refresh`);

export const startRefreshingInInterval = createAction(`${ ACTIONS_SCOPE } Start Refreshing In Interval`);

export const stopRefreshingInInterval = createAction(`${ ACTIONS_SCOPE } Stop Refreshing In Interval`);

export const resetState = createAction(`${ ACTIONS_SCOPE } Reset State`);

export const loadVirtualTerminalData = createAction(`${ ACTIONS_SCOPE } Load Virtual Terminal Data`);
