import { SubscriptionPlan, SubscriptionPlanType } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { CongratulationEnterprisePlanVm } from './congratulation-enterprise-plan-vm';
import { CongratulationFreePlanVm } from './congratulation-free-plan-vm';
import { CongratulationGenericVm } from './congratulation-generic-vm';
import { CongratulationGrowthPlanVm } from './congratulation-growth-plan-vm';
import { CongratulationProPlanVm } from './congratulation-pro-plan-vm';
import { CongratulationStartupPlanVm } from './congratulation-startup-plan-vm';

export function congratulationVmFactory(subscriptionPlan?: SubscriptionPlan | null): CongratulationBaseVm {
	switch (subscriptionPlan?.type) {
		case SubscriptionPlanType.free:
			return new CongratulationFreePlanVm(subscriptionPlan);

		case SubscriptionPlanType.startup:
			return new CongratulationStartupPlanVm(subscriptionPlan);

		case SubscriptionPlanType.pro:
			return new CongratulationProPlanVm(subscriptionPlan);

		case SubscriptionPlanType.growth:
			return new CongratulationGrowthPlanVm(subscriptionPlan);

		case SubscriptionPlanType.enterprise:
			return new CongratulationEnterprisePlanVm(subscriptionPlan);

		default:
			return new CongratulationGenericVm();
	}
}
