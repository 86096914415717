<div class="cards">
	<ng-container *ngFor="let provider of providers; trackBy: trackById">
		<bp-bridger-psp-card
			*bpDelayedRender="0; stagger: 25; allStagger: true"
			@fadeInSemiSlow
			[psp]="provider"
			[paymentOptionType]="paymentOptionType"
			[selected]="selected.has(provider.id!)"
			[selectable]="selected.has(provider.id!) ? true : selectable"
			(click)="toggleProvider(provider)"
		/>
	</ng-container>
</div>
