<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ng-container *ngIf="hasSeparator">
	<hr *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow />
</ng-container>

<ng-container *ngIf="heading">
	<h2 *bpDelayedRender="0; stagger: renderStagger; allStagger: true" class="mat-h2" @fadeInSemiSlow>{{ heading }}</h2>
</ng-container>

<ng-container *ngIf="propertiesMetadata; else sectionSchemeTpl">
	<ng-container *ngFor="let rowPropsMd of propertiesMetadata | chunk: 2">
		<div *bpDelayedRender="0; stagger: renderStagger; allStagger: true" class="row" @fadeInSemiSlow>
			<ng-container *ngFor="let propertyMetadata of rowPropsMd | takePresent">
				<ng-template
					[ngTemplateOutlet]="propertyMetadataViewTpl"
					[ngTemplateOutletContext]="{ $implicit: propertyMetadata }"
				/>
			</ng-container>
		</div>
	</ng-container>
</ng-container>

<ng-template #sectionSchemeTpl>
	<ng-container *ngFor="let rowCellsDefs of sectionScheme | takePresent">
		<div *bpDelayedRender="0; stagger: renderStagger; allStagger: true" class="row" @fadeInSemiSlow>
			<ng-template
				*ngFor="let propertyName of rowCellsDefs | takePresent"
				[ngTemplateOutlet]="propertyMetadataViewTpl"
				[ngTemplateOutletContext]="{ $implicit: getPropertyMetadata(propertyName) }"
			/>
		</div>
	</ng-container>
</ng-template>

<ng-template #propertyMetadataViewTpl let-propertyMetadata>
	<ng-container *ngIf="_castToPropertyMetadata(propertyMetadata)">
		<bp-property-metadata-view
			*ngIf="propertyMetadata.viewOnlyIfPresent ? !_isNil(_get(entity, propertyMetadata.property)) : true"
			[metadata]="propertyMetadata"
			[value]="_get(entity, propertyMetadata.property) ?? propertyMetadata.defaultPropertyValue"
			[customValueView]="_tryGetCustomValueViewFor(propertyMetadata.property)"
		/>
	</ng-container>
</ng-template>
