import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef, HostBinding } from '@angular/core';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

@Component({
	selector: 'bp-subscription-plans-overview-cards',
	templateUrl: './subscription-plans-overview-cards.component.html',
	styleUrls: [ './subscription-plans-overview-cards.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlansOverviewCardsComponent implements OnChanges {

	@Input() subscriptionPlans!: SubscriptionPlan[];

	@Input() currentSubscriptionPlan!: SubscriptionPlan | null;

	@Input() chargePeriod!: SubscriptionPlanChargePeriod;

	@Input() currency!: FiatCurrency;

	@ContentChild(TemplateRef) subscriptionActionTemplate!: TemplateRef<any>;

	@HostBinding('class.five-plans')
	get isFivePlans(): boolean {
		return this.subscriptionPlans.length === 5;
	}

	subscriptionPlanFeatureBlockTitleMap = new Map<SubscriptionPlan, string>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	ngOnChanges({ subscriptionPlans }: SimpleChanges<this>): void {
		if (subscriptionPlans)
			this._setSubscriptionPlanFeatureBlockTitleMap();
	}

	private _setSubscriptionPlanFeatureBlockTitleMap(): void {
		this.subscriptionPlanFeatureBlockTitleMap = new Map(
			this.subscriptionPlans.map((subscriptionPlan, index) => [
				subscriptionPlan,
				subscriptionPlan.includesPreviousPlanFeatures
					? `Includes ${ this.subscriptionPlans.at(index - 1)?.name }, plus:`
					: `${ subscriptionPlan.displayName } includes:`,
			]),
		);
	}

}
