import moment, { Moment } from 'moment';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { Validators } from '@bp/shared/features/validation/models';
import {
	Control, Default, FieldControlType, FieldViewType, Hint, Label,
	Mapper, Required, Table, Unserializable, Validator, View, ViewEmptyValue, DTO, Sortable
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';
import { isInstanceOf, isInstanceOfSome } from '@bp/shared/utilities/core';
import { INamedEntitySummary } from '@bp/shared/models/core';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspScope } from './bridger-psp-scope';
import {
	BridgerPspPaymentOptionApm, BridgerPspPaymentOptionCreditCard, BridgerPspPaymentOptions, bridgerPspPaymentOptionsFactory
} from './payment-options';
import { BridgerPspSummary } from './summaries';
import { BridgerPspLocation } from './bridger-psp-location';
import { BridgerPspIndustry } from './bridger-psp-industry';
import { BridgerPspLicense } from './bridger-psp-license';
import { BridgerPspLegalEntityType } from './bridger-psp-legal-entity-type';
import { BridgerPspProcessingHistory } from './bridger-psp-processing-history';
import { BridgerPspMonthlyTurnoverVolume } from './bridger-psp-monthly-turnover-volume';

export type BridgerPspKeys = NonFunctionPropertyNames<BridgerPsp>;

export type BridgerPspsByPaymentOptionTypeMap = Map<PspPaymentOptionType, BridgerPsp[]>;

export type BridgerPspByPspNameMap = Map<string, BridgerPsp>;

/**
 * Class defining an integrated provider into the bridgerpay system, where we keep general settings of this provider and
 * its information
 */
export class BridgerPsp extends BridgerPspSummary {

	@Required()
	@Default(null)
	@Table()
	@Sortable()
	override name!: string;

	@Hint(
		'For matching the PSP with the internal system psp enum',
		'This field is used for matching this PSP with the corresponding internal system psp enum value',
	)
	@Control(
		FieldControlType.autocomplete,
		{
			nativeAutocomplete: false,
		},
	)
	@Label('Backend Psp Enum Value')
	@Default(null)
	override internalName!: string | null;

	@Default([])
	@Control(
		FieldControlType.chip,
		{
			list: BridgerPspScope.getList(),
			placeholder: 'Add scope...',
		},
	)
	@View(FieldViewType.chip)
	@Mapper(BridgerPspScope)
	@ViewEmptyValue('Hidden')
	@Table()
	@Label('Published to')
	@Hint('Website where the psp is shown')
	scopes!: BridgerPspScope[];

	@Hint('Shown on the popular section of the promo website')
	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	override popular!: boolean;

	@Label('Status')
	@View(FieldViewType.booleanCircle, integrated => integrated ? 'Integrated' : 'Not Integrated')
	override integrated!: boolean;

	@Required()
	@View(FieldViewType.textarea)
	@Control(FieldControlType.textarea)
	override description!: string;

	@Required()
	@Control(FieldControlType.url)
	@View(FieldViewType.link)
	override websiteUrl!: string;

	@Required()
	@Label('Thumbnail Logo')
	@View(FieldViewType.thumbnail)
	@Table()
	override logoUrl!: string;

	@Required()
	@Label('Logo')
	@View(FieldViewType.image)
	override fullLogoUrl!: string;

	@Validator(Validators.requiredArray)
	@Mapper(bridgerPspPaymentOptionsFactory)
	@Default([])
	@Table()
	override paymentOptions!: BridgerPspPaymentOptions[];

	@Label('Updated By')
	@Table()
	@Sortable()
	modifiedBy!: string;

	@Label('Updated')
	@View(FieldViewType.momentFromNow)
	@Table()
	@Sortable({ isDefault: true })
	override modified!: Moment | null;

	@Required()
	@Control(FieldControlType.chip, {
		list: BridgerPspLocation.getList(),
		placeholder: 'Add location...',
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspLocation)
	@Default([])
	override locations!: BridgerPspLocation[];

	@Required()
	@Control(FieldControlType.chip, {
		list: BridgerPspIndustry.getList(),
		placeholder: 'Add industry...',
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspIndustry)
	@Default([])
	override industries!: BridgerPspIndustry[];

	@Default([])
	@ViewEmptyValue('Not used in any merchant')
	@Label('Merchants Participation')
	@Table()
	@Sortable()
	merchants!: INamedEntitySummary[];

	@Required()
	@Control(FieldControlType.url)
	@View(FieldViewType.link)
	override backofficeUrl!: string;

	@Default(null)
	override instructionGuide!: string | null;

	@Control(FieldControlType.url)
	@View(FieldViewType.link)
	@Default(null)
	override instructionGuideUrl!: string | null;

	@Control(FieldControlType.url)
	@View(FieldViewType.link)
	@Default(null)
	override instructionGuideVideoUrl!: string | null;

	@Required()
	@Control(FieldControlType.chip, {
		list: BridgerPspLocation
			.getList()
			.filter(location => location !== BridgerPspLocation.worldwide),
		placeholder: 'Add location...',
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspLocation)
	@Default([])
	override legalEntityLocations!: BridgerPspLocation[];

	@Control(FieldControlType.chip, {
		list: BridgerPspLicense.getList(),
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspLicense)
	@Default([])
	override licenses!: BridgerPspLicense[];

	@Control(FieldControlType.chip, {
		list: BridgerPspLegalEntityType.getList(),
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspLegalEntityType)
	@Default([])
	override legalEntityTypes!: BridgerPspLegalEntityType[];

	@Required()
	@Control(FieldControlType.chip, {
		list: BridgerPspProcessingHistory.getList(),
	})
	@View(FieldViewType.chip)
	@Hint('Amount of time company processing transactions electronically')
	@Mapper(BridgerPspProcessingHistory)
	@Default([])
	override processingHistories!: BridgerPspProcessingHistory[];

	@Required()
	@Control(FieldControlType.chip, {
		list: BridgerPspMonthlyTurnoverVolume.getList(),
	})
	@View(FieldViewType.chip)
	@Mapper(BridgerPspMonthlyTurnoverVolume)
	@Default([])
	override monthlyTurnoverVolumes!: BridgerPspMonthlyTurnoverVolume[];

	@Unserializable()
	readonly lowerCaseName!: string;

	@Unserializable()
	readonly lowerCaseInternalName!: string | null;

	@Unserializable()
	readonly paymentOptionByTypeMap: Map<PspPaymentOptionType, BridgerPspPaymentOptions>;

	/** Used for filtering this psp */
	@Unserializable()
	readonly paymentOptionsCurrenciesByTypeMap: Map<PspPaymentOptionType, FiatCurrency[]> | null;

	/** Used for filtering this psp */
	@Unserializable()
	readonly paymentCardBrands: PaymentCardBrand[] = [];

	@Unserializable()
	readonly locked: boolean;

	@Unserializable()
	readonly someOptionCanBeUsedInPaywith: boolean;

	constructor(dto?: DTO<BridgerPsp>) {
		super(dto);

		if (this.id) {
			this.lowerCaseName = this.name.toLowerCase();

			this.lowerCaseInternalName = this.internalName?.toLowerCase() ?? null;
		} else
			this.modified ||= moment(0);

		this.paymentOptionByTypeMap = new Map(this.paymentOptions.map(option => [ option.type, option ]));

		this.paymentOptionsCurrenciesByTypeMap = new Map(this.paymentOptions.map(option => [ option.type, option.currencies ]));

		this.paymentCardBrands = this.__extractPaymentCardBrands();

		this.locked = this.merchants.length > 0;

		this.someOptionCanBeUsedInPaywith = this.paymentOptions.some(option => this.checkOptionCanBeUsedInPaywith(option));

		this.__restoreScopesSorting();

	}

	checkOptionCanBeUsedInPaywith(option: BridgerPspPaymentOptions): boolean {
		return isInstanceOfSome(BridgerPspPaymentOptionCreditCard, BridgerPspPaymentOptionApm)(option)
			? option.canBeUsedInPaywith
			: false;
	}

	override toString(): string {
		return this.internalName ?? '';
	}

	private __restoreScopesSorting(): void {
		this.scopes = this.scopes
			.sort((a, b) => BridgerPspScope.getList().indexOf(a) - BridgerPspScope.getList().indexOf(b));
	}

	private __extractPaymentCardBrands(): PaymentCardBrand[] {
		return this.paymentOptions
			.filter(isInstanceOf(BridgerPspPaymentOptionCreditCard))
			.flatMap(option => option.brands);
	}

}
