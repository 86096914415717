import m, { Moment } from 'moment';

import {
	DTO, Entity, Default, Mapper, momentMapper, FieldViewType, View, Table, Required, Label, MapFromDTO,
	Control, FieldControlType, Sortable
} from '@bp/shared/models/metadata';
import { Member } from '@bp/shared/domains/members';
import { User } from '@bp/shared/domains/users';
import { Validators } from '@bp/shared/features/validation/models';

import { SupportRequestStatus } from './support-request-status.enum';
import { LoginEvent } from './login-event';

export class SupportRequest extends Entity {

	@Table()
	@Label('#')
	@MapFromDTO()
	ticketNumber!: number;

	@Table({ ellipsis: true })
	@Mapper(Member)
	@View(FieldViewType.personEmail)
	requestedBy!: Member;

	@Table()
	@Sortable({ isDefault: true })
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	override created!: Moment | null;

	@Table()
	@Sortable()
	@View(FieldViewType.momentFromNow)
	@Mapper(momentMapper)
	@Required()
	expiresAt!: Moment;

	@Table()
	@Sortable()
	@View(FieldViewType.momentFromNow)
	@Mapper(momentMapper)
	@Default(null)
	completedAt!: Moment | null;

	@Table({ ellipsis: true })
	@Mapper(User)
	@Default(null)
	@View(FieldViewType.personEmail)
	completedBy!: User | null;

	@Table({ ellipsis: true })
	@Default(null)
	@View(FieldViewType.textarea)
	message!: string | null;

	@MapFromDTO()
	organizationId!: string;

	@Table({ ellipsis: true })
	@Label('Organization')
	organizationName!: string;

	@Label('Subscription Plan')
	organizationSubscriptionPlan!: string;

	@Mapper(LoginEvent)
	@Default([])
	loginsToOrganizationHistory!: LoginEvent[];

	@Default(null)
	@View(FieldViewType.textarea)
	@Control(FieldControlType.textarea, {
		required: true,
		validator: Validators.compose([
			Validators.string,
			Validators.utf8Bmp,
			Validators.maxLength(65535),
		]),
	})
	resolveMessage!: string | null;

	get isExpired(): boolean {
		return m().isAfter(this.expiresAt);
	}

	get isCompleted(): boolean {
		return this.completedAt !== null;
	}

	get isPending(): boolean {
		return !this.isCompleted && !this.isExpired;
	}

	@Table()
	@View(FieldViewType.status)
	get status(): SupportRequestStatus {
		switch (true) {
			case this.isCompleted:
				return SupportRequestStatus.supportRequestCompleted;

			case this.isExpired:
				return SupportRequestStatus.supportRequestExpired;

			case this.isPending:
				return SupportRequestStatus.supportRequested;

			default:
				throw new Error('Invalid support request status');
		}
	}

	constructor(dto?: DTO<SupportRequest>) {
		super(dto);
	}

}
