import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isEmpty } from '@bp/shared/utilities/core';

import { PspPaymentOptionType } from '@bp/frontend/models/business';
import { BridgerPsp } from '@bp/frontend/domains/bridger-psps/core';

import type { IState } from './bridger-psps-shared.reducer';
import { FEATURE_KEY } from './bridger-psps-shared.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const selectAllBridgerPsps = createSelector(
	selectFeatureState,
	state => state.all
		?.filter(bridgerPsp => !!bridgerPsp.internalName)
		.sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectAllBridgerPspsByPspNameMap = createSelector(
	selectAllBridgerPsps,
	bridgerPsps => bridgerPsps && new Map(bridgerPsps.map(v => [ v.internalName!, v ])),
);

export const selectAllBridgerPspsByPaymentOptionTypeMap = createSelector(
	selectAllBridgerPsps,
	bridgerPsps => bridgerPsps && new Map(PspPaymentOptionType
		.getList()
		.map(optionType => <const>[
			optionType,
			bridgerPsps
				.filter(psp => psp.paymentOptionsTypes.includes(optionType))
				.map(psp => new BridgerPsp({
					...psp,
					paymentOptions: psp.paymentOptions.filter(option => option.type === optionType),
				})),
		])
		.filter(([ , psps ]) => !isEmpty(psps))),
);

export const selectAllBridgerPspsInternalNames = createSelector(
	selectAllBridgerPsps,
	bridgerPsps => bridgerPsps?.map(bridgerPsp => bridgerPsp.internalName) ?? null,
);

export const selectPending = createSelector(
	selectFeatureState,
	s => s.pending,
);

export const selectError = createSelector(
	selectFeatureState,
	s => s.error,
);
