import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { FADE_IN_FIXED_TRIGGERED } from '@bp/frontend/animations';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';
import { Destroyable } from '@bp/frontend/models/common';

import { VatConfigPerCountry } from '../../../../models';
import {
	SubscriptionPlanCheckoutPaymentInformationFormVm
} from '../../../../models/subscription-plan-checkout-payment-information-form-vm';

@Component({
	selector: 'bp-price-breakdown',
	templateUrl: './price-breakdown.component.html',
	styleUrls: [ './price-breakdown.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_FIXED_TRIGGERED ],
})
export class PriceBreakdownComponent extends Destroyable implements OnChanges {

	@Input() subscriptionPlan!: SubscriptionPlan;

	@Input() vatConfigPerCountry!: VatConfigPerCountry | null;

	@Input() chargePeriod = SubscriptionPlanChargePeriod.monthly;

	@Input() paymentInformation: SubscriptionPlanCheckoutPaymentInformationFormVm | null = null;

	@Input() currency!: FiatCurrency;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	countryVatPercent: number | null = null;

	countryVatAbbreviation: string | null = null;

	monthlyPrice: number | null = null;

	chargePeriodDiscount: number | null = null;

	priceWithoutDiscountAndTax: number | null = null;

	priceWithoutTax: number | null = null;

	vat: number | null = null;

	totalPrice: number | null = null;

	ngOnChanges({ subscriptionPlan, chargePeriod, currency }: SimpleChanges<this>): void {
		if (subscriptionPlan || chargePeriod || currency)
			this._updateBasePriceAndDiscountsBreakdown();

		this._updateVatAndTotalPriceBreakDown();
	}

	private _updateBasePriceAndDiscountsBreakdown(): void {
		this.priceWithoutTax = this.subscriptionPlan.prices
			?.get(this.chargePeriod)
			?.getPriceFor(this.currency) ?? null;

		this.chargePeriodDiscount = this.subscriptionPlan.prices?.get(this.chargePeriod)?.discount ?? null;

		this.monthlyPrice = this.subscriptionPlan.getMonthPriceFor(this.chargePeriod, this.currency);

		this.priceWithoutDiscountAndTax = this.subscriptionPlan.getMonthPriceFor(SubscriptionPlanChargePeriod.monthly, this.currency)
			* this.chargePeriod.monthsInPeriod;
	}

	private _updateVatAndTotalPriceBreakDown(): void {
		if (!this.vatConfigPerCountry)
			return;

		const countryVatConfig = this.paymentInformation?.country
			? this.vatConfigPerCountry[this.paymentInformation.country.code]
			: null;

		this.countryVatPercent = countryVatConfig?.vat ?? null;

		this.countryVatAbbreviation = countryVatConfig?.vatAbbreviation
			? `VAT (${ countryVatConfig.vatAbbreviation })`
			: 'VAT';

		const skipVat = this.paymentInformation?.vatId && !countryVatConfig?.alwaysAddVatToThePrice;

		this.vat = this.priceWithoutTax && this.countryVatPercent && !skipVat
			? this.priceWithoutTax * this.countryVatPercent
			: null;

		this.totalPrice = (this.priceWithoutTax ?? 0) + (this.vat ?? 0);
	}

}
