import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import { IIdentity } from '@bp/shared/domains/jwt-session';
import { Permission } from '@bp/shared/domains/permissions';

import { BpError } from '@bp/frontend/models/core';

import { IdentityActions } from './identity.actions';

export const IDENTITY_STATE_KEY = 'user';

export type IdentityState<TIidentity extends IIdentity> = {
	[IDENTITY_STATE_KEY]: TIidentity | null;

	organizationPermissions: Set<Permission> | null;

	hiddenOrganizationPermissions: Set<Permission> | null;

	urlForRedirectionAfterLogin: string | null;

	pending: boolean;

	error: BpError | null;

};

export const IDENTITY_INITIAL_STATE: IdentityState<any> = {
	[IDENTITY_STATE_KEY]: null,
	organizationPermissions: null,
	hiddenOrganizationPermissions: null,
	urlForRedirectionAfterLogin: null,
	pending: false,
	error: null,
};

export function composeIdentityReducer<
	TIidentity extends IIdentity,
	TLoginPayload,
	TState extends IdentityState<TIidentity>
>(initialState: TState, actions: IdentityActions<TIidentity, TLoginPayload>): ReducerTypes<TState, ActionCreator[]>[] {
	return [
		on(actions.resetState, () => <any>initialState),

		on(actions.setIdentity, (state, { identity }) => ({
			...state,
			user: identity,
		})),

		on(actions.removeIdentity, state => ({
			...state,
			user: null,
		})),

		on(actions.addOrganizationPermissions, (state, { permissions }) => ({
			...state,
			organizationPermissions: new Set([ ...state.organizationPermissions ?? [], ...permissions ]),
		})),

		on(actions.hideOrganizationPermissions, (state, { permissions }) => ({
			...state,
			hiddenOrganizationPermissions: new Set([ ...state.hiddenOrganizationPermissions ?? [], ...permissions ]),
		})),

		on(actions.login, state => ({
			...state,
			error: null,
			pending: true,
		})),

		on(actions.api.loginSuccess, actions.api.loginFailure, state => ({
			...state,
			pending: false,
		})),

		on(actions.api.loginFailure, (state, { error }) => ({
			...state,
			error,
		})),

		on(actions.saveUrlForRedirectionAfterLogin, (state, { url }) => ({
			...state,
			urlForRedirectionAfterLogin: url,
		})),

		on(actions.resetError, state => ({
			...state,
			error: null,
		})),
	];
}
