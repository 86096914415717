import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'entries',
})
export class EntriesPipe implements PipeTransform {

	transform(object: Object | null): [ string, any ][] {
		return object ? Object.entries(object) : [];
	}

}
