import { BridgerPspPaymentOptionApm } from './bridger-psp-payment-option-apm';
import { BridgerPspPaymentOptionCreditCard } from './bridger-psp-payment-option-credit-card';
import { BridgerPspPaymentOptionCrypto } from './bridger-psp-payment-option-crypto';
import { BridgerPspPaymentOptionExternal } from './bridger-psp-payment-option-external';
import { BridgerPspPaymentOptionWalletGooglePay } from './bridger-psp-payment-option-wallet-google-pay';
import { BridgerPspPaymentOptionWalletApplePay } from './bridger-psp-payment-option-wallet-apple-pay';
import { BridgerPspPaymentOptions } from './bridger-psp-payment-options-factory';
import { BridgerPspPaymentOptionPos } from './bridger-psp-payment-option-pos';
import { BridgerPspPaymentOptionWallet } from './bridger-psp-payment-option-wallet';

export class BridgerPspPaymentOptionsUtils {

	static isCreditCard(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionCreditCard {
		return paymentOption instanceof BridgerPspPaymentOptionCreditCard;
	}

	static isExternal(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionExternal {
		return paymentOption instanceof BridgerPspPaymentOptionExternal;
	}

	static isPos(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionPos {
		return paymentOption instanceof BridgerPspPaymentOptionPos;
	}

	static isWalletApplePay(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionWalletApplePay {
		return paymentOption instanceof BridgerPspPaymentOptionWalletApplePay;
	}

	static isWalletGooglePay(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionWalletGooglePay {
		return paymentOption instanceof BridgerPspPaymentOptionWalletGooglePay;
	}

	static isWallet(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionWallet {
		return paymentOption instanceof BridgerPspPaymentOptionWallet;
	}

	static isCrypto(paymentOption: BridgerPspPaymentOptions): paymentOption is BridgerPspPaymentOptionCrypto {
		return paymentOption instanceof BridgerPspPaymentOptionCrypto;
	}

	static isApm(paymentOption: BridgerPspPaymentOptions): paymentOption is BridgerPspPaymentOptionApm {
		return paymentOption instanceof BridgerPspPaymentOptionApm;
	}

	static canBeUsedInPaywith(
		paymentOption: BridgerPspPaymentOptions,
	): paymentOption is BridgerPspPaymentOptionApm | BridgerPspPaymentOptionCreditCard {
		return (this.isCreditCard(paymentOption) || this.isApm(paymentOption))
			? paymentOption.canBeUsedInPaywith
			: false;
	}

}
