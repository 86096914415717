import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';

import { PaymentCardBrandIconComponent } from '@bp/frontend/components/payment-card/brand';

@Component({
	selector: 'bp-payment-card-brand-icons',
	standalone: true,
	imports: [
		CommonModule,
		PaymentCardBrandIconComponent,
	],
	templateUrl: './payment-card-brands.component.html',
	styleUrls: [ './payment-card-brands.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandsComponent {

	@Input() brands?: PaymentCardBrand[];

}
