import { Moment } from 'moment';

import { DTO, Mapper, View, FieldViewType, MapFromDTO, MetadataEntity, Default, Label, momentMapper } from '@bp/shared/models/metadata';
import { UserShortInfo } from '@bp/shared/domains/users';
import { Dictionary } from '@bp/shared/typings';

export class AuditEventVm extends MetadataEntity {

	@MapFromDTO()
	id!: string;

	@Mapper(UserShortInfo)
	@View(FieldViewType.personEmail)
	actor?: UserShortInfo;

	@MapFromDTO()
	eventName!: string;

	@Label('Happened at')
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	timestamp!: Moment | null;

	@Default(null)
	payload!: Dictionary<any> | null;

	constructor(dto?: DTO<AuditEventVm>) {
		super(dto);
	}

}
