import { firstValueFrom, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { MerchantAdminFeature, FeaturePermission, Permission } from '@bp/shared/domains/permissions';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IIdentityGetAccessDialogGuard } from '@bp/frontend-domains-identity';

import {
	ResourceAccessOtpVerificationDialogData,
	ResourcePaywallDialogComponent,
	ResourcePaywallDialogData,
	ResourceAccessOtpVerificationDialogComponent
} from '../components';

@Injectable({
	providedIn: 'root',
})
export class IdentityGetAccessDialogGuard implements IIdentityGetAccessDialogGuard {

	private readonly __identityFacade = inject(IdentityFacade);

	private readonly __dialog = inject(MatDialog);

	shouldShowGetAccessDialog(permission: Permission): boolean {
		const featurePermission = this.__identityFacade.user!.featurePermissions.get(permission);

		// User doesn't have permission means it is organization permission.
		// They are synthetic, so don't show access dialog.
		if (!featurePermission)
			return false;

		return this.__identityFacade.whenOtpRequiredCheckFeatureAccessIsExpired(featurePermission)
			|| featurePermission.isBehindPaywall;
	}

	async getAccess(permission: Permission): Promise<boolean> {
		let featurePermission = this.__identityFacade.user!.featurePermissions.get(permission)!;

		let hasAccess = true;

		hasAccess = featurePermission.isBehindPaywall
			? await this.__showPaywallDialogAndWaitForResult()
			: hasAccess;

		if (!hasAccess)
			return false;

		// after paywall dialog we need to get the featurePermission again
		featurePermission = this.__identityFacade.user!.featurePermissions.get(permission)!;

		hasAccess = this.__identityFacade.whenOtpRequiredCheckFeatureAccessIsExpired(featurePermission)
			? await this.__showOtpVerificationDialogAndWaitForResult(featurePermission)
			: hasAccess;

		return hasAccess;
	}

	private async __showOtpVerificationDialogAndWaitForResult(
		featurePermission: FeaturePermission<MerchantAdminFeature>,
	): Promise<boolean> {
		return firstValueFrom(
			this.__dialog
				.open<ResourceAccessOtpVerificationDialogComponent, ResourceAccessOtpVerificationDialogData, boolean>(
				ResourceAccessOtpVerificationDialogComponent,
				{
					data: {
						featurePermission,
					},
				},
			)
				.afterClosed()
				.pipe(map(result => !!result)),
		);
	}

	private async __showPaywallDialogAndWaitForResult(): Promise<boolean> {
		return firstValueFrom(
			this.__dialog
				.open<ResourcePaywallDialogComponent, ResourcePaywallDialogData, boolean>(
				ResourcePaywallDialogComponent,
			)
				.afterClosed()
				.pipe(map(result => !!result)),
		);
	}
}
