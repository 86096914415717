import {
	DTO, FieldControlType, MetadataEntity, Property, PropertyDecoratorConfig, PropertyMetadataControl
} from '@bp/shared/models/metadata';

export type CheckoutUrlsDTO = DTO<CheckoutUrls>;

export const baseUrlPropertyMetadata: PropertyDecoratorConfig = {
	control: new PropertyMetadataControl({
		type: FieldControlType.url,
	}),
	// Note that backend will break if we pass `null` as value, so we have to pass empty string instead
	defaultPropertyValue: '',
};

export const webhookUrlPropertyMetadata: PropertyDecoratorConfig = {
	...baseUrlPropertyMetadata,
	label: 'Webhook Notification URL',
	hint: 'A webhook URL is a unique endpoint that allows external systems to send automated HTTP requests to a specific application or service for real-time notifications or data updates',
};

export const successRedirectUrlPropertyMetadata: PropertyDecoratorConfig = {
	...baseUrlPropertyMetadata,
	label: 'Success Redirect URL',
	hint: 'URL of the web page where the user will be redirected after a successful payment',
};

export const cancelRedirectUrlPropertyMetadata: PropertyDecoratorConfig = {
	...baseUrlPropertyMetadata,
	label: 'Cancel Redirect URL',
	hint: 'URL of the web page where the user will be redirected after a canceled payment',
};

export const failurePropertyMetadata: PropertyDecoratorConfig = {
	...baseUrlPropertyMetadata,
	label: 'Failure Redirect URL',
	hint: 'URL of the web page where the user will be redirected after a failed payment',
};

export class CheckoutUrls extends MetadataEntity {

	@Property(webhookUrlPropertyMetadata)
	readonly notification!: string;

	@Property(successRedirectUrlPropertyMetadata)
	readonly success!: string;

	@Property(cancelRedirectUrlPropertyMetadata)
	readonly cancel!: string;

	@Property(failurePropertyMetadata)
	readonly failure!: string;

}
