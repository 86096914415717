import { Moment } from 'moment';

import { Action, createReducer, on } from '@ngrx/store';

import { OtpProvider } from '@bp/shared/domains/jwt-session';
import { FeaturePermission } from '@bp/shared/domains/permissions';

import { Identity, SecurityQuestion } from '@bp/admins-shared/domains/identity/models';

import {
	IdentityState, IDENTITY_STATE_KEY, composeIdentityReducer, IDENTITY_INITIAL_STATE
} from '@bp/frontend-domains-identity';

import {
	createAccountFailure, createAccountSuccess, loadAllSecurityQuestionsFailure, loadAllSecurityQuestionsSuccess, loadAuthenticatorAppKeyFailure, loadAuthenticatorAppKeySuccess, loadIdentitySecurityQuestionsFailure, loadIdentitySecurityQuestionsSuccess, refreshTokenSuccess, registerAuthenticatorFailure, registerAuthenticatorSuccess, resetAuthenticatorAppFailure, resetAuthenticatorAppSuccess, resetPasswordFailure, resetPasswordOtpVerificationFailure, resetPasswordOtpVerificationSuccess, resetPasswordSuccess, sendResetAuthenticatorAppLinkFailure, sendResetAuthenticatorAppLinkSuccess, sendResetPasswordLinkFailure, sendResetPasswordLinkSuccess, setSecurityQuestionsAnswersFailure, setSecurityQuestionsAnswersSuccess, acceptInviteFailure, acceptInviteSuccess, loginOtpVerificationFailure, loginOtpVerificationSuccess, verifySecurityQuestionsAnswersFailure, verifySecurityQuestionsAnswersSuccess, featureAccessOtpVerificationSuccess, featureAccessOtpVerificationFailure, IDENTITY_API_ACTIONS, generateLoginOtpSuccess, generateFeatureAccessOtpSuccess, generateLoginOtpFailure, generateFeatureAccessOtpFailure
} from './identity-api.actions';
import {
	IDENTITY_ACTIONS, acceptInvite, createAccount, loadAllSecurityQuestions, setSecurityQuestionsAnswers, loadAuthenticatorAppKey, registerAuthenticatorApp, resetPassword, resetPasswordOtpVerification, verifySecurityQuestionsAnswers, loginOtpVerification, resetAuthenticatorApp, sendResetAuthenticatorAppLink, sendResetPasswordLink, featureAccessOtpVerification, generateLoginOtp, generateFeatureAccessOtp,
	updateFeatureAccessExpirationsMap
} from './identity.actions';
import { setIncompleteIdentity, setIncompleteIdentityBasedOnLoginQueryParams } from './incomplete-identity.actions';

export const FEATURE_STATE_KEY = '[domain]:identity';

export const IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY = 'featureAccessExpirationsMap';

export interface IState extends IdentityState<Identity> {

	incompleteIdentity: Identity | null;

	allSecurityQuestions: SecurityQuestion[] | null;

	identitySecurityQuestions: SecurityQuestion[] | null;

	authenticatorKey: string | null;

	otpProvider: OtpProvider | null;

	otpExpiresAt: Moment | null;

	[IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY]: Map<FeaturePermission<any>, Moment>;

}

export const initialState: IState = {
	...IDENTITY_INITIAL_STATE,

	incompleteIdentity: null,

	allSecurityQuestions: null,

	identitySecurityQuestions: null,

	authenticatorKey: null,

	otpProvider: null,

	otpExpiresAt: null,

	[IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY]: new Map(),
};

const identityReducer = createReducer(
	initialState,

	...composeIdentityReducer(initialState, IDENTITY_ACTIONS),

	on(
		refreshTokenSuccess,
		(state, { result }): IState => ({
			...state,
			user: result,
		}),
	),

	on(
		updateFeatureAccessExpirationsMap,
		(state, { featureAccessExpirationsMap }): IState => ({
			...state,
			featureAccessExpirationsMap,
		}),
	),

	on(
		setIncompleteIdentityBasedOnLoginQueryParams,
		setIncompleteIdentity,
		(state, { identity }): IState => ({
			...state,
			incompleteIdentity: identity,
			user: null,
		}),
	),

	on(
		acceptInvite,
		createAccount,
		resetPassword,
		resetAuthenticatorApp,
		resetPasswordOtpVerification,
		sendResetAuthenticatorAppLink,
		sendResetPasswordLink,
		loadAllSecurityQuestions,
		setSecurityQuestionsAnswers,
		loadIdentitySecurityQuestionsSuccess,
		verifySecurityQuestionsAnswers,
		loadAuthenticatorAppKey,
		registerAuthenticatorApp,
		loginOtpVerification,
		featureAccessOtpVerification,
		generateLoginOtp,
		generateFeatureAccessOtp,
		(state): IState => ({
			...state,
			error: null,
			pending: true,
		}),
	),

	on(
		acceptInviteSuccess,
		createAccountSuccess,
		resetPasswordSuccess,
		resetPasswordOtpVerificationSuccess,
		resetAuthenticatorAppSuccess,
		setSecurityQuestionsAnswersSuccess,
		verifySecurityQuestionsAnswersSuccess,
		registerAuthenticatorSuccess,
		loginOtpVerificationSuccess,
		(state, { result }): IState => ({
			...state,
			incompleteIdentity: result.isIncomplete ? result : null,
			user: result.isIncomplete ? null : result,
		}),
	),

	on(
		acceptInviteSuccess,
		acceptInviteFailure,
		createAccountSuccess,
		createAccountFailure,
		resetPasswordSuccess,
		resetPasswordFailure,
		resetPasswordOtpVerificationSuccess,
		resetPasswordOtpVerificationFailure,
		resetAuthenticatorAppSuccess,
		resetAuthenticatorAppFailure,
		loadAllSecurityQuestionsSuccess,
		loadAllSecurityQuestionsFailure,
		setSecurityQuestionsAnswersSuccess,
		setSecurityQuestionsAnswersFailure,
		loadIdentitySecurityQuestionsSuccess,
		loadIdentitySecurityQuestionsFailure,
		verifySecurityQuestionsAnswersSuccess,
		verifySecurityQuestionsAnswersFailure,
		loadAuthenticatorAppKeySuccess,
		loadAuthenticatorAppKeyFailure,
		registerAuthenticatorSuccess,
		registerAuthenticatorFailure,
		loginOtpVerificationSuccess,
		loginOtpVerificationFailure,
		featureAccessOtpVerificationSuccess,
		featureAccessOtpVerificationFailure,
		sendResetPasswordLinkSuccess,
		sendResetPasswordLinkFailure,
		sendResetAuthenticatorAppLinkSuccess,
		sendResetAuthenticatorAppLinkFailure,
		generateLoginOtpSuccess,
		generateLoginOtpFailure,
		generateFeatureAccessOtpSuccess,
		generateFeatureAccessOtpFailure,
		(state): IState => ({
			...state,
			pending: false,
		}),
	),

	on(
		acceptInviteFailure,
		createAccountFailure,
		resetPasswordFailure,
		resetPasswordOtpVerificationFailure,
		resetAuthenticatorAppFailure,
		loadAllSecurityQuestionsFailure,
		setSecurityQuestionsAnswersFailure,
		loadIdentitySecurityQuestionsFailure,
		verifySecurityQuestionsAnswersFailure,
		loadAuthenticatorAppKeyFailure,
		registerAuthenticatorFailure,
		loginOtpVerificationFailure,
		featureAccessOtpVerificationFailure,
		sendResetPasswordLinkFailure,
		sendResetAuthenticatorAppLinkFailure,
		generateLoginOtpFailure,
		generateFeatureAccessOtpFailure,
		(state, { error }): IState => ({
			...state,
			error,
		}),
	),

	on(
		loadAllSecurityQuestionsSuccess,
		(state, { result }): IState => ({
			...state,
			allSecurityQuestions: result,
		}),
	),

	on(
		loadIdentitySecurityQuestionsSuccess,
		(state, { result }): IState => ({
			...state,
			identitySecurityQuestions: result,
		}),
	),

	on(
		loadAuthenticatorAppKeySuccess,
		(state, { result }): IState => ({
			...state,
			authenticatorKey: result,
		}),
	),

	on(
		IDENTITY_ACTIONS.effectsInit,
		(state): IState => ({
			...state,
			otpProvider: state[IDENTITY_STATE_KEY]?.otpProvider ?? null,
		}),
	),

	on(
		IDENTITY_API_ACTIONS.loginSuccess,
		(state, { result }): IState => ({
			...state,
			otpProvider: result.otpProvider,
		}),
	),

	on(
		IDENTITY_API_ACTIONS.loginSuccess,
		generateLoginOtpSuccess,
		generateFeatureAccessOtpSuccess,
		(state, { result }): IState => ({
			...state,
			otpExpiresAt: result.otpExpiresAt,
		}),
	),

	on(
		loginOtpVerificationSuccess,
		featureAccessOtpVerificationSuccess,
		(state): IState => ({
			...state,
			otpExpiresAt: null,
		}),
	),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return identityReducer(state, action);
}
