import type { Moment } from 'moment';

import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';
import { FeaturePermission } from '@bp/shared/domains/permissions';

import {
	CreateAccountApiRequest, Identity, ILoginApiRequest, ILoginOtpVerificationApiRequest,
	IRegisterAuthenticatorAppApiRequest, IResetPasswordApiRequest, IResetPasswordOtpVerificationApiRequest, IFeatureAccessOtpVerificationApiRequest, ISendResetPasswordLinkApiRequest, ISetSecurityQuestionsAnswersApiRequest, IVerifySecurityQuestionsAnswersApiRequest
} from '@bp/admins-shared/domains/identity/models';

import type { IdentityActions } from '@bp/frontend-domains-identity';
import { composeIdentityActions, IDENTITY_STATE_SCOPE as SCOPE } from '@bp/frontend-domains-identity';

import { IDENTITY_API_ACTIONS } from './identity-api.actions';

export const IDENTITY_ACTIONS: IdentityActions<Identity, ILoginApiRequest> = {
	...composeIdentityActions(),

	api: IDENTITY_API_ACTIONS,
};

export const localStorageIdentityChanged = createAction(
	`${ SCOPE } Local Storage Identity Changed`,
	props<{ identity: Identity | null }>(),
);

export const startIdentitySessionExpiryTimer = createAction(
	`${ SCOPE } Start Identity Session Expiry Timer`,
	props<{ expiresAt: Moment }>(),
);

export const stopIdentitySessionExpiryTimer = createAction(
	`${ SCOPE } Stop Identity Session Expiry Timer`,
);

export const showIdentitySessionIsAboutToExpireDialog = createAction(
	`${ SCOPE } Show Identity Session Is About To Expire Dialog`,
);

export const refreshAccessToken = createAction(
	`${ SCOPE } Refresh Access Token`,
);

export const sessionExpired = createAction(
	`${ SCOPE } Session Expired`,
);

export const setIdentityBasedOnLoginQueryParams = createAction(
	`${ SCOPE } Set Identity Based On Login Query Params`,
	props<{ identity: Identity }>(),
);

export const generateLoginOtp = createAction(
	`${ SCOPE } Generate Login OTP`,
);

export const loginOtpVerification = createAction(
	`${ SCOPE } Login OTP Verification ${ TELEMETRY_HIDDEN_MARK }`,
	props<ILoginOtpVerificationApiRequest>(),
);

export const generateFeatureAccessOtp = createAction(
	`${ SCOPE } Generate Feature Access OTP`,
	props<{ permission: FeaturePermission<any> }>(),
);

export const featureAccessOtpVerification = createAction(
	`${ SCOPE } Feature Access OTP Verification ${ TELEMETRY_HIDDEN_MARK }`,
	props<IFeatureAccessOtpVerificationApiRequest>(),
);

// #region Signup via link

export const acceptInvite = createAction(
	`${ SCOPE } Accept Invite`,
);

export const createAccount = createAction(
	`${ SCOPE } Create Account ${ TELEMETRY_HIDDEN_MARK }`,
	props<CreateAccountApiRequest>(),
);

// #endregion

// #region Continue Signup

export const loadAllSecurityQuestions = createAction(
	`${ SCOPE } Load All Security Questions`,
);

export const setSecurityQuestionsAnswers = createAction(
	`${ SCOPE } Set Security Questions Answers ${ TELEMETRY_HIDDEN_MARK }`,
	props<ISetSecurityQuestionsAnswersApiRequest>(),
);

export const loadAuthenticatorAppKey = createAction(
	`${ SCOPE } Load Authenticator App Key`,
);

export const registerAuthenticatorApp = createAction(
	`${ SCOPE } Register Authenticator App ${ TELEMETRY_HIDDEN_MARK }`,
	props<IRegisterAuthenticatorAppApiRequest>(),
);

// #endregion

// #region Reset

export const loadIdentitySecurityQuestions = createAction(
	`${ SCOPE } Load Identity Security Questions`,
);

export const verifySecurityQuestionsAnswers = createAction(
	`${ SCOPE } Verify Security Questions Answers ${ TELEMETRY_HIDDEN_MARK }`,
	props<IVerifySecurityQuestionsAnswersApiRequest>(),
);

// #region Reset Password

export const sendResetPasswordLink = createAction(
	`${ SCOPE } Send Reset Password Link`,
	props<ISendResetPasswordLinkApiRequest>(),
);

export const resetPasswordOtpVerification = createAction(
	`${ SCOPE } Reset Password Otp Verification`,
	props<IResetPasswordOtpVerificationApiRequest>(),
);

export const resetPassword = createAction(
	`${ SCOPE } Reset Password`,
	props<IResetPasswordApiRequest & { isExpiredPasswordReset: boolean }>(),
);

// #endregion

// #region Reset Authenticator App

export const sendResetAuthenticatorAppLink = createAction(
	`${ SCOPE } Send Reset Authenticator App Link`,
);

export const resetAuthenticatorApp = createAction(
	`${ SCOPE } Reset Authenticator App`,
	props<IRegisterAuthenticatorAppApiRequest>(),
);

// #endregion

// #endregion

export const updateFeatureAccessExpirationsMap = createAction(
	`${ SCOPE } Update Feature Access Expirations Map`,
	props<{ featureAccessExpirationsMap: Map<FeaturePermission<any>, Moment> }>(),
);
