<bp-input
	[name]="name"
	[color]="color"
	[label]="label"
	[hint]="hint"
	[required]="!!required"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[nativeAutocomplete]="false"
	[autocomplete]="autocomplete"
	[throttle]="throttle"
	[debounce]="debounce"
	[appearance]="appearance"
	[hideErrorText]="hideErrorText"
	[floatLabel]="floatLabel"
	[hasSearchIcon]="hasSearchIcon"
/>

<mat-autocomplete #autocomplete="matAutocomplete" class="psps-autocomplete-panel">
	<div *ngIf="autocomplete.isOpen" @fadeInListStaggered>
		<div *ngFor="let providerName of filtered$ | async | take: 15">
			<mat-option
				*bpDelayedRender="0; allStagger: true"
				class="psp-selector-option"
				[class.selected]="providerName === value"
				[value]="providerName"
			>
				<bp-psp-thumbnail [name]="providerName" [size]="30" />
				<span>{{ providerName }}</span>
			</mat-option>
		</div>
	</div>

	<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
	<mat-option class="hidden" disabled />
</mat-autocomplete>
