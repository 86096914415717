import type { DTO } from '@bp/shared/models/metadata';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionBaseSummary } from './bridger-psp-payment-option-base-summary';

export class BridgerPspPaymentOptionWalletApplePaySummary extends BridgerPspPaymentOptionBaseSummary {

	static override readonly type = PspPaymentOptionType.walletApplePay;

	static {
		this._initClassMetadata();
	}

	readonly type = BridgerPspPaymentOptionWalletApplePaySummary.type;

	constructor(dto: DTO<BridgerPspPaymentOptionWalletApplePaySummary>) {
		super(dto);
	}

}
