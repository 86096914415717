import { without } from 'lodash-es';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { FiatCurrency, ICurrency, ANY_CURRENCY_STUB, CryptoCurrency, ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER, ANY_CRYPTO_CURRENCY_RESET_OPTION_TEXT } from '@bp/shared/models/currencies';
import type { INamedEntitySummary } from '@bp/shared/models/core';
import { Countries, Country, countryMapper } from '@bp/shared/models/countries';
import {
	Control, Default, Entity, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Required,
	Unserializable, View, ViewEmptyValue, DTO, Secret, Copyable
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { Dictionary } from '@bp/shared/typings';
import { isEmpty } from '@bp/shared/utilities/core';

import { PayoutMethodType } from '../enums';

export type PspCredentialKeys = NonFunctionPropertyNames<PspCredential>;

export class PspCredential extends Entity {

	@Label('PSP Credential ID')
	@Copyable()
	override id!: string;

	@MapFromDTO()
	pspId!: string;

	@Control(FieldControlType.input)
	override name!: string;

	@Control(FieldControlType.switch)
	@Label('Status')
	@View(FieldViewType.booleanCircle)
	@Default(true)
	override isEnabled!: boolean;

	@Control(FieldControlType.switch)
	@Label('Used for payouts')
	@Hint('This credential details will be used also for paying out.')
	@View(FieldViewType.boolean)
	allowPayouts!: boolean;

	@Mapper(PayoutMethodType)
	@Control(FieldControlType.select, {
		list: without(PayoutMethodType.getList(), PayoutMethodType.none),
		required: true,
	})
	@Default(null)
	payoutMethodType!: PayoutMethodType | null;

	@Control(FieldControlType.country, {
		typeControlOptions: {
			hasWorldwide: true,
		},
	})
	@Mapper(countryMapper)
	@View(FieldViewType.country)
	@Required()
	@Default(Countries.worldwide)
	country!: Country;

	@Mapper(FiatCurrency)
	@Default(null)
	currency!: FiatCurrency | null;

	@View(FieldViewType.currencyCode)
	@Control(FieldControlType.autocomplete)
	@Label('Currency')
	@Required()
	get anyMappedCurrency(): ICurrency {
		return this.currency ?? ANY_CURRENCY_STUB;
	}

	set anyMappedCurrency(value: ICurrency) {
		this.currency = value === ANY_CURRENCY_STUB ? null : <FiatCurrency>value;
	}

	/**
	 * Shown for Crypto Payment Option Credential
	 */

	@View(FieldViewType.boxedItems)
	@Control(FieldControlType.chip, {
		placeholder: ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER,
		typeControlOptions: {
			resetOptionText: ANY_CRYPTO_CURRENCY_RESET_OPTION_TEXT,
		},
	})
	@Mapper(CryptoCurrency)
	@ViewEmptyValue('Any')
	@Default(null)
	cryptoCurrencies!: CryptoCurrency[] | null;

	/**
	 * Shown for Credit Card Payment Option Credential
	 */
	@Control(FieldControlType.brandChips, {
		placeholder: 'Add brand...',
		typeControlOptions: {
			resetOptionText: 'Any brand',
		},
	})
	@Mapper(PaymentCardBrand)
	@ViewEmptyValue('Any')
	@Default(null)
	brands!: PaymentCardBrand[] | null;

	@Default({})
	@Secret()
	metadata!: Dictionary<Object[] | boolean | string>;

	@Default([])
	@ViewEmptyValue('Not attached to any route')
	@Label('Payment routes participation')
	paymentRoutes!: INamedEntitySummary[];

	@Label('API url')
	@Required()
	apiUrl!: string;

	@Unserializable()
	readonly isWorldwide: boolean;

	@Unserializable()
	readonly isUsedInPaymentRoutes: boolean;

	constructor(dto?: DTO<PspCredential>) {
		super(dto);

		this.isWorldwide = this.country === Countries.worldwide;

		this.isUsedInPaymentRoutes = this.paymentRoutes.length > 0;

		this.brands = isEmpty(this.brands) ? null : this.brands;
	}

}
