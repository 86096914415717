import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, Renderer2, HostListener, inject } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { CountryControlOptions, FieldControlType, PropertyMetadata } from '@bp/shared/models/metadata';
import type {
	AutocompleteControlOptions, ChipControlOptions, InputBasedControlOptions, PropertyMetadataControl, SelectControlOptions
} from '@bp/shared/models/metadata';
import type { TextMaskConfigInput } from '@bp/shared/features/text-mask';
import { isEmpty } from '@bp/shared/utilities/core';

import type { OnChanges, SimpleChange, SimpleChanges } from '@bp/frontend/models/core';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { FormFieldAppearance } from '@bp/frontend/components/core';
import { AutocompleteOptionDirective, ChipDirective, SelectOptionDirective } from '@bp/frontend/components/controls';
import { ActionConfirmDialogComponent, ActionConfirmDialogData } from '@bp/frontend/components/dialogs';

@Component({
	selector: 'bp-property-metadata-control',
	templateUrl: './property-metadata-control.component.html',
	styleUrls: [ './property-metadata-control.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyMetadataControlComponent extends Destroyable implements OnChanges {

	private readonly __renderer = inject(Renderer2);

	private readonly __dialog = inject(MatDialog);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	FieldControlType = FieldControlType;

	@Input() metadata!: PropertyMetadata;

	@Input() control!: UntypedFormControl;

	@Input() appearance?: FormFieldAppearance;

	@Input() floatLabel?: FloatLabelType;

	@Input() label?: string | null;

	@Input() color?: ThemePalette;

	@Input() pending?: boolean;

	@Input() disabled?: boolean;

	@Input() items?: any[] | null;

	@Input() textMask?: TextMaskConfigInput | null;

	@ContentChild(SelectOptionDirective)
	protected _customSelectOption?: SelectOptionDirective;

	@ContentChild(ChipDirective)
	protected _customChip?: ChipDirective;

	@ContentChild(AutocompleteOptionDirective)
	protected _customAutocompleteOption?: AutocompleteOptionDirective;

	private _initialControlValidator: ValidatorFn | null = null;

	get controlMetadata(): PropertyMetadataControl<unknown> {
		return this.metadata.control;
	}

	get $$items(): any[] {
		return this.items ?? this.controlMetadata.list;
	}

	get $$textMask(): TextMaskConfigInput | null | undefined {
		return this.textMask ?? this.$$inputControlOptions.textMask;
	}

	get $$inputControlOptions(): InputBasedControlOptions {
		return this.getControlOptions(FieldControlType.input);
	}

	get $$countryControlOptions(): CountryControlOptions {
		return this.getControlOptions(FieldControlType.country);
	}

	get $$chipControlOptions(): ChipControlOptions {
		return this.getControlOptions(FieldControlType.chip);
	}

	get $$autocompleteControlOptions(): AutocompleteControlOptions {
		return this.getControlOptions(FieldControlType.autocomplete);
	}

	get $$selectControlOptions(): SelectControlOptions {
		return this.getControlOptions(FieldControlType.select);
	}

	get controlLabel(): string | null | undefined {
		return this.label === null
			? ''
			: (this.label ?? this.metadata.label);
	}

	isControlTypeInputLike = false;

	isControlTypeInputChipLike = false;

	isControlTypeChipLike = false;

	private __pristineAndDirtyCheckSubscription: Subscription | null = null;

	private __controlValueTransformerSubscription: Subscription | null = null;

	ngOnChanges({ metadata, control, disabled }: SimpleChanges<this>): void {
		if (metadata) {
			this._setHostClass(metadata);

			this.isControlTypeInputLike = FieldControlType.inputLike.includes(<any> this.controlMetadata.type);

			this.isControlTypeInputChipLike = FieldControlType.inputChipLike.includes(this.controlMetadata.type);

			this.isControlTypeChipLike = FieldControlType.chipLike.includes(this.controlMetadata.type);
		}

		if (control)
			this._initialControlValidator = this.control.validator;

		if ((metadata || control) && (this.metadata.control.validator || this._initialControlValidator))
			this.__addValidatorsOrPlanUpdatingOnPristineOrDirty(metadata);

		if (metadata || control)
			this.__onValueChangeApplyTransformers();

		if (disabled)
			this.disabled ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
	}

	getControlOptions<TControlOptions>(_type: FieldControlType<TControlOptions>): TControlOptions {
		return <TControlOptions>({
			...this.controlMetadata.type.options,
			...<object> this.controlMetadata.typeControlOptions,
		});
	}

	private _setHostClass({ previousValue, currentValue }: SimpleChange<this[ 'metadata' ]>): void {
		previousValue && this.__renderer.removeClass(this.__$host, this._getHostClass(previousValue));

		currentValue && this.__renderer.addClass(this.__$host, this._getHostClass(currentValue));
	}

	private _getHostClass(md: PropertyMetadata): string {
		return `control-type-${ md.control.type.cssClass }`;
	}

	private __addValidatorsOrPlanUpdatingOnPristineOrDirty(metadataChange?: SimpleChange<PropertyMetadata>): void {
		if (metadataChange
			&& metadataChange.currentValue !== metadataChange.previousValue
			&& metadataChange.previousValue?.control.validator)
			this.control.removeValidators(metadataChange.previousValue.control.validator);

		if (this.controlMetadata.isSecret)
			this._onSecretControlPristineOrDirtyUpdateValidators();
		else if (this.metadata.control.validator)
			this.control.addValidators(this.metadata.control.validator);
	}

	private _onSecretControlPristineOrDirtyUpdateValidators(): void {
		this.__pristineAndDirtyCheckSubscription?.unsubscribe();

		this.__pristineAndDirtyCheckSubscription = this.control.valueChanges
			.pipe(
				startWith(null),
				map(() => this.control.pristine),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(isPristine => {
				if (isPristine)
					this.control.clearValidators();
				else {
					this.control.setValidators([
						...(this._initialControlValidator ? [ this._initialControlValidator ] : []),
						...(this.metadata.control.validator ? [ this.metadata.control.validator ] : []),
					]);
				}

				this.control.updateValueAndValidity();
			});
	}

	private __onValueChangeApplyTransformers(): void {
		this.__controlValueTransformerSubscription?.unsubscribe();

		this.__controlValueTransformerSubscription = this.control.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe((value: any) => {
				if (!this.controlMetadata.controlValueTransformer)
					return;

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const transformedValue = this.controlMetadata.controlValueTransformer(value);

				if (transformedValue !== value)
					this.control.setValue(transformedValue, { emitEvent: false });
			});
	}

	@HostListener('focusin', [ '$event' ])
	protected _showWarningDialogIfMetadataHasWarning(event: FocusEvent): void {
		if (isEmpty(this.controlMetadata.warning) || !(<HTMLElement | null>event.relatedTarget)?.contains(this.__$host))
			return;

		this.__dialog
			.open<ActionConfirmDialogComponent<ActionConfirmDialogData>, ActionConfirmDialogData>(ActionConfirmDialogComponent, {
			data: {
				kind: 'acknowledge',
				title: 'Warning!',
				descriptionText: this.controlMetadata.warning,
				color: 'warn',
			},
		});

	}

}
