import { isEmpty, isNumber } from 'lodash-es';
import { Moment } from 'moment';

import {
	AliasFor, Default, DTO, MapFromDTO, Mapper, MetadataEntity, momentMapper
} from '@bp/shared/models/metadata';
import { JwtPayload, JwtToken } from '@bp/shared/utilities/core';
import {
	BridgerAdminFeature, CheckoutFeature, Feature, FeaturePermissions, featurePermissionsMapFactory, MerchantAdminFeature
} from '@bp/shared/domains/permissions';

type PermissionsJwtSessionDTO = Omit<PermissionsJwtSession<Feature>, 'permissions'> & { permissions: string[] };

export type BaseJwtSessionDTO = DTO<JwtPayload & PermissionsJwtSessionDTO>;

export abstract class PermissionsJwtSession<TFeature extends Feature> extends MetadataEntity {

	protected static _parseJWT(jwt: string): Record<string, unknown> {
		const decoded = JwtToken.decode<BaseJwtSessionDTO>(jwt);

		if (isEmpty(decoded.permissions))
			throw new Error('The user doesn\'t have any permissions');

		if (!isNumber(decoded.exp))
			throw new Error('The user session JWT must contain expiration timestamp');

		return decoded;
	}

	@Default(new Map())
	@Mapper(featurePermissionsMapFactory(
		claim => CheckoutFeature.parse(claim)
			?? BridgerAdminFeature.parse(claim)
			?? MerchantAdminFeature.parse(claim),
	))
	permissions!: FeaturePermissions<TFeature>;

	@MapFromDTO()
	id!: string;

	@MapFromDTO()
	exp!: number;

	@Mapper(momentMapper)
	@AliasFor('iat', {
		serializeAliasSourceProperty: true,
		serializeAliasProperty: false,
	})
	issuedAt!: Moment;

	constructor(dto?: BaseJwtSessionDTO) {
		super(dto);
	}

	hasPermission(permission: Parameters<typeof this.permissions['has']>[0]): boolean {
		return this.permissions.has(permission);
	}

	toJSON(): DTO<PermissionsJwtSessionDTO> {
		return {
			...this,
			permissions: [ ...this.permissions.values() ].map(({ claim }) => claim),
		};
	}
}
