import { Enumeration } from '@bp/shared/models/core/enum';

export class ClaimLifetimeType extends Enumeration {

	static readonly singleUse = new ClaimLifetimeType();

	static readonly shortTtl = new ClaimLifetimeType();

	static readonly longTtl = new ClaimLifetimeType();

	get isSingleUse(): boolean {
		return this === ClaimLifetimeType.singleUse;
	}

	get isShortTtl(): boolean {
		return this === ClaimLifetimeType.shortTtl;
	}

	get isLongTtl(): boolean {
		return this === ClaimLifetimeType.longTtl;
	}
}
