import { createAction, props } from '@ngrx/store';

import { IdentifiableEntity } from '@bp/shared/models/metadata';

import { ErrorActionPayload, RecordsPageResultActionPayload } from '@bp/frontend/models/common';

import { EntitiesApiActions } from './entities-api.actions';

export function composeEntitiesListApiActions<TEntity extends IdentifiableEntity>(
	scope: string,
): EntitiesApiActions<TEntity> {
	return {
		loadSuccess: createAction(
			`${ scope } Load Success`,
			props<RecordsPageResultActionPayload<TEntity>>(),
		),

		loadFailure: createAction(
			`${ scope } Load Failure`,
			props<ErrorActionPayload>(),
		),

		loadAllSuccess: createAction(
			`${ scope } Load All Success`,
			props<RecordsPageResultActionPayload<TEntity>>(),
		),

		loadAllFailure: createAction(
			`${ scope } Load All Failure`,
			props<ErrorActionPayload>(),
		),
	};
}
