import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Checkout } from '@bp/frontend/domains/checkout/models';

import { EntitiesInMemoryPagedListFacade } from '@bp/admins-shared/features/entity';

import { CheckoutsQueryParams } from '../../models';

import { CHECKOUTS_LIST_ACTIONS } from './checkouts.actions';
import { CHECKOUTS_LIST_SELECTORS } from './checkouts.selectors';
import { IState } from './checkouts.feature';

@Injectable({
	providedIn: 'root',
})
export class CheckoutsFacade extends EntitiesInMemoryPagedListFacade<Checkout, IState, CheckoutsQueryParams> {
	readonly actions = CHECKOUTS_LIST_ACTIONS;

	readonly selectors = CHECKOUTS_LIST_SELECTORS;

	queryParamsFactory(params: Params): CheckoutsQueryParams {
		return new CheckoutsQueryParams(params);
	}
}
