import { FiatCurrency } from '@bp/shared/models/currencies';
import { DTO, MapFromDTO, Mapper, Required } from '@bp/shared/models/metadata';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { SubscriptionPlanCheckoutPaymentInformationFormVm } from './subscription-plan-checkout-payment-information-form-vm';

export class SubscriptionPlanPurchase extends SubscriptionPlanCheckoutPaymentInformationFormVm {

	@Mapper(SubscriptionPlanChargePeriod)
	chargePeriod!: SubscriptionPlanChargePeriod;

	@Required()
	subscriptionPlan!: SubscriptionPlan;

	@MapFromDTO()
	currency!: FiatCurrency;

	readonly subscriptionId: string; // TODO: remove after fixed backend deployment

	readonly subscriptionPlanId: string;

	constructor(dto?: DTO<SubscriptionPlanPurchase>) {
		super(dto);

		this.subscriptionId = this.subscriptionPlanId = this.subscriptionPlan.id!;
	}

}
