import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TransactionStatus } from '@bp/frontend/models/business';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

@Component({
	selector: 'bp-transaction-status-icon[status]',
	standalone: true,
	imports: [
		SharedFeaturesSvgIconsModule,
	],
	templateUrl: './transaction-status-icon.component.html',
	styleUrls: [ './transaction-status-icon.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionStatusIconComponent {

	@Input()
	set status(value: TransactionStatus) {
		this.__setStatusClass(value);

		this.__setIcon(value);
	}

	@HostBinding('class')
	protected _class = '';

	protected _icon!: 'transaction-status-arrow-icon' | 'transaction-status-declined-icon';

	private __setStatusClass(status: TransactionStatus): void {
		this._class = `status-${ status.isDeclinedLike ? 'declined' : status.kebabCase }`;
	}

	private __setIcon(status: TransactionStatus): void {
		switch (true) {
			case status.isDeclinedLike:

			case status === TransactionStatus.skippedDueToMissingFingerprint:

			case status === TransactionStatus.filteredByRule:
				this._icon = 'transaction-status-declined-icon';

				break;

			default:
				this._icon = 'transaction-status-arrow-icon';
		}
	}

}
