import { compact, uniq } from 'lodash-es';

import { FiatCurrency } from '@bp/shared/models/currencies';
import type { Country } from '@bp/shared/models/countries';
import { Countries } from '@bp/shared/models/countries';
import {
	Control, Copyable, Default, DTO, Entity, FieldControlType, Label, MapFromDTO, Mapper, Required, Unserializable, ViewEmptyValue
} from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';
import { CheckoutUrls } from '@bp/shared/domains/checkouts';

import { CheckoutTheme, CheckoutType } from '@bp/frontend/domains/checkout/core';
import { PaymentOptionType } from '@bp/frontend/models/business';

import { CheckoutLaunchButton } from './checkout-launch-button';
import { CheckoutStatus } from './checkout-status';
import { CheckoutPaymentMethod } from './checkout-payment-method';

export type CheckoutDTO = DTO<Checkout>;

export class Checkout extends Entity {

	@Required()
	title!: string;

	@Label('Base currency')
	@Mapper(FiatCurrency)
	baseCurrency!: FiatCurrency;

	@Label('Activation key')
	@Copyable()
	licenseKey!: string;

	@Label('Activation plugin')
	@ViewEmptyValue('Not activated')
	@Default(null)
	pluginName!: string | null;

	@Mapper(CheckoutStatus)
	@Default(CheckoutStatus.notActive)
	status!: CheckoutStatus;

	@Mapper(CheckoutTheme)
	theme!: CheckoutTheme;

	@Mapper(CheckoutType)
	@Control(FieldControlType.select, {
		list: CheckoutType.assignable,
	})
	@Default(CheckoutType.regular)
	@Required()
	type!: CheckoutType;

	@Mapper(CheckoutLaunchButton)
	button!: CheckoutLaunchButton | null;

	@Mapper(CheckoutUrls)
	url!: CheckoutUrls;

	@MapFromDTO()
	languages?: string[];

	@Mapper(CheckoutPaymentMethod)
	@Default([])
	paymentMethods!: CheckoutPaymentMethod[];

	get isVirtualTerminal(): boolean {
		return this.type === CheckoutType.virtualTerminal;
	}

	get isActive(): boolean {
		return this.status === CheckoutStatus.active;
	}

	get isNotActive(): boolean {
		return this.status === CheckoutStatus.notActive;
	}

	@Unserializable()
	readonly languagesCountries: Country[];

	@Unserializable()
	readonly ccPaymentMethod?: CheckoutPaymentMethod;

	@Unserializable()
	readonly currencies: FiatCurrency[];

	constructor(dto?: CheckoutDTO) {
		super(dto);

		this.isEnabled = this.status === CheckoutStatus.active;

		this.languagesCountries = compact((this.languages ?? []).map(v => v.split('-')[1]))
			.map(v => Countries.findByCodeString(v))
			.filter(isPresent);

		this.currencies = uniq(this.paymentMethods.flatMap(v => v.currencies))
			.filter(isPresent);

		this.ccPaymentMethod = this.paymentMethods.find(v => v.type === PaymentOptionType.creditCard);
	}

	override toString(): string {
		return this.title;
	}
}
