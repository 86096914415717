import { Enumeration } from '@bp/shared/models/core/enum';
import { SECONDS_IN_DAY } from '@bp/shared/utilities/core';

export class SupportRequestDuration extends Enumeration {

	static day = new SupportRequestDuration(SECONDS_IN_DAY, '1 day');

	static threeDays = new SupportRequestDuration(SECONDS_IN_DAY * 3, '3 days');

	static week = new SupportRequestDuration(SECONDS_IN_DAY * 7);

	constructor(
		public readonly seconds: number,
		displayName?: string,
	) {
		super(displayName);
	}

}
