import type { ClassMetadata, DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionApm } from './bridger-psp-payment-option-apm';
import { BridgerPspPaymentOptionCreditCard } from './bridger-psp-payment-option-credit-card';
import { BridgerPspPaymentOptionCrypto } from './bridger-psp-payment-option-crypto';
import { BridgerPspPaymentOptionWalletGooglePay } from './bridger-psp-payment-option-wallet-google-pay';
import { BridgerPspPaymentOptionWalletApplePay } from './bridger-psp-payment-option-wallet-apple-pay';
import { BridgerPspPaymentOptionExternal } from './bridger-psp-payment-option-external';
import { BridgerPspPaymentOptionPos } from './bridger-psp-payment-option-pos';
import { BridgerPspPaymentOptionWallet } from './bridger-psp-payment-option-wallet';

export type BridgerPspPaymentOptions = BridgerPspPaymentOptionApm | BridgerPspPaymentOptionCreditCard | BridgerPspPaymentOptionCrypto | BridgerPspPaymentOptionExternal | BridgerPspPaymentOptionPos | BridgerPspPaymentOptionWallet | BridgerPspPaymentOptionWalletApplePay | BridgerPspPaymentOptionWalletGooglePay;

const bridgerPspPaymentOptionsConstructors = [
	BridgerPspPaymentOptionCreditCard,
	BridgerPspPaymentOptionWalletGooglePay,
	BridgerPspPaymentOptionWalletApplePay,
	BridgerPspPaymentOptionWallet,
	BridgerPspPaymentOptionExternal,
	BridgerPspPaymentOptionPos,
	BridgerPspPaymentOptionApm,
	BridgerPspPaymentOptionCrypto,
];

export function bridgerPspPaymentOptionsFactory(dtos: DTO<BridgerPspPaymentOptions>[]): BridgerPspPaymentOptions[] {
	return dtos
		.map(dto => dto.type && bridgerPspPaymentOptionFactory(dto.type, <any> dto))
		.filter(isPresent);
}

export function bridgerPspPaymentOptionFactory<T extends BridgerPspPaymentOptions = BridgerPspPaymentOptions>(
	type: PaymentOptionType | string,
	dto?: DTO<T>,
): T | null {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	if (paymentOptionType.isDeprecated)
		return null;

	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);

}

export function getBridgerPspPaymentOptionClassMetadata(
	type: PaymentOptionType,
): ClassMetadata<BridgerPspPaymentOptions> {
	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === type,
	);

	if (targetPaymentOptionConstructor)
		return targetPaymentOptionConstructor.getClassMetadata();

	throw new Error(`No appropriate class metadata for ${ type }`);
}
