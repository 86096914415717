import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { filterPresent } from '@bp/frontend/rxjs';

import { loadAll, resetState } from './subscription-plans-shared.actions';
import {
	selectAllSubscriptions, selectError, selectEnterpriseSubscriptionPlan, selectFreeSubscriptionPlan, selectLoading, selectProSubscriptionPlan, selectAllPublicPlans
} from './subscription-plans-shared.selectors';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionPlansSharedFacade {

	all$ = this._store$.select(selectAllSubscriptions);

	allPresent$ = this.all$.pipe(filterPresent);

	allPublic$ = this._store$
		.select(selectAllPublicPlans)
		.pipe(filterPresent);

	all: SubscriptionPlan[] | null = null;

	free$ = this._store$
		.select(selectFreeSubscriptionPlan)
		.pipe(filterPresent);

	pro$ = this._store$
		.select(selectProSubscriptionPlan)
		.pipe(filterPresent);

	enterprise$ = this._store$
		.select(selectEnterpriseSubscriptionPlan)
		.pipe(filterPresent);

	loading$ = this._store$.select(selectLoading);

	error$ = this._store$.select(selectError);

	constructor(
		private readonly _store$: Store,
	) {
		this._updateAllPropertyOnStateChange();
	 }

	loadAll(): void {
		this._store$.dispatch(loadAll());
	}

	resetState(): void {
		this._store$.dispatch(resetState());
	}

	private _updateAllPropertyOnStateChange(): void {
		this.allPresent$.subscribe(all => (this.all = all));
	}
}
