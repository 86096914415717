
import { map } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';

import { Destroyable } from '@bp/frontend/models/common';

import { TurnstileService } from '../../turnstile.service';

@Component({
	selector: 'bp-turnstile-widget',
	standalone: true,
	templateUrl: './turnstile-widget.component.html',
	styleUrls: [ './turnstile-widget.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnstileWidgetComponent extends Destroyable implements OnDestroy, OnInit {

	private readonly __turnstileService = inject(TurnstileService);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	@HostBinding('class.enabled')
	readonly enabled = this.__turnstileService.enabled;

	@Input({ required: true })
	action!: string;

	@Input()
	 set reset(value: boolean) {
		value && this.__turnstileService.resetChallenge(this.__$host);
	}

	@Output('pending')
	readonly pending$ = this.__turnstileService.token$.pipe(map(token => this.enabled ? !token : false));

	ngOnInit(): void {
		if (!this.enabled)
			return;

		void this.__turnstileService.renderChallenge(this.__$host, this.action);
	}

	ngOnDestroy(): void {
		void this.__turnstileService.removeChallenge(this.__$host);
	}

}
