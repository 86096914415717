import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-feature-presence-or-count[value]',
	templateUrl: './feature-presence-or-count.component.html',
	styleUrls: [ './feature-presence-or-count.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturePresenceOrCountComponent {

	@Input() value!: boolean | number | string;

}
