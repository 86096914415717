import { Feature } from '../feature';
import { CommonFeatureActions } from '../feature-actions';

export class CheckoutFeature<T extends CommonFeatureActions = CommonFeatureActions> extends Feature<T> {

	static checkout = new CheckoutFeature(CommonFeatureActions);

	static override parseHook = (value: unknown): CheckoutFeature | null => {
		if (value === 'cashier')
			return CheckoutFeature.checkout;

		return null;
	};

}
