import { sortBy, uniqBy } from 'lodash-es';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import {
	ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER, ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, CryptoCurrency, FiatCurrency
} from '@bp/shared/models/currencies';
import {
	booleanMapper, Control, Default, Entity, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Table, Unserializable, View, ViewEmptyValue, DTO, Required, Sortable
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import type { INamedEntitySummary } from '@bp/shared/models/core';

import { BridgerPsp, BridgerPspPaymentOptions } from '@bp/frontend/domains/bridger-psps/core';
import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { PspStatus } from '../enums';
import { ApiPropertiesMetadataSection } from '../api-property-metadata';

import { PspCredential } from './psp-credential';
import { PspThreeDsCredential } from './psp-three-ds-credential';

export type MerchantPspKeys = NonFunctionPropertyNames<MerchantPsp>;

export type MerchantPspDTO = DTO<MerchantPsp>;

export class MerchantPsp extends Entity {

	@Label('Name')
	@MapFromDTO()
	@Table()
	@Sortable({
		isDefault: true,
		defaultDir: 'asc',
	})
	pspName!: string;

	@Default(PspStatus.active)
	@Mapper(PspStatus)
	@View(FieldViewType.status)
	status!: PspStatus;

	@Mapper(PspPaymentOptionType)
	@Table()
	@Sortable()
	type!: PspPaymentOptionType;

	@Default([])
	@Mapper(PspCredential)
	@Table()
	@Sortable()
	credentials!: PspCredential[];

	@Default([])
	@Mapper(PspThreeDsCredential)
	threeDsCredentials!: PspThreeDsCredential[];

	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip, // Items source is added lazily
		{
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@Mapper(FiatCurrency)
	@ViewEmptyValue('Any')
	@Default([])
	@Required()
	currencies!: FiatCurrency[];

	/**
	 * Present only on crypto psps
	 */
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip, // Items source is added lazily
		{
			placeholder: ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@Mapper(CryptoCurrency)
	@ViewEmptyValue('Any')
	@Default([])
	@Required()
	cryptoCurrencies!: CryptoCurrency[];

	/**
	 * Present only on credit card psps
	 */
	@Control(FieldControlType.brandChips, { // Items source is added lazily
		placeholder: 'Add brand...',
	})
	@Mapper(PaymentCardBrand)
	@ViewEmptyValue('Any')
	@Default([])
	@Required()
	brands!: PaymentCardBrand[];

	@Control(FieldControlType.switch)
	@Default(false)
	@Hint('By enabling this flag, the system will process transactions based on currencies of credentials. Otherwise, it will process transactions through all credentials.')
	@Label('Filter credentials by currency')
	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	filterMidByCurrency!: boolean;

	@Control(FieldControlType.switch)
	@Default(false)
	@Hint('By enabling this flag, the system will not use a currency of the country for processing transactions. If there is a mid with the specific currency, the system will use that mid\'s currency during the deposit.')
	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	bypassCurrencyByCountry!: boolean;

	get displayName(): string {
		return `${ this.pspName } ${ this.type.displayName }`;
	}

	get isCreditCard(): boolean {
		return this.type === PspPaymentOptionType.creditCard;
	}

	@Mapper(ApiPropertiesMetadataSection)
	propertiesMetadata!: ApiPropertiesMetadataSection[];

	@Label('Payment routes participation')
	@Table()
	@Sortable()
	@Unserializable()
	@ViewEmptyValue('Not attached to any route')
	readonly paymentRoutes: INamedEntitySummary[];

	get isUsedInPaymentRoutes(): boolean {
		return this.paymentRoutes.length > 0;
	}

	@Unserializable()
	@Default(null)
	bridgerPsp!: BridgerPsp | null;

	@Unserializable()
	bridgerPspPaymentOption: BridgerPspPaymentOptions | null;

	@Default(false)
	@View(FieldViewType.boolean)
	@Unserializable()
	readonly canBeUsedInPaywith: boolean;

	constructor(dto?: MerchantPspDTO) {
		super(dto);

		this.name = this.pspName;

		this.credentials = this.__sortCredentials();

		this.paymentRoutes = this.__aggregateUniqCredentialsPaymentRoutes();

		this.bridgerPspPaymentOption = this.bridgerPsp?.paymentOptions.find(v => v.type === this.type) ?? null;

		this.canBeUsedInPaywith = !!this.bridgerPspPaymentOption && !!this.bridgerPsp?.checkOptionCanBeUsedInPaywith(this.bridgerPspPaymentOption);

		this.__logicallyBindIsEnabledAndStatus();
	}

	private __logicallyBindIsEnabledAndStatus(): void {
		if (this.isEnabled === null)
			this.isEnabled = this.status === PspStatus.active;
		else
			this.status = this.isEnabled ? PspStatus.active : PspStatus.disabled;
	}

	private __sortCredentials(): PspCredential[] {
		return sortBy(
			this.credentials,
			v => !v.isEnabled,
		);
	}

	private __aggregateUniqCredentialsPaymentRoutes(): INamedEntitySummary[] {
		return uniqBy(
			this.credentials.flatMap(v => v.paymentRoutes),
			it => it.id,
		);
	}

}
