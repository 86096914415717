import { isString } from 'lodash-es';

import { booleanMapper, DTO, Mapper } from '@bp/shared/models/metadata';

import { BridgerPspScope } from './bridger-psp-scope';
import { BridgerPspSummaryQueryParams } from './summaries';

export class BridgerPspsQueryParams extends BridgerPspSummaryQueryParams {

	scopes: BridgerPspScope[];

	@Mapper(booleanMapper)
	popular?: boolean;

	constructor(dto?: DTO<BridgerPspsQueryParams>) {
		super(dto);

		this.scopes = (isString(dto?.scopes)
			? dto!.scopes.split(',')
			: (dto?.scopes ?? []))
			.map(v => BridgerPspScope.parse(v))
			.filter((v): v is BridgerPspScope => !!v);
	}

}
