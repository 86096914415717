import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Validators } from '@bp/shared/features/validation/models';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { FeaturePermission } from '@bp/shared/domains/permissions';

import { FormBaseComponent, FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/frontend/components/core';

import { IFeatureAccessOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-resource-access-otp-verification-form',
	templateUrl: './resource-access-otp-verification-form.component.html',
	styleUrls: [ './resource-access-otp-verification-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,

			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round',
				hideRequiredMarker: true,
			},
		},
	],
})
export class ResourceAccessOtpVerificationFormComponent extends FormBaseComponent<IFeatureAccessOtpVerificationApiRequest> {

	@Input({ required: true })
	permission!: FeaturePermission<any>;

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<IFeatureAccessOtpVerificationApiRequest>({
				code: [ null, { validators: Validators.required }],
			}),
		);
	}
}
