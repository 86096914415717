import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

export type CurrentSubscriptionManagementModeLiterals = GetEnumerationLiterals<
	typeof CurrentSubscriptionManagementMode
>;

export class CurrentSubscriptionManagementMode extends Enumeration {
	static readonly manage = new CurrentSubscriptionManagementMode();

	static readonly purchase = new CurrentSubscriptionManagementMode();

	static readonly upgrade = new CurrentSubscriptionManagementMode();

	get isManage(): boolean {
		return this === CurrentSubscriptionManagementMode.manage;
	}

	get isPurchase(): boolean {
		return this === CurrentSubscriptionManagementMode.purchase;
	}

	get isUpgrade(): boolean {
		return this === CurrentSubscriptionManagementMode.upgrade;
	}
}
