import { camelCase } from 'lodash-es';

import type { SortDirection } from '@angular/material/sort';

import { Constructor } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';
import { ISortQueryParams } from '@bp/shared/models/common';

import { ClassMetadata } from '../class-metadata';
import { MetadataEntity } from '../metadata-entity';
import { Control, Default, Mapper, Validator } from '../decorators';
import { FieldControlType } from '../enums';

import { MetadataQueryParamsBaseCtor } from './query-params-base';
import { inSortableFieldsListOf } from './in-sortable-fields-of.validator';

export interface ISortQueryParamsMixin extends ISortQueryParams{

	setDefaultSortParamsBasedOnMetadata: (classMetadata: ClassMetadata<any>) => void;

}

type SortQueryParamsCtor = Constructor<ISortQueryParamsMixin>;

export function mixinMetadataSortQueryParams<T extends MetadataQueryParamsBaseCtor>(
	base: T,
	metadataEntity: typeof MetadataEntity | null,
): SortQueryParamsCtor & T {
	class MixedIn extends base implements ISortQueryParamsMixin {

		@Mapper(camelCase)
		@Validator(metadataEntity ? inSortableFieldsListOf(metadataEntity) : Validators.noop())
		sortField?: string;

		@Control(FieldControlType.select, {
			validator: Validators.inList([ 'asc', 'desc' ] satisfies SortDirection[]),
		})
		@Default('desc')
		sortDir?: SortDirection;

		constructor(...args: any[]) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			super(...args);

			if (metadataEntity)
				this.setDefaultSortParamsBasedOnMetadata(metadataEntity.getClassMetadata());
		}

		setDefaultSortParamsBasedOnMetadata(classMetadata: ClassMetadata<any>): void {
			if (this.sortField || !classMetadata.defaultSortProperty)
				return;

			this.sortDir = classMetadata.defaultSortProperty.defaultSortDir;

			this.sortField = classMetadata.defaultSortProperty.property;
		}
	}

	return MixedIn;
}
