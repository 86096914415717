@if (mode.isPurchase) {
	<h1 class="text-center">Welcome! Thank you for your registration.</h1>
	<p>A member of our team will be in touch shortly.</p>
	<p>If you have any questions in the meantime,</p>
	<p>please contact <a href="mailto: support@bridgerpay.com">support&#64;bridgerpay.com</a></p>
} @else {
	<h1 class="text-center">Your current plan is limited!</h1>
	<p>Discover the full spectrum of possibilities for your business by upgrading your plan.</p>
	<p>
		Reach out to our dedicated support team via
		<a href="mailto: support@bridgerpay.com">support&#64;bridgerpay.com</a> or engage in live chat session now.
	</p>
	<p>We're ready to guide you towards a more powerful and tailored solution for your success.</p>
}
