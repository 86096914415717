import type { Type } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type { ThemePalette } from '@angular/material/core';

export const ACTION_CONFIRM_DIALOG_DESCRIPTION_COMPONENT_CONTEXT = new InjectionToken(
	'action_confirm_dialog_description_component_context',
);

export type ActionConfirmDialogData = {
	name?: string;
	type?: string;
	title?: string;
	kind?: 'acknowledge' | 'confirm';
	requireTypedConfirm?: boolean;
	typedConfirmText?: string;
	confirmButtonText?: string;
	color?: ThemePalette;

	/** For simple descriptions */
	descriptionText?: string;

	/** For complex descriptions */
	descriptionComponent?: Type<any>;
	descriptionContext?: Object;
};
