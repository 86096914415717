import { Data } from '@angular/router';

import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

const { selectRouteData: ngrxSelectRouteData } = getRouterSelectors();

export const selectRouteData = createSelector(
	ngrxSelectRouteData,
	(v: Data | undefined) => v ?? {},
);
