import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CurrentSubscriptionManagementMode } from '../../../../models';

@Component({
	selector: 'bp-contact-to-change-subscription-plan-pane',
	standalone: true,
	templateUrl: './contact-to-change-subscription-plan-pane.component.html',
	styleUrls: [ './contact-to-change-subscription-plan-pane.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactToChangeSubscriptionPlanPaneComponent {

	@Input({ required: true }) mode!: CurrentSubscriptionManagementMode;

}
