import { Default, Entity, MapFromDTO, Mapper } from '@bp/shared/models/metadata';

import { CheckoutType } from '@bp/frontend/domains/checkout/core';
import { CheckoutDTO, CheckoutStatus } from '@bp/frontend/domains/checkout/models';

type CrmOrganizationCheckoutSubset = Pick<
CheckoutDTO,
'licenseKey' | 'pluginName' | 'status' | 'title' | 'type'
>;

export class CrmOrganizationCheckout extends Entity implements CrmOrganizationCheckoutSubset {

	@MapFromDTO()
	title!: string;

	@MapFromDTO()
	licenseKey!: string;

	@Default(null)
	pluginName!: string | null;

	@Mapper(CheckoutStatus)
	status!: CheckoutStatus;

	@Mapper(CheckoutType)
	type!: CheckoutType;

	constructor(dto: CheckoutDTO) {
		super(dto);
	}

}
