import {
	ApplicationRef, Directive, ElementRef, HostBinding, HostListener, Input
} from '@angular/core';

@Directive({
	host: {
		class: 'card',
	},
})
export class EditableCardBaseComponent {

	@HostBinding('class.show-shadow')
	showShadow = false;

	@Input()
	@HostBinding('class.can-edit')
	canEdit = true;

	edit = false;

	get view(): boolean {
		return !this.edit;
	}

	@HostBinding('class.card-edit')
	get isCardEdit(): boolean {
		return this.canEdit && this.edit;
	}

	protected readonly _$host = this._host.nativeElement;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	private readonly _$appRoot: HTMLElement = this._appRef.components[0].location.nativeElement;

	constructor(
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _appRef: ApplicationRef,
	) { }

	@HostListener('window:click', [ '$event.target' ])
	onWindowClick($target: Node): void {
		// We need the $appRoot reference to avoid switching to the view mode on clicks inside dialogs
		if (this.edit && this._$appRoot.contains($target) && !this._$host.contains($target))
			this.edit = false;
	}
}
