import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@bp/shared/models/metadata';
import { Country, CountryCode } from '@bp/shared/models/countries';

import { ISubscriptionPlanCheckoutConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class SubscriptionPlanCheckoutApiService {

	readonly collectionPath = 'subscription-plan-checkout-config';

	constructor(private readonly _http: HttpClient) { }

	getSubscriptionPlanCheckoutConfig(): Observable<ISubscriptionPlanCheckoutConfig> {
		return this._http.get<DTO<ISubscriptionPlanCheckoutConfig>>(this.collectionPath)
			.pipe(
				map(({ country: countryCode, vatConfigPerCountry }) => ({
					vatConfigPerCountry: vatConfigPerCountry ?? {},
					country: countryCode ? new Country(<CountryCode>countryCode) : null,
				})),
			);
	}

}
