import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationStartupPlanVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoCart;

	title = 'Congratulations! Start up with bridger!';

	subtitle = 'Some of your <b>START-UP</b> account perks are:';

	additionalInfo = this._buildPromoPricingPageLink();

	features = [
		this._buildPspsFeature(),
		this._buildCheckoutsFeature(),
		'Generate <b>instant reports</b>',
		'Issue <b>refunds</b>',
		'Use <b>tokenization</b> for faster checkout',
	];

	constructor(subscriptionPlan: SubscriptionPlan) {
		super(subscriptionPlan);
	}
}
