import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import type { MerchantPsp } from '@bp/admins-shared/core/models';
import { composeEntitiesInMemoryPagedListSelectors } from '@bp/admins-shared/features/entity';

import type { MerchantPspsByPaymentOptionTypeByPspNameMap } from '../models';

import type { IState } from './merchant-psps.reducer';
import { FEATURE_KEY } from './merchant-psps.reducer';

// eslint-disable-next-line ngrx/prefix-selectors-with-select
export const MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS = composeEntitiesInMemoryPagedListSelectors<
MerchantPsp,
IState
>(createFeatureSelector<IState>(FEATURE_KEY));

const { all: selectAllMerchantPsps } = MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS;

export const selectAllMerchantPspsByPaymentOptionTypeByPspNameMap = createSelector(
	selectAllMerchantPsps,
	allMerchantPsps => allMerchantPsps && groupMerchantPspsByPaymentOptionTypeByPspName(allMerchantPsps),
);

export const selectAllEnabledMerchantPsps = createSelector(
	selectAllMerchantPsps,
	allMerchantPsps => allMerchantPsps?.filter(merchantPsp => merchantPsp.isEnabled) ?? null,
);

export const selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap = createSelector(
	selectAllEnabledMerchantPsps,
	allEnabledMerchantPsps => allEnabledMerchantPsps && groupMerchantPspsByPaymentOptionTypeByPspName(allEnabledMerchantPsps),
);

export const selectAllMerchantPspPaymentOptionTypes = createSelector(
	selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap,
	map => map ? [ ...map.keys() ] : null,
);

function groupMerchantPspsByPaymentOptionTypeByPspName(
	allMerchantPsps: MerchantPsp[],
): MerchantPspsByPaymentOptionTypeByPspNameMap {
	return new Map(
		PspPaymentOptionType.getList()
			.map(
				optionType => <const>[
					optionType,
					new Map(
						allMerchantPsps.filter(psp => psp.type === optionType).map(psp => <const>[ psp.pspName, psp ]),
					),
				],
			)
			.filter(([ , psps ]) => psps.size > 0),
	);
}
