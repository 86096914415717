import { isEmpty, isObject, isString } from 'lodash-es';
import { BehaviorSubject, mapTo, merge, Subject } from 'rxjs';
import { Memoize } from 'typescript-memoize';

import {
	ChangeDetectionStrategy, Component, ContentChild, Directive, HostBinding, Input, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatLegacyAutocomplete } from '@angular/material/legacy-autocomplete';

import { bpQueueMicrotask, matchIgnoringCase, includesIgnoringCase } from '@bp/shared/utilities/core';
import { IDescribable } from '@bp/shared/models/core';
import { Dictionary } from '@bp/shared/typings';

import { FADE, FADE_IN_LIST_STAGGERED } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { InputComponent } from '../input';

@Directive({
	selector: '[bpAutocompleteOption]',
})
export class AutocompleteOptionDirective {
	@Input()
	bpAutocompleteOptionPanelClass?: string;

	constructor(public tpl: TemplateRef<any>) { }

}

@Component({
	selector: 'bp-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: [ './autocomplete.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AutocompleteComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: AutocompleteComponent,
			multi: true,
		},
	],
	animations: [ FADE_IN_LIST_STAGGERED, FADE ],
})
export class AutocompleteComponent extends FormFieldControlComponent<any | null> implements OnInit, OnChanges {

	@Input() items?: any[] | null;

	@Input() itemDisplayPropertyName?: string;

	@Input() suggestedItem?: any;

	@Input() suggestedItemTooltip?: string;

	@Input() suggestedItemButtonTextPrefix?: string;

	@Input() panelClass?: string;

	@Input() filterListFn?: (item: any, search: string) => boolean;

	@Input({ transform: coerceBooleanProperty })
	hasSearchIcon = false;

	@Input() override nativeAutocomplete?: boolean | string = false;

	@Output('inputChange')
	readonly inputChange$ = new Subject<string>();

	@Input() optionTpl?: TemplateRef<any>;

	@Input({ transform: coerceBooleanProperty })
	autoGrow = false;

	@Input({ transform: coerceBooleanProperty })
	@HostBinding('class.has-chevron')
	hasChevron = false;

	@ContentChild(TemplateRef)
	private readonly __customOptionTpl?: TemplateRef<any>;

	@ContentChild(AutocompleteOptionDirective)
	private readonly __customOption?: AutocompleteOptionDirective;

	protected get _customOptionTpl(): TemplateRef<any> | undefined {
		return this.__customOptionTpl ?? this.__customOption?.tpl ?? this.optionTpl;
	}

	@ViewChild(InputComponent, { static: true })
	private readonly _input!: InputComponent;

	@ViewChild(MatLegacyAutocomplete, { static: true })
	protected readonly _autocomplete!: MatLegacyAutocomplete;

	protected readonly _isAutocompleteOpen$ = new BehaviorSubject<boolean>(false);

	override throttle = 0;

	filtered$ = new BehaviorSubject<any[]>([]);

	constructor() {
		super();

		this._internalControl.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => this._internalControl.dirty && void this.inputChange$.next(v));
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.__observeAutocompleteOpenState();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { items } = changes;

		if (items)
			this.filtered$.next(this.items ?? []);
	}

	focus(): void {
		this._input.focus();
	}

	@Memoize()
	private __getSearchableTerms(item: Dictionary<string> & object): string[] {
		const terms: string[] = [ `${ item }` ];

		if (isObject(item)) {
			if (this.itemDisplayPropertyName && this.itemDisplayPropertyName in item)
				terms.push(item[this.itemDisplayPropertyName]);

			if ('description' in item)
				terms.push(item['description']);

			if ('displayName' in item)
				terms.push(item['displayName']);

			if ('name' in item)
				terms.push(item['name']);
		}

		return terms;
	}

	// #region Implementation of the ControlValueAccessor interface
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	override writeValue(value: any): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			this._setIncomingValueToInternalControl(this.value?.toString() ?? '');
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	// #region Implementation of the Validator interface
	protected override _validator: ValidatorFn | null = ({ value }: AbstractControl): ValidationErrors | null => !value && this._internalControl.value
		? { autocompleteNotFound: true }
		: null;
	// #endregion Implementation of the Validator interface

	/**
	 * The value of the internal control could
	 * be as string as an item of the autocomplete list which is any
	 */
	protected override _onInternalControlValueChange(searchTermOrSelectedValue: any | string | null): void {
		if (isEmpty(this.items))
			return;

		let found: any;

		if (isString(searchTermOrSelectedValue)) {
			const searchTerm = searchTermOrSelectedValue.trim();

			if (searchTerm.length > 1) {
				this.filtered$.next(
					this.items!.filter(item => this.__filterItem(item, searchTerm)),
				);

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				found = this.items!.find(item => this.__matchItem(item, searchTerm));
			} else
				this.filtered$.next(this.items!);

		} else {
			this.filtered$.next(this.items!);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			found = searchTermOrSelectedValue;
		}

		this._cdr.markForCheck();

		this.setValue(found);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected _hasDescription(item: any): item is IDescribable {
		return isObject(item) && 'description' in item && item.description !== undefined;
	}

	private __matchItem(item: any, searchTerm: string): boolean {
		return this.__getSearchableTerms(item)
			.some(itemSearchTerm => matchIgnoringCase(itemSearchTerm, searchTerm));
	}

	private __filterItem(item: any, searchTerm: string): boolean {
		return this.filterListFn
			? this.filterListFn(item, searchTerm)
			: this.__getSearchableTerms(item)
				.some(itemSearchTerm => includesIgnoringCase(itemSearchTerm, searchTerm));
	}

	private __observeAutocompleteOpenState() {
		merge(
			this._autocomplete.opened.pipe(mapTo(true)),
			this._autocomplete.closed.pipe(mapTo(false)),
		)
			.pipe(takeUntilDestroyed(this))
			.subscribe(state => void this._isAutocompleteOpen$.next(state));
	}

}
