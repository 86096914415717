import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	DTO, MapFromDTO, MetadataQueryParamsBase, mixinMetadataPageQueryParams, mixinMetadataSortQueryParams
} from '@bp/shared/models/metadata';

import { SubscriptionPlan } from './subscription-plan';

type SubscriptionPlansQueryParamsDTO = DTO<SubscriptionPlansQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO;

export class SubscriptionPlansQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, SubscriptionPlan),
) {

	@MapFromDTO()
	wizardSetupId?: string;

	constructor(dto?: SubscriptionPlansQueryParamsDTO) {
		super(dto);
	}

}
