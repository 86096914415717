import { AdminFeature } from '../admin-feature';
import { CommonFeatureActions, EditAndAccessFeatureActions } from '../feature-actions';

import {
	CheckoutsFeatureActions, MembersFeatureActions, PaymentCardTokensFeatureActions, PayoutsFeatureActions, ReportsFeatureActions, TransactionsFeatureActions
} from './merchant-admin-feature-actions';

export class MerchantAdminFeature<T extends CommonFeatureActions = CommonFeatureActions> extends AdminFeature<T> {

	static reports = new MerchantAdminFeature(ReportsFeatureActions);

	static transactions = new MerchantAdminFeature(TransactionsFeatureActions);

	static payouts = new MerchantAdminFeature(PayoutsFeatureActions);

	static checkouts = new MerchantAdminFeature(CheckoutsFeatureActions);

	static creditCardTokens = new MerchantAdminFeature(PaymentCardTokensFeatureActions);

	static paymentRoutes = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static psps = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static members = new MerchantAdminFeature(MembersFeatureActions);

	static requestSupport = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static dashboard = new MerchantAdminFeature(CommonFeatureActions);

	static virtualTerminal = new MerchantAdminFeature(CommonFeatureActions);

	static billing = new MerchantAdminFeature(CommonFeatureActions);

	static subscriptionsManagement = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static enterpriseSubscriptionCheckout = new MerchantAdminFeature(CommonFeatureActions);

	static pspCredentials = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentRouteRules = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentRouteCredentials = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static api = new MerchantAdminFeature(CommonFeatureActions);

	static mpi = new MerchantAdminFeature(CommonFeatureActions);

	// #region User Workflows

	static otpVerify = new MerchantAdminFeature(CommonFeatureActions);

	// #region Signup Via Link Workflow

	static acceptInvite = new MerchantAdminFeature(CommonFeatureActions);

	static loginAfterInvite = new MerchantAdminFeature(CommonFeatureActions);

	static createAccount = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	// #region deprecated, remove after accepting invite implementation by backend

	static confirmationEmail = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !deprecated

	// #region Continue Signup

	static setSecurityQuestionsAnswers = new MerchantAdminFeature(CommonFeatureActions);

	static registerAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	// #region Reset Password

	static verifyAnswerBeforeResetPassword = new MerchantAdminFeature(CommonFeatureActions);

	static otpResetPasswordVerification = new MerchantAdminFeature(CommonFeatureActions);

	static resetPassword = new MerchantAdminFeature(CommonFeatureActions);

	static resetExpiredPassword = new MerchantAdminFeature(CommonFeatureActions);

	/**
	 * We use this permission as the reset expired password feature
	 * to reuse the same component and logic at backend
	 */
	static setPasswordOnLogin = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	// #region Reset Authenticator App

	static sendResetAuthenticatorLink = new MerchantAdminFeature(CommonFeatureActions);

	static resetAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	static verifyAnswerBeforeResetAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	// #endregion

	// #region Generated on the fly depending on the merchant level access

	static paywith = new MerchantAdminFeature(CommonFeatureActions);

	static bpThreeDs = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	// #region Deprecated unused anywhere in the app but sent by the backend

	static bridgerRouter = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentProviders = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion

	static override parseHook = (value: unknown): MerchantAdminFeature | null => {
		if (value === 'cashiers')
			return MerchantAdminFeature.checkouts;

		if (value === MerchantAdminFeature.setPasswordOnLogin.value)
			return MerchantAdminFeature.resetExpiredPassword;

		return null;
	};
}
