import { FiatCurrency } from '@bp/shared/models/currencies';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';
import { DTO, MapFromDTO, MetadataEntity, Required } from '@bp/shared/models/metadata';

export class DowngradeSubscriptionPlanApiRequest extends MetadataEntity {
	@Required()
	subscriptionPlan!: SubscriptionPlan;

	@MapFromDTO()
	currency!: FiatCurrency;

	@MapFromDTO()
	chargePeriod!: SubscriptionPlanChargePeriod;

	readonly subscriptionId!: string;

	constructor(dto?: DTO<DowngradeSubscriptionPlanApiRequest>) {
		super(dto);

		this.subscriptionId = this.subscriptionPlan.id!;
	}
}
