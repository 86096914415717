import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedFeaturesCopyToClipboardModule } from '@bp/frontend/features/copy-to-clipboard';
import { TransactionStatusIconComponent } from '@bp/frontend/components/transaction-status-icon';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';

import {
	PropertyMetadataControlComponent, PropertyMetadataControlContextDirective, PropertyMetadataControlCustomDirective
} from './property-metadata-control';
import { PropertyMetadataControlsSectionComponent } from './property-metadata-controls-section';
import { PropertyMetadataCustomValueViewDirective, PropertyMetadataViewComponent } from './property-metadata-view';
import { PropertyMetadataViewsSectionComponent } from './property-metadata-views-section';
import { ClassMetadataSortDirective } from './directives';

const EXPOSED_COMPONENTS = [
	PropertyMetadataControlComponent,
	PropertyMetadataControlsSectionComponent,
	PropertyMetadataControlContextDirective,
	PropertyMetadataControlCustomDirective,
	PropertyMetadataViewComponent,
	PropertyMetadataViewsSectionComponent,
	PropertyMetadataCustomValueViewDirective,
	ClassMetadataSortDirective,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatSlideToggleModule,
		MatIconModule,
		MatChipsModule,
		MatCheckboxModule,
		MatFormFieldModule,
		PortalModule,

		TransactionStatusIconComponent,

		SharedPipesModule,
		SharedFeaturesMomentPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedDirectivesCoreModule,
		SharedFeaturesCopyToClipboardModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,
	],
	exports: EXPOSED_COMPONENTS,
	declarations: EXPOSED_COMPONENTS,
})
export class SharedComponentsMetadataModule { }
