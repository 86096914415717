import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

@Component({
	selector: 'bp-subscription-plan-charge-period-switcher',
	templateUrl: './subscription-plan-charge-period-switcher.component.html',
	styleUrls: [ './subscription-plan-charge-period-switcher.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlanChargePeriodSwitcherComponent {
	@Input() annualDiscount!: number;

	@Input()
	selectedChargePeriod!: SubscriptionPlanChargePeriod;

	@Output()
	readonly selectedChargePeriodChange = new EventEmitter<SubscriptionPlanChargePeriod>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	chargePeriods = SubscriptionPlanChargePeriod.getList();
}
