import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash-es';

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { Destroyable } from '@bp/frontend/models/common';
import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';

import { BridgerPspsSharedFacade } from '../../state';
import { PSP_THUMBNAIL_DEFAULT_SIZE, PSP_THUMBNAIL_THREE_DS_NAME, PSP_THUMBNAIL_THREE_DS_LOGO_URL } from '../../models';

@Component({
	selector: 'bp-psp-thumbnail',
	templateUrl: './psp-thumbnail.component.html',
	styleUrls: [ './psp-thumbnail.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PspThumbnailComponent extends Destroyable {

	@Input()
	set name(v: string | null | undefined) {
		this._name$.next(v);
	}

	get name(): string | null | undefined {
		return this._name$.value;
	}

	@Input()
	showPspNameTooltip = true;

	private readonly _name$ = new OptionalBehaviorSubject<string | null | undefined>();

	name$ = this._name$.asObservable();

	@Input() size = PSP_THUMBNAIL_DEFAULT_SIZE;

	@Input()
	@HostBinding('class')
	status?: 'warning' | null;

	pspImgUrl$ = combineLatest([
		this.name$,
		this._bridgerPspsSharedFacade.allByPspNameMap$,
	])
		.pipe(
			map(([ name, allByPspName ]) => isNil(name)
				?				null
				: (this._isThreeDsLogo ? PSP_THUMBNAIL_THREE_DS_LOGO_URL : allByPspName.get(name)?.logoUrl)),
		);

	protected get _isThreeDsLogo(): boolean {
		return this.name === PSP_THUMBNAIL_THREE_DS_NAME;
	}

	constructor(private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade) {
		super();
	}

}
