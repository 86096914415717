<button
	mat-button
	type="button"
	class="current-subscription-indicator {{ currentSubscriptionPlan.type.kebabCase }}"
	[class.warning]="transactionsLimit.isWarningThresholdReached"
	[class.danger]="transactionsLimit.isDangerThresholdReached"
>
	<div class="plan-name">
		{{ currentSubscriptionPlan.isFree ? 'Free plan' : currentSubscriptionPlan.displayName }}

		<bp-chevron-rotatable-180-degrees [rotated]="isLimitsMeterPopupOpen" />
	</div>

	<div *ngIf="transactionsLimit.isNotUnlimited" class="transactions-limit-indicator grid-stacked-children">
		<div class="transactions-count-progress-bar-rail"></div>

		<div
			class="transactions-count-progress-bar"
			[style.width]="transactionsLimit.counterToLimitPercent | percent"
		></div>
	</div>
</button>
