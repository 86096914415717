import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationEnterprisePlanVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoRocket;

	title = 'Congratulations! Welcome to bridger enterprise!';

	subtitle = '<b>Enterprise</b> gives you exclusive access to:';

	additionalInfo = 'Anything you need, we’ve got your back!';

	features = [
		'<b>24/7</b> support',
		this._buildFreeIntegratedPspsFeature(),
		'Dedicated <b>Success & Support Managers</b>',
		'<b>Bridger BI, automations</b> and <b>payouts</b>',
	];

	constructor(subscriptionPlan: SubscriptionPlan) {
		super(subscriptionPlan);
	}
}
