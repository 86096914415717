import { has, unset } from 'lodash-es';
import { filter } from 'rxjs/operators';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';
import type { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, PRIMARY_OUTLET, Router, RouterOutlet, RoutesRecognized } from '@angular/router';

import { MODAL_OUTLET } from '@bp/shared/models/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import type { IModalHostComponent } from './modal-host-component.interface';
import { ModalComponent } from './modal.component';

const URL_TREE_PRIMARY_MODAL_OUTLET_PATH = `root.children.${ PRIMARY_OUTLET }.children.${ MODAL_OUTLET }`;
const URL_TREE_MODAL_OUTLET_PATH = `root.children.${ MODAL_OUTLET }`;

@Component({
	selector: 'bp-modal-outlet',
	template: '<ng-content />',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOutletComponent extends Destroyable implements OnInit {

	@ContentChild(RouterOutlet, { static: true }) outlet!: RouterOutlet;

	private _activeDialog!: MatDialogRef<any> | null;

	private _urlWithModalOutlet!: string | null;

	private _destinationUrl!: string;

	private _shouldNavigateToDestinationUrlAfterModalClosed!: boolean;

	constructor(
		public router: Router,
		private readonly _dialogsManager: MatDialog,
	) {
		super();
	 }

	ngOnInit(): void {
		this.outlet.activateEvents
			.pipe(takeUntilDestroyed(this))
			.subscribe((cmpt: IModalHostComponent) => void this._outletActivate(cmpt));

		this.router.events
			.pipe(
				filter((event): event is NavigationEnd => event instanceof NavigationEnd),
				takeUntilDestroyed(this),
			)
			.subscribe(navigationEndEvent => {
				this._urlWithModalOutlet = this._checkUrlHasModalOutlet(navigationEndEvent.url)
					? this.router.url
					: null;
			});

		/*
		 * We redirect to the destination url only after the drawer is animatedly closed
		 * otherwise the router outlets content in the drawer deletes right at the animation start
		 * which create a nasty visual glitch
		 */
		this.router.events
			.pipe(
				filter((event): event is RoutesRecognized => event instanceof RoutesRecognized && !!this._activeDialog),
				takeUntilDestroyed(this),
			)
			.subscribe(routeRecognizedEvent => {
				if (!this._urlWithModalOutlet || this._checkUrlHasModalOutlet(routeRecognizedEvent.url))
					return;

				/**
				 * Navigation back to the url with modal outlet may be canceled (due to guards redirecting to different routes)
				 * causing eternal redirection loop, so we must wait for the dialog close end.
				 */
				if (this._shouldNavigateToDestinationUrlAfterModalClosed)
					return;

				/*
				 * If the destination url doesn't have the modal outlet that means the user
				 * intends to navigate to the page not to another modal
				 * this._urlWithModalOutlet contains the current url from which the navigation was initiated
				 * since it's updated only on NavigationEnd, but we are still processing RoutesRecognized.
				 * We interrupt navigating to the destination url by renavigating back to the current url.
				 */
				void this.router.navigateByUrl(this._urlWithModalOutlet);

				this._destinationUrl = routeRecognizedEvent.url;

				this._shouldNavigateToDestinationUrlAfterModalClosed = true;

				// The handler on the close event will actually navigate to the destination url
				this._activeDialog?.close();
			});
	}

	private _outletActivate(cmpt: IModalHostComponent): void {
		if (!(cmpt.modal instanceof ModalComponent))
			throw new Error('The component attached to the modal router outlet must implement the IModalHostComponent interface');

		this._shouldNavigateToDestinationUrlAfterModalClosed = false;

		this._activeDialog?.close();

		const activeDialog = this._activeDialog = this._dialogsManager.open(cmpt.modal.template, {
			id: cmpt.id,
			panelClass: [ ...(cmpt.panelClass ?? []), 'bp-modal-overlay-pane' ],
			disableClose: !!cmpt.disableClose,
		});

		this._activeDialog
			.afterClosed()
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				if (this._activeDialog !== activeDialog)
					return;

				this._activeDialog = null;

				if (this._shouldNavigateToDestinationUrlAfterModalClosed) {
					// If navigation was made forcefully, say by a guard,
					// we don't need to navigate back to the destination url
					if (this.router.url !== this._destinationUrl)
						void this.router.navigateByUrl(this._destinationUrl);
				} else
					void this.router.navigateByUrl(this._getUrlWithoutModalOutlet());
			});
	}

	private _getUrlWithoutModalOutlet(): string {
		const urlTree = this.router.parseUrl(this.router.url);

		unset(urlTree, URL_TREE_PRIMARY_MODAL_OUTLET_PATH);

		unset(urlTree, URL_TREE_MODAL_OUTLET_PATH);

		return urlTree.toString();
	}

	private _checkUrlHasModalOutlet(url: string): boolean {
		const urlTree = this.router.parseUrl(url);

		return has(urlTree, URL_TREE_PRIMARY_MODAL_OUTLET_PATH) || has(urlTree, URL_TREE_MODAL_OUTLET_PATH);
	}
}
