import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';
import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

@Component({
	selector: 'bp-current-subscription-limits-meter-popup',
	templateUrl: './current-subscription-limits-meter-popup.component.html',
	styleUrls: [ './current-subscription-limits-meter-popup.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionLimitsMeterPopupComponent {
	@Input() currentOrganizationSubscription!: CurrentOrganizationSubscription;

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentOrganizationSubscription.subscriptionPlan;
	}

	get featureLimitsMap(): CurrentOrganizationSubscription['featureLimitsMap'] {
		return this.currentOrganizationSubscription.featureLimitsMap;
	}
}
