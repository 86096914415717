import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedFeaturesCalendlyModule } from '@bp/frontend/features/calendly';

import { CalendlyBookDemoDirective } from './directives';
import { BookDemoCalendarComponent } from './components';

const EXPORTS = [
	BookDemoCalendarComponent,
	CalendlyBookDemoDirective,
];

@NgModule({
	imports: [
		CommonModule,
		SharedFeaturesCalendlyModule,
	],
	declarations: EXPORTS,
	exports: [
		...EXPORTS,
		SharedFeaturesCalendlyModule,
	],

})
export class SharedFeaturesCalendlyBookDemoModule { }
