import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import { SubscriptionPlanPurchase } from '@bp/frontend/domains/subscription-plans/checkout';

import { DowngradeSubscriptionPlanApiRequest } from '../models';

export const ACTIONS_SCOPE = '[Domain][Current Organization Subscription]';

export const load = createAction(`${ ACTIONS_SCOPE } Load`);

export const openBillingPortal = createAction(`${ ACTIONS_SCOPE } Open billing portal`);

export const loadBillingPortalDetails = createAction(`${ ACTIONS_SCOPE } Load billing portal details`);

export const refresh = createAction(`${ ACTIONS_SCOPE } Refresh`);

export const purchaseSubscriptionPlan = createAction(
	`${ ACTIONS_SCOPE } Purchase subscription plan ${ TELEMETRY_HIDDEN_MARK }`,
	props<{ subscriptionPlanPurchase: SubscriptionPlanPurchase }>(),
);

export const downgradeSubscriptionPlan = createAction(
	`${ ACTIONS_SCOPE } Downgrade subscription plan`,
	props<{ downgradeSubscriptionPlanApiRequest: DowngradeSubscriptionPlanApiRequest }>(),
);

export const resetState = createAction(`${ ACTIONS_SCOPE } Reset state`);

export const resetError = createAction(`${ ACTIONS_SCOPE } Reset error`);

export const setMustPurchaseSubscriptionPlan = createAction(
	`${ ACTIONS_SCOPE } Set is must purchase subscription plan state`,
	props<{ isMustPurchaseSubscriptionPlan: boolean }>(),
);
