<caption class="flex-align-items-baseline">
	<span [bpTooltip]="featureLimit.featureDescription">{{ featureLimit.featureLabel }}</span>

	<span class="counter-to-limit-info ml-auto font-weight-bold">
		<span class="counter">{{ featureLimit.counter }}</span>
		<span class="limit text-slate-gray">
			/
			<span [bpTooltip]="featureLimit.isNotUnlimited ? 'Quantity included in the plan' : null">
				{{ featureLimit.limit ?? '∞' }}</span
			>
		</span>
	</span>
</caption>

<div *ngIf="featureLimit.isNotUnlimited" class="mt-1 grid-stacked-children limit-meter-bar-container">
	<div class="limit-meter-bar-rail"></div>
	<div class="limit-meter-bar" [style.width.%]="featureLimit.counterToLimitPercent! * 100"></div>
</div>
