import { createAction, props } from '@ngrx/store';

import { IIdentity } from '@bp/shared/domains/jwt-session';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

import { IdentityApiActions } from './identity-api.actions';

export const IDENTITY_STATE_SCOPE = '[Domain][Identity]';

export const IDENTITY_STATE_API_SCOPE = `[API]${ IDENTITY_STATE_SCOPE }`;

export function composeIdentityApiActions<TIidentity extends IIdentity>(): IdentityApiActions<TIidentity> {
	return {
		loginSuccess: createAction(
			`${ IDENTITY_STATE_API_SCOPE } Login Success`,
			props<ResultActionPayload<TIidentity>>(),
		),

		loginFailure: createAction(`${ IDENTITY_STATE_API_SCOPE } Login Failure`, props<ErrorActionPayload>()),
	};
}
