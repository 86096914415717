<mat-progress-bar
	*ngIf="_changeSubscriptionPlanPending$ | async"
	class="bp-toolbar-progress-bar"
	color="primary"
	mode="indeterminate"
	@slide
/>

<div class="header">
	<button
		*ngIf="!_isSubscriptionPlansTabActive"
		mat-icon-button
		class="back-arrow-button"
		bpTooltip="Back to Plans"
		type="button"
		@fade
		(click)="gotoSubscriptionPlansTab()"
	>
		<mat-icon class="material-icons-round">arrow_back</mat-icon>
	</button>
</div>

<div
	class="content bp-thin-scrollbar"
	[class.checkout-tab-active]="_isCheckoutTabActive"
	[class.downgrade-tab-active]="_isDowngradeTabActive"
>
	<div *ngIf="_currentOrganizationSubscriptionFacade.loading$ | async" class="pending-spinner-container" @fade>
		<bp-spinning-logo />
	</div>

	<ng-container>
		<bp-enterprise-subscription-pane *ngIf="_currentSubscriptionPlan?.isEnterprise; else nonEnterprisePlansTpl" />

		<ng-template #nonEnterprisePlansTpl>
			@if (_environment.isProduction) {
				<bp-contact-to-change-subscription-plan-pane [mode]="mode" />
			} @else {
				<mat-tab-group
					class="headless-mat-tab-group"
					dynamicHeight
					@fade
					[selectedIndex]="_currentTabIndex$ | async"
					[disablePagination]="true"
					(selectedTabChange)="onSelectedTabChange($event)"
				>
					<mat-tab>
						<div
							*ngIf="_subscriptionPlansSharedFacade.allPublic$ | async as subscriptionPlans"
							class="subscription-plans-tab-container"
						>
							<bp-subscription-plans-overview
								[mode]="mode"
								[currentOrganizationSubscription]="
									(_currentOrganizationSubscriptionFacade.entity$ | async) ?? null
								"
								[subscriptionPlans]="subscriptionPlans"
								(executeSubscriptionPlanAction)="executeSubscriptionPlanAction($event)"
								(selectedChargePeriodChange)="_selectedChargePeriod = $event"
								(selectedCurrencyChange)="_selectedCurrency = $event"
							/>
						</div>
					</mat-tab>

					<mat-tab>
						<ng-template matTabContent>
							<bp-subscription-plan-checkout
								[subscriptionPlan]="_selectedSubscriptionPlan!"
								[chargePeriod]="_selectedChargePeriod"
								[merchantName]="(_currentOrganizationFacade.entity$ | async)?.name"
								[currency]="_selectedCurrency"
								[pending]="_changeSubscriptionPlanPending$ | async"
								[error]="_currentOrganizationSubscriptionFacade.error$ | async"
								(purchaseSubscriptionPlan)="
									_currentOrganizationSubscriptionFacade.purchaseSubscriptionPlan($event)
								"
							/>
						</ng-template>
					</mat-tab>

					<mat-tab>
						<ng-template matTabContent>
							<bp-subscription-downgrade
								*ngIf="
									_currentOrganizationSubscriptionFacade.entity$
										| async as currentOrganizationSubscription
								"
								[subscriptionPlan]="_selectedSubscriptionPlan!"
								[chargePeriod]="_selectedChargePeriod"
								[currentOrganizationSubscription]="currentOrganizationSubscription"
								[error]="_currentOrganizationSubscriptionFacade.error$ | async"
								(cancel)="gotoSubscriptionPlansTab()"
								(downgrade)="downgradeSubscription()"
							/>
						</ng-template>
					</mat-tab>

					<mat-tab bodyClass="subscription-plan-congratulation">
						<ng-template matTabContent>
							<bp-subscription-plan-congratulation
								[subscriptionPlan]="(_currentOrganizationSubscriptionFacade.subscriptionPlan$ | async)!"
								(continue)="subscriptionPlanSuccessfullyPurchased.emit()"
							/>
						</ng-template>
					</mat-tab>
				</mat-tab-group>
			}
		</ng-template>
	</ng-container>
</div>

<div
	*ngIf="(_currentSubscriptionPlan$ | async)?.isFree && _isSubscriptionPlansTabActive && !_environment.isProduction"
	class="footer"
	@fade
>
	<content class="flex-align-items-center">
		<div
			*ngIf="_subscriptionPlansSharedFacade.pro$ | async as proSubscriptionPlan"
			class="money-back-guarantee ml-4 mr-5 mat-body-2 text-primary flex-column-align-items-center"
		>
			<div class="flex-align-items-center">
				<mat-icon *ngFor="let star of 3 | range" class="bp-icon-sm material-icons-round"> star </mat-icon>
				<span class="ml-2 mr-2">
					First {{ proSubscriptionPlan.prices!.get(_selectedChargePeriod)!.refundableForDays ?? 14 }} Days
				</span>
				<mat-icon *ngFor="let star of 3 | range" class="bp-icon-sm material-icons-round"> star </mat-icon>
			</div>
			<div>Money Back Guarantee</div>
		</div>

		<img src="assets/trust-icons.png" />

		<button
			*bpHasAccess="_MerchantAdminFeature.subscriptionsManagement.actions.edit"
			type="button"
			class="bp-round-button ml-auto"
			color="primary"
			mat-flat-button
			(click)="upgradeToPro()"
		>
			{{ mode.isPurchase ? 'Purchase Pro' : 'Upgrade to Pro' }}
		</button>
	</content>
</div>
