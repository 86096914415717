import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { selectRouteData } from '@bp/frontend/state';

import type { RightDrawerComponent } from '../components';

import {
	closeFloatOutlets, closeSidenav, hideBackdrop, hideFullpageBackdrop, openSidenav,
	showBackdrop, showFullpageBackdrop
} from './layout.actions';
import {
	getHasBackdrop, getHasFullpageBackdrop, getShowSidenav
} from './layout.selectors';

@Injectable({
	providedIn: 'root',
})
export class LayoutFacade {

	showSidenav$ = this._store$.select(getShowSidenav);

	showSidenav = false;

	hasBackdrop$ = this._store$.select(getHasBackdrop);

	hasFullpageBackdrop$ = this._store$.select(getHasFullpageBackdrop);

	fullscreen$ = this._store$
		.select(selectRouteData)
		// eslint-disable-next-line ngrx/avoid-mapping-selectors
		.pipe(map(v => !!v['fullscreen']));

	closeFloatOutlets$ = this._actions$.pipe(ofType(closeFloatOutlets));

	private readonly _activeRightDrawer$ = new BehaviorSubject<RightDrawerComponent | null>(null);

	activeRightDrawer$ = this._activeRightDrawer$.asObservable();

	get activeRightDrawer(): RightDrawerComponent | null {
		return this._activeRightDrawer$.value;
	}

	constructor(
		private readonly _store$: Store,
		private readonly _actions$: Actions,
	) {
		this._updateShowSidenavOnStateChange();
	}

	closeFloatOutlets(): void {
		this._store$.dispatch(closeFloatOutlets());
	}

	openSidenav(): void {
		this._store$.dispatch(openSidenav());
	}

	closeSidenav(): void {
		this._store$.dispatch(closeSidenav());
	}

	showBackdrop(): void {
		this._store$.dispatch(showBackdrop());
	}

	hideBackdrop(): void {
		this._store$.dispatch(hideBackdrop());
	}

	showFullpageBackdrop(): void {
		this._store$.dispatch(showFullpageBackdrop());
	}

	hideFullpageBackdrop(): void {
		this._store$.dispatch(hideFullpageBackdrop());
	}

	setActiveRightDrawer(activeRightDrawer: RightDrawerComponent | null): void {
		this._activeRightDrawer$.next(activeRightDrawer);
	}

	private _updateShowSidenavOnStateChange(): void {
		this.showSidenav$
			.subscribe(showSidenav => (this.showSidenav = showSidenav));
	}
}
