import { hasOwnProperty } from './has-own-property';

export function trackById<T extends Object>(index: number, item: T) {
	if (!hasOwnProperty(item, 'id'))
		throw new Error('The Id Tracker requires on the item to have the id property');

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	return (<any> item).id;
}

export function trackByIdentity<T extends Object>(index: number, item: T) {
	return item;
}
