export type CryptoCurrencyDefinition = {
	name: string;
	symbol?: string;

	/**
	 * Won't be used in pickers, but the value can be used in other places as readonly
	 */
	isDeprecated?: boolean;
};

/* eslint-disable @typescript-eslint/naming-convention */
export const CRYPTO_CURRENCY_CODES_AND_NAMES = {
	'1INCH': { name: '1inch' },
	AAVE: { name: 'Aave' },
	ADA: { name: 'Cardano', symbol: '₳' },
	AKRO: { name: 'Akropolis' },
	ALPHA: { name: 'Alpha Finance Lab' },
	AMP: { name: 'Amp' },
	AUDIO: { name: 'Audius' },
	AXS: { name: 'Axie Infinity' },
	BADGER: { name: 'Badger DAO' },
	BAL: { name: 'Balancer' },
	BAND: { name: 'Band Protocol' },
	BAT: { name: 'Basic Attention Token', symbol: '⟁' },
	BCH: { name: 'Bitcoin Cash', symbol: 'Ƀ' },
	BNB: { name: 'Binance Coin' },
	BTC: { name: 'Bitcoin', symbol: '₿' },
	'BTC-LIQUID': { name: 'Bitcoin, Liquid', symbol: '₿' },
	'BTC-BECH32': { name: 'Bitcoin, SegWit', symbol: '₿' },
	'BTC-LIGHTNING': { name: 'Bitcoin, Lightning', symbol: '₿' },
	BUSD: { name: 'Binance USD' },
	'BUSD-ERC20': { name: 'Binance USD, Ethereum' },
	'BUSD-BEP20': { name: 'Binance USD, Binance' },
	BZRX: { name: 'bZx Protocol' },
	CAKE: { name: 'PancakeSwap' },
	CEL: { name: 'Celsius' },
	CHR: { name: 'Chromia' },
	CHZ: { name: 'Chiliz' },
	COMP: { name: 'Compound' },
	CRV: { name: 'Curve DAO Token' },
	DAI: { name: 'Dai', symbol: '◈' },
	DASH: { name: 'DASH' },
	DOGE: { name: 'Dogecoin', symbol: 'Ɖ' },
	ENJ: { name: 'Enjin Coin' },
	ETH: { name: 'Ethereum', symbol: '⟠' },
	'ETH-BEP20': { name: 'Ethereum, Binance', symbol: '⟠' },
	FET: { name: 'Fetch.ai' },
	FTM: { name: 'Fantom' },
	FTT: { name: 'FTX Token' },
	GRT: { name: 'The Graph' },
	GUSD: { name: 'Gemini Dollar' },
	HOT: { name: 'Holo' },
	IOST: { name: 'IOSToken' },
	LEO: { name: 'UNUS SED LEO' },
	LINK: { name: 'Chainlink' },
	LRC: { name: 'Loopring' },
	LTC: { name: 'Litecoin', symbol: 'Ł' },
	MANA: { name: 'Decentraland' },
	MATIC: { name: 'Polygon' },
	MKR: { name: 'Maker', symbol: 'Μ' },
	NEM: { name: 'NEM' },
	NEO: { name: 'NEO' },
	'NU-ETH': { name: 'NuCypher' },
	OCEAN: { name: 'Ocean Protocol' },
	OMG: { name: 'OmiseGO' },
	PAX: { name: 'Paxos Standard Token' },
	QNT: { name: 'Quant' },
	RARI: { name: 'Rarible' },
	REEF: { name: 'Reef' },
	REN: { name: 'Ren' },
	REV: { name: 'Revain' },
	RSR: { name: 'Reserve Rights' },
	SAND: { name: 'The Sandbox' },
	SHIB: { name: 'Shiba Inu' },
	'SHIB-ERC20': { name: 'Shiba Inu, Ethereum' },
	'SHIB-BEP20': { name: 'Shiba Inu, Binance' },
	SNX: { name: 'Synthetix' },
	SRM: { name: 'Serum' },
	SUSHI: { name: 'SushiSwap' },
	SXP: { name: 'Swipe' },
	TEL: { name: 'Telcoin' },
	TRX: { name: 'TRX' },
	TUSD: { name: 'TrueUSD' },
	UNI: { name: 'Uniswap' },
	USDP: { name: 'Pax Dollar' },
	USDT: { name: 'Tether', symbol: '₮' },

	USDTE: { name: 'Tether, Avalanche', symbol: '₮' },

	USDTT: { name: 'Tether, Tron', symbol: '₮' },
	'USDT-LIQUID': { name: 'Tether, Liquid', symbol: '₮' },
	'USDT-TRC20': { name: 'Tether, Tron', symbol: '₮' },
	'USDT (TRC-20)': { name: 'Tether, Tron', symbol: '₮', isDeprecated: true },

	'USDT-ERC20': { name: 'Tether, Ethereum', symbol: '₮' },
	'USDT (ERC-20)': { name: 'Tether, Ethereum', symbol: '₮', isDeprecated: true },

	USDTB: { name: 'Tether, Binance', symbol: '₮' },
	'USDT-BEP20': { name: 'Tether, Binance', symbol: '₮' },
	'USDT (BEP-20)': { name: 'Tether, Binance', symbol: '₮', isDeprecated: true },

	USDC: { name: 'USD Coin' },
	'USDC-ERC20': { name: 'USD Coin, Ethereum' },
	'USDC-TRC20': { name: 'USD Coin, Tron' },
	'USDC-BEP20': { name: 'USD Coin, Binance' },

	UST: { name: 'TerraUSD' },
	VIU: { name: 'Viuly' },
	XLM: { name: 'Stellar', symbol: '🚀' },
	XMR: { name: 'Monero', symbol: 'ɱ' },
	XRP: { name: 'Ripple', symbol: '✕' },
	YFI: { name: 'yearn.finance' },
	ZEC: { name: 'Zcash', symbol: 'ⓩ' },
	ZRX: { name: '0x' },
	'WBNB-BEP20': { name: 'Wrapped Binance Coin, Binance' },
	BINANCEPAY: { name: 'Binance Pay' },
	SOL: { name: 'Solana' },
	AVAX: { name: 'Avalanche' },
} satisfies {[code: string]: CryptoCurrencyDefinition };

export type CryptoCurrencyCode = keyof typeof CRYPTO_CURRENCY_CODES_AND_NAMES;
