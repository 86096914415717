import { DescribableEnumeration } from '@bp/shared/models/core';

export class Role extends DescribableEnumeration {

	// #region Bridger Admin

	static readonly baMember = new Role({
		isAssignable: false,
	});

	static readonly baAdmin = new Role({
		displayName: 'Administrator',
		description: 'Unrestricted access to the console',
	});

	static readonly baBridgerPspsManager = new Role({
		displayName: 'Bridger Psps Manager',
		description: 'Can edit all PSP settings',
	});

	static readonly baBridgerPspsPublicInfoManager = new Role({
		displayName: 'Bridger Psps Public Info Manager',
		description: 'Can edit public PSP settings',
	});

	static readonly baSupportAgentTier0 = new Role({
		displayName: 'Support Tier 0',
		description: 'Can only log in into merchant organization and get restricted readonly access',
	});

	static readonly baSupportAgentTier1 = new Role({
		displayName: 'Support Tier 1',
		description: 'Can log in into merchant organization and get restricted readonly access',
	});

	static readonly baSupportAgentTier2 = new Role({
		displayName: 'Support Tier 2',
		description: 'Can log in into merchant organization and get full readonly access',
	});

	static readonly baReadonly = new Role({
		displayName: 'Readonly',
		description: 'Has readonly access to Bridger Admin',
	});

	static readonly baIntegrationSpecialist = new Role({
		displayName: 'Integration Specialist',
		description: 'Responsible for onboarding new merchants and helping them with integration',
	});

	static readonly baOrganizationsManager = new Role({
		displayName: 'Organizations\' Manager',
		description: 'Has access to organizations and able to change feature flags',
	});

	static readonly baOrganizationsMembersManager = new Role({
		displayName: 'Organizations\' Members\' Manager',
		description: 'Has access to organizations and able to invite members to organizations',
	});

	static readonly bridgerAdmin = [
		Role.baMember,
		Role.baAdmin,
		Role.baBridgerPspsManager,
		Role.baBridgerPspsPublicInfoManager,
		Role.baSupportAgentTier0,
		Role.baSupportAgentTier1,
		Role.baSupportAgentTier2,
		Role.baReadonly,
		Role.baIntegrationSpecialist,
		Role.baOrganizationsManager,
		Role.baOrganizationsMembersManager,
	];

	static readonly bridgerAdminAssignable = Role.bridgerAdmin.filter(role => role.isAssignable);

	// #endregion Bridger Admin

	// #region Merchant Admin

	static readonly merchantOwner = new Role({
		displayName: 'Owner',
		description: 'Unrestricted access to the organization, its data, and its settings.',
	});

	static readonly admins = new Role({
		displayName: 'Administrator',
		description: 'Has full access to the system except for the most sensitive organization-wide settings, like subscription plan changes.',
	});

	static readonly maAccountant = new Role({
		displayName: 'Accountant',
		description: 'View and export reports.\n View dashboard.\n View and execute payouts.',
	});

	static readonly maAccountantJunior = new Role({
		displayName: 'Junior Accountant',
		description: 'View and export reports.\n View dashboard.\n View-only payouts.',
	});

	static readonly maSales = new Role({
		displayName: 'Sales',
		description: 'View, refund, capture and void transactions.\n View-only router, route rules, and psps. \n Access virtual terminal.',
	});

	static readonly maSalesManager = new Role({
		displayName: 'Sales Manager',
		description: 'View, refund, capture and void transactions.\n View and execute payouts.\n View-only router, route rules, dashboard, reports and psps.\n Access virtual terminal.',
	});

	static readonly api = new Role({
		displayName: 'API',
		description: 'Used for accessing bridgerpay API',
		isAssignable: false,
	});

	static readonly maReadonly = new Role({
		isAssignable: false,
	});

	static readonly merchantAdmin = [
		Role.merchantOwner,
		Role.admins,
		Role.maAccountant,
		Role.maAccountantJunior,
		Role.maSales,
		Role.maSalesManager,
		Role.api,
		Role.maReadonly,
	];

	static readonly merchantAdminAssignable = Role.merchantAdmin.filter(role => role.isAssignable);

	// #endregion Merchant Admin

	get internalName(): string {
		return this.snakeCase;
	}

	get isBridgerAdminScope(): boolean {
		return Role.bridgerAdmin.includes(this);
	}

	get isBridgerAdminAssignableScope(): boolean {
		return Role.bridgerAdminAssignable.includes(this);
	}

	get isMerchantAdminScope(): boolean {
		return Role.merchantAdmin.includes(this);
	}

	get isMerchantAdminAssignableScope(): boolean {
		return Role.merchantAdminAssignable.includes(this);
	}

	readonly isAssignable: boolean;

	constructor(config?: {
		displayName?: string;
		description?: string;
		isAssignable?: boolean;
	}) {
		super(config?.displayName, config?.description);

		this.isAssignable = config?.isAssignable ?? true;
	}

}
