import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { IAppVersion } from '@bp/shared/typings';

import { MomentFormatPipe, MomentFromNowPipe } from '@bp/frontend/features/moment/pipes';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { EnvironmentService } from '@bp/frontend/services/environment';

@Component({
	selector: 'bp-app-version',
	standalone: true,
	imports: [
		CommonModule,

		SharedFeaturesTooltipModule,

		MomentFormatPipe,
		MomentFromNowPipe,
	],
	templateUrl: './app-version.component.html',
	styleUrls: [ './app-version.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppVersionComponent {

	@Input() renderVersion = true;

	protected readonly _environment = inject(EnvironmentService);

	protected get _appVersion(): IAppVersion {
		return this._environment.appVersion;
	}

	constructor() {
		this.__outputAppFrontendVersionToConsole();
	 }

	private __outputAppFrontendVersionToConsole(): void {
		const releaseTitle = this._appVersion.shortSHA
			? ` ${ this._appVersion.releaseName } ✨ ${ this._appVersion.shortSHA } ✨`
			: '';

		console.log(
			`%c✨ ${ this._appVersion.release } ✨ ${ releaseTitle }`,
			 'background-color: #2578ea; color: white; font-family: monospace; padding: 1em 1.5em; font-size: 4.5em; line-height: 1;',
		);
	}
}
