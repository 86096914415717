import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';

import { IIdentity } from '@bp/shared/domains/jwt-session';

import { IdentityState } from './compose-identity-reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeIdentitySelectors<TIdentity extends IIdentity, TState extends IdentityState<TIdentity>>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		user: createSelector(featureStateSelector, state => state.user),

		organizationPermissions: createSelector(
			featureStateSelector,
			state => state.organizationPermissions,
		),

		hiddenOrganizationPermissions: createSelector(
			featureStateSelector,
			state => state.hiddenOrganizationPermissions,
		),

		pending: createSelector(featureStateSelector, state => state.pending),

		error: createSelector(featureStateSelector, state => state.error),

		urlForRedirectionAfterLogin: createSelector(
			featureStateSelector,
			state => state.urlForRedirectionAfterLogin,
		),
	};
}

export type IdentitySelectors<TIdentity extends IIdentity, TState extends IdentityState<TIdentity>> =
	ReturnType<typeof composeIdentitySelectors<TIdentity, TState>>;
