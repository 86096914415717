<mat-button-toggle-group
	class="button-toggle-group-flat"
	[value]="selectedOptionType"
	(change)="selectedOptionTypeChange.emit($event.value)"
>
	<mat-button-toggle
		*ngFor="let option of optionTypes"
		matBadgeSize="small"
		[value]="option"
		[matBadge]="selectedPspsByPaymentOptionTypeMap?.get(option)?.length?.toString() || ''"
		[matBadgeOverlap]="true"
		[matBadgeHidden]="!selectedPspsByPaymentOptionTypeMap?.get(option)?.length"
	>
		{{ option.displayName }}
	</mat-button-toggle>
</mat-button-toggle-group>
