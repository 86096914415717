import { isEqual, isNil, noop, uniq } from 'lodash-es';

import {
	ChangeDetectorRef, Directive, HostBinding, Input, isDevMode, Output, inject, EventEmitter
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { Destroyable } from '@bp/frontend/models/common';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ControlComponent<TControlValue = any>
	extends Destroyable
	implements ControlValueAccessor, Validator {
	protected _cdr = inject(ChangeDetectorRef);

	@Input() value: TControlValue | null = null;

	@Input() name?: string;

	@Output() readonly valueChange = new EventEmitter<TControlValue | null>();

	@HostBinding('class.control')
	isControl = true;

	@HostBinding('class.empty')
	get empty(): boolean {
		return isNil(this.value) || (<any> this.value) === '';
	}

	protected _validator!: ValidatorFn | null;

	private __onChangeHandlers: ((value: TControlValue | null) => void)[] = [];

	validatorOnChange = noop;

	/** `View -> model callback called when input has been touched` */
	onTouched = noop;

	/** `View -> model callback called when value changes` */
	onChange = (v: TControlValue | null): void => void this.__onChangeHandlers.forEach(callback => void callback(v));

	// #region Implementation of the ControlValueAccessor interface
	writeValue(value: TControlValue): void {
		bpQueueMicrotask(() => {
			this.value = value;

			this._cdr.markForCheck();
		});
	}

	registerOnChange(onChangeHandler: (value: any) => void): void {
		this.__onChangeHandlers = uniq([ ...this.__onChangeHandlers, onChangeHandler ]);
	}

	registerOnTouched(onTouchedHandler: () => void): void {
		this.onTouched = onTouchedHandler;
	}
	// #endregion Implementation of the ControlValueAccessor interface

	// #region Implementation of the Validator interface
	registerOnValidatorChange(validatorOnChangeHandler: () => void): void {
		this.validatorOnChange = validatorOnChangeHandler;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this._validator ? this._validator(c) : null;
	}
	// #endregion Implementation of the Validator interface

	setValue(
		value: TControlValue | null,
		{ emitChange, skipEqualCheck }: { emitChange?: boolean; skipEqualCheck?: boolean } = {},
	): void {
		emitChange ??= true;

		skipEqualCheck ??= false;

		if (!skipEqualCheck && isEqual(value, this.value)) {
			this.validatorOnChange();

			// eslint-disable-next-line no-console
			isDevMode() && console.debug(`${ this.constructor.name }[${ this.name }]: wasn't updated due to same value set`);

			return;
		}

		isDevMode() && console.debug(`${ this.constructor.name }[${ this.name }]: set from`, this.value, 'to', value);

		this.value = value;

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (emitChange) {
			this.valueChange.emit(value);

			this.onChange(value);
		}

		// This._cdr.detectChanges();
		this._cdr.markForCheck();
	}

}
