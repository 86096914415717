import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, IState } from './current-organization.reducer';

const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const selectCurrentOrganization = createSelector(selectFeatureState, state => state.entity);

export const selectCurrentSubscription = createSelector(
	selectCurrentOrganization,
	currentOrganization => currentOrganization?.currentSubscription,
);

export const selectCurrentOrganizationLoading = createSelector(selectFeatureState, state => state.loading);

export const selectBridgerpayProviders = createSelector(selectFeatureState, state => state.bridgerPsps);

export const selectError = createSelector(selectFeatureState, state => state.error);

export const selectVirtualTerminalData = createSelector(selectFeatureState, state => state.virtualTerminalData);
