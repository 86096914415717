import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TextMaskConfig } from '@bp/shared/features/text-mask';
import { Validators } from '@bp/shared/features/validation/models';

import { InputComponent } from '@bp/frontend/components/controls';
import { FormFieldControlComponent } from '@bp/frontend/components/core';

@Component({
	selector: 'bp-otp-code-input',
	templateUrl: './otp-code-input.component.html',
	styleUrls: [ './otp-code-input.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: OtpCodeInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: OtpCodeInputComponent,
			multi: true,
		},
	],
})
export class OtpCodeInputComponent extends FormFieldControlComponent<string | null> {

	@ViewChild(InputComponent, { static: true }) private readonly _input!: InputComponent;

	mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		includeMaskInValue: false,
		guide: false,
		mask: [	/\d/u, /\d/u, /\d/u, '-', /\d/u, /\d/u, /\d/u ],
	});

	protected override _validator = Validators.minLength(6);

	focus(): void {
		this._input.focus();
	}
}
