import { Enumeration } from '@bp/shared/models/core/enum';

export class CalendlyEvent extends Enumeration {

	static profilePageViewed = new CalendlyEvent();

	static eventTypeViewed = new CalendlyEvent();

	static dateAndTimeSelected = new CalendlyEvent();

	static eventScheduled = new CalendlyEvent();

}
