import { DescribableEnumeration } from '@bp/shared/models/core';
import { Role } from '@bp/shared/domains/roles';

export class MemberRole extends DescribableEnumeration {

	static readonly merchantOwner = new MemberRole(Role.merchantOwner);

	static readonly admins = new MemberRole(Role.admins);

	static readonly maAccountantJunior = new MemberRole(Role.maAccountantJunior);

	static readonly maAccountant = new MemberRole(Role.maAccountant);

	static readonly maSales = new MemberRole(Role.maSales);

	static readonly maSalesManager = new MemberRole(Role.maSalesManager);

	get isOwner(): boolean {
		return this === MemberRole.merchantOwner;
	}

	get isAdmin(): boolean {
		return this === MemberRole.admins;
	}

	get affectableRoles(): MemberRole[] {
		return MemberRole.getList().filter(role => this.canInteractWith(role));
	}

	get internalName(): string {
		return this.snakeCase;
	}

	constructor(config: {
		displayName?: string;
		description?: string;
	}) {
		super(config.displayName, config.description);
	}

	canInteractWith(role: MemberRole): boolean {
		if (this.isOwner)
			return true;

		if (this.isAdmin) {
			return ![
				MemberRole.merchantOwner,
				MemberRole.admins,
			].includes(role);
		}

		return false;
	}

}
