<bp-lottie-player eager looped class="animated-logo" [animationName]="congratulationVm.logoLottieAnimation.kebabCase" />

<h2 class="title text-center uppercase">{{ congratulationVm.title }}</h2>

<div class="features-wrapper mb-4">
	<div class="features">
		<div class="title mb-3" [innerHTML]="congratulationVm.subtitle"></div>

		<ul class="features">
			<li *ngFor="let feature of congratulationVm.features | takePresent" class="feature mb-3">
				<bp-feature-presence-or-count [value]="true" />

				<div class="description" [innerHTML]="feature"></div>
			</li>
		</ul>
	</div>

	<bp-img class="router" url="{{ assetsDir }}router.png" />
</div>

<div *ngIf="isWizardContext" class="continue-on-laptop-call">
	<bp-img url="{{ assetsDir }}continue-on-laptop.svg" />
	<p class="mt-3 mb-3">Continue from your desktop for a better experience</p>
</div>

<!--<div [innerHTML]="congratulationVm.additionalInfo"></div>-->
