import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';
import { JSON_NAMING_STRATEGY_HEADER, JsonNamingStrategy } from '@bp/shared/models/common';
import { API_PATH_SEGMENT } from '@bp/shared/models/core';

import { takeFirstTruthy } from '@bp/frontend/rxjs';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { LocalBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { HttpConfig } from './models';

export const CF_WORKER_PATH_SEGMENT = 'cf'; // cloudflare worker path segment

export const CORRELATION_ID_HEADER = 'x-correlation-id';

export const CONTENT_TYPE_HEADER = 'Content-Type';

const AUTHORIZATION_HEADER = 'Authorization';

const HTTP_CONFIG_SERVICE = 'BP_HTTP_CONFIG';

@Injectable({ providedIn: 'root' })
export class HttpConfigService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __backendEndpointApiSegment = `/${ API_PATH_SEGMENT }`;

	private readonly __backendEndpointBaseSegment = this.__environment.api === 'relative'
		? this.__backendEndpointApiSegment
		: (this.__environment.api.includes('api')
			? this.__environment.api
			: `${ this.__environment.api }${ this.__backendEndpointApiSegment }`);

	private __backendEndpointVersion = this.__environment.apiVersion ?? null;

	private get __backendEndpointVersionSegment(): string {
		return this.__backendEndpointVersion
			? `/${ this.__backendEndpointVersion }`
			: '';
	}

	private get __localBackendHost(): string {
		const protocol = `http${ this.__environment.isLocal ? 's' : '' }`;

		return `${ protocol }://localhost:5000${ this.__backendEndpointApiSegment }${ this.__backendEndpointVersionSegment }`;
	}

	get backendBaseSegment(): string {
		return LocalBackendState.isActive
			? this.__localBackendHost
			: `${ this.__backendEndpointBaseSegment }${ this.__backendEndpointVersionSegment }`;
	}

	readonly headers: Record<string, string | null> = {
		[CONTENT_TYPE_HEADER]: 'application/json',
		[JSON_NAMING_STRATEGY_HEADER]: JsonNamingStrategy.CamelCase,

		/*
		 * All the api calls should bypass the service worker since due to cloudlflare we sometimes have the 302
		 * response code which if handled by the browser redirects the page, but with the service worker used as proxy for the api calls
		 * it doesn't happen
		 */
		'ngsw-bypass': '',
		// For cloudflare access https://developers.cloudflare.com/access/faq/
		credentials: 'same-origin',
	};

	private readonly __authorized$ = new BehaviorSubject(false);

	readonly hasAuthorizationToken$ = this.__authorized$.pipe(takeFirstTruthy);

	private readonly __configPerApiEndpointNamespace: Record<string, HttpConfig> = {};

	constructor() {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any>globalThis)[HTTP_CONFIG_SERVICE] = this;

		this.__tryCloneAuthHeaderFromParentWindow();
	}

	setAuthorizationHeader(token: string): void {
		this.headers[AUTHORIZATION_HEADER] = `Bearer ${ token }`;

		this.__authorized$.next(true);
	}

	removeAuthorizationHeader(): void {
		this.headers[AUTHORIZATION_HEADER] = '';

		this.__authorized$.next(false);
	}

	setHttpHeader(key: string, value: string): void {
		this.headers[key] = value;
	}

	setAcceptLanguageHeader(lang: string): void {
		this.setHttpHeader('Accept-Language', lang);
	}

	setBackendEndpointVersion(version: string): void {
		this.__backendEndpointVersion = version;
	}

	setConfigPerApiEndpointNamespace(
		namespace: string,
		config: DTO<HttpConfig>,
	): void {
		this.__configPerApiEndpointNamespace[namespace] = new HttpConfig(config);
	}

	getApiEndpointNamespaceConfig(endpoint: string): HttpConfig | undefined {
		const [ , rest ] = endpoint.split(`${ API_PATH_SEGMENT }/`);

		if (!rest)
			return;

		const [ namespace ] = rest.split(/\/|\?/u);

		return this.__configPerApiEndpointNamespace[namespace];
	}

	private __tryCloneAuthHeaderFromParentWindow(): void {
		try {
			const isNestedWindowOfSameOrigin = window.parent !== window.self && window.parent.origin === window.self.origin;

			if (isNestedWindowOfSameOrigin) {
				const parentWindowAuthHeader = window.parent.BP_HTTP_CONFIG.headers[AUTHORIZATION_HEADER];

				parentWindowAuthHeader && this.setAuthorizationHeader(parentWindowAuthHeader);
			}
		} catch {
			// parent origin will throw an error if the parent is not of the same origin
		}
	}

}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		[HTTP_CONFIG_SERVICE]: HttpConfigService;
	}
}
