import { isNil } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';
import { InputComponent } from '@bp/frontend/components/controls';

@Component({
	selector: 'bp-psp-selector',
	templateUrl: './psp-selector.component.html',
	styleUrls: [ './psp-selector.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	animations: [ FADE_IN_LIST_STAGGERED ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PspSelectorComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PspSelectorComponent,
			multi: true,
		},
	],
})
export class PspSelectorComponent extends FormFieldControlComponent<string | null> implements OnChanges {

	@Input()
	get providers() {
		return this._providers;
	}

	set providers(value: string[] | null | undefined) {
		this._providers = value ?? [];
	}

	private _providers: string[] = [];

	@Input() override label = 'Payment provider';

	@Input() override placeholder = 'Type to search';

	@Input() panelClass!: string;

	@Input() hasSearchIcon?: boolean;

	@ViewChild(InputComponent) private readonly _input?: InputComponent;

	override name = 'pspName';

	loweredProviders: {
		originalName: string;
		loweredName: string;
	}[] = [];

	filtered$ = new BehaviorSubject<string[]>([]);

	override throttle = 0;

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { providers, value } = changes;

		providers && this._setupSelectorState();

		value && this._setSelectorStateAccordingToInputValue();
	}

	focus(): void {
		this._input?.focus();
	}

	private _setSelectorStateAccordingToInputValue() {
		this._filterProviders(this.value);

		this.writeValue(this.value);
	}

	private _setupSelectorState() {
		this.filtered$.next(this._providers);

		this.loweredProviders = this._providers.map(name => ({
			originalName: name,
			loweredName: name.toLowerCase(),
		}));
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: string | null): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			this._setIncomingValueToInternalControl(value ?? '');
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	/*
	 * #region Implementation of the Validator interface
	 * tslint:disable-next-line: no-unnecessary-type-annotation
	 */
	protected override _validator: ValidatorFn | null = ({ value }: AbstractControl): ValidationErrors | null => !value && this._internalControl.value
		? { pspNotFound: true }
		: null;
	// #endregion Implementation of the Validator interface

	protected override _onInternalControlValueChange(input: string) {
		this._filterProviders(input);

		if (this.value === input)
			return;

		this.setValue(this._tryFindProviderOrNull(input));
	}

	private _tryFindProviderOrNull(input: string | null) {
		if (isNil(input))
			return null;

		const loweredInput = input.toLowerCase();

		return this.loweredProviders
			.find(v => v.loweredName === loweredInput)
			?.originalName
			?? null;
	}

	private _filterProviders(input: string | null) {
		const loweredInput = input?.toLowerCase();

		this.filtered$.next(
			loweredInput
				? this.loweredProviders
					.filter(v => v.loweredName.includes(loweredInput))
					.map(v => v.originalName)
				: this._providers,
		);
	}
}
