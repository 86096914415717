import moment, { Moment } from 'moment';
import { isString } from 'lodash-es';

import { IIdentity, IdentityJwtSession, OtpProvider } from '@bp/shared/domains/jwt-session';
import { DTO, Secret, Entity } from '@bp/shared/models/metadata';
import { Sensitive } from '@bp/shared/models/core';
import { Role } from '@bp/shared/domains/roles';
import {
	BridgerAdminFeature, FeatureAction, FeaturePermissions, MerchantAdminFeature
} from '@bp/shared/domains/permissions';

export class Identity extends Entity implements IIdentity {

	private static __convertToDto(dtoOrJWT: DTO<Identity> | string): DTO<Identity> {
		return isString(dtoOrJWT) ? { jwt: dtoOrJWT } : dtoOrJWT;
	}

	@Secret()
	readonly [Sensitive.jwt]!: string;

	@Secret()
	readonly [Sensitive.refreshToken]?: string | null;

	/**
	 * Full name of the user.
	 */
	override readonly name: string | null;

	readonly email: string;

	readonly roles: string[] | null;

	readonly sessionExpiresAt: Moment;

	readonly otpExpiresAt: Moment | null;

	readonly featurePermissions: FeaturePermissions<BridgerAdminFeature | MerchantAdminFeature> = new Map();

	/**
	 * An identity which cannot proceed to the portal and must finish some steps before logging in
	 */
	readonly isIncomplete: boolean;

	readonly otpProvider: OtpProvider | null;

	readonly organizationId: string | null;

	readonly isOwner: boolean;

	private readonly __jwtSession: IdentityJwtSession;

	constructor(
		dtoOrJWT: Parameters<typeof Identity.__convertToDto>[0],
		refreshToken?: string | null,
		otpExpiresAt?: number | null,
	) {
		super(Identity.__convertToDto(dtoOrJWT));

		this.__jwtSession = IdentityJwtSession.parseJWT(this.jwt);

		this.refreshToken = refreshToken ?? this.refreshToken;

		this.sessionExpiresAt = moment
			.unix(this.__jwtSession.exp)
			.subtract('10', 'minute'); // ten mins earlier to refresh token while it's still valid

		this.otpExpiresAt = otpExpiresAt ? moment.unix(otpExpiresAt) : null;

		this.email = this.__jwtSession.userEmail;

		this.id = this.__jwtSession.userIdentityId!;

		this.organizationId = this.__jwtSession.organizationId ?? null;

		this.name = this.__jwtSession.userFullName ?? null;

		this.otpProvider = this.__jwtSession.otpProvider ? OtpProvider.parseStrict(this.__jwtSession.otpProvider) : null;

		this.roles = this.__jwtSession.roles ?? null;

		this.isOwner = !!this.roles?.includes(Role.merchantOwner.internalName);

		this.featurePermissions = this.__jwtSession.permissions;

		this.modified ??= moment();

		this.isIncomplete = this.__isIncomplete();
	}

	hasPermission(featureOrAction: BridgerAdminFeature | FeatureAction | MerchantAdminFeature): boolean {
		return this.featurePermissions.has(featureOrAction);
	}

	private __isIncomplete(): boolean {
		return [
			BridgerAdminFeature.baAcceptInvite,
			BridgerAdminFeature.baCreateAccount,
			BridgerAdminFeature.baLoginAfterInvite,

			MerchantAdminFeature.acceptInvite,
			MerchantAdminFeature.createAccount,
			MerchantAdminFeature.loginAfterInvite,

			MerchantAdminFeature.confirmationEmail,
			MerchantAdminFeature.setSecurityQuestionsAnswers,
			MerchantAdminFeature.registerAuthenticator,
			MerchantAdminFeature.resetPassword,
			MerchantAdminFeature.resetExpiredPassword,
			MerchantAdminFeature.resetAuthenticator,
			MerchantAdminFeature.otpVerify,
		].some(featurePermission => this.hasPermission(featurePermission));
	}
}
