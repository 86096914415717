import { IDescribable, IEnumeration } from '@bp/shared/models/core/enum';

export const WALLET_PSEUDO_PAYMENT_OPTION: IDescribable & IEnumeration = {
	name: 'wallet',
	displayName: 'Wallet',
	description: 'ApplePay, GooglePay, etc.',
	valueOf() {
		return this.name;
	},
};
