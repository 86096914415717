import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { FADE } from '@bp/frontend/animations';
import { BridgerPsp, BridgerPspPaymentOptionsUtils } from '@bp/frontend/domains/bridger-psps/core';
import { PspPaymentOptionType } from '@bp/frontend/models/business';

@Component({
	selector: 'bp-bridger-psp-card',
	templateUrl: './bridger-psp-card.component.html',
	styleUrls: [ './bridger-psp-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class BridgerPspCardComponent {

	@Input() psp!: BridgerPsp;

	@Input() paymentOptionType!: PspPaymentOptionType;

	@Input() selected!: boolean;

	@Input() selectable = true;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	BridgerPspPaymentOptionsUtils = BridgerPspPaymentOptionsUtils;

	protected _MerchantAdminFeature = MerchantAdminFeature;

}
