import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Identity } from '@bp/admins-shared/domains/identity/models';

import { composeIdentitySelectors } from '@bp/frontend-domains-identity';

import type { IState } from './identity.reducer';
import { FEATURE_STATE_KEY } from './identity.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_STATE_KEY);

export const IDENTITY_SELECTORS = composeIdentitySelectors<Identity, IState>(selectFeatureState);

export const selectIncompleteIdentity = createSelector(selectFeatureState, state => state.incompleteIdentity);

export const selectAllSecurityQuestions = createSelector(selectFeatureState, state => state.allSecurityQuestions);

export const selectIdentitySecurityQuestions = createSelector(
	selectFeatureState,
	state => state.identitySecurityQuestions,
);

export const selectAuthenticatorKey = createSelector(selectFeatureState, state => state.authenticatorKey);

export const selectOtpProvider = createSelector(selectFeatureState, state => state.otpProvider);

export const selectOtpExpiresAt = createSelector(selectFeatureState, state => state.otpExpiresAt);

export const selectFeatureAccessExpirationsMap = createSelector(
	selectFeatureState,
	state => state.featureAccessExpirationsMap,
);
