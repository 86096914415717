import { Control, Default, FieldControlType, FieldViewType, Label, MetadataEntity, View } from '@bp/shared/models/metadata';

import type { PspPaymentOptionType } from '@bp/frontend/models/business';

export abstract class BridgerPspPaymentOptionBase extends MetadataEntity {

	static readonly type: PspPaymentOptionType;

	abstract readonly type: PspPaymentOptionType;

	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Open Checkout In A New Tab')
	openInNewWindow!: boolean;

	@Default(null)
	credentialApiUrl!: string | null;

	@Control(FieldControlType.chip, {
		list: [ 'FirstName', 'LastName', 'Address', 'Address1', 'ZipCode', 'State', 'City', 'Phone', 'Email' ],
	})
	@View(FieldViewType.chip)
	optionalFields?: string[];

}
