import { DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { CurrencyMarkup } from './currency-markup';

export class CurrencyMarkups extends MetadataEntity {
	@Mapper(CurrencyMarkup)
	payload!: CurrencyMarkup[];

	constructor(dto: DTO<CurrencyMarkups>) {
		super(dto);
	}
}
