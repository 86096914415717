import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

import { SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

@Component({
	selector: 'bp-charge-period-switcher',
	templateUrl: './charge-period-switcher.component.html',
	styleUrls: [ './charge-period-switcher.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChargePeriodSwitcherComponent {

	@Input() chargePeriod = SubscriptionPlanChargePeriod.monthly;

	@Output() readonly chargePeriodChange = new EventEmitter<SubscriptionPlanChargePeriod>();

	get isSelectedAnnuallyPeriod(): boolean {
		return this.chargePeriod === SubscriptionPlanChargePeriod.annually;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	emitChargePeriodChange(event: MatSlideToggleChange): void {
		this.chargePeriodChange.emit(
			event.checked
				? SubscriptionPlanChargePeriod.annually
				: SubscriptionPlanChargePeriod.monthly,
		);
	}

}
