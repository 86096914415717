<div class="flex font-weight-bold">
	<caption>
		{{
			subscriptionPlan.name
		}}
		Plan
	</caption>

	<div *ngIf="priceWithoutTax" class="ml-auto">
		<ng-container *ngIf="chargePeriod.monthsInPeriod > 1">
			({{ monthlyPrice | bpCurrency: currency }} x {{ chargePeriod.monthsInPeriod }} months)
		</ng-container>
		{{ priceWithoutTax | bpCurrency: currency }}
	</div>
</div>

<div
	class="flex text-mediumgray mt-1"
	[@fadeInFixedTriggered]="!subscriptionPlan.isEnterprise && !!chargePeriodDiscount"
>
	<div>{{ chargePeriod.displayName }} plan {{ chargePeriodDiscount | percent }} savings</div>

	<s class="ml-auto">{{ priceWithoutDiscountAndTax! | bpCurrency: currency }}</s>
</div>

<div class="mat-caption flex font-weight-bold mt-2" [@fadeInFixedTriggered]="!!vat">
	<caption>
		{{
			countryVatAbbreviation
		}}
		{{
			countryVatPercent | percent
		}}
	</caption>

	<div class="ml-auto text-primary">{{ vat | bpCurrency: currency }}</div>
</div>

<div class="flex mt-4 font-weight-bold">
	<caption>
		Total
	</caption>

	<div
		class="pending-stubs ml-auto"
		[class.loading]="!vatConfigPerCountry"
		[class.hide-pending-stubs]="vatConfigPerCountry"
	>
		<output class="inline-block">
			<ng-container *ngIf="totalPrice">{{ totalPrice | bpCurrency: currency }}</ng-container>
		</output>
	</div>
</div>
