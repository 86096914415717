import { isString } from 'lodash-es';

import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	DTO, MapFromDTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams,
	mixinMetadataSortQueryParams
} from '@bp/shared/models/metadata';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspLocation } from '../bridger-psp-location';
import { BridgerPspLicense } from '../bridger-psp-license';
import { BridgerPspProcessingHistory } from '../bridger-psp-processing-history';
import { BridgerPspMonthlyTurnoverVolume } from '../bridger-psp-monthly-turnover-volume';
import { BridgerPspLegalEntityType } from '../bridger-psp-legal-entity-type';
import { BridgerPspIndustry } from '../bridger-psp-industry';

import { BridgerPspSummary } from './bridger-psp-summary';
import { BNPL_PSEUDO_PAYMENT_OPTION } from './payment-options';
import { WALLET_PSEUDO_PAYMENT_OPTION } from './payment-options/wallet-pseudo-payment-option.constants';

type BridgerPspSummaryQueryParamsDTO = DTO<BridgerPspSummaryQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO;

export class BridgerPspSummaryQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, BridgerPspSummary),
) {

	@MapFromDTO()
	name?: string;

	@MapFromDTO()
	internalName?: string;

	locations: BridgerPspLocation[];

	industries: BridgerPspIndustry[];

	paymentOptionType?: PspPaymentOptionType;

	@Mapper(BridgerPspLocation)
	legalEntityLocation?: BridgerPspLocation;

	licenses!: BridgerPspLicense[];

	@Mapper(BridgerPspProcessingHistory)
	processingHistory?: BridgerPspProcessingHistory;

	@Mapper(BridgerPspMonthlyTurnoverVolume)
	monthlyTurnoverVolume?: BridgerPspMonthlyTurnoverVolume;

	@Mapper(BridgerPspLegalEntityType)
	legalEntityType?: BridgerPspLegalEntityType;

	supportsBnpl?: boolean;

	allWalletPaymentOptions?: boolean;

	constructor(dto?: BridgerPspSummaryQueryParamsDTO) {
		super(dto);

		this.locations = (isString(dto?.locations)
			? dto!.locations.split(',')
			: (dto?.locations ?? []))
			.map(v => BridgerPspLocation.parse(v))
			.filter((v): v is BridgerPspLocation => !!v);

		this.industries = (isString(dto?.industries)
			? dto!.industries.split(',')
			: (dto?.industries ?? []))
			.map(v => BridgerPspIndustry.parse(v))
			.filter((v): v is BridgerPspIndustry => !!v);

		this.licenses = (isString(dto?.licenses)
			? dto!.licenses.split(',')
			: (dto?.licenses ?? []))
			.map(v => BridgerPspLicense.parse(v))
			.filter((v): v is BridgerPspLicense => !!v);

		this.paymentOptionType = PspPaymentOptionType.parse(dto?.paymentOptionType) ?? undefined;

		if (dto?.paymentOptionType === BNPL_PSEUDO_PAYMENT_OPTION.name)
			this.supportsBnpl = true;

		if (dto?.paymentOptionType === WALLET_PSEUDO_PAYMENT_OPTION.name)
			this.allWalletPaymentOptions = true;
	}

}
