import { createAction, props } from '@ngrx/store';

import {
	CurrentOrganizationSubscription,
	OrganizationBillingPortalDetails
} from '@bp/shared/domains/organizations';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';
import { SubscriptionPlanPurchase } from '@bp/frontend/domains/subscription-plans/checkout';

import { ACTIONS_SCOPE } from './current-organization-subscription.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<CurrentOrganizationSubscription | null>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);

type PurchaseSubscriptionPlanActionPayload = { request: SubscriptionPlanPurchase };

export const purchaseSubscriptionPlanSuccess = createAction(
	`${ scope } Purchase Subscription Plan Success`,
	props<PurchaseSubscriptionPlanActionPayload>(),
);

export const purchaseSubscriptionPlanFailure = createAction(
	`${ scope } Purchase Subscription Plan Failure`,
	props<ErrorActionPayload & PurchaseSubscriptionPlanActionPayload>(),
);

export const downgradeSubscriptionPlanFailure = createAction(
	`${ scope } Downgrade Subscription Plan Failure`,
	props<ErrorActionPayload>(),
);
export const downgradeSubscriptionPlanSuccess = createAction(`${ scope } Downgrade Subscription Plan Success`);

export const loadBillingPortalDetailsSuccess = createAction(
	`${ ACTIONS_SCOPE } Load Billing Portal Details Success`,
	props<ResultActionPayload<OrganizationBillingPortalDetails>>(),
);

export const loadBillingPortalDetailsFailure = createAction(
	`${ ACTIONS_SCOPE } Load Billing Portal Details Failure`,
	props<ErrorActionPayload>(),
);
