import { map, shareReplay, take } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';

import { filterPresent } from '@bp/frontend/rxjs';
import { BridgerPspScope } from '@bp/frontend/domains/bridger-psps/core';

import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';

import {
	resetState, load, loadVirtualTerminalData, refresh, startRefreshingInInterval, stopRefreshingInInterval
} from './current-organization.actions';
import {
	selectError, selectCurrentOrganization, selectCurrentOrganizationLoading, selectCurrentSubscription, selectVirtualTerminalData
} from './current-organization.selectors';

@Injectable({
	providedIn: 'root',
})
export class CurrentOrganizationFacade {

	private readonly __bridgerPspsSharedFacade = inject(BridgerPspsSharedFacade);

	private readonly __store$ = inject(Store);

	entity$ = this.__store$.select(selectCurrentOrganization);

	entity: Organization | null = null;

	presentEntity$ = this.entity$.pipe(filterPresent);

	currentSubscriptionPlan$ = this.__store$.select(selectCurrentSubscription).pipe(filterPresent);

	currencies$ = this.entity$.pipe(map(organization => organization?.virtualTerminalCurrencies ?? []));

	loading$ = this.__store$.select(selectCurrentOrganizationLoading);

	loadingFirst$ = this.loading$.pipe(take(2));

	error$ = this.__store$.select(selectError);

	virtualTerminalData$ = this.__store$.select(selectVirtualTerminalData);

	bridgerPsps$ = this.__bridgerPspsSharedFacade.all$.pipe(
		map(bridgerPsps => bridgerPsps.filter(bridgerPsp => bridgerPsp.scopes.includes(BridgerPspScope.merchantAdmin))),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	bridgerPspsInternalNames$ = this.bridgerPsps$.pipe(
		map(bridgerPsps => bridgerPsps.map(bridgerPsp => bridgerPsp.internalName!)),
	);

	constructor() {
		this._keepEntityPropertyUpdated();
	}

	load(): void {
		this.__store$.dispatch(load());
	}

	refresh(): void {
		this.__store$.dispatch(refresh());
	}

	loadVirtualTerminalData(): void {
		this.__store$.dispatch(loadVirtualTerminalData());
	}

	resetState(): void {
		this.__store$.dispatch(resetState());
	}

	startRefreshingInInterval(): void {
		this.__store$.dispatch(startRefreshingInInterval());
	}

	stopRefreshingInInterval(): void {
		this.__store$.dispatch(stopRefreshingInInterval());
	}

	private _keepEntityPropertyUpdated(): void {
		this.entity$.subscribe(entity => (this.entity = entity));
	}
}
