import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';

import { IdentifiableEntity } from '@bp/shared/models/metadata';

import { EntitiesInMemoryPagedListState, EntitiesListState } from './compose-entities-list-reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeEntitiesListSelectors<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesListState<TEntity>
>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		recordsPage: createSelector(
			featureStateSelector,
			state => state.recordsPage,
		),

		page: createSelector(
			featureStateSelector,
			state => state.page,
		),

		pending: createSelector(
			featureStateSelector,
			state => state.pending,
		),

		error: createSelector(
			featureStateSelector,
			state => state.error,
		),
	};
}

export type EntitiesListSelectors<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesListState<TEntity>
> = ReturnType<typeof composeEntitiesListSelectors<TEntity, TState>>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeEntitiesInMemoryPagedListSelectors<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesInMemoryPagedListState<TEntity>
>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		...composeEntitiesListSelectors<TEntity, TState>(featureStateSelector),

		all: createSelector(
			featureStateSelector,
			state => state.all,
		),

		filteredInMemory: createSelector(
			featureStateSelector,
			state => state.filteredInMemory,
		),
	};
}

export type EntitiesInMemoryPagedListSelectors<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesInMemoryPagedListState<TEntity>
> = ReturnType<typeof composeEntitiesInMemoryPagedListSelectors<TEntity, TState>>;
