// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'libs/frontend/environments/src/environment';

import { EnvironmentService } from '@bp/frontend/services/environment';
import {
	MockedBackendState,
	LocalBackendState,
	ConsoleLoggingState
} from '@bp/frontend/services/persistent-state-keepers';
import { TelemetryService } from '@bp/frontend/services/telemetry';

/**
 * Logic that should be kicked in before execution of any app module file
 */
EnvironmentService.init(environment);

if (environment.name === 'local' || MockedBackendState.isActive || LocalBackendState.isActive)
	ConsoleLoggingState.set(true);

if (EnvironmentService.isHumanManaged && !LocalBackendState.isActive)
	TelemetryService.init(environment);

TelemetryService.log('Environment has been setup');
