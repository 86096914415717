import { Enumeration } from '@bp/shared/models/core/enum';

export class PayoutMethodType extends Enumeration {

	static none = new PayoutMethodType();

	static creditCard = new PayoutMethodType();

	static bankAccount = new PayoutMethodType();

	static eWallet = new PayoutMethodType('EWallet');

	static crypto = new PayoutMethodType('Crypto');

}
