import { debounce, isNil, isObject, mapValues, omitBy, snakeCase } from 'lodash-es';
import { lastValueFrom } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { isEmpty, isEqual, toPlainObject } from '@bp/shared/utilities/core';
import { Dictionary, Platform } from '@bp/shared/typings';
import { Environment } from '@bp/shared/models/core';

import { AsyncFlashSubject, ZoneService } from '@bp/frontend/rxjs';
import { $ } from '@bp/frontend/utilities/dom';
import { ANALYTICS_OBSERVERS, Gtag, GtagEvents, IAnalyticsObserver, ICookiesUsageConsent, IGoogleTagGlobalVariables } from '@bp/frontend/features/analytics/models';

interface IInitConfig {

	analyticsId: string;

	transportUrl: string;

	environment: Environment;

	platform: Platform;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	debug_mode?: boolean;

	consent: ICookiesUsageConsent;
}

@Injectable({ providedIn: 'root' })
export class GoogleTagService {

	private static readonly __dataLayerPropertyName = 'dataLayer';

	private static get __dataLayer(): unknown[] {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (<any>window)[this.__dataLayerPropertyName] ?? ((<any>window)[this.__dataLayerPropertyName] = []);
	}

	private static readonly __gtag: Gtag.IGtagFunction = function() {
		// eslint-disable-next-line prefer-rest-params
		ZoneService.runOutsideAngular(() => GoogleTagService.__dataLayer.push(arguments));
	};

	private readonly __analyticsObservers = inject<IAnalyticsObserver[]>(ANALYTICS_OBSERVERS, { optional: true });

	private __inited = false;

	private readonly __gtagInitConfig$ = new AsyncFlashSubject<Gtag.IConfig>();

	private readonly __debouncedDispatchingGlobalConfigUpdatedEvent = debounce(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		() => void this.dispatchEvent('global_config_updated', { traffic_type: 'internal' }),
		500,
	);

	private __gtagConfig?: Gtag.IConfig;

	constructor() {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method
		(<any>window).BP_GoogleTagService = this;
	}

	init(config: IInitConfig): void {
		if (this.__inited)
			return;

		this.__inited = true;

		this.__setInitConfig(config);

		this.__injectScriptIntoHead(config);
	}

	updateConsent(consent: ICookiesUsageConsent): void {
		this.__setConsent({ consent, isInit: false });
	}

	setGlobalVariables(params: IGoogleTagGlobalVariables): void {
		params = this.__ifTestUserEmailMarkAnalyticsAsInternalTraffic(params);

		void this.__setGlobalVariables(params);
	}

	setGlobalEventPayloads(params: GtagEvents.EventPayloads): void {
		void this.__setGlobalVariables(params);
	}

	dispatchEvent<TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	): void {
		payload &&= toPlainObject(payload);

		this.__analyticsObservers?.forEach(observer => void observer.dispatchEvent?.(event, payload));

		void this.__dispatchEvent(event, payload);
	}

	private async __dispatchEvent<TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	): Promise<void> {
		await lastValueFrom(this.__gtagInitConfig$);

		this.__debouncedDispatchingGlobalConfigUpdatedEvent.cancel(); // no need to dispatch if we are already dispatching

		GoogleTagService.__gtag(
			'event',
			<TEventName>snakeCase(event),
			payload && <any> this.__normalizeGtagPayload(payload),
		);
	}

	private __setInitConfig(config: IInitConfig): void {
		const gtagInitConfig: Gtag.IConfig = toPlainObject({
			analyticsId: config.analyticsId,
			environment: config.environment,
			platform: config.platform,
			/* eslint-disable @typescript-eslint/naming-convention */
			transport_url: config.transportUrl,
			first_party_collection: true,
			debug_mode: config.debug_mode,
			/* eslint-enable @typescript-eslint/naming-convention */
		});

		this.__gtagInitConfig$.complete(gtagInitConfig);

		GoogleTagService.__gtag('js', new Date());

		GoogleTagService.__gtag('config', config.analyticsId, gtagInitConfig);

		this.__setConsent({ consent: config.consent, isInit: true });
	}

	private __setConsent({ consent, isInit }: { consent: ICookiesUsageConsent; isInit: boolean }): void {
	/* eslint-disable @typescript-eslint/naming-convention */
		GoogleTagService.__gtag(
			'consent',
			isInit ? 'default' : 'update',
			{
				analytics_storage: consent.preferences ? 'granted' : 'denied',
				functionality_storage: consent.preferences ? 'granted' : 'denied',
				personalization_storage: consent.preferences ? 'granted' : 'denied',
				security_storage: consent.preferences ? 'granted' : 'denied',
				ad_storage: consent.marketing ? 'granted' : 'denied',
				ad_user_data: consent.marketing ? 'granted' : 'denied',
				ad_personalization: consent.marketing ? 'granted' : 'denied',
			},
		);

		GoogleTagService.__gtag('set', {
			ads_data_redaction: !consent.marketing,
			url_passthrough: !consent.marketing || !consent.preferences,
		});
		/* eslint-enable @typescript-eslint/naming-convention */
	}

	private async __setGlobalVariables(params: Dictionary<any>): Promise<void> {
		const gtagInitConfig = await lastValueFrom(this.__gtagInitConfig$);

		if (isEmpty(params))
			return;

		this.__analyticsObservers?.forEach(observer => void observer.setGlobalVariables?.(params));

		const newGtagConfig = this.__normalizeGtagPayload({
			...this.__gtagConfig,
			...params,
			...gtagInitConfig,
			/* eslint-disable @typescript-eslint/naming-convention */
			send_page_view: false,
			/* eslint-enable @typescript-eslint/naming-convention */
		});

		if (isEqual(newGtagConfig, this.__gtagConfig))
			return;

		this.__gtagConfig = newGtagConfig;

		GoogleTagService.__gtag(
			'config',
			gtagInitConfig.analyticsId,
			this.__gtagConfig,
		);

		this.__debouncedDispatchingGlobalConfigUpdatedEvent();
	}

	private __injectScriptIntoHead(config: IInitConfig): void {
		document.head.append($.buildAsyncScriptElement({
			src: `${ config.transportUrl }/gtag/js?id=${ config.analyticsId }&l=${ GoogleTagService.__dataLayerPropertyName }`,
		}));
	}

	private __normalizeGtagPayload<T extends Object>(object: T): T {
		object = <T>mapValues(object, value => isObject(value) ? JSON.stringify(value) : value);

		object = <T>omitBy(object, isNil);

		return object;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private __ifTestUserEmailMarkAnalyticsAsInternalTraffic(params: IGoogleTagGlobalVariables): IGoogleTagGlobalVariables {
		if (params.userEmail && [ 'bridgerpay', 'test' ].some(internalTrafficSign => params.userEmail?.includes(internalTrafficSign)))
			params.traffic_type = 'internal';

		return params;
	}

}
