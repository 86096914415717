import { inject } from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

import { GtagEvents, IAnalyticsObserver, IGoogleTagGlobalVariables } from '@bp/frontend/features/analytics/models';
import { IntercomService } from '@bp/frontend/services/intercom';
import { TelemetryService } from '@bp/frontend/services/telemetry';

export class TelemetryAnalyticsObserverService implements IAnalyticsObserver {

	private readonly __telemetry = inject(TelemetryService);

	private readonly __intercomService = inject(IntercomService);

	private __isInternalTraffic = false;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	setGlobalVariables({ traffic_type }: IGoogleTagGlobalVariables): void {
		this.__isInternalTraffic = traffic_type === 'internal';
	}

	dispatchEvent<TEventName extends keyof GtagEvents.IEventPayloadMap>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	): void {
		if (this.__isInternalTraffic || payload?.traffic_type === 'internal')
			return;

		this.__telemetry.track(event, payload);

		void this.__intercomService.trackEvent(event, <Dictionary<string>>payload);
	}

}
