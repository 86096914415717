<div
	class="mat-drawer-backdrop"
	[class.mat-drawer-shown]="layoutFacade.showSidenav$ | async"
	(click)="layoutFacade.closeSidenav()"
></div>

<mat-sidenav
	[opened]="layoutFacade.showSidenav$ | async"
	[fixedInViewport]="true"
	(keydown.escape)="layoutFacade.closeSidenav()"
>
	<mat-nav-list>
		<ng-content />
	</mat-nav-list>
</mat-sidenav>
