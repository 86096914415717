import { Enumeration } from '@bp/shared/models/core/enum';

export class FeatureVersion extends Enumeration {
	static v1 = new FeatureVersion();

	static v2 = new FeatureVersion();

	static v3 = new FeatureVersion();

	static v4 = new FeatureVersion();
}
