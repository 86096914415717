import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { PaywithBadgeModule, PspsAddingModule, PspSelectorComponent, PspThumbnailModule } from './components';

const EXPOSED_COMPONENTS = [
	PspSelectorComponent,
];

const EXPOSED_MODULES = [
	PspsAddingModule,
	PspThumbnailModule,
	PaywithBadgeModule,
];

@NgModule({
	imports: [
		CommonModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatIconModule,
		MatPseudoCheckboxModule,
		MatRippleModule,
		MatSelectModule,
		ReactiveFormsModule,
		ScrollingModule,

		SharedDirectivesCoreModule,
		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,

		EXPOSED_MODULES,
	],
	exports: [ EXPOSED_COMPONENTS, EXPOSED_MODULES ],
	declarations: EXPOSED_COMPONENTS,
})
export class AdminsSharedBridgerPspsModule {

	constructor(
		private readonly _apiMocksManagerService: ApiMockPluginsManagerService,
	) {
		void this._apiMocksManagerService.register(
			AdminsSharedBridgerPspsModule,
			async () => import('./api-mock-plugin/admins-shared-bridger-psps-api-mock-plugin'),
		);
	}

}
