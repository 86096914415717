<a
	*ngIf="targetBlank; else inPageTpl"
	class="bp-round-button bp-button-md min-width-auto"
	mat-flat-button
	color="primary"
	bpTargetBlank
	href="/(modal:subscription-management;mode=upgrade)"
>
	<ng-container [ngTemplateOutlet]="contentTpl" />
</a>

<ng-template #inPageTpl>
	<a
		class="bp-round-button bp-button-md min-width-auto"
		mat-flat-button
		color="primary"
		[outletLinkRelativeToRoot]="{ modal: ['subscription-management', { mode: 'upgrade' }] }"
	>
		<ng-container [ngTemplateOutlet]="contentTpl" />
	</a>
</ng-template>

<ng-template #contentTpl>Upgrade {{ addMore ? 'To Add More' : 'Your Plan' }}</ng-template>
