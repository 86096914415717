import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LottiePlayerComponent } from './components';

const EXPOSED = [
	LottiePlayerComponent,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesLottiePlayerModule { }
