import { AbstractControl, AbstractControlOptions } from '@angular/forms';

import { NonFunctionPropertyNames } from './typings';

export type FormBuilderValue = [ any, (AbstractControlOptions | null)?];

export type FormGroupConfig<T, U = FormBuilderValue> = {
	[ K in NonFunctionPropertyNames<T> ]?: T[ K ] extends never ? never : U
};

export type FormControls<T> = {
	[ K in NonFunctionPropertyNames<T> ]?: T[ K ] extends never ? never : AbstractControl
};

export function ensureFormGroupConfig<T>(config: FormGroupConfig<T>): FormGroupConfig<T> {
	return config;
}
