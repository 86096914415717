import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { LottieAnimationEnum } from './lottie-animation.enum';

export abstract class CongratulationBaseVm {

	readonly abstract logoLottieAnimation: LottieAnimationEnum;

	readonly abstract title: string;

	readonly abstract subtitle: string;

	readonly abstract additionalInfo: string;

	readonly abstract features: (string | null)[];

	constructor(protected readonly _subscriptionPlan?: SubscriptionPlan) {

	}

	protected _buildPromoPricingPageLink(text: string = 'See all the perks of your plan'): string {
		return `<a href="https://bridgerpay.com/pricing" class="link" target="_blank">${ text }</a>`;
	}

	protected _buildPspsFeature(): string {
		return this._subscriptionPlan!.limits.merchantPsps === null
			? 'Connect <b>any amount of PSPs</b> or payment tools'
			: `Connect up to <b>${ this._subscriptionPlan!.limits.merchantPsps } PSPs</b> or payment tools`;
	}

	protected _buildCheckoutsFeature(): string {
		return this._subscriptionPlan!.limits.checkouts === null
			? 'Unlimited Checkouts'
			: `Up to ${ this._subscriptionPlan!.limits.checkouts } Checkouts`;
	}

	protected _buildFreeIntegratedPspsFeature(): string | null {
		const freeNewPspIntegrationsFeatureName = 'Free new PSP integrations';
		const freePspsFeature = this._subscriptionPlan!.features.find(
			feature => feature.name === freeNewPspIntegrationsFeatureName,
		);

		if (!freePspsFeature)
			return null;

		if (!freePspsFeature.count) {
			console.error('Missing free new PSP integration feature');

			return null;
		}

		return `Get ${ freePspsFeature.count } new PSP integrated for free`;
	}
}
