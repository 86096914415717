<ng-container [ngSwitch]="identityFacade.otpProvider$ | async">
	<ng-container *ngSwitchCase="OtpProvider.authApp">
		Get a verification code from your authenticator app.
	</ng-container>

	<ng-container *ngSwitchCase="OtpProvider.email">
		Verification code sent to your email.
		<bp-otp-generate-code-button [feature]="feature" />
	</ng-container>
</ng-container>
