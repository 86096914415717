import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import type { DTO } from '@bp/shared/models/metadata';
import { FeaturePermission, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { filterPresent } from '@bp/frontend/rxjs';

import {
	CreateAccountApiRequest, Identity, ILoginApiRequest, ILoginOtpVerificationApiRequest, IRegisterAuthenticatorAppApiRequest, IResetPasswordApiRequest, IResetPasswordOtpVerificationApiRequest, IFeatureAccessOtpVerificationApiRequest, ISecurityQuestionAnswerApiRequest, ISendResetPasswordLinkApiRequest, ISetSecurityQuestionsAnswersApiRequest
} from '@bp/admins-shared/domains/identity/models';

import { IdentityFacade as IdentityBaseFacade } from '@bp/frontend-domains-identity';

import {
	createAccount, IDENTITY_ACTIONS, loadAllSecurityQuestions, loadAuthenticatorAppKey, loadIdentitySecurityQuestions, registerAuthenticatorApp, resetAuthenticatorApp, resetPassword, resetPasswordOtpVerification, sendResetAuthenticatorAppLink, sendResetPasswordLink, setSecurityQuestionsAnswers, showIdentitySessionIsAboutToExpireDialog, loginOtpVerification, verifySecurityQuestionsAnswers, featureAccessOtpVerification, refreshAccessToken, generateLoginOtp, generateFeatureAccessOtp, acceptInvite
} from './identity.actions';
import {
	IDENTITY_SELECTORS, selectAllSecurityQuestions, selectAuthenticatorKey, selectFeatureAccessExpirationsMap, selectIdentitySecurityQuestions, selectIncompleteIdentity, selectOtpExpiresAt, selectOtpProvider
} from './identity.selectors';
import {
	createAccountSuccess, registerAuthenticatorSuccess, resetAuthenticatorAppSuccess, resetPasswordSuccess, setSecurityQuestionsAnswersSuccess, loginOtpVerificationSuccess, featureAccessOtpVerificationSuccess, refreshTokenSuccess, acceptInviteSuccess
} from './identity-api.actions';
import { setIncompleteIdentity } from './incomplete-identity.actions';
import { IState } from './identity.reducer';

@Injectable({ providedIn: 'root' })
export class IdentityFacade extends IdentityBaseFacade<Identity, IState, ILoginApiRequest> {
	readonly actions = IDENTITY_ACTIONS;

	readonly selectors = IDENTITY_SELECTORS;

	/**
	 * Happens when user got inside the platform from the intro pages
	 */
	override readonly userHasLoggedIn$: Observable<Identity> = this._actions$.pipe(
		ofType(
			this.actions.api.loginSuccess,
			acceptInviteSuccess,
			createAccountSuccess,
			setSecurityQuestionsAnswersSuccess,
			registerAuthenticatorSuccess,
			resetPasswordSuccess,
			loginOtpVerificationSuccess,
			resetAuthenticatorAppSuccess,
		),
		filter(({ result }) => !result.isIncomplete),
		map(({ result }) => result),
	);

	incompleteIdentity$ = this._store$.select(selectIncompleteIdentity);

	incompleteIdentity!: Identity | null;

	allSecurityQuestions$ = this._store$.select(selectAllSecurityQuestions);

	identitySecurityQuestions$ = this._store$.select(selectIdentitySecurityQuestions);

	authenticatorKey$ = this._store$.select(selectAuthenticatorKey);

	otpProvider$ = this._store$.select(selectOtpProvider);

	otpExpiresAt$ = this._store$.select(selectOtpExpiresAt).pipe(filterPresent);

	featureAccessExpirationsMap$ = this._store$.select(selectFeatureAccessExpirationsMap);

	featureAccessExpirationsMap: IState['featureAccessExpirationsMap'] = new Map();

	resourceAccessOtpVerificationSuccess$ = this._actions$.pipe(
		ofType(featureAccessOtpVerificationSuccess),
		map(({ result }) => result),
	);

	refreshTokenSuccess$ = this._actions$.pipe(
		ofType(refreshTokenSuccess),
		map(({ result }) => result),
	);

	constructor(store$: Store, actions$: Actions) {
		super(store$, actions$);

		this.__keepIncompleteIdentityPropertyUpdated();

		this.__keepFeaturesAccessExpirationsMapPropertyUpdated();
	}

	readonly factory = (dto: DTO<Identity>): Identity => new Identity(dto);

	showIdentitySessionIsAboutToExpireDialog(): void {
		this._store$.dispatch(showIdentitySessionIsAboutToExpireDialog());
	}

	setIncompleteIdentity(identity: Identity): void {
		this._store$.dispatch(setIncompleteIdentity({ identity }));
	}

	generateLoginOtp(): void {
		this._store$.dispatch(generateLoginOtp());
	}

	loginOtpVerification(payload: ILoginOtpVerificationApiRequest): void {
		this._store$.dispatch(loginOtpVerification(payload));
	}

	generateFeatureAccessOtp(permission: FeaturePermission<any>): void {
		this._store$.dispatch(generateFeatureAccessOtp({ permission }));
	}

	featureAccessOtpVerification(payload: IFeatureAccessOtpVerificationApiRequest): void {
		this._store$.dispatch(featureAccessOtpVerification(payload));
	}

	whenOtpRequiredCheckFeatureAccessIsExpired(permission: FeaturePermission<any>): boolean {
		if (!permission.isOtpRequired)
			return false;

		const expiration = this.featureAccessExpirationsMap.get(permission);

		return !expiration || expiration.isBefore();
	}

	refreshAccessToken(): void {
		this._store$.dispatch(refreshAccessToken());
	}

	// #region Signup Via Invite

	acceptInvite(): void {
		this._store$.dispatch(acceptInvite());
	}

	createAccount(payload: CreateAccountApiRequest): void {
		this._store$.dispatch(createAccount(payload));
	}

	// #endregion

	// #region Continue Signup

	loadAllSecurityQuestions(): void {
		this._store$.dispatch(loadAllSecurityQuestions());
	}

	setSecurityQuestionsAnswers(payload: ISetSecurityQuestionsAnswersApiRequest): void {
		this._store$.dispatch(setSecurityQuestionsAnswers(payload));
	}

	loadAuthenticatorAppKey(): void {
		this._store$.dispatch(loadAuthenticatorAppKey());
	}

	registerAuthenticatorApp(payload: IRegisterAuthenticatorAppApiRequest): void {
		this._store$.dispatch(registerAuthenticatorApp(payload));
	}

	// #endregion

	// #region reset

	loadIdentitySecurityQuestions(): void {
		this._store$.dispatch(loadIdentitySecurityQuestions());
	}

	verifySecurityQuestionAnswer(payload: ISecurityQuestionAnswerApiRequest): void {
		this._store$.dispatch(verifySecurityQuestionsAnswers({ answers: [ payload ]}));
	}

	// #region reset password

	sendResetPasswordLink(payload: ISendResetPasswordLinkApiRequest): void {
		this._store$.dispatch(sendResetPasswordLink(payload));
	}

	resetPasswordOtpVerification(payload: IResetPasswordOtpVerificationApiRequest): void {
		this._store$.dispatch(resetPasswordOtpVerification(payload));
	}

	resetPassword(payload: IResetPasswordApiRequest): void {
		this._store$.dispatch(resetPassword({
			...payload,
			isExpiredPasswordReset: !!this.incompleteIdentity?.hasPermission(
				MerchantAdminFeature.resetExpiredPassword,
			),
		}));
	}

	// #endregion

	// #region reset authenticator app

	sendResetAuthenticatorAppLink(): void {
		this._store$.dispatch(sendResetAuthenticatorAppLink());
	}

	resetAuthenticatorApp(payload: IRegisterAuthenticatorAppApiRequest): void {
		this._store$.dispatch(resetAuthenticatorApp(payload));
	}

	// #endregion

	// #endregion

	private __keepIncompleteIdentityPropertyUpdated(): void {
		this.incompleteIdentity$.subscribe(incompleteIdentity => (this.incompleteIdentity = incompleteIdentity));
	}

	private __keepFeaturesAccessExpirationsMapPropertyUpdated(): void {
		this.featureAccessExpirationsMap$
			.subscribe(featureAccessExpirationsMap => (this.featureAccessExpirationsMap = featureAccessExpirationsMap));
	}
}
