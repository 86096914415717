import { InjectionToken } from '@angular/core';

import { GtagEvents } from './gtag-events.interfaces';
import { IGoogleTagGlobalVariables } from './gtag-global-variables.interface';

export const ANALYTICS_OBSERVERS = new InjectionToken<ReadonlyArray<IAnalyticsObserver>>('ANALYTICS_OBSERVERS');

export interface IAnalyticsObserver {

	setGlobalVariables?: (globalVariables: IGoogleTagGlobalVariables) => void;

	dispatchEvent?: <TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	) => void;

}
