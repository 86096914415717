import { inject } from '@angular/core';

import { IAnalyticsObserver, IGoogleTagGlobalVariables } from '@bp/frontend/features/analytics/models';

import { PardotService } from './pardot.service';

export class PardotAnalyticsObserverService implements IAnalyticsObserver {

	private readonly __pardotService = inject(PardotService);

	// eslint-disable-next-line @typescript-eslint/naming-convention
	setGlobalVariables({ utmTags, userEmail, traffic_type }: IGoogleTagGlobalVariables): void {
		if (utmTags)
			this.__pardotService.setUtmTags(utmTags);

		if (userEmail && traffic_type !== 'internal')
			this.__pardotService.setVisitorEmail(userEmail);
	}

}
