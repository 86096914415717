import {
	EmbeddedViewRef, ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef, Optional
} from '@angular/core';

import { Permission } from '@bp/shared/domains/permissions';

import { takeUntilDestroyed } from '@bp/frontend/models/common';

import { FeaturePermissionsService } from '../services';

import { HasAccessBaseDirective } from './has-access-base.directive';

@Directive({
	selector: '[bpHasAccess]',
	exportAs: 'hasAccess',
})
export class HasAccessStructuralDirective extends HasAccessBaseDirective {
	@Input()
	set bpHasAccess(permission: Permission) {
		this._observeUserAccessToPermission(permission);
	}

	@Input()
	set bpHasAccessElse(tplRef: TemplateRef<any> | null) {
		this._noAccessTplRef = tplRef;

		this._updateEmbeddedViewAccordingToAccess(this.hasAccess);
	}

	private _noAccessTplRef: TemplateRef<any> | null = null;

	private _hasAccessEmbeddedViewRef: EmbeddedViewRef<any> | null = null;

	private _noAccessEmbeddedViewRef: EmbeddedViewRef<any> | null = null;

	constructor(
		private readonly _viewContainerRef: ViewContainerRef,
		@Optional() private readonly _hasAccessTplRef: TemplateRef<any> | null,
		featurePermissionsService: FeaturePermissionsService,
		cdr: ChangeDetectorRef,
	) {
		super(featurePermissionsService, cdr);

		this._onHasAccessChangeUpdateEmbeddedView();
	}

	private _onHasAccessChangeUpdateEmbeddedView(): void {
		this._hasAccess$
			.pipe(takeUntilDestroyed(this))
			.subscribe(hasAccess => void this._updateEmbeddedViewAccordingToAccess(hasAccess));
	}

	private _updateEmbeddedViewAccordingToAccess(hasAccess: boolean): void {
		if (hasAccess)
			this._createHasAccessEmbeddedView();
		else
			this._tryCreateNoAccessEmbeddedView();
	}

	private _createHasAccessEmbeddedView(): void {
		if (this._hasAccessEmbeddedViewRef || !this._hasAccessTplRef)
			return;

		this._viewContainerRef.clear();

		this._noAccessEmbeddedViewRef = null;

		this._hasAccessEmbeddedViewRef = this._viewContainerRef.createEmbeddedView(this._hasAccessTplRef);

		this._hasAccessEmbeddedViewRef.detectChanges();
	}

	private _tryCreateNoAccessEmbeddedView(): void {
		if (this._noAccessEmbeddedViewRef)
			return;

		this._viewContainerRef.clear();

		this._hasAccessEmbeddedViewRef = null;

		if (this._noAccessTplRef) {
			this._noAccessEmbeddedViewRef = this._viewContainerRef.createEmbeddedView(this._noAccessTplRef);

			this._noAccessEmbeddedViewRef.detectChanges();
		}
	}
}
