import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@bp/frontend/environments';
import '@bp/frontend/startup';

import { AppModule } from './app/app.module';

if (environment.isDeployed)
	enableProdMode();

void platformBrowserDynamic()
	.bootstrapModule(AppModule, {
		ngZoneEventCoalescing: true,
		ngZoneRunCoalescing: true,
	});
