
import {
	CryptoCurrency, FiatCurrency, ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER
} from '@bp/shared/models/currencies';
import { DTO, Hint, Control, FieldControlType, Mapper, Required, FieldViewType, View } from '@bp/shared/models/metadata';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionCryptoSummary } from '../summaries';

import { BridgerPspPaymentOptionBase } from './bridger-psp-payment-option-base';

export class BridgerPspPaymentOptionCrypto extends BridgerPspPaymentOptionBase implements BridgerPspPaymentOptionCryptoSummary {

	static override readonly type = PspPaymentOptionType.crypto;

	readonly type = BridgerPspPaymentOptionCrypto.type;

	@View(FieldViewType.boxedItems)
	@Control(FieldControlType.chip, {
		list: FiatCurrency.list,
		placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
	})
	@Mapper(FiatCurrency)
	@Hint('Fiat currencies this PSP can convert crypto currencies to')
	@Required()
	currencies!: FiatCurrency[];

	@View(FieldViewType.boxedItems)
	@Control(FieldControlType.chip, {
		list: CryptoCurrency.listWithoutDeprecated,
		placeholder: ADD_CRYPTO_CURRENCY_CHIP_PLACEHOLDER,
	})
	@Mapper(CryptoCurrency)
	@Hint('Crypto currencies this PSP support')
	@Required()
	cryptoCurrencies!: CryptoCurrency[];

	constructor(dto: DTO<BridgerPspPaymentOptionCrypto>) {
		super(dto);
	}

}
