import { createAction, props } from '@ngrx/store';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { ErrorActionPayload, RecordsPageResultActionPayload } from '@bp/frontend/models/common';

import { ACTIONS_SCOPE } from './subscription-plans-shared.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadAllSuccess = createAction(
	`${ scope } Load All Success`,
	props<RecordsPageResultActionPayload<SubscriptionPlan>>(),
);

export const loadAllFailure = createAction(
	`${ scope } Load All Failure`,
	props<ErrorActionPayload>(),
);
