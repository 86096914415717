import { Enumeration } from '@bp/shared/models/core/enum';

// Order of items matters
export class OtpProvider extends Enumeration {

	static email = new OtpProvider();

	static authApp = new OtpProvider();

}
