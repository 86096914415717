import m, { Moment } from 'moment';

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

import { momentMapper } from '@bp/shared/models/metadata';

import { SLIDE_RIGHT } from '@bp/frontend/animations';
import { DatepickerCalendarHeaderComponent, FormFieldControlComponent } from '@bp/frontend/components/core';

@Component({
	selector: 'bp-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: [ './datepicker.component.scss' ],
	animations: [ SLIDE_RIGHT ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DatePickerComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: DatePickerComponent,
			multi: true,
		},
	],
})
export class DatePickerComponent extends FormFieldControlComponent<Moment | null> {

	/** The minimum valid date. */
	@Input() min?: Moment;

	 /** The maximum valid date. */
	@Input() max?: Moment;

	/** The date to open the calendar to initially. */
	@Input() startAt?: Moment;

	@Input() startView?: 'month' | 'multi-year' | 'year';

	@ViewChild(MatInput, { static: true }) input!: MatInput;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	DatepickerCalendarHeaderComponent = DatepickerCalendarHeaderComponent;

	override writeValue(value: Moment | null): void {
		super.writeValue((!value || m.isMoment(value)) ? value : momentMapper(value));
	}

	set(v?: Moment | null) {
		if (v && this.value && v.isSame(this.value) || v !== this.value)
			this.setValue(v ?? null);
	}

	focus(): void {
		this.input.focus();
	}

}
