import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import type { DTO, IEntitiesApiService } from '@bp/shared/models/metadata';
import { RecordsPage } from '@bp/shared/models/common';
import { toPlainObject } from '@bp/shared/utilities/core';

import { toHttpParams } from '@bp/frontend/utilities/common';
import { BridgerPsp, BridgerPspsQueryParams } from '@bp/frontend/domains/bridger-psps/core';

@Injectable({
	providedIn: 'root',
})
export class BridgerPspsApiService implements IEntitiesApiService<BridgerPsp, BridgerPspsQueryParams> {

	readonly collectionPath = 'bridger-psps';

	constructor(private readonly _httpClient: HttpClient) { }

	readonly factory = (v: BridgerPsp | DTO<BridgerPsp>): BridgerPsp => v instanceof BridgerPsp
		? v
		: new BridgerPsp(v);

	getRecordsPage(query?: BridgerPspsQueryParams): Observable<RecordsPage<BridgerPsp>> {
		return this._httpClient
			.get<RecordsPage<DTO<BridgerPsp>>>(
			this.collectionPath,
			{ params: toHttpParams(query) },
		)
			.pipe(map(result => new RecordsPage(query, result, this.factory)));
	}

	get(id: string): Observable<BridgerPsp | null> {
		return this._httpClient
			.get<DTO<BridgerPsp> | undefined>(`${ this.collectionPath }/${ id }`)
			.pipe(map(v => v ? this.factory(v) : null));
	}

	save(model: BridgerPsp): Observable<BridgerPsp> {
		return (model.id
			? this._httpClient.put(`${ this.collectionPath }/${ model.id }`, toPlainObject(model))
			: this._httpClient.post(`${ this.collectionPath }`, toPlainObject(model))
		)
			.pipe(map(this.factory));
	}

	delete(model: BridgerPsp): Observable<void> {
		return <Observable<void>> <unknown> this._httpClient
			.delete(`${ this.collectionPath }/${ model.id }`);
	}

	getIntegratedPspsNames(): Observable<string[]> {
		return this._httpClient.get<string[]>(`${ this.collectionPath }/integrated-psps-names`);
	}

}
