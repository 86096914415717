import { isObject, isString } from 'lodash-es';
import moment from 'moment';

import { PrimitiveConstructable } from '@bp/shared/models/core';
import { NonFunctionProperties } from '@bp/shared/typings';

export class LoginOtpRequestInterval extends PrimitiveConstructable {

	static always = new LoginOtpRequestInterval({
		displayName: 'Always',
		value: moment.duration({ hours: 0 }).toISOString(),
	});

	static every6Hours = new LoginOtpRequestInterval({
		displayName: 'Every 6 hours',
		value: moment.duration({ hours: 6 }).toISOString(),
	});

	static every12Hours = new LoginOtpRequestInterval({
		displayName: 'Every 12 hours',
		value: moment.duration({ hours: 12 }).toISOString(),
	});

	static every24Hours = new LoginOtpRequestInterval({
		displayName: 'Every 24 hours',
		value: moment.duration({ hours: 24 }).toISOString(),
	});

	static getList(): LoginOtpRequestInterval[] {
		return [
			LoginOtpRequestInterval.always,
			LoginOtpRequestInterval.every6Hours,
			LoginOtpRequestInterval.every12Hours,
			LoginOtpRequestInterval.every24Hours,
		];
	}

	readonly value!: string;

	readonly displayName!: string;

	constructor(dtoOValue: Partial<NonFunctionProperties<LoginOtpRequestInterval>> | string) {
		super(isString(dtoOValue) ? dtoOValue : dtoOValue.value!);

		if (this._isCached())
			return this;

		if (isObject(dtoOValue))
			Object.assign(this, dtoOValue);

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'login_otp_request_interval';
	}

}
