import type { DTO, PropertyMetadata } from '@bp/shared/models/metadata';
import { MetadataEntity, MapFromDTO, Mapper, titleCase, Unserializable } from '@bp/shared/models/metadata';

import { ApiPropertyMetadata } from './api-property-metadata';

export class ApiPropertiesMetadataArray extends MetadataEntity {

	@MapFromDTO()
	name!: string;

	@Mapper(titleCase)
	title!: string;

	@Mapper(ApiPropertyMetadata)
	fields!: ApiPropertyMetadata[];

	@Unserializable()
	readonly $$fields: PropertyMetadata[];

	constructor(dto?: DTO<ApiPropertiesMetadataArray>) {
		super(dto);

		this.$$fields = this.fields.map(field => field.propertyMetadata);
	}
}
