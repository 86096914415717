import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

import { PaywithBadgeComponent } from './paywith-badge.component';

const EXPOSED = [ PaywithBadgeComponent ];

@NgModule({
	imports: [
		CommonModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
	],
	exports: EXPOSED,
	declarations: EXPOSED,
})
export class PaywithBadgeModule { }
