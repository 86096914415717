import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

import { ModalOutletComponent } from './modal-outlet.component';
import { ModalComponent } from './modal.component';

const EXPOSED = [
	ModalComponent,
	ModalOutletComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		SharedFeaturesSvgIconsModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesModalModule { }
