<button
	*ngIf="showResendOtpButton$ | async"
	type="button"
	class="link interactive"
	@slide
	[disabled]="(canResendOtp$ | async) === false"
	(click)="feature === 'login' ? identityFacade.generateLoginOtp() : identityFacade.generateFeatureAccessOtp(feature)"
>
	Send again

	<span *ngIf="(canResendOtp$ | async) === false" @fade> in {{ secondsUntilOtpExpires$ | async }} seconds</span>
</button>
