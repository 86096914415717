import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';
import { FeaturePermission } from '@bp/shared/domains/permissions';

import { mapTo } from '@bp/frontend/rxjs';

import {
	GenerateOtpApiResponse, IChangePasswordApiRequest, Identity,
	IIdentitySessionApiResponse, ILoginApiRequest, ILoginOtpVerificationApiRequest,
	IRegisterAuthenticatorAppApiRequest, IResetPasswordApiRequest, IResetPasswordOtpVerificationApiRequest,
	IFeatureAccessOtpVerificationApiRequest, ISendResetPasswordLinkApiRequest, ISetSecurityQuestionsAnswersApiRequest, IVerifySecurityQuestionsAnswersApiRequest, SecurityQuestion, VerifyOtpApiResponse
} from '@bp/admins-shared/domains/identity/models';

import { IIdentityApiService } from '@bp/frontend-domains-identity';

import { AUTH_PATH_SEGMENT } from '../constants';

@Injectable({ providedIn: 'root' })
export class IdentityApiService implements IIdentityApiService<Identity, ILoginApiRequest> {
	private readonly _namespace = AUTH_PATH_SEGMENT;

	constructor(private readonly _http: HttpClient) {}

	factory = ({ accessToken: { token, otpExpiresAt }, refreshToken }: IIdentitySessionApiResponse): Identity => new Identity(token, refreshToken, otpExpiresAt);

	login(credentials: ILoginApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/login`, credentials)
			.pipe(map(this.factory));
	}

	generateLoginOtp(): Observable<GenerateOtpApiResponse> {
		return this._http
			.post<DTO<GenerateOtpApiResponse>>(`${ this._namespace }/otp/login/generate`, null)
			.pipe(mapTo(GenerateOtpApiResponse));
	}

	loginOtpVerification({ code }: ILoginOtpVerificationApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/verify/${ code }`, null)
			.pipe(map(this.factory));
	}

	generateFeatureAccessOtp(permission: FeaturePermission<any>): Observable<GenerateOtpApiResponse> {
		return this._http
			.post<void>(
			`${ this._namespace }/otp/${ permission.claim }/generate`,
			{
				featureDisplayName: permission.featureDisplayName,
			},
		)
			.pipe(mapTo(GenerateOtpApiResponse));
	}

	featureAccessOtpVerification({
		permission,
		code,
	}: IFeatureAccessOtpVerificationApiRequest): Observable<VerifyOtpApiResponse> {
		return this._http
			.post<DTO<VerifyOtpApiResponse> | null>(
			`${ this._namespace }/otp/${ permission.claim }/verify/${ code }`,
			null,
		)
			.pipe(map(dto => new VerifyOtpApiResponse({
				permission,
				expiresAt: permission.lifetimeType?.isSingleUse
					? moment().add(1, 'second') // 1s window to pass the guard
					: dto!.expiresAt!,
			})));
	}

	changePassword(request: IChangePasswordApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/change-password`, request)
			.pipe(map(this.factory));
	}

	// #region Continue Signup

	getAllSecurityQuestions(): Observable<SecurityQuestion[]> {
		return this._http.get<DTO<SecurityQuestion>[]>(`${ this._namespace }/all-security-questions`)
			.pipe(map(questions => questions.map(question => new SecurityQuestion(question))));
	}

	setSecurityQuestionsAnswers({ answers }: ISetSecurityQuestionsAnswersApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/set-security-questions-answers`, answers)
			.pipe(map(this.factory));
	}

	getAuthenticatorAppKey(): Observable<string> {
		return this._http.get(`${ this._namespace }/otp/qr-code-key`, { responseType: 'text' });
	}

	registerAuthenticatorApp(request: IRegisterAuthenticatorAppApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/register-authenticator`, request)
			.pipe(map(this.factory));
	}

	// #endregion

	// #region reset

	getIdentitySecurityQuestions(): Observable<SecurityQuestion[]> {
		return this._http.get<DTO<SecurityQuestion>[]>(`${ this._namespace }/security-questions`)
			.pipe(map(questions => questions.map(question => new SecurityQuestion(question))));
	}

	verifySecurityQuestionsAnswers({ answers }: IVerifySecurityQuestionsAnswersApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/verify-security-questions-answers`, answers)
			.pipe(map(this.factory));
	}

	// #region reset password

	sendResetPasswordLink(request: ISendResetPasswordLinkApiRequest): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/reset-password-link`, request);
	}

	resetPasswordOtpVerification({ code }: IResetPasswordOtpVerificationApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/reset-password/verify/${ code }`, null)
			.pipe(map(this.factory));
	}

	resetPassword(request: IResetPasswordApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/reset-password`, request)
			.pipe(map(this.factory));
	}

	resetExpiredPassword({ password }: IResetPasswordApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/create-password`, { password })
			.pipe(map(this.factory));
	}

	// #endregion

	// #region reset authenticator app

	sendResetAuthenticatorAppLink(): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/otp/reset-authenticator-link`, null);
	}

	resetAuthenticatorApp(request: IRegisterAuthenticatorAppApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/reset-authenticator`, request)
			.pipe(map(this.factory));
	}

	// #endregion

	// #endregion

	refreshToken(identity: Identity): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/refresh-token`, {
			refreshToken: identity.refreshToken,
			accessToken: identity.jwt,
		})
			.pipe(map(this.factory));
	}

}
