import type { MetadataEntity } from '../metadata-entity';

import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

/**
 * If the property value is undefined the provided default value gets assigned
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Default(value: any | (() => any)): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		Property({ defaultPropertyValue: value })(model, property);
	};
}
