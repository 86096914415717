import { isBoolean } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';

export class PspStatus extends Enumeration {

	static active = new PspStatus();

	static disabled = new PspStatus();

	static override parseHook(value: unknown): PspStatus | null {
		if (isBoolean(value))
			return value ? PspStatus.active : PspStatus.disabled;

		return null;
	}

}
