import pluralize from 'pluralize';
import { lowerFirst } from 'lodash-es';

import { booleanMapper, Default, DTO, MapFromDTO, Mapper, MetadataEntity, numberMapper } from '@bp/shared/models/metadata';

import { subscriptionPlanFeatureNameMapper } from './mappers';

export class SubscriptionPlanFeature extends MetadataEntity {

	@Mapper(subscriptionPlanFeatureNameMapper)
	name!: string;

	@MapFromDTO()
	displayName!: string | null | undefined;

	@MapFromDTO()
	description!: string;

	/**
	 * Present for a feature that has countable nature, e.g 2 free PSP integrations
	 * but it is not used by the bridgerpay system in any way, on contrary to @see SubscriptionPlanLimitedFeature
	 * which represents a limit to specific business entities used in the system.
	 * null means feature is unavailable for the subscription plan
	 */
	@Mapper(numberMapper)
	@Default(null)
	count!: number | null;

	@Mapper(booleanMapper)
	isIncludedInSubscription?: boolean;

	constructor(dto: DTO<SubscriptionPlanFeature>) {
		super(dto);

		if (dto.displayName === undefined)
			this.displayName = this._buildDisplayName();
	}

	private _buildDisplayName(): string | null | undefined {
		return this.count
			? `${ this.count } ${ pluralize(lowerFirst(this.name), this.count) }`
			: this.name;
	}

}

export class SubscriptionPlanLimitedFeature extends SubscriptionPlanFeature {

	@Mapper(numberMapper)
	@Default(null)
	limit!: number | null; // null unlimited

	constructor(dto: DTO<SubscriptionPlanLimitedFeature>) {
		super(dto);

		if (dto.displayName === undefined)
			this.displayName = `${ this._buildDisplayNamePrefix() } ${ this._buildPluralizedFeatureName() }${ this._buildDisplayNameSuffix() }`;
	}

	private _buildPluralizedFeatureName(): string {
		return this.limit ? pluralize(this.name, this.limit) : this.name;
	}

	private _buildDisplayNamePrefix(): string {
		if (this.limit === 1)
			return '1';

		return this.limit
			? `${ this.limit }`
			: 'Unlimited';
	}

	private _buildDisplayNameSuffix(): string {
		return this.limit ? ' (total)' : '';
	}

}
