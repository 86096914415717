import { interval, map, firstValueFrom, filter } from 'rxjs';
import * as intercom from '@intercom/messenger-js-sdk';

import { Injectable } from '@angular/core';

import { applySnakeCaseToKeysRecursively } from '@bp/shared/utilities/core';
import { Dictionary } from '@bp/shared/typings';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { RouterService } from '@bp/frontend/services/router';

import { IIntercomBootOptions, IIntercomCompany, IIntercomOptions } from './models';

@Injectable({
	providedIn: 'root',
})
export class IntercomService {

	enabled = !!this._environment.intercom;

	private _inited = false;

	constructor(
		private readonly _environment: EnvironmentService,
		private readonly _telemetryService: TelemetryService,
		private readonly _router: RouterService,
	) {
		this._trackUserSessionRecordingUrl();
	 }

	init(): void {
		if (this._inited || !this.enabled)
			return;

		TelemetryService.log('Init Intercom');

		intercom.Intercom(applySnakeCaseToKeysRecursively({
			appId: this._environment.intercom!,
			environment: this._environment.environment.name,
			platform: this._environment.platform,
		}));

		this._whenPageChangeUpdateIntercom();

		this._inited = true;
	}

	update(options: IIntercomOptions = {}): void {
		if (!this.enabled)
			return;

		options = this._addUserSessionRecordingsURLIfPossible(options);

		intercom.update(applySnakeCaseToKeysRecursively(options));
	}

	company(company: IIntercomCompany): void {
		if (!this.enabled)
			return;

		void this.update({ company });
	}

	show(): void {
		if (!this.enabled)
			return;

		intercom.show();
	}

	 startSurvey(surveyId: string): void {
		if (!this.enabled)
			return;

		intercom.startSurvey(surveyId);
	}

	trackEvent(event: string, data?: Dictionary<string>): void {
		if (!this.enabled)
			return;

		intercom.trackEvent(event, data ? applySnakeCaseToKeysRecursively(data) : undefined);
	}

	reboot(): void {
		if (!this.enabled)
			return;

		this.shutdown();

		this.__bootIntercom();
	}

	 shutdown(): void {
		if (!this.enabled)
			return;

		intercom.shutdown();
	}

	async getVisitorId(): Promise<string | null> {
		if (!this.enabled)
			return Promise.resolve(null);

		return firstValueFrom(interval(5000).pipe(
			map(() => window.Intercom?.('getVisitorId') ?? null),
			filter(id => !!id),
		));
	}

	private __bootIntercom(options: IIntercomBootOptions = {}): void {
		intercom.boot(applySnakeCaseToKeysRecursively({
			appId: this._environment.intercom!,
			...options,
		}));
	}

	private _whenPageChangeUpdateIntercom(): void {
		this._router.navigationEnd$.subscribe(() => void this.update());
	}

	private _trackUserSessionRecordingUrl(): void {
		this._telemetryService.userSessionRecordingUrl$?.subscribe(url => void this.trackEvent('LogRocket', {
			userSessionRecordingUrl: url,
		}));
	}

	private _addUserSessionRecordingsURLIfPossible(options: IIntercomOptions): IIntercomOptions {
		if (!this._environment.logrocket || !options.email)
			return options;

		return {
			...options,
			recordings: `https://app.logrocket.com/${ this._environment.logrocket }/sessions?u=${ options.email }`,
		};
	}

}
