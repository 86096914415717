<bp-current-organization-subscription-feature-limit-meter
	[featureLimit]="featureLimitsMap.get('transactions')!"
	[reflectWarning]="true"
/>

<bp-current-organization-subscription-feature-limit-meter [featureLimit]="featureLimitsMap.get('merchantPsps')!" />

<bp-current-organization-subscription-feature-limit-meter [featureLimit]="featureLimitsMap.get('checkouts')!" />

<a
	*ngIf="!currentSubscriptionPlan.isEnterprise"
	class="bp-round-button bp-button-md mt-2 min-width-auto"
	mat-flat-button
	color="primary"
	[routerLink]="[{ outlets: { modal: ['subscription-management', { mode: 'upgrade' }] } }]"
>
	Upgrade Your Plan
</a>
