<mat-progress-bar *ngIf="_identityFacade.pending$ | async" color="primary" mode="indeterminate" @slideUp />

<h2 mat-dialog-title>Access OTP Verification</h2>

<mat-dialog-content>
	<bp-resource-access-otp-verification-form
		#resourceAccessOtpVerificationForm
		[permission]="_dialogData.featurePermission"
		[pending]="_identityFacade.pending$ | async"
		[error]="_identityFacade.error$ | async"
		(submitted)="
			_identityFacade.featureAccessOtpVerification({
				code: $event.code,
				permission: _dialogData.featurePermission
			})
		"
	/>
</mat-dialog-content>

<mat-dialog-actions class="mt-3">
	<button mat-stroked-button class="mr-auto" type="button" [mat-dialog-close]="false">Cancel</button>

	<button
		mat-stroked-button
		color="primary"
		type="button"
		[disabled]="(resourceAccessOtpVerificationForm.formDirtyAndValid$ | async) !== true"
		(click)="resourceAccessOtpVerificationForm.submit()"
	>
		Access
	</button>
</mat-dialog-actions>
