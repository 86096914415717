import { BehaviorSubject } from 'rxjs';

import type { OnChanges } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import type { TextMaskConfigInput } from '@bp/shared/features/text-mask';
import { ClassMetadata, MetadataEntity } from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

@Directive({
	selector: '[bpControlContext]',
})
export class PropertyMetadataControlContextDirective<TEntity extends MetadataEntity> implements OnChanges {

	@Input('bpControlContextInferFrom') inferFrom!: ClassMetadata<TEntity>;

	@Input('bpControlContext') propertyName!: NonFunctionPropertyNames<TEntity>;

	@Input('bpControlContextFormControl') formControl?: UntypedFormControl;

	@Input('bpControlContextPending') pending?: boolean;

	@Input('bpControlContextDisabled') disabled?: boolean;

	@Input('bpControlContextDisabledDueTo') disabledDueTo?: string | null;

	@Input('bpControlContextItems') items?: any[] | null;

	@Input('bpControlContextTextMask') textMask?: TextMaskConfigInput | null;

	private readonly _changed$ = new BehaviorSubject<PropertyMetadataControlContextDirective<TEntity>>(this);

	changed$ = this._changed$.asObservable();

	ngOnChanges(): void {
		this._changed$.next(this);
	}

}
