import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

import { Destroyable } from '@bp/frontend/models/common';

import { CalendlyEvent, CalendlyEventScheduledPayload, CalendlyPostMessageEvent } from '../models';

@Directive()
export abstract class CalendlyEventsHandlerDirective extends Destroyable {

	@Output('bpCalendlyProfilePageViewed')
	readonly profilePageViewed = new EventEmitter<void>();

	@Output('bpCalendlyEventTypeViewed')
	readonly eventTypeViewed = new EventEmitter<void>();

	@Output('bpCalendlyDateAndTimeSelected')
	readonly dateAndTimeSelected = new EventEmitter<void>();

	@Output('bpCalendlyEventScheduled')
	readonly eventScheduled = new EventEmitter<CalendlyEventScheduledPayload>();

	private readonly _calendlyEventPrefix = 'calendly.';

	@HostListener('window:message', [ '$event' ])
	handleCalendlyEvents(event: MessageEvent): void {
		if (!this._isCalendlyEvent(event))
			return;

		const [ , eventName ] = event.data.event.split(this._calendlyEventPrefix);
		const calendlyEvent = CalendlyEvent.parse(eventName);

		if (calendlyEvent)
			this._emitCalendlyEvent(calendlyEvent, event);
		else
			console.warn('Unknown Calendly event', calendlyEvent);
	}

	private _isCalendlyEvent(event: MessageEvent): event is MessageEvent<CalendlyPostMessageEvent> {
		return event.origin === 'https://calendly.com'
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			&& event.data?.event?.startsWith(this._calendlyEventPrefix);
	}

	private _emitCalendlyEvent(calendlyEvent: CalendlyEvent, event: MessageEvent<CalendlyPostMessageEvent>): void {
		switch (calendlyEvent) {
			case CalendlyEvent.eventTypeViewed:
				this.eventTypeViewed.emit();
				break;

			case CalendlyEvent.dateAndTimeSelected:
				this.dateAndTimeSelected.emit();
				break;

			case CalendlyEvent.profilePageViewed:
				this.profilePageViewed.emit();
				break;

			case CalendlyEvent.eventScheduled:
				this.eventScheduled.emit(<CalendlyEventScheduledPayload>event.data.payload);
				break;

			default:
				throw new Error('Unhandled Calendly Event');
		}
	}

}
