import { createReducer, on, Action } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';
import { isEqual } from '@bp/shared/utilities/core';

import { BpError } from '@bp/frontend/models/core';
import { BridgerPsp } from '@bp/frontend/domains/bridger-psps/core';

import { VirtualTerminalData } from '@bp/admins-shared/core/models';

import {
	loadFailure,
	loadSuccess,
	loadVirtualTerminalDataFailure,
	loadVirtualTerminalDataSuccess,
	refreshFailure,
	refreshSuccess
} from './current-organization-api.actions';
import { resetState } from './current-organization.actions';

export const FEATURE_KEY = '[domain]:current-organization';

export interface IState {
	entity: Organization | null;

	loading: boolean;

	error: BpError | null;

	bridgerPsps: BridgerPsp[];

	virtualTerminalData: VirtualTerminalData | null;
}

export const initialState: IState = {
	entity: null,
	bridgerPsps: [],
	loading: true,
	error: null,
	virtualTerminalData: null,
};

const currentOrganizationReducer = createReducer(
	initialState,

	on(resetState, (): IState => initialState),

	on(
		loadSuccess,
		refreshSuccess,
		(state, { result }): IState => ({
			...state,
			entity: isEqual(result, state.entity) ? state.entity : result,
			loading: false,
			error: null,
		}),
	),

	on(
		loadFailure,
		refreshFailure,
		(state, { error }): IState => ({
			...state,
			error,
			entity: null,
			loading: false,
		}),
	),

	on(
		loadVirtualTerminalDataSuccess,
		(state, { result }): IState => ({
			...state,
			virtualTerminalData: result,
			error: null,
		}),
	),

	on(
		loadVirtualTerminalDataFailure,
		(state, { error }): IState => ({
			...state,
			error,
			virtualTerminalData: null,
		}),
	),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return currentOrganizationReducer(state, action);
}
