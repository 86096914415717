import { createSelector } from '@ngrx/store';

import { currentOrganizationSubscriptionFeature } from './current-organization-subscription.feature';

export const { selectEntity, selectError, selectLoading, selectPending, selectIsMustPurchaseSubscriptionPlan }
	= currentOrganizationSubscriptionFeature;

export const selectCurrentSubscriptionPlan = createSelector(
	selectEntity,
	currentOrganizationSubscription => currentOrganizationSubscription?.subscriptionPlan,
);

export const selectCurrentChargePeriod = createSelector(
	selectEntity,
	currentOrganizationSubscription => currentOrganizationSubscription?.chargePeriod,
);
