import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { FeaturePermissionsService, PermissionBasedNavigationService } from '@bp/frontend-domains-identity';

@Injectable({
	providedIn: 'root',
})
export class MerchantAdminPermissionBasedNavigationService extends PermissionBasedNavigationService {
	private readonly __router = inject(Router);

	private readonly __featurePermissionsService = inject(FeaturePermissionsService);

	tryNavigate(): boolean {
		switch (true) {
			case this.__featurePermissionsService.hasAccess(MerchantAdminFeature.enterpriseSubscriptionCheckout):
				void this.__router.navigateByUrl('/enterprise-subscription-plan-checkout');
				break;

			case this.__featurePermissionsService.hasAccess(MerchantAdminFeature.dashboard):
				void this.__router.navigateByUrl('/dashboard');
				break;

			case this.__featurePermissionsService.hasAccess(MerchantAdminFeature.transactions):
				void this.__router.navigateByUrl('/transactions');
				break;

			case this.__featurePermissionsService.hasAccess(MerchantAdminFeature.subscriptionsManagement):
				void this.__router.navigateByUrl('/purchase-subscription-plan');
				break;

			default:
				return false;
		}

		return true;
	}
}
