import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'bp-paywall-button',
	templateUrl: './paywall-button.component.html',
	styleUrls: [ './paywall-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallButtonComponent {

	@Input() addMore = false;

	@Input() targetBlank = false;

}
