<mat-card
	class="bp-card mild-box-shadow hoverable"
	matRipple
	[matRippleDisabled]="!selectable"
	(click)="!selectable && $event.stopPropagation()"
>
	<mat-card-title class="mat-primary flex-align-items-center">
		<bp-psp-thumbnail [name]="psp.internalName" [showPspNameTooltip]="false" />

		<h3
			class="ml-3 mb-0 mr-3 text-ellipsis link interactive primary-text"
			[matMenuTriggerFor]="detailsPanel"
			(click)="$event.stopPropagation()"
		>
			{{ psp.name }}
		</h3>

		<mat-pseudo-checkbox *ngIf="selectable" @fade [state]="selected ? 'checked' : 'unchecked'" />

		<mat-menu #detailsPanel="matMenu" class="psp-details-panel">
			<ng-template matMenuContent>
				<h2>
					<a bpTargetBlank class="link" [href]="psp.websiteUrl">{{ psp.name }}</a>
				</h2>
				<div *ngIf="psp.description" class="mb-3">
					{{ psp.description }}
				</div>

				<ng-container *ngTemplateOutlet="brandsAndCurrenciesTpl; context: { isPopup: true }" />
			</ng-template>
		</mat-menu>
	</mat-card-title>

	<mat-card-content class="flex flex-column gap-1">
		<ng-container *ngTemplateOutlet="brandsAndCurrenciesTpl" />
	</mat-card-content>

	<ng-template #brandsAndCurrenciesTpl let-isPopup="isPopup">
		<ng-container *ngIf="psp.paymentOptionByTypeMap.get(paymentOptionType) as paymentOption">
			<div class="mb-1" [class.overlay-at-right]="!isPopup">
				<bp-boxed-items
					*ngIf="paymentOption.currencies?.length; else anyCurrencyTpl"
					class="nowrap"
					[ngClass]="isPopup ? 'flex-wrap-1' : 'flex-1'"
					[items]="isPopup ? paymentOption.currencies : (paymentOption.currencies | takeSafeCount)"
				/>

				<ng-template #anyCurrencyTpl>
					<span class="boxed-value">Any fiat currency</span>
				</ng-template>
			</div>

			<div *ngIf="BridgerPspPaymentOptionsUtils.isCreditCard(paymentOption)" [class.overlay-at-right]="!isPopup">
				<bp-payment-card-brand-icons
					*ngIf="paymentOption.brands?.length; else anyBrandTpl"
					[ngClass]="isPopup ? 'flex-wrap-1' : 'flex-1'"
					[brands]="isPopup ? paymentOption.brands : (paymentOption.brands | takeSafeCount)"
				/>

				<ng-template #anyBrandTpl>
					<span class="boxed-value">Any brand</span>
				</ng-template>
			</div>

			<div *ngIf="BridgerPspPaymentOptionsUtils.isCrypto(paymentOption)" [class.overlay-at-right]="!isPopup">
				<bp-boxed-items
					*ngIf="paymentOption.cryptoCurrencies?.length; else anyCurrencyTpl"
					class="nowrap"
					[ngClass]="isPopup ? 'flex-wrap-1' : 'flex-1'"
					[items]="
						isPopup ? paymentOption.cryptoCurrencies : (paymentOption.cryptoCurrencies | takeSafeCount)
					"
				/>

				<ng-template #anyCurrencyTpl>
					<span class="boxed-value">Any crypto currency</span>
				</ng-template>
			</div>

			<ng-container *bpHasAccess="_MerchantAdminFeature.paywith">
				<bp-paywith-badge
					*ngIf="BridgerPspPaymentOptionsUtils.canBeUsedInPaywith(paymentOption)"
					class="mt-2 mb-n2"
					size="md"
				/>
			</ng-container>
		</ng-container>
	</ng-template>
</mat-card>
