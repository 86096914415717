
import { Label, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { CheckoutLaunchButtonMode } from '@bp/frontend/domains/checkout/core';

export class CheckoutLaunchButton extends MetadataEntity {

	@Label('Button Mode')
	@Mapper(CheckoutLaunchButtonMode)
	mode!: CheckoutLaunchButtonMode;

	@Label('Button Name')
	name!: string;

}
