import { snakeCase } from 'lodash-es';

import { Type } from '@angular/core';

import { Enumeration } from '@bp/shared/models/core/enum';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { CommonFeatureActions, FeatureAction, isFeatureAction } from './feature-actions';

export type Permission = Feature | FeatureAction;

export class Feature<T extends CommonFeatureActions = CommonFeatureActions> extends Enumeration {
	static isValid(value: unknown): value is Permission {
		return value instanceof Feature || isFeatureAction(<any>value);
	}

	readonly actions!: T;

	/**
	 * Backend permission claim to the feature
	 * `{feature_name}.access`
	 */
	readonly claim!: string;

	constructor(actionsTypes: Type<T>) {
		super();

		bpQueueMicrotask(() => {
			// @ts-expect-error actions is readonly, we run the actions initialization down the event loop to be sure all
			// the class metadata is set
			this.actions = new actionsTypes();

			// @ts-expect-error prop is readonly,
			this.claim = `${ snakeCase(this.name) }.access`;
		});
	}

	override toString(): string | undefined {
		return this.claim;
	}

	getActionClaim(action: keyof Omit<T, 'classMetadata'>): string {
		return `${ snakeCase(this.name) }.${ snakeCase(String(action)) }`;
	}
}
