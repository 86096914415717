import { uniq } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import type { TrackByFunction } from '@angular/core';
import { Directive, Input, Output } from '@angular/core';

import type { ClassMetadata, MetadataEntity, PropertyMetadata } from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { trackById } from '@bp/shared/utilities/core';

import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

export type Menued<T> = T | 'menu';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TableHostComponent<
	TEntity extends MetadataEntity,
	TCustomColumnsKeys = undefined,
	TColumnsKeys = TCustomColumnsKeys extends undefined
		? NonFunctionPropertyNames<TEntity>
		: NonFunctionPropertyNames<TEntity> | TCustomColumnsKeys
>
	extends Destroyable {

	private readonly _displayedPropertiesMetadata$ = new OptionalBehaviorSubject<PropertyMetadata[]>();

	@Output('displayedPropertiesMetadataChange')
	readonly displayedPropertiesMetadata$ = this._displayedPropertiesMetadata$.asObservable();

	@Input()
	get displayedPropertiesMetadata() {
		return this._displayedPropertiesMetadata$.value!;
	}

	set displayedPropertiesMetadata(value: PropertyMetadata[]) {
		this._displayedPropertiesMetadata$.next(value);
	}

	metadata!: ClassMetadata<TEntity>;

	columnsOrder!: Menued<TColumnsKeys>[];

	trackById: TrackByFunction<TEntity> = trackById;

	customColumns: TColumnsKeys[] = [];

	private readonly _columnsMetadata$ = new BehaviorSubject<PropertyMetadata[]>([]);

	readonly columnsMetadata$ = this._columnsMetadata$.asObservable();

	private readonly _headerColumnsIds$ = new BehaviorSubject<TColumnsKeys[]>([]);

	readonly headerColumnsIds$ = <Observable<string[]>> <unknown> this._headerColumnsIds$.asObservable();

	private readonly _displayedColumnsIds$ = new BehaviorSubject<Menued<TColumnsKeys>[]>([]);

	readonly typedDisplayedColumnsIds$ = this._displayedColumnsIds$.asObservable();

	readonly displayedColumnsIds$ = <Observable<string[]>> this._displayedColumnsIds$.asObservable();

	private _alwaysShownColumnsMetadata: PropertyMetadata[] = [];

	constructor() {
		super();

		this._displayedPropertiesMetadata$
			.pipe(takeUntilDestroyed(this))
			.subscribe(displayedPropertiesMd => {
				const propertiesMd = uniq([ ...displayedPropertiesMd, ...this._alwaysShownColumnsMetadata ]);

				const headlessColumnsIds = <Menued<TColumnsKeys>[]> propertiesMd
					.filter(v => v.table!.headless)
					.map(v => v.property);

				let columnsIds = [
					...<Menued<TColumnsKeys>[]> propertiesMd.map(v => v.property),
					...this.customColumns,
				];

				columnsIds = this._sortAccordingColumnsOrder(columnsIds);

				this._headerColumnsIds$.next(<TColumnsKeys[]> columnsIds.filter(v => !headlessColumnsIds.includes(v)));

				if (this.columnsOrder.includes('menu'))
					columnsIds.unshift('menu');

				this._displayedColumnsIds$.next(columnsIds);
			});
	}

	/**
	 *
	 */
	setConfig(cf: {
		metadata: ClassMetadata<TEntity>;

		/**
		 * If populated will be used to order the columns accordingly, all the non specified columns to be rendered will be shown unordered after the ordered ones
		 */
		columnsOrder?: Menued<TColumnsKeys>[];

		/**
		 * If populated will tell the table to show only the specified property columns metadata for which is taken from the class metadata. The order of keys will be reflected in the table columns
		 */
		columns?: Menued<TColumnsKeys>[];

		/**
		 * To show columns which don't belong to the view model linked to the table
		 */
		customColumns?: TColumnsKeys[];
	}): void {
		this.metadata = cf.metadata;

		this.columnsOrder = cf.columnsOrder ?? cf.columns ?? [];

		this.customColumns = cf.customColumns ?? [];

		this._columnsMetadata$.next(this._sortPropertyMetadataAccordingColumnsOrder(
			cf.metadata.values
				.filter(v => !!v.table && (cf.columns?.includes(<Menued<TColumnsKeys>> v.property) ?? true)),
		));

		this._alwaysShownColumnsMetadata = this._columnsMetadata$.value.filter(v => v.table!.alwaysShown);

		this.displayedPropertiesMetadata = this._columnsMetadata$.value.filter(v => !v.table!.optional);
	}

	protected _castToEntity(value: unknown): TEntity {
		return <TEntity>value;
	}

	private _sortPropertyMetadataAccordingColumnsOrder(propertiesMd: PropertyMetadata[]): PropertyMetadata[] {
		return propertiesMd.sort(
			(a, b) => this.columnsOrder.indexOf(<TColumnsKeys> <any> a.property) - this.columnsOrder.indexOf(<TColumnsKeys> <any> b.property),
		);
	}

	private _sortAccordingColumnsOrder(properties: Menued<TColumnsKeys>[]): Menued<TColumnsKeys>[] {
		return properties.sort((a, b) => this.columnsOrder.indexOf(a) - this.columnsOrder.indexOf(b));
	}
}
