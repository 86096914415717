import { isString, upperFirst } from 'lodash-es';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';
import {
	booleanMapper, Default, FieldControlType, FieldViewType, MapFromDTO, Mapper, MetadataEntity, PropertyMetadata,
	PropertyMetadataControl, titleCase, Unserializable, DTO
} from '@bp/shared/models/metadata';

export interface IPropertyMetadataValidatorDTO {

	name: string | 'regex';

	value: string;

	errorMessage: string;

}

export class ApiPropertyMetadata extends MetadataEntity {

	@MapFromDTO()
	name!: string;

	@Mapper(titleCase)
	title!: string;

	@Mapper(upperFirst)
	description!: string;

	@Mapper(upperFirst)
	warning!: string;

	@Mapper(value => FieldControlType.parse(value) ?? FieldControlType.input)
	@Default(FieldControlType.input)
	controlType!: FieldControlType;

	@Mapper(booleanMapper)
	@Default(true)
	required!: boolean;

	@MapFromDTO()
	default?: unknown[] | boolean | number | string;

	@MapFromDTO()
	items?: string[];

	@Mapper(booleanMapper)
	@Default(false)
	isSecret?: boolean;

	@MapFromDTO()
	validators!: IPropertyMetadataValidatorDTO[] | null;

	@Unserializable()
	readonly propertyMetadata: PropertyMetadata;

	@Unserializable()
	readonly validator: IValidatorFunc | null;

	constructor(dto?: DTO<ApiPropertyMetadata>) {
		super(dto);

		this.validator = this._buildValidatorFunc();

		this.propertyMetadata = this._buildPropertyMetadata();
	}

	private _buildValidatorFunc(): IValidatorFunc | null {
		return this.validators
			? Validators.compose(
				this.validators.map(v => v.name === 'regex'
					? Validators.pattern(v.value, v.errorMessage)
					: null),
			)
			: null;
	}

	private _buildPropertyMetadata(): PropertyMetadata {
		const viewType = this.__inferViewType();

		return new PropertyMetadata({
			property: this.name,
			label: this.title,
			longHint: this.description,
			viewType,
			viewFormatter: (viewType === FieldViewType.moment) ? () => 'LLL' : undefined,
			defaultPropertyValue: this._tryGetDefaultPropertyValue(),
			isSecret: this.isSecret,
			control: new PropertyMetadataControl({
				required: this.required,
				type: this._inferControlType(),
				list: this.controlType === FieldControlType.currency ? FiatCurrency.list : this.items,
				validator: this.validator,
				isSecret: this.isSecret,
				warning: this.warning,
			}),
		});
	}

	private __inferViewType(): FieldViewType {
		switch (this.controlType) {
			case FieldControlType.checkbox:

			case FieldControlType.switch:
				return FieldViewType.boolean;

			case FieldControlType.date:
				return FieldViewType.moment;

			default:
				return FieldViewType.text;
		}
	}

	private _inferControlType(): FieldControlType {
		switch (this.controlType) {
			case FieldControlType.currency:
				return FieldControlType.autocomplete;

			case FieldControlType.input:
				return this.name.toLowerCase().includes('password')
					? FieldControlType.password
					: FieldControlType.input;

			default:
				return this.controlType;
		}
	}

	private _tryGetDefaultPropertyValue(): unknown {
		if (!this.default || !isString(this.default))
			return this.default;

		try {
			return JSON.parse(this.default);
		} catch {
			return this.default;
		}
	}

}
