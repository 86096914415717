import { first } from 'lodash-es';

import {
	ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, SimpleChanges
} from '@angular/core';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { FiatCurrency } from '@bp/shared/models/currencies';

import { FADE_IN } from '@bp/frontend/animations';
import { PaymentOptionType, PspPaymentOptionType } from '@bp/frontend/models/business';
import { Destroyable } from '@bp/frontend/models/common';
import { BpError } from '@bp/frontend/models/core';
import { BridgerPsp, BridgerPspsByPaymentOptionTypeMap } from '@bp/frontend/domains/bridger-psps/core';

@Component({
	selector: 'bp-psps-adding',
	templateUrl: './psps-adding.component.html',
	styleUrls: [ './psps-adding.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class PspsAddingComponent extends Destroyable implements OnChanges {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	PaymentOptionType = PaymentOptionType;

	@Input() pspsByPaymentOptionTypeMap!: BridgerPspsByPaymentOptionTypeMap | null;

	@Input() error: BpError | null = null;

	@Input() pspsLimit!: number | null;

	@Output() readonly hasSelectedPsps = new EventEmitter<boolean>();

	@Output() readonly selectedPspsCountChange = new EventEmitter<number>();

	@Output()
	readonly selectedPspsByPaymentOptionTypeMapChange = new EventEmitter<BridgerPspsByPaymentOptionTypeMap>();

	selectedPaymentOptionType = PspPaymentOptionType.creditCard;

	selectedPspsByPaymentOptionTypeMap = new Map<PspPaymentOptionType, BridgerPsp[]>();

	paymentOptionTypes: PspPaymentOptionType[] = [];

	pspsFilter: Partial<BridgerPsp> | null = null;

	availableCurrenciesPerPaymentOptionType = new Map<PspPaymentOptionType, Set<FiatCurrency>>();

	availableCreditCardBrands = new Set<PaymentCardBrand>();

	get isCreditCardPaymentOptionTypeSelected(): boolean {
		return this.selectedPaymentOptionType === PspPaymentOptionType.creditCard;
	}

	selectedPspsCount = 0;

	ngOnChanges({ pspsByPaymentOptionTypeMap }: Partial<SimpleChanges>): void {
		if (pspsByPaymentOptionTypeMap) {
			this.paymentOptionTypes = [ ...(this.pspsByPaymentOptionTypeMap?.keys() ?? []) ];

			this.selectedPaymentOptionType = first(this.paymentOptionTypes) ?? PspPaymentOptionType.creditCard;

			this.availableCurrenciesPerPaymentOptionType
				= this._aggregateCurrenciesPerPaymentOptionTypeFromAllBridgerPsps();

			this.availableCreditCardBrands = this._aggregateCreditCardBrandsFromAllBridgerPsps();
		}
	}

	storeSelectedPaymentOptionPsps(optionType: PspPaymentOptionType, selectedPspIds: Set<string>): void {
		const psps = this.pspsByPaymentOptionTypeMap
			?.get(optionType)
			?.filter(psp => selectedPspIds.has(psp.id!)) ?? [];

		this.selectedPspsByPaymentOptionTypeMap = new Map([
			...this.selectedPspsByPaymentOptionTypeMap,
			[ optionType, [ ...psps ]],
		]);

		this.selectedPspsByPaymentOptionTypeMapChange.emit(this.selectedPspsByPaymentOptionTypeMap);

		this.hasSelectedPsps.emit(this._checkIfHasSelectedPsps());

		this.selectedPspsCountChange.emit(this._countSelectedPsps());
	}

	private _checkIfHasSelectedPsps(): boolean {
		return [ ...this.selectedPspsByPaymentOptionTypeMap.keys() ]
			.some(option => this.selectedPspsByPaymentOptionTypeMap.get(option)?.length);
	}

	private _countSelectedPsps(): number {
		this.selectedPspsCount = [ ...this.selectedPspsByPaymentOptionTypeMap.values() ]
			.flat()
			.length;

		return this.selectedPspsCount;
	}

	private _aggregateCurrenciesPerPaymentOptionTypeFromAllBridgerPsps(): Map<PspPaymentOptionType, Set<FiatCurrency>> {
		return new Map(
			[ ...(this.pspsByPaymentOptionTypeMap?.entries() ?? []) ]
				.map(([ paymentOptionType, bridgerPsps ]) => [
					paymentOptionType,
					new Set(bridgerPsps.flatMap(
						bridgerPsp => bridgerPsp.paymentOptionByTypeMap.get(paymentOptionType)?.currencies ?? [],
					)),
				]),
		);
	}

	private _aggregateCreditCardBrandsFromAllBridgerPsps(): Set<PaymentCardBrand> {
		return new Set([ ...(this.pspsByPaymentOptionTypeMap?.values() ?? []) ]
			.flatMap(bridgerPsps => bridgerPsps.flatMap(
				bridgerPsp => bridgerPsp.paymentCardBrands,
			)));
	}

}
