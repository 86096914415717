import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { getMaxAnnualDiscount } from '@bp/frontend/domains/subscription-plans/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { CurrentSubscriptionManagementMode } from '../../../../models';

@Component({
	selector: 'bp-subscription-plans-overview',
	templateUrl: './subscription-plans-overview.component.html',
	styleUrls: [ './subscription-plans-overview.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlansOverviewComponent implements OnInit, OnChanges {
	@Input() mode!: CurrentSubscriptionManagementMode;

	@Input()
	get currentOrganizationSubscription(): CurrentOrganizationSubscription | null {
		return this._currentOrganizationSubscription;
	}

	set currentOrganizationSubscription(value: CurrentOrganizationSubscription | null) {
		this._currentOrganizationSubscription = value;

		this._setOppositeChargePeriodToCurrent();
	}

	private _currentOrganizationSubscription!: CurrentOrganizationSubscription | null;

	@Input() subscriptionPlans!: SubscriptionPlan[];

	@Output() readonly executeSubscriptionPlanAction = new EventEmitter<SubscriptionPlan>();

	@Output() readonly selectedChargePeriodChange = new EventEmitter<SubscriptionPlanChargePeriod>();

	@Output() readonly selectedCurrencyChange = new EventEmitter<FiatCurrency>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected readonly _MerchantAdminFeature = MerchantAdminFeature;

	get currentSubscriptionPlan(): SubscriptionPlan | null {
		return this.currentOrganizationSubscription?.subscriptionPlan ?? null;
	}

	selectedCurrency = new FiatCurrency('USD');

	// eslint-disable-next-line @typescript-eslint/naming-convention
	SubscriptionPlanChargePeriod = SubscriptionPlanChargePeriod;

	selectedChargePeriod = SubscriptionPlanChargePeriod.annually;

	oppositeChargePeriodToCurrent!: SubscriptionPlanChargePeriod;

	maxAnnualDiscount!: number;

	subscriptionPlanActionLabelMap = new Map<SubscriptionPlan, string>();

	ngOnChanges({ subscriptionPlans, currentSubscriptionPlan }: SimpleChanges<this>): void {
		if (subscriptionPlans)
			this._updateAnnualDiscount();

		if (currentSubscriptionPlan || subscriptionPlans)
			this._setSubscriptionActionLabelMap();
	}

	castToSubscription(value: unknown): SubscriptionPlan {
		return <SubscriptionPlan>value;
	}

	ngOnInit(): void {
		this.selectedCurrencyChange.emit(this.selectedCurrency);
	}

	switchToOppositeChargePeriod(subscriptionPlan: SubscriptionPlan): void {
		this.selectedChargePeriodChange.emit(this.oppositeChargePeriodToCurrent);

		this.executeSubscriptionPlanAction.emit(subscriptionPlan);
	}

	changeSubscriptionPlan(subscriptionPlan: SubscriptionPlan): void {
		this.selectedChargePeriodChange.emit(this.selectedChargePeriod);

		this.executeSubscriptionPlanAction.emit(subscriptionPlan);
	}

	private _setOppositeChargePeriodToCurrent(): void {
		this.oppositeChargePeriodToCurrent
			= this.currentOrganizationSubscription?.chargePeriod === SubscriptionPlanChargePeriod.annually
				? SubscriptionPlanChargePeriod.monthly
				: SubscriptionPlanChargePeriod.annually;
	}

	private _setSubscriptionActionLabelMap(): void {
		const currentSubscriptionPlanIndex = this.subscriptionPlans.findIndex(
			subscription => subscription.id === this.currentSubscriptionPlan?.id,
		);

		this.subscriptionPlanActionLabelMap = new Map(
			this.subscriptionPlans.map((subscriptionPlan, index) => [
				subscriptionPlan,
				index < currentSubscriptionPlanIndex
					? 'Downgrade'
					: (this.mode.isPurchase
						? 'Purchase now!'
						: 'Upgrade now!'),
			]),
		);
	}

	private _updateAnnualDiscount(): void {
		this.maxAnnualDiscount = getMaxAnnualDiscount(this.subscriptionPlans);
	}
}
