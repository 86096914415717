import { DTO, Entity, Mapper, View, FieldViewType } from '@bp/shared/models/metadata';
import { User } from '@bp/shared/domains/users';

export class LoginEvent extends Entity {

	@Mapper(User)
	@View(FieldViewType.personEmail)
	user!: User;

	constructor(dto?: DTO<LoginEvent>) {
		super(dto);
	}

}
