import { Observable, defer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IIdentity } from '@bp/shared/domains/jwt-session';
import { Permission } from '@bp/shared/domains/permissions';
import { DTO } from '@bp/shared/models/metadata';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

import { filterPresent, filterTruthy } from '@bp/frontend/rxjs';

import { IdentitySelectors } from './compose-identity-selectors';
import { IdentityActions } from './identity.actions';
import { IdentityState } from './compose-identity-reducer';

@Injectable({ providedIn: 'root' })
export abstract class IdentityFacade<
	TIdentity extends IIdentity,
	TState extends IdentityState<TIdentity>,
	TLoginPayload = undefined
> {

	abstract readonly actions: IdentityActions<TIdentity, TLoginPayload>;

	abstract readonly selectors: IdentitySelectors<TIdentity, TState>;

	readonly user$: Observable<TIdentity | null> = defer(() => this._store$.select(this.selectors.user));

	user!: TIdentity | null;

	readonly userPresent$: Observable<TIdentity> = this.user$.pipe(filterPresent);

	readonly userIsLoggedIn$: Observable<boolean> = this.user$.pipe(
		map(v => !!v),
		distinctUntilChanged(),
	);

	readonly userIsLoggedOut$: Observable<boolean> = this.userIsLoggedIn$.pipe(map(v => !v));

	readonly userHasLoggedIn$: Observable<TIdentity> = defer(() => this._actions$.pipe(
		ofType(this.actions.api.loginSuccess),
		map(({ result }) => result),
	));

	readonly userHasLoggedOut$: Observable<null> = defer(() => this._actions$.pipe(
		ofType(this.actions.logout),
		map(() => null),
	));

	readonly organizationPermissions$ = defer(() => this._store$.select(
		this.selectors.organizationPermissions,
	));

	organizationPermissions = new Set<Permission>();

	readonly hiddenOrganizationPermissions$ = defer(() => this._store$.select(
		this.selectors.hiddenOrganizationPermissions,
	));

	hiddenOrganizationPermissions = new Set<Permission>();

	readonly pending$ = defer(() => this._store$.select(this.selectors.pending));

	readonly urlForRedirectionAfterLogin$ = defer(() => this._store$.select(
		this.selectors.urlForRedirectionAfterLogin,
	));

	readonly reset$ = defer(() => this._actions$.pipe(ofType(this.actions.resetState)));

	readonly error$ = defer(() => this._store$.select(this.selectors.error));

	constructor(protected readonly _store$: Store, protected readonly _actions$: Actions) {
		// at the end of the event loop to be sure the selectors and actions are set
		bpQueueMicrotask(() => {
			this._keepUserPropertyUpdated();

			this._keepOrganizationPermissionsPropertyUpdated();

			this._keepHiddenOrganizationPermissionsPropertyUpdated();
		});
	}

	abstract factory(v?: DTO<TIdentity>): TIdentity;

	setIdentity(identity: TIdentity): void {
		this._store$.dispatch(this.actions.setIdentity({ identity }));
	}

	removeIdentity(): void {
		this._store$.dispatch(this.actions.removeIdentity());
	}

	login(payload: TLoginPayload): void {
		this._store$.dispatch(this.actions.login({ payload }));
	}

	confirmLogout(): void {
		this._store$.dispatch(this.actions.confirmLogout());
	}

	logout(): void {
		this._store$.dispatch(this.actions.logout());
	}

	saveUrlForRedirectionAfterLogin(url: string): void {
		this._store$.dispatch(this.actions.saveUrlForRedirectionAfterLogin({ url }));
	}

	resetState(): void {
		this._store$.dispatch(this.actions.resetState());
	}

	resetError(): void {
		this._store$.dispatch(this.actions.resetError());
	}

	/**
	 * Happens when the user logins, when we recover the user from local storage or when when the user logged
	 * in on another tab
	 */
	onIdentityFirstSet(action: (identity: TIdentity) => void): void {
		this.userIsLoggedIn$.pipe(filterTruthy).subscribe(() => void action(this.user!));
	}

	onIdentityLogout(action: () => void): void {
		this.userHasLoggedOut$.subscribe(action);
	}

	onPermissionFirstPresence(permission: Permission, action: () => void): void {
		this.onIdentityFirstSet(identity => {
			if (identity.featurePermissions.has(permission))
				action();
		});
	 }

	addOrganizationPermissions(permissions: Permission[]): void {
		this._store$.dispatch(this.actions.addOrganizationPermissions({ permissions }));
	}

	hideOrganizationPermissions(permissions: Permission[]): void {
		this._store$.dispatch(this.actions.hideOrganizationPermissions({ permissions }));
	}

	private _keepUserPropertyUpdated(): void {
		this.user$.subscribe(user => (this.user = user ?? null));
	}

	private _keepOrganizationPermissionsPropertyUpdated(): void {
		this.organizationPermissions$.subscribe(
			organizationPermissions => (this.organizationPermissions = organizationPermissions ?? new Set()),
		);
	}

	private _keepHiddenOrganizationPermissionsPropertyUpdated(): void {
		this.hiddenOrganizationPermissions$.subscribe(
			hiddenOrganizationPermissions => (this.hiddenOrganizationPermissions = hiddenOrganizationPermissions ?? new Set()),
		);
	}
}
