import { Enumeration } from '@bp/shared/models/core/enum';

export class LottieAnimationEnum extends Enumeration {

	static readonly logoBalloon = new LottieAnimationEnum();

	static readonly logoCart = new LottieAnimationEnum();

	static readonly logoRocket = new LottieAnimationEnum();

}
