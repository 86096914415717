import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedPipesModule } from '@bp/frontend/pipes';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';

import { SharedDomainsIdentityModule } from '@bp/frontend-domains-identity';

import {
	BridgerPspsFilterComponent, BridgerPspsMultipickerModule,
	PaymentOptionTypeToggleGroupComponent
} from './components';
import { PspsAddingComponent } from './psps-adding.component';

@NgModule({
	imports: [
		CommonModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatButtonModule,
		MatBadgeModule,
		MatSlideToggleModule,

		SharedPipesModule,
		SharedDirectivesCoreModule,
		SharedDomainsIdentityModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		AdminsSharedCoreComponentsModule,

		BridgerPspsMultipickerModule,
	],
	exports: [ PspsAddingComponent ],
	declarations: [
		PaymentOptionTypeToggleGroupComponent,
		PspsAddingComponent,
		BridgerPspsFilterComponent,
	],
})
export class PspsAddingModule { }
