import { mapValues, snakeCase, isObject, mapKeys } from 'lodash-es';

import { CamelToSnakeCase } from '@bp/shared/typings';

export function applySnakeCaseToKeysRecursively<T extends Object>(object: T): CamelToSnakeCase<T> {
	return <CamelToSnakeCase<T>><unknown>mapValues(
		mapKeys(object, (_, key) => snakeCase(key)),
		value => isObject(value) ? applySnakeCaseToKeysRecursively(value) : value,
	);
}
