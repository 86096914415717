/* eslint-disable max-classes-per-file */
import { EditAndAccessFeatureActions, FeatureAction, FeatureActionSymbol } from '../feature-actions';

export class SupportRequestsFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	generateOrganizationAccessToken!: FeatureAction;

}

export class WizardSetupsFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	createEnterpriseSetup!: FeatureAction;

}

export class UsersFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	liftPermanentLock!: FeatureAction;

	@FeatureActionSymbol
	deleteEmailSuppression!: FeatureAction;

	@FeatureActionSymbol
	resetSecurityQuestions!: FeatureAction;

}

export class OrganizationsFeatureActions extends EditAndAccessFeatureActions {

	@FeatureActionSymbol
	inviteMembers!: FeatureAction;

	@FeatureActionSymbol
	changeMemberRole!: FeatureAction;

}
