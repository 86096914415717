import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { MerchantPsp } from '@bp/admins-shared/core/models';
import { EntitiesInMemoryPagedListFacade } from '@bp/admins-shared/features/entity';

import { MerchantPspsQueryParams, MerchantPspsByPaymentOptionTypeByPspNameMap } from '../models';

import { addMerchantPspsToList, MERCHANT_PSPS_LIST_ACTIONS } from './merchant-psps.actions';
import {
	selectAllEnabledMerchantPsps,
	selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap,
	selectAllMerchantPspsByPaymentOptionTypeByPspNameMap,
	MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS,
	selectAllMerchantPspPaymentOptionTypes
} from './merchant-psps.selectors';
import { IState } from './merchant-psps.reducer';

@Injectable({
	providedIn: 'root',
})
export class MerchantPspsFacade extends EntitiesInMemoryPagedListFacade<MerchantPsp, IState, MerchantPspsQueryParams> {
	readonly actions = MERCHANT_PSPS_LIST_ACTIONS;

	readonly selectors = MERCHANT_PSPS_IN_MEMORY_PAGED_LIST_SELECTORS;

	allByPaymentOptionTypeByPspNameMap$ = this._store$.select(selectAllMerchantPspsByPaymentOptionTypeByPspNameMap);

	allByPaymentOptionTypeByPspNameMap: MerchantPspsByPaymentOptionTypeByPspNameMap | null = null;

	allEnabled$ = this._store$.select(selectAllEnabledMerchantPsps);

	allEnabledByPaymentOptionTypeByPspName$ = this._store$.select(
		selectAllEnabledMerchantPspsByPaymentOptionTypeByPspNameMap,
	);

	allPaymentOptionTypes$ = this._store$.select(selectAllMerchantPspPaymentOptionTypes);

	constructor(store$: Store, actions$: Actions) {
		super(store$, actions$);

		this.__updateAllByPaymentOptionTypeByPspNameMapOnStateChange();
	}

	queryParamsFactory(params: Params): MerchantPspsQueryParams {
		return new MerchantPspsQueryParams(params);
	}

	getByPaymentOptionTypeAndPspName(paymentOptionType: PaymentOptionType, pspName: string): MerchantPsp | null {
		return this.allByPaymentOptionTypeByPspNameMap?.get(paymentOptionType)?.get(pspName) ?? null;
	}

	addPspsToList(psps: MerchantPsp[]): void {
		this._store$.dispatch(addMerchantPspsToList({ psps }));
	}

	private __updateAllByPaymentOptionTypeByPspNameMapOnStateChange(): void {
		this.allByPaymentOptionTypeByPspNameMap$.subscribe(v => (this.allByPaymentOptionTypeByPspNameMap = v));
	}
}
