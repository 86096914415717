
import type { OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';

import type { ClassMetadata } from '@bp/shared/models/metadata';

import { SortDirective } from '@bp/frontend/directives/core';

@Directive({
	selector: '[bpClassMetadataSort][matSort]',
})
export class ClassMetadataSortDirective extends SortDirective implements OnInit {

	@Input('bpClassMetadataSort') classMetadata!: ClassMetadata<any>;

	ngOnInit(): void {
		this._trySetDefaultSortFieldFromClassMetadata();
	}

	private _trySetDefaultSortFieldFromClassMetadata(): void {
		if (!this.classMetadata.defaultSortProperty)
			return;

		const { property, defaultSortDir } = this.classMetadata.defaultSortProperty;

		this._matSort.active ||= property;

		if (!this._matSort.direction) {
			this._matSort.direction ||= defaultSortDir;

			this._matSort.start = defaultSortDir === '' ? 'desc' : defaultSortDir;
		}
	}

}
