<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<bp-input
	*ngIf="isControlTypeInputLike"
	[textarea]="controlMetadata.type === FieldControlType.textarea"
	[number]="controlMetadata.type === FieldControlType.number"
	[formControl]="control"
	[color]="color"
	[name]="metadata.property"
	[label]="controlLabel"
	[hint]="metadata.hint"
	[longHint]="metadata.longHint"
	[pending]="pending"
	[required]="controlMetadata.required"
	[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
	[placeholder]="controlMetadata.placeholder"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[maxLength]="$$inputControlOptions.maxLength"
	[mask]="$$textMask"
	[isSecret]="controlMetadata.isSecret"
	[hideClearButton]="$$inputControlOptions.hideClearButton"
	[inputmode]="$$inputControlOptions.inputmode"
/>

<bp-input-chips
	*ngIf="isControlTypeInputChipLike"
	[number]="controlMetadata.type === FieldControlType.numberChips"
	[formControl]="control"
	[color]="color"
	[name]="metadata.property"
	[label]="controlLabel"
	[hint]="metadata.hint"
	[longHint]="metadata.longHint"
	[pending]="pending"
	[required]="controlMetadata.required"
	[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
	[placeholder]="controlMetadata.placeholder"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
/>

<ng-container *ngIf="isControlTypeChipLike">
	<bp-chips
		*ngIf="$$chipControlOptions as controlOptions"
		[items]="$$items"
		[formControl]="control"
		[color]="color"
		[name]="metadata.property"
		[label]="controlLabel"
		[hint]="metadata.hint"
		[longHint]="metadata.longHint"
		[pending]="pending"
		[required]="controlMetadata.required"
		[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		[placeholder]="controlMetadata.placeholder"
		[trackByFn]="controlOptions.trackByFn"
		[resetOptionText]="controlOptions.resetOptionText"
		[appearance]="appearance"
		[floatLabel]="floatLabel"
		[customAutocompleteOption]="_customAutocompleteOption"
		[chipTpl]="_customChip?.tpl"
	>
		<ng-container *ngIf="controlMetadata.type === FieldControlType.brandChips">
			<bp-payment-card-brand-icon *bpChip="let brand" [src]="brand" />

			<div *bpAutocompleteOption="let brand" class="flex-align-items-center">
				<bp-payment-card-brand-icon class="mr-2" [src]="brand" />
				{{ brand.displayName }}
			</div>
		</ng-container>

		<ng-container *ngIf="controlMetadata.type === FieldControlType.countryChips">
			<bp-country *bpChip="let country" [src]="country" [compact]="true" />

			<bp-country *bpAutocompleteOption="let country" class="mr-2" [src]="country" />
		</ng-container>
	</bp-chips>
</ng-container>

<ng-container [ngSwitch]="controlMetadata.type">
	<ng-container *ngSwitchCase="FieldControlType.date">
		<bp-datepicker
			*ngIf="getControlOptions(FieldControlType.date) as controlOptions"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="metadata.label"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[min]="controlOptions.min"
			[max]="controlOptions.max"
			[startView]="controlOptions.startView"
			[startAt]="controlOptions.startAt"
		/>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.country">
		<bp-country-selector
			*ngIf="$$countryControlOptions as controlOptions"
			[countries]="controlMetadata.list"
			[hasWorldwide]="!!controlOptions.hasWorldwide"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[hideClearButton]="controlOptions.hideClearButton"
		/>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.select">
		<bp-select-field
			*ngIf="$$selectControlOptions as controlOptions"
			[items]="$$items"
			[multiple]="controlOptions.multiple"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[optionTpl]="_customSelectOption?.tpl"
		/>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.autocomplete">
		<bp-autocomplete
			*ngIf="$$autocompleteControlOptions as controlOptions"
			[items]="$$items"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[pending]="pending"
			[suggestedItem]="controlOptions.suggestedItem"
			[suggestedItemTooltip]="controlOptions.suggestedItemTooltip"
			[suggestedItemButtonTextPrefix]="controlOptions.suggestedItemButtonTextPrefix"
			[optionTpl]="_customAutocompleteOption?.tpl"
		/>
	</ng-container>

	<bp-button-toggle
		*ngSwitchCase="FieldControlType.buttonToggle"
		[items]="$$items"
		[formControl]="control"
		[color]="color"
		[name]="metadata.property"
		[label]="controlLabel"
		[hint]="metadata.hint"
		[longHint]="metadata.longHint"
		[required]="controlMetadata.required"
		[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		[placeholder]="controlMetadata.placeholder"
	/>

	<div *ngSwitchCase="FieldControlType.switch" class="control mat-form-field mat-form-field-switch">
		<mat-slide-toggle
			[formControl]="control"
			[name]="metadata.property"
			[color]="color ?? 'primary'"
			[required]="controlMetadata.required"
			(click)="control.markAsTouched()"
		>
			<span bpTooltipShownOnViewportOverflow class="label text-ellipsis" [bpTooltip]="controlLabel">
				<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>{{ controlLabel }}</span>
			</span>
			<bp-hint *ngIf="metadata.hint || metadata.longHint as hint" [message]="hint" />
		</mat-slide-toggle>
		<mat-error [bpFieldError]="control" [bpFieldErrorAnimate]="true" />
	</div>

	<div *ngSwitchCase="FieldControlType.checkbox" class="control mat-form-field mat-form-field-checkbox">
		<mat-checkbox
			[formControl]="control"
			[name]="metadata.property"
			[color]="color ?? 'primary'"
			[required]="controlMetadata.required"
			(click)="control.markAsTouched()"
		>
			<span bpTooltipShownOnViewportOverflow class="label text-ellipsis" [bpTooltip]="controlLabel">
				<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>{{ controlLabel }}</span>
			</span>
			<bp-hint *ngIf="metadata.hint || metadata.longHint as hint" [message]="hint" />
		</mat-checkbox>

		<mat-error [bpFieldError]="control" [bpFieldErrorAnimate]="true" />
	</div>

	<ng-container *ngSwitchCase="FieldControlType.phone">
		<bp-phone-input
			*ngIf="getControlOptions(FieldControlType.phone) as controlOptions"
			[initialDialCodeCountry]="controlOptions.initialDialCodeCountry"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		/>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.password">
		<bp-password-input
			*ngIf="getControlOptions(FieldControlType.password) as controlOptions"
			[formControl]="control"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[longHint]="metadata.longHint"
			[required]="controlMetadata.required"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[maxLength]="controlOptions.maxLength"
			[mask]="$$textMask"
		/>
	</ng-container>
</ng-container>
