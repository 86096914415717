import { Injectable } from '@angular/core';

import { PermissionBasedNavigationService } from './permission-based-navigation.service';

@Injectable({
	providedIn: 'root',
})
export class NoopPermissionBasedNavigationService extends PermissionBasedNavigationService {

	tryNavigate(): boolean {
		return false;
	}

}
