import { QuicklinkModule } from 'ngx-quicklink';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedFeaturesMatMenuTriggerObserverModule } from '@bp/frontend/features/mat-menu-trigger-observer';

import {
	EditableViewCardComponent, EditableViewListCardComponent, LayoutComponent, NavGroupComponent, NavItemComponent,
	RightDrawerComponent, RightDrawersOrchestratorComponent, SidenavComponent, SvgAdminSharedIconsDefinitionsComponent, ToolbarComponent
} from './components';
import { ActiveTabSetterOnRouteChangeDirective, RightDrawerNavBarDirective } from './directives';

const COMPONENTS = [
	LayoutComponent,
	NavItemComponent,
	NavGroupComponent,
	SidenavComponent,
	RightDrawerComponent,
	RightDrawersOrchestratorComponent,
	ToolbarComponent,
	SvgAdminSharedIconsDefinitionsComponent,
	EditableViewCardComponent,
	EditableViewListCardComponent,

	ActiveTabSetterOnRouteChangeDirective,
	RightDrawerNavBarDirective,
];

@NgModule({
	imports: [
		QuicklinkModule,
		CommonModule,
		RouterModule,
		StoreModule,
		EffectsModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,

		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesMatMenuTriggerObserverModule,
		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class AdminsSharedFeaturesLayoutModule { }
