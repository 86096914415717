import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ANALYTICS_OBSERVERS } from '@bp/frontend/features/analytics/models';

import { GoogleTagDirective } from './directives';
import { LinkedInAnalyticsObserverService, PardotAnalyticsObserverService, TelemetryAnalyticsObserverService } from './services';

const EXPOSED = [
	GoogleTagDirective,
];

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesAnalyticsModule {
	static forRoot(): ModuleWithProviders<SharedFeaturesAnalyticsModule> {
		return {
			ngModule: SharedFeaturesAnalyticsModule,
			providers: [
				{ provide: ANALYTICS_OBSERVERS, useClass: LinkedInAnalyticsObserverService, multi: true },
				{ provide: ANALYTICS_OBSERVERS, useClass: TelemetryAnalyticsObserverService, multi: true },
				{ provide: ANALYTICS_OBSERVERS, useClass: PardotAnalyticsObserverService, multi: true },
			],
		};
	}
}
