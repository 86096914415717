import { isArray } from 'lodash-es';

import {
	AliasFor, DTO, MapFromDTO, Mapper, Required
} from '@bp/shared/models/metadata';
import { WithRequired } from '@bp/shared/typings';
import { BridgerAdminFeature, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { PermissionsJwtSession, BaseJwtSessionDTO } from './permissions-jwt-session';

export type IdentityJwtSessionDTO = DTO<BaseJwtSessionDTO & Omit<IdentityJwtSession, 'permissions'>> & { permissions?: string[] };

export type OrganizationIdentityJwtSession = WithRequired<IdentityJwtSession, 'organizationId'>;

export class IdentityJwtSession extends PermissionsJwtSession<BridgerAdminFeature | MerchantAdminFeature> {

	static parseJWT(jwt: string): IdentityJwtSession {
		return new IdentityJwtSession(this._parseJWT(jwt));
	}

	@MapFromDTO()
	userFullName?: string;

	@Required()
	userEmail!: string;

	/**
	 * AspNetUser Id
	 */
	@AliasFor('sub')
	userIdentityId!: string;

	@AliasFor('id')
	organizationId?: string;

	@MapFromDTO()
	otpProvider?: string;

	@AliasFor('rol')
	@Mapper((roleDTO: string[] | string) => isArray(roleDTO) ? roleDTO : [ roleDTO ])
	roles?: string[];

	@MapFromDTO()
	wizardSetupId?: string;

	constructor(dto?: IdentityJwtSessionDTO) {
		super(dto);
	}

}
