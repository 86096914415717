import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import type { MerchantPsp } from '@bp/admins-shared/core/models';
import type { EntitiesInMemoryPagedListState } from '@bp/admins-shared/features/entity';
import {
	composeEntitiesInMemoryPagedListReducer,
	ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE
} from '@bp/admins-shared/features/entity';

import { addMerchantPspsToList, MERCHANT_PSPS_LIST_ACTIONS } from './merchant-psps.actions';

export const FEATURE_KEY = '[domain]:merchant-psps';

export interface IState extends EntitiesInMemoryPagedListState<MerchantPsp> {}

export const initialState: IState = {
	...ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE,
};

const merchantPspsReducer = createReducer(
	initialState,

	...composeEntitiesInMemoryPagedListReducer(initialState, MERCHANT_PSPS_LIST_ACTIONS),

	on(
		addMerchantPspsToList,
		(state, { psps }): IState => ({
			...state,
			all: [ ...psps, ...(state.all ?? []) ],
		}),
	),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return merchantPspsReducer(state, action);
}
