import { mapValues, range } from 'lodash-es';

import { Input, ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import {
	SecurityQuestion, ISecurityQuestionAnswerApiRequest
} from '@bp/admins-shared/domains/identity/models';

type SetSecurityQuestionsAnswersFormVm = { answers: ISecurityQuestionAnswerApiRequest[] };

const COUNT_OF_QUESTIONS = 2;

@Component({
	selector: 'bp-set-security-questions-answers-form',
	templateUrl: './set-security-questions-answers-form.component.html',
	styleUrls: [ './set-security-questions-answers-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetSecurityQuestionsAnswersFormComponent
	extends FormBaseComponent<SetSecurityQuestionsAnswersFormVm>
	implements OnChanges {

	@Input() allSecurityQuestions!: SecurityQuestion[] | null;

	@Output() readonly login = new EventEmitter();

	override onSubmitShowInvalidInputsToast = false;

	get answersFormArray(): UntypedFormArray {
		return <UntypedFormArray> this.form!.get('answers')!;
	}

	get selectedSecurityQuestionsAnswers(): ISecurityQuestionAnswerApiRequest[] {
		return this.answersFormArray.value;
	}

	availableSecurityQuestionsPerAnswer: Record<string, SecurityQuestion[]> = {
		/* eslint-disable @typescript-eslint/naming-convention */
		0: [],
		1: [],
		/* eslint-enable @typescript-eslint/naming-convention */
	};

	constructor() {
		super();

		this.form = this._formBuilder.group({
			answers: this._formBuilder.array(range(COUNT_OF_QUESTIONS).map(() => this._formBuilder.group({
				id: [ null, { validators: Validators.required }],
				answer: [ null, [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(255),
				]],
			}))),
		});
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.allSecurityQuestions && this.allSecurityQuestions)
			this.updateAvailableSecurityQuestionsPerAnswer();
	}

	updateAvailableSecurityQuestionsPerAnswer(): void {
		const selectedSecurityQuestionsIds = new Set(this.selectedSecurityQuestionsAnswers.map(({ id }) => id));

		const availableSecurityQuestions = this.allSecurityQuestions!
			.filter(question => !selectedSecurityQuestionsIds.has(question));

		this.availableSecurityQuestionsPerAnswer = mapValues(
			this.availableSecurityQuestionsPerAnswer,
			(value, key): SecurityQuestion[] => {
				const selectedSecurityQuestion = this.allSecurityQuestions!
					.find(securityQuestion => securityQuestion === this.selectedSecurityQuestionsAnswers[Number(key)].id);

				return [
					...(selectedSecurityQuestion ? [ selectedSecurityQuestion ] : []),
					...availableSecurityQuestions,
				]
					.sort((a, b) => this.allSecurityQuestions!.indexOf(a) - this.allSecurityQuestions!.indexOf(b));
			},
		);

		this._cdr.detectChanges();
	}

}
