import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

export type BookDemoCalendarKindLiterals = GetEnumerationLiterals<typeof BookDemoCalendarKind>;

export class BookDemoCalendarKind extends Enumeration {

	static readonly bdm = new BookDemoCalendarKind();

	static readonly bdr = new BookDemoCalendarKind();

}
