
import { ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, FiatCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { FieldViewType, View, Control, FieldControlType, Mapper, Required } from '@bp/shared/models/metadata';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionWalletGooglePaySummary } from '../summaries';

import { BridgerPspPaymentOptionBase } from './bridger-psp-payment-option-base';

export class BridgerPspPaymentOptionWalletGooglePay extends BridgerPspPaymentOptionBase implements BridgerPspPaymentOptionWalletGooglePaySummary {

	static override readonly type = PspPaymentOptionType.walletGooglePay;

	readonly type = BridgerPspPaymentOptionWalletGooglePay.type;

	@View(FieldViewType.boxedItems)
	@Control(FieldControlType.chip, {
		list: FiatCurrency.list,
		placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
	})
	@Mapper(FiatCurrency)
	@Required()
	currencies!: FiatCurrency[];

	constructor(dto: DTO<BridgerPspPaymentOptionWalletGooglePay>) {
		super(dto);
	}

}
