import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import { assertIsMetadataQueryParamsBased, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import {
	CrmUser, CrmUserDTO, CrmUsersQueryParams, CRM_USERS_COLLECTION_PATH, WriteCrmUserApiRequest,
	WRITE_CRM_USER_FB_FN
} from '@bp/frontend/domains/crm/users/models';
import { FirebaseService } from '@bp/frontend/features/firebase';
import { FirebaseDocumentWriter } from '@bp/frontend/features/firebase/models';

@Injectable({
	providedIn: 'root',
})
export class CrmUsersApiService implements IEntitiesFirebaseApiService<CrmUser, CrmUsersQueryParams> {

	private readonly __environment = inject(EnvironmentService);

	private readonly __firebaseService = inject(FirebaseService);

	readonly collectionPath = `bp/${ this.__environment.name }/${ CRM_USERS_COLLECTION_PATH }`;

	private __previousWriteRequestId: string | null = null;

	readonly factory = (dto: CrmUserDTO): CrmUser => new CrmUser(dto);

	listenToQueriedRecordsPageChanges(query?: CrmUsersQueryParams): Observable<RecordsPage<CrmUser>> {
		assertIsMetadataQueryParamsBased(query);

		return this.__firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
		);
	}

	listenToRemoteChanges(id: string): Observable<CrmUser | null> {
		return this.__firebaseService
			.listenToDocumentChanges(`${ this.collectionPath }/${ id }`, this.factory);
	}

	get(id: string): Observable<CrmUser | null> {
		return this.__firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	write(user: CrmUser): Observable<void> {
		if (!user.id)
			throw new Error('Cannot save a user without id');

		const previousWriteRequestId = this.__previousWriteRequestId;
		const writeRequestId = this.__previousWriteRequestId = uuid();

		return this.__firebaseService
			.callPostCloudFunction<WriteCrmUserApiRequest>(
			`${ WRITE_CRM_USER_FB_FN }${ this.__environment.firebaseFunctionSuffix }`,
			new WriteCrmUserApiRequest({
				previousWriteRequestId,
				writeRequestId,
				writer: FirebaseDocumentWriter.frontend,
				environment: this.__environment.environment,
				data: user,
			}),
		);
	}

	delete(user: CrmUser): Observable<void> {
		return this.__firebaseService.delete(`${ this.collectionPath }/${ user.id }`);
	}

	save(_entity: CrmUser): Observable<CrmUser> {
		throw new Error('Method not implemented.');
	}
}
