export function subscriptionPlanFeatureNameMapper(name: string): string {
	switch (name) {
		case 'Psp expert call':
			return 'PSP Expert Call';

		case 'Account manager':
			return 'Account Manager';

		default:
			return name;
	}
}
