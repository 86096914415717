import { take } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';

import { filterPresent } from '@bp/frontend/rxjs';
import { SubscriptionPlanPurchase } from '@bp/frontend/domains/subscription-plans/checkout';

import { DowngradeSubscriptionPlanApiRequest } from '../models';

import {
	resetState,
	load,
	purchaseSubscriptionPlan,
	downgradeSubscriptionPlan,
	refresh,
	openBillingPortal,
	resetError
} from './current-organization-subscription.actions';
import {
	purchaseSubscriptionPlanSuccess,
	downgradeSubscriptionPlanSuccess
} from './current-organization-subscription-api.actions';
import {
	selectCurrentChargePeriod,
	selectCurrentSubscriptionPlan,
	selectEntity,
	selectError,
	selectLoading,
	selectIsMustPurchaseSubscriptionPlan,
	selectPending
} from './current-organization-subscription.selectors';

@Injectable({
	providedIn: 'root',
})
export class CurrentOrganizationSubscriptionFacade {
	private readonly __store$ = inject(Store);

	private readonly __actions$ = inject(Actions);

	entity$ = this.__store$.select(selectEntity);

	entity: CurrentOrganizationSubscription | null | undefined = undefined;

	presentEntity$ = this.entity$.pipe(filterPresent);

	subscriptionPlan$ = this.__store$.select(selectCurrentSubscriptionPlan);

	chargePeriod$ = this.__store$.select(selectCurrentChargePeriod);

	loading$ = this.__store$.select(selectLoading);

	loadingFirst$ = this.loading$.pipe(take(2));

	pending$ = this.__store$.select(selectPending);

	error$ = this.__store$.select(selectError);

	isMustPurchaseSubscriptionPlan$ = this.__store$.select(selectIsMustPurchaseSubscriptionPlan);

	purchaseSubscriptionPlanSuccess$ = this.__actions$.pipe(ofType(purchaseSubscriptionPlanSuccess));

	downgradeSubscriptionPlanSuccess$ = this.__actions$.pipe(ofType(downgradeSubscriptionPlanSuccess));

	changeSubscriptionPlanSuccess$ = this.__actions$.pipe(
		ofType(purchaseSubscriptionPlanSuccess, downgradeSubscriptionPlanSuccess),
	);

	constructor() {
		this._keepEntityPropertyUpdated();
	}

	load(): void {
		this.__store$.dispatch(load());
	}

	resetState(): void {
		this.__store$.dispatch(resetState());
	}

	refresh(): void {
		this.__store$.dispatch(refresh());
	}

	purchaseSubscriptionPlan(subscriptionPlanPurchase: SubscriptionPlanPurchase): void {
		this.__store$.dispatch(purchaseSubscriptionPlan({ subscriptionPlanPurchase }));
	}

	downgradeSubscription(downgradeSubscriptionPlanApiRequest: DowngradeSubscriptionPlanApiRequest): void {
		this.__store$.dispatch(downgradeSubscriptionPlan({ downgradeSubscriptionPlanApiRequest }));
	}

	openBillingPortal(): void {
		this.__store$.dispatch(openBillingPortal());
	}

	resetError(): void {
		this.__store$.dispatch(resetError());
	}

	private _keepEntityPropertyUpdated(): void {
		this.entity$.subscribe(entity => (this.entity = entity));
	}
}
