import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { FeaturePermission, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { BaseDialogComponent } from '@bp/frontend/components/dialogs';
import { SLIDE_UP } from '@bp/frontend/animations';
import { takeUntilDestroyed } from '@bp/frontend/models/common';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

export type ResourceAccessOtpVerificationDialogData = {
	featurePermission: FeaturePermission<MerchantAdminFeature>;
};

@Component({
	selector: 'bp-resource-access-otp-verification-dialog',
	templateUrl: './resource-access-otp-verification-dialog.component.html',
	styleUrls: [ './resource-access-otp-verification-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP ],
})
export class ResourceAccessOtpVerificationDialogComponent extends BaseDialogComponent<ResourceAccessOtpVerificationDialogData> {
	protected readonly _identityFacade = inject(IdentityFacade);

	constructor() {
		super();

		this._identityFacade.generateFeatureAccessOtp(this._dialogData.featurePermission);

		this._closeDialogOnSuccessfulOtpVerification();
	}

	private _closeDialogOnSuccessfulOtpVerification(): void {
		this._identityFacade.resourceAccessOtpVerificationSuccess$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._dialogRef.close(true));
	}

	protected _canCloseWithTrueOnEnter(): boolean {
		return false;
	}
}
