/// <reference path="../../../../../../../typings.d.ts" />
import { FirebaseError, getApp, getApps, initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from '@firebase/app-check';
import * as auth from '@firebase/auth';
import * as firestore from '@firebase/firestore';
import * as storage from '@firebase/storage';
import { identity, isEmpty, last, noop, snakeCase, take } from 'lodash-es';
import moment from 'moment';
import { MonoTypeOperatorFunction, firstValueFrom, lastValueFrom, defer, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RecordsPage, ISortQueryParams, IPageQueryParams } from '@bp/shared/models/common';
import { DTO, FirebaseEntity } from '@bp/shared/models/metadata';
import { Dictionary } from '@bp/shared/typings';
import { has, toPlainObject } from '@bp/shared/utilities/core';

import { BpError } from '@bp/frontend/models/core';
import { AsyncVoidSubject, observeInsideNgZone, subscribeOutsideNgZone, ZoneService } from '@bp/frontend/rxjs';
import { FIREBASE_SETTINGS } from '@bp/frontend/features/firebase/settings';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { EnvironmentService } from '@bp/frontend/services/environment';

export const FIREBASE_APP_CONFIG = new InjectionToken('FIREBASE_APP_CONFIG');

export type FirebaseAppConfig = {
	appId: string;
	enablePersistence?: boolean;
	hasAuth?: boolean;
	runAppCheck?: boolean;
};

export type FirestoreQueryComposer<T> = (firestoreQuery: firestore.Query<DTO<T>, DTO<T>>) => firestore.Query<DTO<T>, DTO<T>>;

export type HttpClientPostOptions = Parameters<InstanceType<typeof HttpClient>['post']>[2];

// https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
export const MAX_ALLOWED_QUERY_ARRAY_ITEMS = 30;

@Injectable({ providedIn: 'root' })
export class FirebaseService {

	protected readonly _firebaseConfig = inject<FirebaseAppConfig>(FIREBASE_APP_CONFIG);

	protected readonly _http = inject(HttpClient);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __zoneService = inject(ZoneService);

	private readonly __environment = inject(EnvironmentService);

	uploadProgress$ = new Subject<number | null>();

	uploadedDownloadUrl$ = new Subject<string>();

	uploadError$ = new Subject<string>();

	init$ = new AsyncVoidSubject();

	protected _uploadTask?: storage.UploadTask;

	private readonly __defaultSortField = 'updatedAt';

	private readonly __queryDocumentSnapshotsById: Dictionary<any> = {};

	private readonly __auth$ = this.init$.pipe(map(() => auth.getAuth()));

	private readonly __firestore$ = this.init$.pipe(map(() => firestore.getFirestore()));

	private readonly __storage$ = this.init$.pipe(map(() => storage.getStorage()));

	private readonly __enablePersistence = this.__environment.isDeployed && this._firebaseConfig.enablePersistence;

	constructor() {
		void this.__zoneService.runOutsideAngular(async () => {
			if (isEmpty(getApps())) {
				const app = initializeApp({
					...FIREBASE_SETTINGS,
					appId: this._firebaseConfig.appId,
				});

				if (this.__environment.isLocal)
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					(<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true;

				const runAppCheck = !has(this._firebaseConfig, 'runAppCheck') || this._firebaseConfig.runAppCheck;

				// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
				// site key and pass it to initializeAppCheck().
				runAppCheck && initializeAppCheck(app, {
					provider: new ReCaptchaEnterpriseProvider(FIREBASE_SETTINGS.appCheckSiteKey),
					isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
				});
			}

			const firestoreInstance = firestore.initializeFirestore(getApp(), this._buildFirestoreSettings());

			// Note we MUST wait for persistence to be enabled before allowing any other usage of firestore, otherwise it stuck forever.
			if (this.__enablePersistence)
				await firestore.enableMultiTabIndexedDbPersistence(firestoreInstance);

			if (this._firebaseConfig.hasAuth)
				void this.auth();

			this.init$.complete();
		});
	}

	protected _buildFirestoreSettings(): firestore.FirestoreSettings {
		return this.__enablePersistence
			? { cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED }
			: {};
	}

	async auth(): Promise<auth.Auth> {
		return firstValueFrom(this.__auth$);
	}

	async getCurrentUser(): Promise<auth.User | null> {
		const authInstance = await this.auth();

		return authInstance.currentUser;
	}

	private async _storage(): Promise<storage.FirebaseStorage> {
		return firstValueFrom(this.__storage$);
	}

	private async _firestore(): Promise<firestore.Firestore> {
		return firstValueFrom(this.__firestore$);
	}

	async generateNewDocumentId(collectionPath: string): Promise<string> {
		return lastValueFrom(
			defer(async () => this.getCollectionRef(collectionPath))
				.pipe(
					map(collectionReference => firestore.doc(collectionReference).id),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async getCollectionRef<T = firestore.DocumentData>(collectionPath: string): Promise<firestore.CollectionReference<T>> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => <firestore.CollectionReference<T>>firestore.collection(firestoreInstance, collectionPath)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async getDocumentRef(documentPath: string): Promise<firestore.DocumentReference> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => firestore.doc(firestoreInstance, documentPath)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async batch(): Promise<firestore.WriteBatch> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => firestore.writeBatch(firestoreInstance)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	firestoreQueryComposer<T>(
		composer: (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>>,
	): (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>> {
		return composer;
	}

	/**
	 * @returns a hot stream
	 */
	listenToQueriedRecordsPageChanges<T>(
		collectionPath: string,
		{ page, limit, authorUid, search, sortField, sortDir }: IPageQueryParams & Partial<ISortQueryParams> & {
			search?: string;
			authorUid?: string;
		},
		factory: ((data: DTO<T>) => T) | null,
		firestoreQueryComposer: FirestoreQueryComposer<T> = identity,
	): Observable<RecordsPage<T>> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => {
					let query = firestore.query<DTO<T>, DTO<T>>(
						<firestore.CollectionReference<DTO<T>, DTO<T>>>collection,
						firestore.orderBy(
							sortField ?? this.__defaultSortField,
							(<firestore.OrderByDirection | undefined> sortDir) ?? 'desc',
						),
					);

					if (limit !== -1) {
						query = firestore.query<DTO<T>, DTO<T>>(
							query,
							firestore.limit(limit),
						);
					}

					if (authorUid) {
						query = firestore.query<DTO<T>, DTO<T>>(
							query,
							firestore.where('authorUid', '==', authorUid),
						);
					}

					query = firestoreQueryComposer(query);

					if (search) {
						// 10 is cause array-contains-any support up to 10 comparison values only.
						const searchTerms = take(
							search
								.toLowerCase()
								.split(/\s|-/u)
								.filter(v => !!v),
							10,
						);

						if (searchTerms.length > 0) {
							query = firestore.query(
								query,
								firestore.where('searchTerms', 'array-contains-any', searchTerms),
							);
						}
					}

					if (page && this.__queryDocumentSnapshotsById[page]) {
						query = firestore.query(
							query,
							firestore.startAfter(this.__queryDocumentSnapshotsById[page]),
						);
					}

					return new Observable<RecordsPage<T>>(observer => {
						const unsubscribe = firestore.onSnapshot(
							query,
							({ docs }) => {
								const lastDocument = last(docs);
								const nextPageCursor = docs.length === limit && lastDocument
									? lastDocument.id
									: null;

								if (nextPageCursor)
									this.__queryDocumentSnapshotsById[nextPageCursor] = lastDocument;

								observer.next(new RecordsPage({
									nextPageCursor,
									firstPage: !page,
									records: docs.map(v => factory
										? factory(v.data())
										: <T> v.data()),
								}));
							},
							error => void observer.error(error),
						);

						return () => void this.__zoneService.runOutsideAngular(unsubscribe);
					});
				}),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * @returns a hot stream
	 */
	listenToCollectionChanges<T>(
		collectionPath: string,
		factory: (data: DTO<T>) => T,
		firestoreQueryComposer: (firestoreQuery: firestore.Query) => firestore.Query<DTO<T>> = identity,
	): Observable<T[]> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => new Observable<T[]>(observer => {
					const unsubscribe = firestore.onSnapshot(
						firestoreQueryComposer(collection),
						snapshot => void observer.next(snapshot.docs.map(v => factory(v.data()))),
						error => void observer.error(error),
					);

					return () => void this.__zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * @returns a cold stream
	 */
	getCollection<T>(
		collectionPath: string,
		factory: (dto: DTO<T>) => T,
		firestoreQueryComposer: FirestoreQueryComposer<T> = identity,
	): Observable<T[]> {
		return from(this.getCollectionRef<T>(collectionPath))
			.pipe(
				concatMap(async collection => firestore.getDocs(firestoreQueryComposer(<any>collection))),
				map(snapshot => snapshot.docs.map(v => factory(v.data()))),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	listenToDocumentChanges<T>(
		documentPath: string,
		factory: (dto: DTO<T>) => T,
	): Observable<T | null> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(document => new Observable<T | null>(observer => {
					const unsubscribe = firestore.onSnapshot(
						document,
						snapshot => {
							const dto = <DTO<T> | undefined> snapshot.data();

							observer.next(dto ? factory(dto) : null);
						},
						error => void observer.error(error),
					);

					return () => void this.__zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * @return a cold stream
	 */
	getDocument<T>(
		documentPath: string,
		factory: (dto: DTO<T>) => T,
	): Observable<T | null> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.getDoc(document)),
				map(snapshot => snapshot.data()),
				map(dto => dto ? factory(<DTO<T>> dto) : null),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * @return a hot stream
	 */
	listenToQueriedDocumentChanges<T>(
		collectionPath: string,
		factory: (data: DTO<T>) => T,
		firestoreQueryComposer: (firestoreQuery: firestore.Query) => firestore.Query<DTO<T> | null> = identity,
	): Observable<T | null> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => new Observable<T | null>(observer => {
					const unsubscribe = firestore.onSnapshot(
						firestore.query(
							firestoreQueryComposer(collection),
							firestore.limit(1),
						),
						snapshot => {
							const document = snapshot.docs[0]?.data();

							void observer.next(document ? factory(document) : null);
						},
						error => void observer.error(error),
					);

					return () => void this.__zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * Deletes the document referred to by this `documentPath`.
	 *
	 * @return A cold stream completed once the document has been successfully
	 * deleted from the backend (Note that it won't complete while you're
	 * offline).
	 */
	delete(documentPath: string): Observable<void> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.deleteDoc(document)),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * Writes to the document referred to by this `documentPath`. If the
	 * document does not yet exist, it will be created,
	 * otherwise the provided data is merged into an existing document.
	 * @return A cold stream completed once the data has been successfully written
	 * to the backend (Note that it won't complete while you're offline).
	 */
	set(documentPath: string, body: Object): Observable<void> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.setDoc(
					document,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					toPlainObject(body),
					{ merge: true },
				)),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	/**
	 * Writes to the document referred to by this `documentPath`. If the
	 * document does not yet exist, it will be created,
	 * otherwise the provided data is merged into an existing document.
	 * @return A cold stream completed once the data has been successfully written
	 * to the backend (Note that it won't complete while you're offline).
	 */
	save<T extends FirebaseEntity>(
		collectionPath: string,
		entity: T,
		factory: (data: DTO<T>) => T,
		{ skipTimestampsOnUpdate = false }: { skipTimestampsOnUpdate?: boolean } = {},
	): Observable<T> {
		return from(this.getCurrentUser())
			.pipe(concatMap(async currentUser => {
				const isAdding = !entity.id;
				const entityId = entity.id ?? await this.generateNewDocumentId(collectionPath);

				const patch: DTO<FirebaseEntity> = isAdding
					? {
						authorUid: currentUser?.uid,
						createdAt: moment(),
						updatedAt: moment(),
					}
					: (skipTimestampsOnUpdate ? {} : { updatedAt: moment() });

				patch.id = entityId;

				const patchedEntity = factory({
					...<DTO<T>> <unknown> entity,
					...patch,
				});

				await lastValueFrom(this.set(`${ collectionPath }/${ entityId }`, patchedEntity));

				return patchedEntity;
			}));
	}

	/**
	 * Upload file to a specific folder path in firebase storage
	 * @param path A relative path to initialize the reference with,
	 * for example path/to/image.jpg. If not passed, the returned
	 * reference points to the bucket root.
	 */
	async upload(file: File, path: string): Promise<void> {
		const startProgressValue = 25;

		this.uploadProgress$.next(startProgressValue);

		return this.__zoneService.runOutsideAngular(async () => {
			this._uploadTask?.cancel();

			const uploadFileRef = await this._getFileRef(file.name, path);

			this._uploadTask = storage.uploadBytesResumable(uploadFileRef, file);

			this._uploadTask.on(
				'state_changed',
				snapshot => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					progress > startProgressValue && this.__zoneService.runInAngularZone(() => void this.uploadProgress$.next(progress));
				},
				error => void this.__zoneService.runInAngularZone(() => {
					this.uploadError$.next(error.message);

					this.__telemetry.captureError(error);
				}),
				() => void storage.getDownloadURL(this._uploadTask!.snapshot.ref)
					.then(downloadURL => void this.__zoneService.runInAngularZone(() => {
						this.uploadedDownloadUrl$.next(downloadURL);

						this.uploadProgress$.next(null);
					})),
			);
		});
	}

	protected _buildFirebaseFunctionUrl(firebaseFunctionName: string): string {
		return `${ FIREBASE_SETTINGS.functionsURL }/${ firebaseFunctionName }`;
	}

	callGetCloudFunction<T = void>(
		firebaseFunctionName: string,
		options?: HttpClientPostOptions,
	): Observable<T> {
		return this._http.get<T>(this._buildFirebaseFunctionUrl(firebaseFunctionName), options);
	}

	callPostCloudFunction<T, U = void>(
		firebaseFunctionName: string,
		body: T,
		options?: HttpClientPostOptions,
	): Observable<U> {
		return this._http.post<U>(this._buildFirebaseFunctionUrl(firebaseFunctionName), body, options);
	}

	onAuthIdTokenChanged(): Observable<{ user: auth.User; accessToken: string } | null> {
		return from(this.auth())
			.pipe(
				concatMap(authInstance => new Observable<auth.User | null>(observer => authInstance
					.onIdTokenChanged(
						user => void observer.next(user),
						error => void observer.error(this._mapToBpError(error)),
					))),
				concatMap(async user => {
					if (user) {
						const accessToken = await user.getIdToken();

						return { user, accessToken };
					}

					return null;
				}),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	signinWithPopup(provider: auth.AuthProvider): Observable<{ user: auth.User; accessToken: string }> {
		return from(this.auth())
			.pipe(
				concatMap(async authInstance => {
					const userCredential = await auth.signInWithPopup(authInstance, provider);
					const accessToken = await userCredential.user.getIdToken();

					return {
						user: userCredential.user,
						accessToken,
					};
				}),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBpError(),
			);
	}

	private async _getFileRef(fileName: string, path: string): Promise<storage.StorageReference> {
		const storageInstance = await this._storage();

		const fileReference = storage.ref(
			storage.ref(storageInstance, path),
			this._snakeCaseFileName(fileName),
		);

		const existFileMetadata = < { name: string } | undefined> await storage.getMetadata(fileReference)
			.catch(() => { /* Swallow 404 error since the empty var would be there is no file found */ });

		return existFileMetadata
			? this._getFileRef(this._increaseFileNameCounter(existFileMetadata.name), path)
			: fileReference;
	}

	private _increaseFileNameCounter(name: string): string {
		const fileName = this._getFilenameWithoutExtension(name);
		const counterRegexp = /_(?<counter>\d+)$/u;
		const fileCounter = counterRegexp.exec(fileName)?.groups?.['counter'];
		const nextFileCounter = Number(fileCounter ?? 0) + 1;

		return name.replace(
			fileName,
			fileCounter
				? fileName.replace(counterRegexp, `_${ nextFileCounter }`)
				: `${ fileName }_${ nextFileCounter }`,
		);
	}

	private _snakeCaseFileName(name: string): string {
		const fileName = this._getFilenameWithoutExtension(name);

		return name.replace(fileName, snakeCase(fileName));
	}

	private _getFilenameWithoutExtension(name: string): string {
		if (!name)
			return '';

		return (/(?<filename>.+?)(?<fileExtension>\.[^.]+$|$)/u).exec(name)?.groups?.['filename'] ?? '';
	}

	private _subscribeOutsideAndRethrowAsBpError<T>(): MonoTypeOperatorFunction<T> {
		return (source$: Observable<T>) => source$.pipe(
			subscribeOutsideNgZone(),
			catchError(this._throwAsBpError),
		);
	}

	private _subscribeOutsideButObserveInsideAngularAndRethrowAsBpError<T>(): MonoTypeOperatorFunction<T> {
		return (source$: Observable<T>) => new Observable(observer => {

			/**
			 * We need this http request macrotask to tell angular that there is a pending request to firebase,
			 * since we run all the calls to firebase functionality outside the angular to prevent
			 * firing unnecessary change detections inside angular, firebase schedules a lot of macrotasks during
			 * its lifecycle. E.g. scully relies on counting of macrotasks to decide when to remove the placeholder root
			 * component
			 */
			const zone = Zone.current;
			const fakeXMLHttpRequestTask = zone.scheduleMacroTask('XMLHttpRequest', noop, {}, noop, noop);

			const subscription = source$
				.pipe(
					subscribeOutsideNgZone(),
					catchError(this._throwAsBpError),
					observeInsideNgZone(),

					/**
					 * As we have `listenTo*` tasks, which could be never complete,
					 * we decide data is loaded after first emission.
					 */
					tap(() => fakeXMLHttpRequestTask.state === 'scheduled' && zone.cancelTask(fakeXMLHttpRequestTask)),
				)
				.subscribe(observer);

			return () => void subscription.unsubscribe();
		});
	}

	private readonly _throwAsBpError = (firebaseError: FirebaseError): Observable<never> => throwError(
		() => this._mapToBpError(firebaseError),
	);

	private readonly _mapToBpError = (error: Error | FirebaseError): BpError => new BpError({
		messages: [
			{
				type: error instanceof FirebaseError ? error.code : undefined,
				message: error.message,
			},
		],
	});

}
