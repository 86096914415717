import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedPipesModule } from '@bp/frontend/pipes';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';

import { SharedDomainsIdentityModule } from '@bp/frontend-domains-identity';

import { PspThumbnailModule } from '../../../psp-thumbnail';
import { PaywithBadgeModule } from '../../../paywith-badge';

import { BridgerPspCardComponent } from './bridger-psp-card';
import { BridgerPspsMultipickerComponent } from './bridger-psps-multipicker.component';

const EXPOSED = [ BridgerPspsMultipickerComponent ];

@NgModule({
	imports: [
		CommonModule,
		MatCardModule,
		MatPseudoCheckboxModule,
		MatMenuModule,
		MatRippleModule,

		SharedPipesModule,
		SharedDirectivesCoreModule,
		SharedDomainsIdentityModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		AdminsSharedCoreComponentsModule,

		PspThumbnailModule,
		PaywithBadgeModule,
	],
	exports: EXPOSED,
	declarations: [ EXPOSED, BridgerPspCardComponent ],
})
export class BridgerPspsMultipickerModule { }
