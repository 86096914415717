import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';
import { SubscriptionPlan, SubscriptionPlanChargePeriod } from '@bp/shared/domains/subscription-plans/core';

import { BpError } from '@bp/frontend/models/core';

@Component({
	selector: 'bp-subscription-downgrade',
	templateUrl: './subscription-downgrade.component.html',
	styleUrls: [ './subscription-downgrade.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDowngradeComponent {
	@Input() currentOrganizationSubscription!: CurrentOrganizationSubscription;

	@Input() subscriptionPlan!: SubscriptionPlan;

	@Input() chargePeriod!: SubscriptionPlanChargePeriod;

	@Input() error!: BpError | null;

	@Output() readonly cancel = new EventEmitter<void>();

	@Output() readonly downgrade = new EventEmitter<void>();

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentOrganizationSubscription.subscriptionPlan;
	}

	get currentChargePeriod(): SubscriptionPlanChargePeriod {
		return this.currentOrganizationSubscription.chargePeriod;
	}
}
