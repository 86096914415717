import { isEmpty, isString } from 'lodash-es';
import { merge, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, switchMap } from 'rxjs/operators';

import { OnInit, Directive, ElementRef, Input, HostBinding, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {
	LegacyFloatLabelType as FloatLabelType, MatLegacyFormFieldAppearance as MatFormFieldAppearance
} from '@angular/material/legacy-form-field';

import { attrBoolValue, isPresent, bpQueueMicrotask, uuid } from '@bp/shared/utilities/core';

import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { ControlComponent } from './control.component';
import { FormFieldAppearance, FORM_FIELD_DEFAULT_OPTIONS } from './form-field-default-options';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormFieldControlComponent<TControlValue, TInternalControlValue = TControlValue>
	extends ControlComponent<TControlValue>
	implements OnChanges, OnInit {

	protected _defaultOptions = inject(FORM_FIELD_DEFAULT_OPTIONS, { optional: true });

	protected _$host = <HTMLElement>inject(ElementRef).nativeElement;

	private readonly __formGroupDirective = inject(FormGroupDirective, { optional: true });

	@Input() formControl?: UntypedFormControl;

	@Input() formControlName?: string;

	@Input() appearance?: FormFieldAppearance;

	@Input() floatLabel?: FloatLabelType;

	@Input() color?: ThemePalette;

	@Input() override name!: string;

	@Input() placeholder?: string | null;

	@Input() label?: string | null;

	@Input() hint?: string | null;

	@Input() longHint?: string | null;

	@Input() nativeAutocomplete?: boolean | string = true;

	@Input() required?: boolean | '' | null;

	@Input() hideClearButton?: boolean | '' | null;

	@Input() disabled?: boolean | null;

	@Input() throttle?: number | '';

	@Input() debounce?: number | '';

	@Input() hideErrorText?: boolean | '' | null;

	@Input() pending?: boolean | null;

	get isFocused(): boolean {
		return this._$host === document.activeElement || this._$host.contains(document.activeElement);
	}

	protected get _appearance(): FormFieldAppearance | null {
		return this.appearance ?? this._defaultOptions?.appearance ?? null;
	}

	protected get _matAppearance(): MatFormFieldAppearance {
		if (this._isRoundAppearance)
			return 'outline';

		return <MatFormFieldAppearance> (this._appearance ?? 'standard');
	}

	protected get _floatLabel(): FloatLabelType {
		return this.floatLabel ?? this._defaultOptions?.floatLabel ?? 'auto';
	}

	@HostBinding('class.form-field-appearance-round')
	protected get _isRoundAppearance(): boolean {
		return !!this._appearance?.startsWith('round');
	}

	@HostBinding('class.form-field-appearance-round-lg')
	protected get _isRoundLargeAppearance(): boolean {
		return this._appearance === 'round-lg';
	}

	@HostBinding('class.form-field-compact')
	protected get _isCompact(): boolean {
		return !!this._defaultOptions?.compact;
	}

	protected get _externalControl(): UntypedFormControl | null {
		if (this.formControl)
			return this.formControl;

		if (this.formControlName)
			return <UntypedFormControl | undefined> this._form?.controls[this.formControlName] ?? null;

		return null;
	}

	protected get _form(): UntypedFormGroup | undefined {
		return this.__formGroupDirective?.form;
	}

	protected _internalControl = new UntypedFormControl(undefined, {
		updateOn: this._form?.updateOn,
	});

	protected _externalControl$ = new OptionalBehaviorSubject<UntypedFormControl | null>();

	private readonly __dummyNativeAutocompleteValue = `value-to-disable-native-autocomplete-${ uuid() }`;

	protected get _attrAutocompleteValue(): string {
		return isString(this.nativeAutocomplete)
			? this.nativeAutocomplete
			: (this.nativeAutocomplete ? this.name : this.__dummyNativeAutocompleteValue);
	}

	protected get _name(): string {
		return this.nativeAutocomplete ? this.name : this._attrAutocompleteValue;
	}

	protected _onWriteValue$ = new Subject<void>();

	private __updateSubscription = Subscription.EMPTY;

	private __throttleTime = 0;

	private __debounceTime = 0;

	private readonly __defaultThrottleTime = 200;

	private readonly __defaultDebounceTime = 400;

	ngOnChanges({ formControl, formControlName, throttle, debounce, value, disabled }: SimpleChanges<this>): void {
		if (formControl || formControlName)
			this._externalControl$.next(this._externalControl);

		if (value)
			this.writeValue(this.value);

		if (throttle)
			this.__throttleTime = this.throttle === '' ? this.__defaultThrottleTime : this.throttle!;

		if (debounce)
			this.__debounceTime = this.debounce === '' ? this.__defaultDebounceTime : this.debounce!;

		if (throttle || debounce)
			this.__listenToInternalControlValueChanges();

		if (disabled)
			this.setDisabledState(this.disabled!);
	}

	ngOnInit(): void {
		this.required = attrBoolValue(this.required);

		this.hideClearButton = attrBoolValue(this.hideClearButton) || this._defaultOptions?.hideClearButton;

		this.hideErrorText = attrBoolValue(this.hideErrorText) || this._defaultOptions?.hideErrorText;

		this.__listenToInternalControlValueChanges();

		this.__reflectExternalControlOnInternal();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: TControlValue | null): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			this._setIncomingValueToInternalControl(value);

			this._onWriteValue$.next();
		});
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled)
			this._internalControl.disable({ emitEvent: false });
		else
			this._internalControl.enable({ emitEvent: false });

		this._cdr.detectChanges();
	}
	// #endregion Implementation of the ControlValueAccessor interface

	abstract focus(): void;

	protected _eraseInternalControlValue(): void {
		if (this._internalControl.value === null)
			return;

		this._internalControl.markAsDirty();

		this._internalControl.setValue(null);
	}

	protected _setIncomingValue(value: TControlValue | null): void {
		this.setValue(value, { emitChange: false });
	}

	protected _setIncomingValueToInternalControl<U = TControlValue>(value: U | null): void {
		this._internalControl.setValue(value, { emitEvent: false });

		this._cdr.markForCheck();
	}

	protected _onInternalControlValueChange(v: TInternalControlValue): void {
		this.setValue(<TControlValue><unknown>v);
	}

	private __listenToInternalControlValueChanges(): void {
		this.__updateSubscription.unsubscribe();

		this.__updateSubscription = (
			this.__debounceTime
				? this._internalControl.valueChanges.pipe(debounceTime(this.__debounceTime))
				: (this.__throttleTime
					? this._internalControl.valueChanges.pipe(auditTime(this.__throttleTime))
					: this._internalControl.valueChanges)
		)
			.pipe(
				takeUntilDestroyed(this),
				filter(() => this._internalControl.dirty),
			)
			.subscribe(v => {
				this._externalControl?.markAsDirty();

				this._onInternalControlValueChange(v);
			});
	}

	private __reflectExternalControlOnInternal(): void {
		this._externalControl$
			.pipe(
				filter(isPresent),
				switchMap(externalControl => merge(
					externalControl.statusChanges,
					this._onWriteValue$,
				)),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this.__reflectExternalControlStateOnInternalControl();

				this._internalControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });

				const errors = {
					...this._externalControl?.errors,
					...this._internalControl.errors,
				};

				this._internalControl.setErrors(isEmpty(errors) ? null : errors);

				this._cdr.markForCheck();
			});
	}

	private __reflectExternalControlStateOnInternalControl(): void {
		if (this._externalControl?.dirty && this._internalControl.pristine)
			this._internalControl.markAsDirty();

		if (this._externalControl?.pristine && this._internalControl.dirty)
			this._internalControl.markAsPristine();

		if (this._externalControl?.touched && this._internalControl.untouched)
			this._internalControl.markAsTouched();

		if (this._externalControl?.untouched && this._internalControl.touched)
			this._internalControl.markAsUntouched();

	}
}
