import { createAction, props } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';
import { createVerboseAction } from '@bp/frontend/ngrx';

import { VirtualTerminalData } from '@bp/admins-shared/core/models';

import { ACTIONS_SCOPE } from './current-organization.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(`${ scope } Load Success`, props<ResultActionPayload<Organization>>());

export const loadFailure = createAction(`${ scope } Load Failure`, props<ErrorActionPayload>());

export const refreshSuccess = createVerboseAction(
	`${ scope } Refresh Success`,
	props<ResultActionPayload<Organization>>(),
);

export const refreshFailure = createVerboseAction(`${ scope } Refresh Failure`, props<ErrorActionPayload>());

export const loadVirtualTerminalDataSuccess = createAction(
	`${ scope } Load Virtual Terminal Data Success`,
	props<ResultActionPayload<VirtualTerminalData>>(),
);

export const loadVirtualTerminalDataFailure = createAction(
	`${ scope } Load Virtual Terminal Data Failure`,
	props<ErrorActionPayload>(),
);
