import type { OnInit } from '@angular/core';
import { Directive, HostBinding } from '@angular/core';
import { MatLegacyTabNav as MatTabNav } from '@angular/material/legacy-tabs';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { RightDrawerComponent } from '../components';

@Directive({
	selector: '[mat-tab-nav-bar][bpRightDrawerNavBar]',
})
export class RightDrawerNavBarDirective extends Destroyable implements OnInit {

	@HostBinding('attr.mat-align-tabs')
	matAlignTabs = 'center';

	constructor(
		private readonly _matTabNav: MatTabNav,
		private readonly _drawer: RightDrawerComponent,
	) {
		super();
	}

	ngOnInit(): void {
		this._alignInkBarOnDrawerWidthChange();
	}

	private _alignInkBarOnDrawerWidthChange(): void {
		this._drawer.animationEnd$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._matTabNav._alignInkBarToSelectedTab());
	}
}
