import { FIREBASE_FUNCTIONS_CLOUDFLARE_PROXY_PATH_SEGMENT } from '@bp/frontend/features/firebase/models';

export const FIREBASE_SETTINGS = {
	apiKey: 'AIzaSyCE0HJJUq4otCVdCbdBINJApcVmj3h-isU',
	authDomain: 'web-hosting-213618.firebaseapp.com',
	databaseURL: 'https://web-hosting-213618.firebaseio.com',
	projectId: 'web-hosting-213618',
	storageBucket: 'web-hosting-213618.appspot.com',
	messagingSenderId: '977741303368',
	functionsRegion: 'europe-west1',
	appCheckSiteKey: '6LdGfospAAAAAHz6CfEal4HSuoQHXjbkn1n_Ynir',
	get functionsURL(): string {
		return `/api/${ FIREBASE_FUNCTIONS_CLOUDFLARE_PROXY_PATH_SEGMENT }`;
	},
};

export const LOCAL_FIREBASE_SETTINGS = {
	...FIREBASE_SETTINGS,
	get functionsURL(): string {
		return `http://localhost:5000/${ FIREBASE_SETTINGS.projectId }/${ FIREBASE_SETTINGS.functionsRegion }`;
	},
};
