import { Directive, ElementRef, inject } from '@angular/core';

import { HasAccessStructuralDirective } from '@bp/frontend-domains-identity';

import { IdentityGetAccessDialogGuard } from '../guards';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a, button',
})
export class ButtonIdentityGetAccessDialogGuardDirective {

	private readonly __identityGetAccessDialogGuard = inject(IdentityGetAccessDialogGuard);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	private readonly __hasAccessGuard = inject(HasAccessStructuralDirective, { optional: true });

	constructor() {
		if (this.__hasAccessGuard) {
			this.__$host.addEventListener(
				'click',
				event => void this.__guardClickWithIdentityGetAccessDialogs(event),
			);
		}
	}

	private async __guardClickWithIdentityGetAccessDialogs(event: Event): Promise<void> {
		if (!this.__identityGetAccessDialogGuard.shouldShowGetAccessDialog(this.__hasAccessGuard!.trackedPermission!))
			return;

		event.stopPropagation();

		event.preventDefault();

		event.stopImmediatePropagation();

		const identityGotAccess = await this.__identityGetAccessDialogGuard.getAccess(
			this.__hasAccessGuard!.trackedPermission!,
		);

		if (identityGotAccess)
			this.__$host.click();
	}
}
