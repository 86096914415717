import { filter } from 'rxjs/operators';

import { Directive, inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDialogComponent<TDialogData = unknown, TDialogResult = boolean> extends Destroyable {

	protected readonly _dialogRef = inject<MatDialogRef<TDialogData, TDialogResult>>(MatDialogRef);

	protected readonly _dialogData = <TDialogData>inject(MAT_DIALOG_DATA, { optional: true });

	constructor() {
		super();

		this._stopEscapeKeydownEventPropagation();

		this._submitOnEnter();
	}

	protected abstract _canCloseWithTrueOnEnter(): boolean;

	private _stopEscapeKeydownEventPropagation(): void {
		this._dialogRef
			.keydownEvents()
			.pipe(takeUntilDestroyed(this))
			.subscribe(event => (event.key === 'Escape') && void event.stopPropagation());
	}

	private _submitOnEnter(): void {
		this._dialogRef
			.keydownEvents()
			.pipe(
				filter(event => event.key === 'Enter' && this._canCloseWithTrueOnEnter()),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._dialogRef.close(<TDialogResult>true));
	}

}
