<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!-- eslint-disable @angular-eslint/template/conditional-complexity -->

<div
	bpTooltipShownOnViewportOverflow
	[class.feature-under-development]="metadata.underDevelopment"
	[bpTooltip]="
		tooltip ? tooltip : showGeneralTooltip && !customValueView && !customValueTemplate ? displayValue : null
	"
>
	<ng-container
		*ngIf="
			viewType !== FieldViewType.boolean || !!customValueView || !!customValueTemplate;
			else booleanViewTypeTpl
		"
	>
		<caption *ngIf="showLabel && !table" class="property-view-caption">
			{{
				metadata.label
			}}

			<bp-hint *ngIf="metadata.longHint || metadata.hint as hint" size="sm" [message]="hint" />

			<bp-copy-to-clipboard-btn *ngIf="metadata.copyable" iconSize="xs" [value]="value" />
		</caption>

		<div *ngIf="value === '' || value === undefined || value === null || value.length === 0; else valueTpl">
			<div *ngIf="viewType === FieldViewType.thumbnail; else emptyValueTpl" class="thumbnail empty">
				<mat-icon>image</mat-icon>
			</div>

			<ng-template #emptyValueTpl>
				{{ viewEmptyValue ? viewEmptyValue : '—' }}
			</ng-template>
		</div>

		<ng-template #valueTpl>
			<ng-template
				*ngIf="!!customValueView || !!customValueTemplate; else defaultValueTpl"
				[ngTemplateOutlet]="customValueView?.tpl || customValueTemplate!"
				[ngTemplateOutletContext]="{ $implicit: value, metadata: metadata }"
			/>

			<ng-template #defaultValueTpl>
				<ng-container [ngSwitch]="viewType">
					<span *ngSwitchCase="FieldViewType.textarea">
						{{ displayValue }}
					</span>

					<div class="text-ellipsis">
						<span *ngIf="showGeneralTooltip" bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
							<span *ngSwitchCase="FieldViewType.text">
								{{ displayValue }}
							</span>

							<span *ngSwitchCase="FieldViewType.currency">
								{{ viewFormatter ? viewFormattedValue : (value | bpCurrency) }}
							</span>

							<span *ngSwitchCase="FieldViewType.percent">
								{{ value | percent: '0.0-2' }}
							</span>

							<span *ngSwitchCase="FieldViewType.currencyCode" [bpTooltip]="value.description">
								{{ value.displayName }}
							</span>
						</span>

						<ng-container *ngSwitchCase="FieldViewType.email">
							<span
								*ngIf="metadata.isSecret && _displayValueHasMaskedChars; else emailLinkTpl"
								bpShowTooltipOnViewportOverflow
								bpViewportOverflownObserver
							>
								{{ displayValue }}
							</span>

							<ng-template #emailLinkTpl>
								<a
									class="link"
									bpTargetBlank
									href="mailto:{{ value }}"
									(click)="$event.stopPropagation()"
								>
									<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
										{{ value }}
									</span>
								</a>
							</ng-template>
						</ng-container>

						<ng-container *ngSwitchCase="FieldViewType.personEmail">
							<a
								class="link"
								bpTargetBlank
								href="mailto:{{ value.email }}"
								bpTooltip="{{ value.email }}"
								(click)="$event.stopPropagation()"
							>
								<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
									{{ value | bpDisplayValue }}
								</span>
							</a>
						</ng-container>

						<ng-container *ngSwitchCase="FieldViewType.link">
							<a class="link" bpTargetBlank [href]="displayValue" (click)="$event.stopPropagation()">
								<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
									{{ value }}
								</span>
							</a>
						</ng-container>

						<bp-country
							*ngSwitchCase="FieldViewType.country"
							[src]="viewFormattedValue"
							[compact]="isCompact"
						/>

						<bp-payment-card-brand-icon
							*ngSwitchCase="FieldViewType.paymentOptionBrand"
							bpShowTooltipOnViewportOverflow
							bpViewportOverflownObserver
							[src]="value"
						/>

						<ng-container *ngSwitchCase="FieldViewType.moment">
							{{
								value | momentFormat: (viewFormatter ? viewFormattedValue : defaultMomentFormat) | async
							}}
						</ng-container>

						<span
							*ngSwitchCase="FieldViewType.momentFromNow"
							bpTooltip="{{ value | momentFormat: 'LLL' | async }}"
						>
							{{ value | momentFromNow | async }}
						</span>

						<div *ngSwitchCase="FieldViewType.momentSplitIntoTwoRows" class="text-center">
							{{ value | momentFormat: 'L' | async }}
							<br />
							{{ value | momentFormat: 'LTS' | async }}
						</div>

						<ng-container *ngSwitchCase="FieldViewType.status">
							<span
								[ngClass]="'status-' + value.cssClass"
								[class.mr-1]="!isCompact"
								[class.circle-sm]="!isCompact"
								[class.circle]="isCompact"
								[bpTooltip]="isCompact ? value.displayName : null"
							>
							</span>
							<ng-container *ngIf="!isCompact">
								{{ value.displayName }}
							</ng-container>
						</ng-container>

						<ng-container *ngSwitchCase="FieldViewType.transactionStatus">
							<bp-transaction-status-icon
								class="inline-flex v-align-middle mr-1"
								[bpTooltip]="isCompact ? value.displayName : null"
								[status]="value"
							/>

							<span *ngIf="!isCompact" class="v-align-middle">
								{{ value.displayName }}
							</span>
						</ng-container>

						<ng-container *ngSwitchCase="FieldViewType.booleanCircle">
							<ng-container *ngIf="displayValue; else defaultBooleanCircleViewTpl">
								<ng-container
									*ngIf="isEnumeration(displayValue); else displayValueBooleanCircleViewTpl"
								>
									<span
										[ngClass]="'status-' + displayValue.cssClass"
										[class.mr-1]="!isCompact"
										[class.circle-sm]="!isCompact"
										[class.circle]="isCompact"
										[bpTooltip]="isCompact ? displayValue.displayName : null"
									>
									</span>
									<ng-container *ngIf="!isCompact">
										{{ displayValue.displayName }}
									</ng-container>
								</ng-container>

								<ng-template #displayValueBooleanCircleViewTpl>
									<span
										[ngClass]="'status-' + value"
										[class.mr-1]="!isCompact"
										[class.circle-sm]="!isCompact"
										[class.circle]="isCompact"
										[bpTooltip]="isCompact ? displayValue : null"
									>
									</span>
									<ng-container *ngIf="!isCompact">
										{{ displayValue }}
									</ng-container>
								</ng-template>
							</ng-container>
							<ng-template #defaultBooleanCircleViewTpl>
								<span class="circle status-{{ value }}"> </span>
							</ng-template>
						</ng-container>

						<ng-container *ngSwitchCase="FieldViewType.chip">
							<mat-chip-list *ngIf="value?.length" [selectable]="false">
								<mat-chip *ngFor="let chip of value" selected [bpTooltip]="chip.description">
									{{ chip.displayName || chip.name || chip }}
								</mat-chip>
							</mat-chip-list>
						</ng-container>

						<bp-img *ngSwitchCase="FieldViewType.thumbnail" thumbnail [size]="40" [url]="value" />

						<bp-img *ngSwitchCase="FieldViewType.image" [url]="value" />
					</div>

					<bp-boxed-items *ngSwitchCase="FieldViewType.boxedItems" class="flex-wrap-2 mt-1" [items]="value" />
				</ng-container>
			</ng-template>
		</ng-template>
	</ng-container>

	<ng-template #booleanViewTypeTpl>
		<mat-icon *ngIf="table; else defaultBooleanViewTypeTpl" [ngClass]="booleanIcon">
			{{ booleanIcon }}
		</mat-icon>

		<ng-template #defaultBooleanViewTypeTpl>
			<span [ngClass]="booleanIcon">
				<mat-icon>{{ booleanIcon }}</mat-icon>
				{{ metadata.label }}
				<bp-hint *ngIf="metadata.longHint || metadata.hint as hint" [message]="hint" />
			</span>
		</ng-template>
	</ng-template>
</div>
