import { range } from 'lodash-es';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Lottie, { AnimationItem } from 'lottie-web-light';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { first, mergeMap, map, switchMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input } from '@angular/core';

import { attrBoolValue } from '@bp/shared/utilities/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { fromViewportIntersection, OptionalBehaviorSubject, subscribeOutsideNgZone } from '@bp/frontend/rxjs';
import { MediaService } from '@bp/frontend/features/media';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

const LOTTIE_ANIMATIONS_ASSETS_DIR = '/assets/lottie-animations';

@Component({
	selector: 'bp-lottie-player',
	templateUrl: './lottie-player.component.html',
	styleUrls: [ './lottie-player.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottiePlayerComponent extends Destroyable implements OnDestroy, OnChanges {

	@Input()
	@HostBinding('class')
	animationName!: string;

	@Input() relatedToScroll: boolean | '' = false;

	@Input() looped: boolean | '' = false;

	@Input() eager: boolean | '' = false;

	@HostBinding('class.loaded')
	loaded = false;

	private readonly _$host = this._hostRef.nativeElement;

	private readonly __animationItem$ = new OptionalBehaviorSubject<AnimationItem>();

	private get __animationItem(): AnimationItem | undefined {
		return this.__animationItem$.value;
	}

	private __animationItemCreatingSubscription = Subscription.EMPTY;

	constructor(
		private readonly _hostRef: ElementRef<HTMLElement>,
		private readonly _http: HttpClient,
		private readonly _mediaService: MediaService,
		private readonly _cdr: ChangeDetectorRef,
	) {
		super();
	}

	 ngOnChanges(_changes: SimpleChanges<this>): void {
		this.relatedToScroll = attrBoolValue(this.relatedToScroll);

		this.looped = attrBoolValue(this.looped);

		this.eager = attrBoolValue(this.eager);

		this.loaded = false;

		this.__runCreateLottieAnimationFlow();

		if (this.relatedToScroll)
			this.__playAnimationOnScrollInsideViewport();
		else
			this.__playAnimationWhenFullyInsideViewport();

		this.__markAsLoadedOnLottieDOMLoad();
	}

	private __runCreateLottieAnimationFlow(): void {
		this.__animationItem?.destroy();

		this.__animationItemCreatingSubscription.unsubscribe();

		this.__animationItemCreatingSubscription = (this.eager
			? this.__createLottieAnimation$()
			: this.__createLottieAnimationWhenHostIsAboutToEnterViewport$())
			.pipe(takeUntilDestroyed(this))
			.subscribe(animationItem => void this.__animationItem$.next(animationItem));
	}

	ngOnDestroy(): void {
		// some transition animations can be applied to the host so we cleanup
		// when most certainly the animation is not needed anymore
		setTimeout(() => this.__animationItem?.destroy(), 1000);
	}

	private __markAsLoadedOnLottieDOMLoad(): void {
		this.__animationItem$
			.pipe(
				switchMap(animationItem => fromEvent(animationItem, 'DOMLoaded')),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this.loaded = true;

				this._cdr.markForCheck();
			});
	}

	private __createLottieAnimationWhenHostIsAboutToEnterViewport$(): Observable<AnimationItem> {
		return this.__observeHostIsAboutToEnterViewport()
			.pipe(
				first(entry => entry.isIntersecting),
				mergeMap(() => this.__createLottieAnimation$()),
			);
	}

	private __createLottieAnimation$(): Observable<AnimationItem> {
		return this.__loadAnimationDataAccordingToDPR$().pipe(
			map(animationData => Lottie.loadAnimation({
				animationData,
				container: this._$host,
				renderer: 'svg',
				loop: !this.relatedToScroll && !!this.looped,
				autoplay: false,
			})),
			subscribeOutsideNgZone(),
		);
	}

	private __loadAnimationDataAccordingToDPR$(): Observable<Record<string, unknown>> {
		const sceneFolderSource = `${ LOTTIE_ANIMATIONS_ASSETS_DIR }/${ this.animationName }`;
		const dataFileSource = `${ sceneFolderSource }/data.json`;
		const imageFolderSource = `${ sceneFolderSource }/images${ this._mediaService.isHighDPR ? '@2x' : '' }/`;

		return this._http
			.get(dataFileSource, { responseType: 'text' })
			.pipe(map(animationDataText => JSON.parse(animationDataText.replace(/images\//ug, imageFolderSource))));
	}

	private __playAnimationOnScrollInsideViewport(): void {
		this.__animationItem$.pipe(
			switchMap(animationItem => this.__observeHostScrollingInsideViewport().pipe(
				map(intersectionObserverEntry => <const>[ animationItem, intersectionObserverEntry ]),
			)),
			takeUntilDestroyed(this),
		)
			.subscribe(([ animationItem, { boundingClientRect, intersectionRatio }]) => {
				if (boundingClientRect.top > 0)
					// 50 hardcoded value due to stupid animations provided by Ben, should be totalFrames
					animationItem.goToAndStop(intersectionRatio * 50, true);
			});
	}

	private __playAnimationWhenFullyInsideViewport(): void {
		this.__animationItem$.pipe(
			switchMap(animationItem => this.__observeHostEnterViewport().pipe(
				map(intersectionObserverEntry => <const>[ animationItem, intersectionObserverEntry ]),
			)),
			takeUntilDestroyed(this),
		)
			.subscribe(([ animationItem, { boundingClientRect, intersectionRatio, rootBounds }]) => {
				if (intersectionRatio >= 0.9)
					animationItem.play();

				if (rootBounds && boundingClientRect.top > rootBounds.height)
					animationItem.goToAndStop(0); // Reset when the host gets in the viewport from the top
			});
	}

	private __observeHostScrollingInsideViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				rootMargin: '0px 0px -25% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

	private __observeHostIsAboutToEnterViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				rootMargin: '0px 0px 75% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

	private __observeHostEnterViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				threshold: range(0, 1, 0.01),
			},
		);
	}
}
