import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedComponentsMetadataModule } from '@bp/frontend/components/metadata';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { PaymentCardCvvInputComponent } from '@bp/frontend/components/payment-card/cvv-input';
import { PaymentCardExpireInputComponent } from '@bp/frontend/components/payment-card/expire-input';
import { PaymentCardHolderInputComponent } from '@bp/frontend/components/payment-card/holder-input';
import { PaymentCardNumberInputComponent } from '@bp/frontend/components/payment-card/number-input';

import {
	SubscriptionPlanCheckoutComponent, PaymentInformationFormComponent, ChargePeriodSwitcherComponent, PriceBreakdownComponent
} from './components';
import { subscriptionPlanCheckoutFeature } from './state/subscription-plan-checkout.feature';
import { SubscriptionPlanCheckoutEffects } from './state/subscription-plan-checkout.effects';

const EXPOSED = [
	SubscriptionPlanCheckoutComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatSlideToggleModule,

		StoreModule.forFeature(subscriptionPlanCheckoutFeature),
		EffectsModule.forFeature([ SubscriptionPlanCheckoutEffects ]),

		SharedFeaturesAnalyticsModule,
		SharedComponentsCoreModule,
		SharedComponentsMetadataModule,
		SharedDirectivesCoreModule,
		SharedPipesModule,

		PaymentCardCvvInputComponent,
		PaymentCardExpireInputComponent,
		PaymentCardHolderInputComponent,
		PaymentCardNumberInputComponent,
	],
	declarations: [
		EXPOSED,
		PaymentInformationFormComponent,
		ChargePeriodSwitcherComponent,
		PriceBreakdownComponent,
	],
	exports: EXPOSED,
})
export class SharedDomainsSubscriptionPlansCheckoutModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			SharedDomainsSubscriptionPlansCheckoutModule,
			async () => import('./api-mock-plugin/subscription-plan-checkout-api-mock-plugin'),
		);
	}
}
