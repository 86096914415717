import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OtpProvider } from '@bp/shared/domains/jwt-session';
import { FeaturePermission } from '@bp/shared/domains/permissions';

import { IdentityFacade } from '../../state';

@Component({
	selector: 'bp-otp-provider-message',
	templateUrl: './otp-provider-message.component.html',
	styleUrls: [ './otp-provider-message.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpProviderMessageComponent {

	@Input({ required: true })
	feature!: FeaturePermission<any> | 'login';

	// eslint-disable-next-line @typescript-eslint/naming-convention
	OtpProvider = OtpProvider;

	constructor(public readonly identityFacade: IdentityFacade) { }

}
