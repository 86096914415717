<button
	*ngIf="options.closeButton"
	class="btn-icon-close bp-icon-button-xxs"
	mat-icon-button
	type="button"
	(click)="remove()"
>
	<bp-svg-icon class="bp-icon-xxs" name="close" />
</button>

<header *ngIf="title" class="mb-2 mat-h3 {{ options.titleClass }}">
	{{ title }}
	<ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</header>

<div *ngIf="message && options.enableHtml" [class]="options.messageClass" [innerHTML]="message"></div>

<div *ngIf="message && !options.enableHtml" [class]="options.messageClass">
	{{ message }}
</div>

<div *ngIf="options.undoBtn || options.button" class="mt-3">
	<button
		*ngIf="options.undoBtn"
		class="bp-button-sm"
		color="primary"
		mat-stroked-button
		type="button"
		[disabled]="hasUndid"
		(click)="undo($event)"
	>
		{{ hasUndid ? 'undid' : 'undo' }}
	</button>

	<ng-container *ngIf="options.button as btn">
		<button
			*ngIf="btn!.onClick && !btn.href && !btn.routerLink"
			class="bp-button-sm"
			color="primary"
			mat-stroked-button
			type="button"
			(click)="btn!.onClick!()"
		>
			{{ btn!.text }}
		</button>

		<a
			*ngIf="btn!.href"
			class="bp-button-sm"
			color="primary"
			mat-stroked-button
			bpTargetBlank
			[href]="btn!.href"
			(click)="btn!.onClick && btn!.onClick!()"
		>
			{{ btn!.text }}
		</a>

		<a
			*ngIf="btn!.routerLink"
			class="bp-button-sm"
			color="primary"
			mat-stroked-button
			[routerLink]="btn!.routerLink"
			(click)="btn!.onClick && btn!.onClick!()"
		>
			{{ btn!.text }}
		</a>
	</ng-container>
</div>

<div *ngIf="options.progressBar && width > 0" class="toast-progress-container" @fade>
	<div class="toast-progress" [style.width.%]="width"></div>
</div>
