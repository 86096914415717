import { Directive, HostListener, Input } from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

import { GtagEvents } from '@bp/frontend/features/analytics/models';

import { GoogleTagService } from '../services';

@Directive({
	selector: '[bpGoogleTagOnClick]',
})
export class GoogleTagDirective<TEventName extends GtagEvents.List> {

	@Input('bpGoogleTagOnClick')
	onClickEvent?: TEventName;

	eventPayload?: TEventName extends never
		? Dictionary<any>
		: GtagEvents.IEventPayloadMap[TEventName];

	constructor(private readonly _googleTagService: GoogleTagService) { }

	@HostListener('click', [ '$event' ])
	onClick(): void {
		if (!this.onClickEvent)
			return;

		this._googleTagService.dispatchEvent(
			this.onClickEvent,
			<any> this.eventPayload,
		);
	}

}
