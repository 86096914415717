<div class="carousel-main-row">
	<div
		class="viewport"
		cdkDropList
		cdkDropListLockAxis="x"
		cdkDropListOrientation="horizontal"
		[style.height.px]="viewportHeight$ | async"
		[cdkDropListDisabled]="(items$ | async)?.length === 1 || !sortable"
		(cdkDropListDropped)="drop($event)"
	>
		<ul
			#slidesContainer
			class="carousel-slides"
			[class.canceled-slide-animation]="(animate$ | async) === false"
			[@fadeInListStaggered]="(items$ | async)?.length"
			[@.disabled]="!slideInAnimation"
		>
			<li
				*ngFor="let item of items$ | async; let i = index; let first = first; let last = last; trackBy: trackBy"
				#slide
				class="carousel-slide"
				cdkDrag
				[ngClass]="slideClass || ''"
				[class.first-fully-visible]="slidesVisibility.firstFullyVisible === i"
				[class.fully-visible]="
					i
						| bpInRange
							: slidesVisibility.firstFullyVisible ?? 0
							: (slidesVisibility.lastFullyVisible ?? 0) + 1
				"
				[class.first-partially-visible]="slidesVisibility.firstPartiallyVisible === i"
				[class.last-fully-visible]="slidesVisibility.lastFullyVisible === i"
				[class.last-partially-visible]="slidesVisibility.lastPartiallyVisible === i"
				[cdkDragDisabled]="sortableItem && !sortableItem!(item)"
			>
				<ng-container
					*ngTemplateOutlet="
						template;
						context: {
							$implicit: item,
							index: i,
							first: this.first,
							last: this.last
						}
					"
				/>
			</li>
		</ul>
	</div>

	<div *ngIf="showArrowButtons$ | async" class="buttons-overlay">
		<button
			type="button"
			class="btn-arrow prev"
			matRipple
			[matRippleDisabled]="disableRipple || (prevButtonDisabled$ | async) || false"
			[class.disabled]="(prevButtonDisabled$ | async) || false"
			[ngClass]="['arrow-type-' + arrowType, 'arrow-size-' + arrowSize]"
			(click)="activatePrev()"
		>
			<ng-container
				[ngTemplateOutlet]="arrowTemplate ? arrowTemplate : defaultArrowTpl"
				[ngTemplateOutletContext]="{ disabled: prevButtonDisabled$ | async }"
			/>
		</button>

		<button
			type="button"
			class="btn-arrow next"
			matRipple
			[matRippleDisabled]="disableRipple || (nextButtonDisabled$ | async) || false"
			[class.disabled]="(nextButtonDisabled$ | async) || false"
			[ngClass]="['arrow-type-' + arrowType, 'arrow-size-' + arrowSize]"
			(click)="activateNext()"
		>
			<ng-container
				[ngTemplateOutlet]="arrowTemplate ? arrowTemplate : defaultArrowTpl"
				[ngTemplateOutletContext]="{ disabled: nextButtonDisabled$ | async }"
			/>
		</button>
	</div>
</div>

<div
	*ngIf="isShowBullets"
	class="bullets"
	[style.visibility]="((items$ | async)?.length || 0) > ((currentItemsPerView$ | async) || 0) ? null : 'hidden'"
>
	<button
		*ngFor="let item of items$ | async; let i = index"
		type="button"
		class="bullet"
		[class.active]="
			((slidesVisibility$ | async)?.firstFullyVisible || 0) <= i &&
			i <= ((slidesVisibility$ | async)?.lastFullyVisible || 0)
		"
		(click)="activateIndex(i)"
	></button>
</div>

<ng-template #defaultArrowTpl>
	<svg *ngIf="arrowType === CarouselArrowType.Circled" class="icon circled-arrow" viewBox="0 0 7 12">
		<path
			d="M.5 11.7c-.1 0-.3-.1-.4-.2-.2-.1-.1-.5.1-.6L5.9 6 .2 1.1C0 1-.1.6.1.4.3.2.6.2.8.3l5.9 5.1c.2.2.3.4.3.6s-.1.4-.3.6L.8 11.7H.5zm5.6-5.5z"
		/>
	</svg>

	<svg *ngIf="arrowType === CarouselArrowType.LongCircled" class="icon circled-arrow" viewBox="0 0 15 12">
		<path
			d="M.6 5.4h11.97L7.986 1.035a.6.6 0 11.828-.87l5.234 4.986a1.2 1.2 0 01-.01 1.707l-5.224 4.976a.597.597 0 01-.849-.02.6.6 0 01.02-.848L12.589 6.6H.6a.6.6 0 010-1.2"
		/>
	</svg>

	<svg *ngIf="arrowType === CarouselArrowType.Inner" class="icon inner" viewBox="0 0 20 16">
		<path d="M18.5 9H1c-.5 0-1-.4-1-1s.4-1 1-1h17.5c.6 0 1 .4 1 1s-.4 1-1 1z" />
		<path
			d="M12 16c-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l6-6.3-6-6.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L20 8l-7.3 7.7c-.2.2-.4.3-.7.3z"
		/>
	</svg>
</ng-template>
