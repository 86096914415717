<content class="mat-body-2">
	<bp-subscription-plan-congratulation-content [subscriptionPlan]="subscriptionPlan" [context]="context" />
</content>

<footer>
	<a
		*ngIf="continueBtnUrl; else justButtonTpl"
		mat-flat-button
		class="continue-btn bp-round-button"
		color="primary"
		href="{{ continueBtnUrl }}"
	>
		{{ continueButtonLabel }}
	</a>

	<ng-template #justButtonTpl>
		<button
			mat-flat-button
			type="button"
			class="continue-btn bp-round-button"
			color="primary"
			(click)="continue.emit()"
		>
			{{ continueButtonLabel }}
		</button>
	</ng-template>
</footer>
