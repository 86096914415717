import { Component, ChangeDetectionStrategy } from '@angular/core';

import { SharedFeaturesLottiePlayerModule } from '@bp/frontend/features/lottie-player';

@Component({
	selector: 'bp-spinning-logo',
	standalone: true,
	imports: [
		SharedFeaturesLottiePlayerModule,
	],
	templateUrl: './spinning-logo.component.html',
	styleUrls: [ './spinning-logo.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinningLogoComponent { }
