import { identity } from 'rxjs';
import { omit, pickBy, snakeCase } from 'lodash-es';

import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';

import { NonNullableRecords } from '@bp/shared/typings';

import { FADE } from '@bp/frontend/animations';
import { takeUntilDestroyed } from '@bp/frontend/models/common';

import { ICalendlyOptions } from '../../models';
import { CalendlyEventsHandlerDirective } from '../../directives';

const DEFAULT_OPTIONS: Partial<ICalendlyOptions> = {
	hideGdprBanner: 1,
	embedDomain: '*',
	embedType: 'PopupText',
};

@Component({
	selector: 'bp-calendly[options]',
	templateUrl: './calendly.component.html',
	styleUrls: [ './calendly.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class CalendlyComponent extends CalendlyEventsHandlerDirective implements OnInit {

	@Input() options!: ICalendlyOptions;

	calendlyUrl: string | null = null;

	isCalendlyLoaded = false;

	ngOnInit(): void {
		this._setCalendlyUrl();

		this._markAsLoadedOnCalendlyLoad();
	}

	private _setCalendlyUrl(): void {
		const options = {
			...DEFAULT_OPTIONS,
			...pickBy(this.options, identity),
		};

		this._assertOptions(options);

		const url = new URL(`https://calendly.com/${ options.accountId }/${ options.eventId }`);

		url.search = this._convertICalendlyOptionsToUrlParams(options).toString();

		this.calendlyUrl = url.toString();
	}

	private _assertOptions(options: Partial<ICalendlyOptions>): asserts options is NonNullableRecords<ICalendlyOptions> {
		if (!options.accountId || !options.eventId)
			throw new Error('Account and Event IDs must be specified');

		if ((options.firstName || options.lastName) && options.name)
			throw new Error('Either first and last name or full name must be specified');

		if (options.customQuestions && Object.keys(options.customQuestions).some(v => !v.startsWith('a')))
			throw new Error('Questions must be prefixed with `a` symbol. E.g. `a1`, `a2`, etc.');
	}

	private _convertICalendlyOptionsToUrlParams(options: NonNullableRecords<ICalendlyOptions>): URLSearchParams {
		const plainParams = Object
			.entries(omit(options, [ 'accountId', 'eventId', 'customQuestions' ]))
			.map(([ key, value ]) => [ snakeCase(key), value.toString() ]);

		const questionsParams = options.customQuestions
			? Object.entries(options.customQuestions)
				.map(([ questionName, answerOrAnswers ]) => [ questionName, answerOrAnswers.toString() ])
			: [];

		return new URLSearchParams([
			...plainParams,
			...questionsParams,
		]);
	}

	private _markAsLoadedOnCalendlyLoad(): void {
		this.eventTypeViewed
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => (this.isCalendlyLoaded = true));
	}

}
