import { createReducer, on, createFeature } from '@ngrx/store';

import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';

import { BpError } from '@bp/frontend/models/core';

import {
	downgradeSubscriptionPlanFailure,
	downgradeSubscriptionPlanSuccess,
	loadFailure,
	loadSuccess,
	loadBillingPortalDetailsFailure,
	loadBillingPortalDetailsSuccess,
	purchaseSubscriptionPlanSuccess,
	purchaseSubscriptionPlanFailure
} from './current-organization-subscription-api.actions';
import {
	purchaseSubscriptionPlan,
	downgradeSubscriptionPlan,
	load,
	resetState,
	loadBillingPortalDetails,
	resetError,
	setMustPurchaseSubscriptionPlan
} from './current-organization-subscription.actions';

export interface IState {
	entity: CurrentOrganizationSubscription | null | undefined;

	loading: boolean;

	pending: boolean;

	error: BpError | null;

	loadingBillingPortalDetails: boolean;

	isMustPurchaseSubscriptionPlan: boolean;
}

export const initialState: IState = {
	entity: undefined,
	loading: false,
	pending: false,
	error: null,
	loadingBillingPortalDetails: false,
	isMustPurchaseSubscriptionPlan: false,
};

export const currentOrganizationSubscriptionFeature = createFeature({
	name: '[domain]:current-organization-subscription',
	reducer: createReducer(
		initialState,

		on(resetState, (): IState => initialState),

		on(
			resetError,
			(state): IState => ({
				...state,
				error: null,
			}),
		),

		on(
			load,
			(state): IState => ({
				...state,
				loading: true,
				error: null,
			}),
		),

		on(
			loadSuccess,
			(state, { result }): IState => ({
				...state,
				entity: result,
			}),
		),

		on(
			loadSuccess,
			loadFailure,
			(state): IState => ({
				...state,
				loading: false,
			}),
		),

		on(
			loadFailure,
			downgradeSubscriptionPlanFailure,
			(state, { error }): IState => ({
				...state,
				error,
				loading: false,
				pending: false,
			}),
		),

		on(
			purchaseSubscriptionPlanFailure,
			(state, { error }): IState => ({
				...state,
				error,
				pending: false,
			}),
		),

		on(
			purchaseSubscriptionPlan,
			downgradeSubscriptionPlan,
			(state): IState => ({
				...state,
				pending: true,
				error: null,
			}),
		),

		on(
			purchaseSubscriptionPlanSuccess,
			downgradeSubscriptionPlanSuccess,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			loadBillingPortalDetails,
			loadBillingPortalDetailsSuccess,
			loadBillingPortalDetailsFailure,
			(state, action): IState => ({
				...state,
				loadingBillingPortalDetails: action.type === loadBillingPortalDetails.type,
			}),
		),

		on(
			setMustPurchaseSubscriptionPlan,
			(state, { isMustPurchaseSubscriptionPlan }): IState => ({
				...state,
				isMustPurchaseSubscriptionPlan,
			}),
		),
	),
});
