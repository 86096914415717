import { ContentChild, OnInit, ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { attrBoolValue } from '@bp/shared/utilities/core';

@Component({
	selector: 'bp-modal',
	templateUrl: './modal.component.html',
	styleUrls: [ './modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {

	@Input() hideCloseBtn: boolean | '' = false;

	@ViewChild(TemplateRef, { static: true }) template!: TemplateRef<any>;

	// Note we can't use content projection, because child view gets initialized before dialog init,
	// which causes to mat-dialog-close directive malfunction.
	@ContentChild(TemplateRef) content!: TemplateRef<any>;

	ngOnInit(): void {
		this.hideCloseBtn = attrBoolValue(this.hideCloseBtn);
	}

}
