import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

@Component({
	selector: 'bp-subscription-plan-congratulation[subscriptionPlan]',
	templateUrl: './subscription-plan-congratulation.component.html',
	styleUrls: [ './subscription-plan-congratulation.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlanCongratulationComponent {

	@Input() subscriptionPlan!: SubscriptionPlan | null;

	@Input() continueButtonLabel = 'Continue to Admin';

	@Input() context: 'merchant-admin' | 'wizard' = 'merchant-admin';

	@Input() continueBtnUrl?: string;

	@Output() readonly continue = new EventEmitter<void>();
}
