import { FiatCurrency } from '@bp/shared/models/currencies';
import { Control, DTO, FieldControlType, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { CurrencyMarkupTarget } from './currency-markup-target';

export class CurrencyMarkup extends MetadataEntity {
	@Mapper(FiatCurrency)
	@Control(FieldControlType.autocomplete, {
		list: FiatCurrency.list,
		required: true,
	})
	baseCurrency!: FiatCurrency;

	@Mapper(CurrencyMarkupTarget)
	targetCurrencies!: CurrencyMarkupTarget[];

	constructor(dto: DTO<CurrencyMarkup>) {
		super(dto);
	}
}
