import {
	Control, DTO, FieldControlType, Label, MapFromDTO, Mapper, MetadataEntity
} from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';
import { SupportRequestDuration } from '@bp/shared/domains/support-requests';

export class SupportRequestCreateModel extends MetadataEntity {

	@Control(FieldControlType.textarea, {
		validator: Validators.compose([
			Validators.maxLength(2000),
			Validators.utf8Bmp,
		]),
	})
	message!: string;

	@Label('Grant readonly access for:')
	@Mapper(SupportRequestDuration)
	@Control(FieldControlType.buttonToggle, {
		required: true,
		list: SupportRequestDuration.getList(),
	})
	duration!: SupportRequestDuration;

	@MapFromDTO()
	organizationName!: string; // Hack to not retrieve it on BE from laggy remote service

	@MapFromDTO()
	organizationSubscriptionPlan!: string; // Hack to not retrieve it on BE from laggy remote service

	constructor(dto?: DTO<SupportRequestCreateModel>) {
		super(dto);
	}

}
