import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesLottiePlayerModule } from '@bp/frontend/features/lottie-player';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { FrontendDomainsSubscriptionPlansCoreModule } from '@bp/frontend/domains/subscription-plans/core';

import { SubscriptionPlanCongratulationComponent } from './subscription-plan-congratulation.component';
import { SubscriptionPlanCongratulationContentComponent } from './components';

const EXPOSED = [
	SubscriptionPlanCongratulationComponent,
	SubscriptionPlanCongratulationContentComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,

		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
		FrontendDomainsSubscriptionPlansCoreModule,
		SharedFeaturesLottiePlayerModule,
		SharedFeaturesSvgIconsModule,
		SharedPipesModule,

		RouterModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
	providers: [],
})
export class SharedDomainsSubscriptionPlansCongratulationModule {}
