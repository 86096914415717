import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-paywith-badge',
	templateUrl: './paywith-badge.component.html',
	styleUrls: [ './paywith-badge.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywithBadgeComponent {

	@Input() size: 'md' | 'sm' = 'sm';

}
