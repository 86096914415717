import { isBoolean, isEqual, uniq } from 'lodash-es';
import { EMPTY, of } from 'rxjs';
import { bufferTime, exhaustMap, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import m from 'moment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import {
	NewsSummariesQueryParams,
	NewsSummary,
	AnnouncementsHubStatePerUser
} from '@bp/frontend/domains/newsroom/models';
import { NewsSummariesApiService } from '@bp/frontend/domains/newsroom';
import { apiResult, apiVoidResult } from '@bp/frontend/models/common';
import { BpScheduler, filterPresent, takeFirstPresent } from '@bp/frontend/rxjs';
import { AppStorageService } from '@bp/frontend/services/storage';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { FirebaseEntitiesListEffects } from '@bp/admins-shared/features/entity';
import { LayoutFacade } from '@bp/admins-shared/features/layout';
import type { Notification } from '@bp/admins-shared/features/notifications-hub';
import { NotificationsHubService } from '@bp/admins-shared/features/notifications-hub';

import {
	listeningToAnnouncementsHubStatePerUserApiUpdatesFailure,
	updateAnnouncementsHubStatePerUserFailure,
	updateAnnouncementsHubStatePerUserSuccess,
	announcementsHubStatePerUserApiUpdate
} from './announcements-api.actions';
import type { MarkAnnouncementsAsSeenRequest } from './announcements.actions';
import {
	close,
	listenToAnnouncementsHubStatePerUserChanges,
	markAnnouncementsAsSeen,
	open,
	updateAnnouncementsHubStatePerUser
} from './announcements.actions';
import { AnnouncementsFacade } from './announcements.facade';
import { IState } from './announcements.reducer';

const SHOWN_TOAST_NOTIFICATIONS_IDS = 'shown-toast-notifications-ids';

@Injectable()
export class AnnouncementsEffects extends FirebaseEntitiesListEffects<
NewsSummary,
IState,
NewsSummariesQueryParams,
AnnouncementsFacade
> {
	readonly routeComponentType = null;

	closePanelOnLayoutCloseFloatOutlets$ = createEffect(() => this._layoutFacade.closeFloatOutlets$.pipe(map(close)));

	onPanelOpen$ = createEffect(
		() => this._actions$.pipe(
			ofType(open),
			tap(() => void this._layoutFacade.showFullpageBackdrop()),
		),
		{ dispatch: false },
	);

	onPanelClose$ = createEffect(
		() => this._actions$.pipe(
			ofType(close),
			tap(() => void this._layoutFacade.hideFullpageBackdrop()),
		),
		{ dispatch: false },
	);

	onUserLoginCreateAnnouncementsHubStatePerUserIfDoesntExist$ = createEffect(
		() => this._identityFacade.userPresent$.pipe(
			tap(user => {
				void this._newsSummariesApiService.createAnnouncementsHubStateForCurrentUserIfDoesntExist(user);

				this._announcementsFacade.load();
			}),
		),
		{ dispatch: false },
	);

	listenToAnnouncementsHubStatePerUserChanges$ = createEffect(() => this._actions$.pipe(
		ofType(listenToAnnouncementsHubStatePerUserChanges),
		switchMap(() => this._newsSummariesApiService
			.listenToAnnouncementsHubStatePerUserChanges(this._identityFacade.user!.id!)
			.pipe(
				filterPresent,
				apiResult(
					announcementsHubStatePerUserApiUpdate,
					listeningToAnnouncementsHubStatePerUserApiUpdatesFailure,
				),
				takeUntil(this._identityFacade.userHasLoggedOut$),
			)),
	));

	onUserLoginStartListeningToAnnouncementsHubStatePerUserChanges$ = createEffect(() => this._identityFacade.userHasLoggedIn$.pipe(map(listenToAnnouncementsHubStatePerUserChanges)));

	onUserLogoutResetStateToClearAnnouncementsHubStatePer$ = createEffect(() => this._identityFacade.userHasLoggedOut$.pipe(map(this.actions.resetState)));

	onPanelOpenMarkAnnouncementsAsSeenForCurrentUser$ = createEffect(() => this._actions$.pipe(
		ofType(open),
		concatLatestFrom(() => this._announcementsFacade.recordsPage$.pipe(takeFirstPresent)),
		map(([ , { records }]) => markAnnouncementsAsSeen({
			announcements: records,
			showNotificationDot: false,
		})),
	));

	markAnnouncementsAsSeen$ = createEffect(() => this._actions$.pipe(
		ofType(markAnnouncementsAsSeen),
		bufferTime(300, BpScheduler.asyncOutside),
		map(requests => this._mergeMarkAnnouncementsAsSeenRequests(requests)),
		concatLatestFrom(() => this._announcementsFacade.stateForCurrentUser$.pipe(takeFirstPresent)),
		mergeMap(([ markAnnouncementsAsSeenRequest, announcementsHubStateForCurrentUser ]) => {
			const nextAnnouncementsHubStateForCurrentUser = this._buildUpdatedAnnouncementsHubStateForCurrentUser(
				markAnnouncementsAsSeenRequest,
				announcementsHubStateForCurrentUser,
			);

			return isEqual(nextAnnouncementsHubStateForCurrentUser, announcementsHubStateForCurrentUser)
				? EMPTY
				: of(updateAnnouncementsHubStatePerUser({ result: nextAnnouncementsHubStateForCurrentUser }));
		}),
	));

	updateAnnouncementsHubStatePerUser$ = createEffect(() => this._actions$.pipe(
		ofType(updateAnnouncementsHubStatePerUser),
		exhaustMap(({ result }) => this._newsSummariesApiService
			.saveAnnouncementsHubStaterPerUser(this._identityFacade.user!.id!, result)
			.pipe(
				apiVoidResult(
					updateAnnouncementsHubStatePerUserSuccess,
					updateAnnouncementsHubStatePerUserFailure,
				),
			)),
	));

	onAnnouncementsUpdateNotifyCurrentUserAboutNewOnes$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loadSuccess),
			concatLatestFrom(() => this._announcementsFacade.stateForCurrentUser$.pipe(takeFirstPresent)),
			tap(
				([
					{
						result: { records },
					},
					{ seenNewsIds },
				]) => void this._createNotificationsAndOneToast(
					this._getNewestUnseenAnnouncementsRequiringNotification(records, seenNewsIds),
				),
			),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _newsSummariesApiService: NewsSummariesApiService,
		private readonly _identityFacade: IdentityFacade,
		private readonly _announcementsFacade: AnnouncementsFacade,
		private readonly _notificationsHubService: NotificationsHubService,
		private readonly _layoutFacade: LayoutFacade,
		private readonly _router: Router,
		private readonly _appStorageService: AppStorageService,
	) {
		super(_newsSummariesApiService, _announcementsFacade);
	}

	private _mergeMarkAnnouncementsAsSeenRequests(
		markAnnouncementsAsSeenRequests: MarkAnnouncementsAsSeenRequest[],
	): MarkAnnouncementsAsSeenRequest {
		return {
			announcements: markAnnouncementsAsSeenRequests.flatMap(request => request.announcements),
			showNotificationDot: markAnnouncementsAsSeenRequests.find(request => isBoolean(request.showNotificationDot))
				?.showNotificationDot,
		};
	}

	private _buildUpdatedAnnouncementsHubStateForCurrentUser(
		markAnnouncementsAsSeenRequest: MarkAnnouncementsAsSeenRequest,
		announcementsHubStateForCurrentUser: AnnouncementsHubStatePerUser,
	): AnnouncementsHubStatePerUser {
		return new AnnouncementsHubStatePerUser({
			...announcementsHubStateForCurrentUser,
			showNotificationDot:
				markAnnouncementsAsSeenRequest.showNotificationDot === undefined
					? announcementsHubStateForCurrentUser.showNotificationDot
					: markAnnouncementsAsSeenRequest.showNotificationDot,
			seenNewsIds: uniq([
				...announcementsHubStateForCurrentUser.seenNewsIds,
				...markAnnouncementsAsSeenRequest.announcements.map(announcement => announcement.id!),
			]),
		});
	}

	private _getNewestUnseenAnnouncementsRequiringNotification(
		announcements: NewsSummary[],
		seenAnnouncementIds: string[],
	): NewsSummary[] {
		const twoWeeksAgo = m().subtract(2, 'week');

		return announcements.filter(
			announcement => announcement.showNotification
				&& !seenAnnouncementIds.includes(announcement.id!)
				&& twoWeeksAgo.isSameOrBefore(announcement.publicationDate, 'day'),
		);
	}

	private _notifyUser(announcement: NewsSummary): Notification {
		return this._notificationsHubService.next({
			id: announcement.id!,
			text: announcement.name!,
			button: {
				text: 'Learn more',
				onClick: () => void this._router.navigate([
					{
						outlets: { modal: [ 'announcement', announcement.id ]},
					},
				]),
			},
			onRead: () => void this._announcementsFacade.markAnnouncementsAsSeen([ announcement ]),
		});
	}

	private _createNotificationsAndOneToast(announcements: NewsSummary[]): void {
		const notifications = announcements.map(announcement => this._notifyUser(announcement));

		void this._showToastForNewestUnshownNotification(notifications);
	}

	private async _showToastForNewestUnshownNotification(notifications: Notification[]): Promise<void> {
		const shownToastNotificationsIds
			= this._appStorageService.get<string[] | null>(SHOWN_TOAST_NOTIFICATIONS_IDS) ?? [];

		const unshownNotification = notifications.find(
			notification => !shownToastNotificationsIds.includes(notification.id),
		);

		if (!unshownNotification)
			return;

		await this._notificationsHubService.showToast(unshownNotification);

		this._appStorageService.set(SHOWN_TOAST_NOTIFICATIONS_IDS, [
			...shownToastNotificationsIds,
			unshownNotification.id,
		]);
	}
}
