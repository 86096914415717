<h1 class="text-center">
	{{ mode.displayName }}
	Your Plan
</h1>

<content>
	<bp-subscription-plan-charge-period-switcher
		[annualDiscount]="maxAnnualDiscount"
		[(selectedChargePeriod)]="selectedChargePeriod"
		(selectedChargePeriodChange)="selectedChargePeriodChange.emit($event)"
	/>

	<div class="subscription-plans-overview-cards-container bp-thin-scrollbar">
		<bp-subscription-plans-overview-cards
			class="bp-thin-scrollbar"
			[subscriptionPlans]="subscriptionPlans"
			[currentSubscriptionPlan]="currentSubscriptionPlan"
			[chargePeriod]="selectedChargePeriod"
			[currency]="selectedCurrency"
		>
			<ng-template let-subscriptionPlan>
				<ng-container *ngIf="castToSubscription(subscriptionPlan) as subscriptionPlan">
					<div
						*ngIf="currentSubscriptionPlan?.id === subscriptionPlan.id; else nonCurrentSubscriptionTpl"
						class="flex-column-align-items-center"
					>
						<h3 class="text-primary m-0 text-center font-weight-bold">
							<div>Your current plan</div>
							<div *ngIf="currentSubscriptionPlan!.hasFlatPrice">
								billed {{ currentOrganizationSubscription!.chargePeriod.displayName | lowercase }}
							</div>
						</h3>

						<ng-container *bpHasAccess="_MerchantAdminFeature.subscriptionsManagement.actions.edit">
							<button
								*ngIf="currentSubscriptionPlan!.hasFlatPrice"
								class="link interactive use-current-color"
								type="button"
								(click)="switchToOppositeChargePeriod(subscriptionPlan)"
							>
								switch to {{ oppositeChargePeriodToCurrent }}
							</button>
						</ng-container>
					</div>

					<ng-template #nonCurrentSubscriptionTpl>
						<ng-container *bpHasAccess="_MerchantAdminFeature.subscriptionsManagement.actions.edit">
							<button
								*ngIf="subscriptionPlan.isEnterprise; else changeSubscriptionButtonTpl"
								type="button"
								class="bp-round-button w-100"
								color="primary"
								mat-flat-button
								bpGoogleTagOnClick="subscription_management_pricing_contact_us_click"
								bpCalendlyBookDemo="bdm"
							>
								Let's talk!
							</button>

							<ng-template #changeSubscriptionButtonTpl>
								<button
									type="button"
									class="bp-round-button w-100"
									color="primary"
									mat-flat-button
									(click)="changeSubscriptionPlan(subscriptionPlan)"
								>
									{{ subscriptionPlanActionLabelMap.get(subscriptionPlan) }}
								</button>
							</ng-template>
						</ng-container>
					</ng-template>
				</ng-container>
			</ng-template>
		</bp-subscription-plans-overview-cards>
	</div>
</content>
