<bp-input
	class="w-100"
	nativeAutocomplete="one-time-code"
	[label]="label ?? '6-digit code'"
	[hint]="hint"
	[color]="color"
	[formControl]="_internalControl"
	[appearance]="appearance"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[mask]="mask"
	[required]="!!required"
/>
