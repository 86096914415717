/* eslint-disable max-classes-per-file */
import { isSymbol } from 'lodash-es';

import { MetadataEntity, Property } from '@bp/shared/models/metadata';

export type FeatureAction = symbol;

const FEATURE_ACTION_SCOPE = 'feature_action_scope';

export function isFeatureAction(action: unknown): boolean {
	return isSymbol(action) && !!action.description?.includes(FEATURE_ACTION_SCOPE);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function FeatureActionSymbol(model: MetadataEntity, property: string): void {
	Property({ symbolize: FEATURE_ACTION_SCOPE })(model, property);
}

export class CommonFeatureActions extends MetadataEntity {
	@FeatureActionSymbol
	access!: FeatureAction;
}

export class EditAndAccessFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	create!: FeatureAction;

	@FeatureActionSymbol
	edit!: FeatureAction;

	@FeatureActionSymbol
	delete!: FeatureAction;
}
