import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

import { ISubscriptionPlanCheckoutConfig } from '../models';

export const ACTIONS_SCOPE = '[Subscription Plan Checkout]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadConfigSuccess = createAction(
	`${ scope } Load Config Success`,
	props<ResultActionPayload<ISubscriptionPlanCheckoutConfig>>(),
);

export const loadConfigFailure = createAction(
	`${ scope } Load Config Failure`,
	props<ErrorActionPayload>(),
);
