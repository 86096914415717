import { defer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { select } from '@ngrx/store';

import { IdentifiableEntity } from '@bp/shared/models/metadata';

import { filterPresent } from '@bp/frontend/rxjs';

import { EntitiesInMemoryPagedListSelectors } from './compose-entities-list-selectors';
import { EntitiesListFacade } from './entities-list.facade';
import { EntitiesListActions } from './entities.actions';
import { EntitiesInMemoryPagedListState } from './compose-entities-list-reducer';

@Injectable({ providedIn: 'root' })
export abstract class EntitiesInMemoryPagedListFacade<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesInMemoryPagedListState<TEntity>,
	TLoadQueryParams
> extends EntitiesListFacade<TEntity, TState, TLoadQueryParams> {

	abstract override readonly actions: EntitiesListActions<TEntity, TLoadQueryParams>;

	abstract override readonly selectors: EntitiesInMemoryPagedListSelectors<TEntity, TState>;

	readonly all$ = defer(() => this._store$.pipe(
		select(this.selectors.all),
		tap(v => (this.all = v)),
	));

	all!: TEntity[] | null;

	readonly allPresent$ = this.all$.pipe(filterPresent);

	readonly filteredInMemory$ = defer(() => this._store$.pipe(
		select(this.selectors.filteredInMemory),
	));

	readonly filteredInMemoryLength$ = this.filteredInMemory$.pipe(
		map(filteredInMemory => filteredInMemory?.length ?? null),
	);

	loadAll(): void {
		this._store$.dispatch(this.actions.loadAll());
	}
}
