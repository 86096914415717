import { camelCase, isMap } from 'lodash-es';
import moment, { Moment } from 'moment';

import { FeatureVersion, MerchantAdminFeature } from '@bp/shared/domains/permissions';
import {
	Default, Entity, Mapper, AliasFor, DTO, MapFromDTO, Table, Control, FieldControlType, View, FieldViewType, Sortable,
	Hint,
	Copyable
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { AuditEventVm } from '@bp/shared/domains/audit';

import { OrganizationSubscription } from './organization-subscription';

export type OrganizationKeys = NonFunctionPropertyNames<Organization>;

export class Organization extends Entity {

	static checkCompanyNameIsSandbox(companyName: string): boolean {
		return companyName.search(/sandbox|sdx|sbx/ui) !== -1;
	}

	@Table()
	@Hint('Also known as Merchant ID')
	@Copyable()
	override id!: string | null;

	@Table()
	override name!: string | null;

	@MapFromDTO()
	liveApiKey!: string;

	@AliasFor('virtualTerminalCashierKey')
	virtualTerminalCheckoutKey!: string;

	@Mapper(FiatCurrency)
	virtualTerminalCurrencies!: FiatCurrency[];

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowRefunds!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowPayouts!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowCreateCreditCardToken!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowPaywith!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowThreeDsCredentials!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowSneakPeekPaymentCardToken!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	allowManualDeposits!: boolean;

	@Mapper((dto?: Object): Map<MerchantAdminFeature, FeatureVersion> => isMap(dto)
		? dto
		: new Map(
			Object.entries(dto ?? {}).map(([ dtoFeatureName, version ]) => [
				MerchantAdminFeature.parseStrict(camelCase(dtoFeatureName)),
				FeatureVersion.parseStrict(version),
			]),
		))
	@Default(new Map())
	featuresVersions!: Map<MerchantAdminFeature, FeatureVersion>;

	@Table()
	@Mapper(FiatCurrency)
	@AliasFor('currency')
	baseCurrency!: FiatCurrency;

	@Table()
	@Mapper(OrganizationSubscription)
	@Default(null)
	currentSubscription!: OrganizationSubscription | null;

	@Default([])
	@Mapper(AuditEventVm)
	auditEvents!: AuditEventVm[];

	readonly wasCreatedAfterFreePlanDisabling!: boolean;

	@MapFromDTO()
	readonly isSandbox!: boolean;

	@MapFromDTO()
	readonly displayName!: string;

	@Table()
	@Sortable({ isDefault: true })
	override created!: Moment | null;

	@Table()
	@Sortable()
	override modified!: Moment | null;

	constructor(dto?: DTO<Organization>) {
		super(dto);

		this.wasCreatedAfterFreePlanDisabling = !!this.created?.isAfter(moment('2023-01-09'));

		this.displayName = this.name ?? '';

		this.isSandbox ||= Organization.checkCompanyNameIsSandbox(this.name ?? '');

		if (this.isSandbox) {
			const nameWithoutSandbox = this.name!.replaceAll(/(:?-*\s*)*(sandbox|sbx|sdx|\[|\])/gui, '').trim();

			this.displayName = `${ nameWithoutSandbox } [sandbox]`;
		}

	}

	override toString(): string {
		return this.displayName;
	}
}
