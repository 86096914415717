<mat-toolbar color="primary">
	<div class="left">
		<bp-burger-btn
			*ngIf="hasSidenavMenu"
			class="btn-menu"
			@fadeIn
			[showCross]="(layoutFacade.showSidenav$ | async) === true"
			(click)="layoutFacade.showSidenav ? layoutFacade.closeSidenav() : layoutFacade.openSidenav()"
		/>

		<a
			*ngIf="hasSidenavMenu; else logoTpl"
			mat-button
			class="btn-logo"
			routerLink="/"
			(click)="layoutFacade.showSidenav && layoutFacade.closeSidenav()"
		>
			<bp-svg-icon name="logo-short-text" />
		</a>

		<ng-template #logoTpl>
			<bp-svg-icon name="logo-short-text" />
		</ng-template>

		<ng-content select=".ng-content-toolbar-left-section" />
	</div>

	<div class="right">
		<button
			*ngIf="!MockedBackendState.isDemoMode && MockedBackendState.isActive"
			mat-flat-button
			type="button"
			color="warn"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			(click)="MockedBackendState.toggleAndReloadPage()"
		>
			Turn Off Mocked Backend
		</button>

		<button
			*ngIf="LocalBackendState.isActive"
			mat-flat-button
			type="button"
			color="warn"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			(click)="LocalBackendState.toggleAndReloadPage()"
		>
			Turn Off Local Backend
		</button>

		<button
			*ngIf="pwaService.newVersionIsAvailable$ | async"
			mat-flat-button
			type="button"
			color="accent"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			@fadeIn
			(click)="pwaService.reloadApp()"
		>
			Update Portal
		</button>

		<ng-content select=".ng-content-toolbar-right-section" />

		<button
			#menuTriggerObserver="bpMatMenuTriggerObserver"
			mat-button
			bpMatMenuTriggerObserver
			type="button"
			class="user"
			[matMenuTriggerFor]="userMenu"
		>
			{{ userName }}

			<bp-chevron-rotatable-180-degrees [rotated]="(menuTriggerObserver.opened$ | async) === true" />
		</button>

		<mat-menu
			#userMenu="matMenu"
			class="user-menu"
			routerLinkActive="active-link"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<ng-content select=".ng-content-toolbar-user-menu-items" />

			<button mat-menu-item type="button" (click)="logout.emit()">Log Out</button>
		</mat-menu>
	</div>
</mat-toolbar>
