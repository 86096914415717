
import { AliasFor, DTO, MetadataEntity } from '@bp/shared/models/metadata';

export class UserShortInfo extends MetadataEntity {

	@AliasFor(
		'userIdentityId', // property from Identity JWT
		{ serializeAliasSourceProperty: false },
	)
	id!: string;

	@AliasFor(
		'userFullName', // property from Identity JWT
		{ serializeAliasSourceProperty: false },
	)
	fullName!: string;

	@AliasFor(
		'userEmail', // property from Identity JWT
		{ serializeAliasSourceProperty: false },
	)
	email!: string;

	constructor(dto: DTO<UserShortInfo>) {
		super(dto);
	}

	override toString(): string {
		return this.fullName;
	}
}
