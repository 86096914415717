import { escapeRegExp } from 'lodash-es';

import { Nil } from '@bp/shared/typings';

/**
 * Case insensitive search for the substring inside the search term
 */
export function includesIgnoringCase(target: Nil | string, search: Nil | string): boolean {
	if (!target || !search)
		return false;

	return target.search(new RegExp(escapeRegExp(search), 'ui')) !== -1;
}

/**
 * Case insensitive exact match to the search term
 */
export function matchIgnoringCase(target: string, search: string): boolean {
	if (!target || !search)
		return false;

	return target.search(new RegExp(`^${ escapeRegExp(search) }$`, 'ui')) !== -1;
}

/**
 * Case insensitive search for every search term word inside string
 */
export function searchByTermsIgnoringCase(target: string, searchTerms: string[]): boolean {
	if (!target || searchTerms.length === 0)
		return false;

	return searchTerms.every(term => includesIgnoringCase(target, term));
}
