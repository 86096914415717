import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationGrowthPlanVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoRocket;

	title = 'READY TO SCALE? WELCOME TO GROWTH!';

	subtitle = 'Take your payments to the next level with:';

	additionalInfo = this._buildPromoPricingPageLink();

	features = [
		this._buildPspsFeature(),
		this._buildCheckoutsFeature(),
		this._buildFreeIntegratedPspsFeature(),
		'<b>Marketplace</b> integration',
		'Dynamic <b>currency converter</b>',
	];

	constructor(subscriptionPlan: SubscriptionPlan) {
		super(subscriptionPlan);
	}
}
