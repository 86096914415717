import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import type { PaymentOptionType } from '@bp/frontend/models/business';
import { PspPaymentOptionType } from '@bp/frontend/models/business';
import { BridgerPspsByPaymentOptionTypeMap } from '@bp/frontend/domains/bridger-psps/core';

@Component({
	selector: 'bp-payment-option-type-toggle-group',
	templateUrl: './payment-option-type-toggle-group.component.html',
	styleUrls: [ './payment-option-type-toggle-group.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOptionTypeToggleGroupComponent {

	@Input() optionTypes!: PaymentOptionType[] | null;

	@Input() selectedOptionType!: PspPaymentOptionType;

	@Output() readonly selectedOptionTypeChange = new EventEmitter<PspPaymentOptionType>();

	@Input() selectedPspsByPaymentOptionTypeMap!: BridgerPspsByPaymentOptionTypeMap | null;

}
