import { Enumeration } from '@bp/shared/models/core/enum';

export class SupportRequestStatus extends Enumeration {

	static supportRequested = new SupportRequestStatus('Requested');

	static supportRequestCompleted = new SupportRequestStatus('Completed');

	static supportRequestExpired = new SupportRequestStatus('Expired');

}
