import { Validators } from '@bp/shared/features/validation/models';
import { Constructor } from '@bp/shared/typings';
import { IPageQueryParams, PAGE_SIZE } from '@bp/shared/models/common';
import { hasOwnProperty } from '@bp/shared/utilities/core';

import { AliasFor, Default, MapFromDTO, Mapper, Validator } from '../decorators';

import { MetadataQueryParamsBaseCtor } from './query-params-base';

export function mixinMetadataPageQueryParams<T extends MetadataQueryParamsBaseCtor>(
	base: T, defaultPageSize = PAGE_SIZE,
): Constructor<IPageQueryParams> & T {
	class MixedIn extends base implements IPageQueryParams {

		@MapFromDTO()
		page!: string | undefined;

		@Validator(Validators.compose([
			Validators.safeInteger,
			Validators.minimum(1),
			Validators.maximum(250),
		])!)
		@Default(defaultPageSize)
		@Mapper(Number)
		@AliasFor('pageSize', { serializeAliasSourceProperty: false })
		limit!: number;

		constructor(...args: any[]) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			super(...args);

			// We do need defined key in object for some checks.
			if (!hasOwnProperty(this, 'page'))
				this.page = undefined;
		}
	}

	return MixedIn;
}
