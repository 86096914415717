import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import type { FiatCurrency } from '@bp/shared/models/currencies';
import { isNotEmpty } from '@bp/shared/utilities/core';

import type { PspPaymentOptionType } from '@bp/frontend/models/business';
import { BridgerPsp } from '@bp/frontend/domains/bridger-psps/core';
import { FADE } from '@bp/frontend/animations';

@Component({
	selector: 'bp-bridger-psps-filter[selectedPaymentOptionType]',
	templateUrl: './bridger-psps-filter.component.html',
	styleUrls: [ './bridger-psps-filter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class BridgerPspsFilterComponent {

	@Input() availableCurrencies?: Set<FiatCurrency>;

	@Input() availableBrands?: Set<PaymentCardBrand> | null;

	@Input() selectedPaymentOptionType!: PspPaymentOptionType;

	@Output() readonly changed = new EventEmitter<Partial<BridgerPsp>>();

	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected _MerchantAdminFeature = MerchantAdminFeature;

	private __filterState: Partial<BridgerPsp> = {};

	protected _emitFilterChanged(partialFilter: Partial<BridgerPsp>): void {
		this.changed.emit(this.__filterState = {
			...this.__filterState,
			...partialFilter,
		});
	}

	protected _emitCurrenciesFilterChanged(selectedCurrencies: FiatCurrency[] | null): void {
		this._emitFilterChanged({
			paymentOptionsCurrenciesByTypeMap: isNotEmpty(selectedCurrencies)
				? new Map([[ this.selectedPaymentOptionType, selectedCurrencies ]])
				: null,
		});
	}
}
