import { Moment } from 'moment';

import { OtpProvider, LoginOtpRequestInterval } from '@bp/shared/domains/jwt-session';
import {
	DTO, Entity, Default, Mapper, Table, FieldControlType, Control, momentMapper, FieldViewType, View, Label,
	PropertyMetadataControl, PropertyDecoratorConfig, PropertyMetadataTable, Property, Sortable
} from '@bp/shared/models/metadata';
import { User } from '@bp/shared/domains/users';
import { IInvitee, InviteeStatus } from '@bp/shared/domains/invitations';

import { MemberRole } from './member-role.enum';

export const memberOtpProviderMetadata: PropertyDecoratorConfig = {
	control: new PropertyMetadataControl({
		type: FieldControlType.select,
		list: OtpProvider.getList(),
		required: true,
	}),
	label: 'OTP provider',
	mapper: OtpProvider,
	table: new PropertyMetadataTable(),
};

export const memberLoginOtpRequestIntervalMetadata: PropertyDecoratorConfig = {
	control: new PropertyMetadataControl({
		type: FieldControlType.select,
		list: LoginOtpRequestInterval.getList(),
		required: true,
	}),
	label: 'Login OTP request interval',
	mapper: LoginOtpRequestInterval,
	table: new PropertyMetadataTable(),
};

export class Member extends Entity implements IInvitee {

	@Mapper(User)
	user!: User | null;

	@Table()
	email!: string;

	@Mapper(MemberRole)
	@Table()
	@Control(FieldControlType.select, {
		list: MemberRole.getList(),
		required: true,
	})
	role!: MemberRole;

	@Mapper(InviteeStatus)
	@Table()
	@Sortable({ isDefault: true })
	status!: InviteeStatus;

	@Table()
	@View(FieldViewType.momentFromNow)
	@Mapper(momentMapper)
	@Default(null)
	lastSeen!: Moment | null;

	@Mapper(momentMapper)
	@Label('Added')
	@View(FieldViewType.momentFromNow)
	@Default(null)
	override created!: Moment | null;

	@View(FieldViewType.momentFromNow)
	@Mapper(momentMapper)
	@Default(null)
	invitedAt!: Moment | null;

	@View(FieldViewType.momentFromNow)
	@Mapper(momentMapper)
	@Default(null)
	inviteExpiresAt!: Moment | null;

	@Property(memberOtpProviderMetadata)
	otpProvider!: OtpProvider | null;

	@Property(memberLoginOtpRequestIntervalMetadata)
	loginOtpRequestInterval!: LoginOtpRequestInterval;

	@Mapper(Member)
	invitedBy?: Member | null;

	get nameOrEmail(): string {
		return this.user?.name ?? this.email;
	}

	get isOwner(): boolean {
		return this.role === MemberRole.merchantOwner;
	}

	readonly displayName: string;

	constructor(dto?: DTO<Member>) {
		super(dto);

		this.name = this.user?.name ?? null;

		this.displayName = this.name ?? this.email;
	}

}
