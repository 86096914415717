import { ToastrService } from 'ngx-toastr';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, interval } from 'rxjs';

import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/frontend/models/common';
import { UserIdleService } from '@bp/frontend/services/core';
import { EnvironmentService } from '@bp/frontend/services/environment';

import { VirtualTerminalData } from '@bp/admins-shared/core/models';

import { CurrentOrganizationApiService } from '../services';

import {
	loadFailure, loadSuccess, loadVirtualTerminalDataFailure, loadVirtualTerminalDataSuccess, refreshFailure, refreshSuccess
} from './current-organization-api.actions';
import {
	load, loadVirtualTerminalData, refresh, startRefreshingInInterval, stopRefreshingInInterval
} from './current-organization.actions';
import { CurrentOrganizationFacade } from './current-organization.facade';

@Injectable()
export class CurrentOrganizationEffects {
	private readonly __currentOrganizationApiService = inject(CurrentOrganizationApiService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __router = inject(Router);

	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	private readonly __actions$ = inject(Actions);

	private readonly __toasterService = inject(ToastrService);

	private readonly __userIdleService = inject(UserIdleService);

	load$ = createEffect(() => this.__actions$.pipe(
		ofType(load),
		switchMap(() => this.__currentOrganizationApiService
			.getCurrent()
			.pipe(apiResult(loadSuccess, loadFailure))),
	));

	refresh$ = createEffect(() => this.__actions$.pipe(
		ofType(refresh),
		filter(() => !!this.__currentOrganizationFacade.entity),
		switchMap(() => this.__currentOrganizationApiService
			.getCurrent()
			.pipe(apiResult(refreshSuccess, refreshFailure))),
	));

	refreshInInterval$ = createEffect(
		() => this.__actions$.pipe(
			ofType(startRefreshingInInterval, stopRefreshingInInterval),
			switchMap(({ type }) => type === startRefreshingInInterval.type
				? this.__userIdleService.active$.pipe(
					switchMap(isActive => (isActive ? interval(15000) : EMPTY)),
						  )
				: EMPTY),
			tap(() => void this.__currentOrganizationFacade.refresh()),
		),
		{ dispatch: false },
	);

	loadFailure$ = createEffect(
		() => this.__actions$.pipe(
			ofType(loadFailure, refreshFailure),
			tap(({ error, type }) => {
				this.__toasterService.error(error.message, type, {
					disableTimeOut: true,
					closeButton: this.__environment.isProduction,
				});

				if (this.__environment.isNotProduction) {
					this.__currentOrganizationFacade.stopRefreshingInInterval();

					void this.__router.navigate([ '/error' ]);
				}
			}),
		),
		{ dispatch: false },
	);

	loadVirtualTerminalData$ = createEffect(() => this.__actions$.pipe(
		ofType(loadVirtualTerminalData),
		switchMap(() => this.__currentOrganizationApiService.getPaymentRoutes().pipe(
			map(paymentRoutes => new VirtualTerminalData(paymentRoutes)),
			apiResult(loadVirtualTerminalDataSuccess, loadVirtualTerminalDataFailure),
		)),
	));
}
