import { get, isEqual, startCase } from 'lodash-es';
import isbot from 'isbot';
import { BehaviorSubject, map, share } from 'rxjs';

import { Inject, Injectable, InjectionToken } from '@angular/core';

import { Deployment, Environment } from '@bp/shared/models/core';
import { IAppVersion, IEnvironmentConfig, IRepositorySummary, Platform } from '@bp/shared/typings';
import { getLocationMainDomain } from '@bp/shared/utilities/core';

import { LocalBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { extractDevEnvironmentSlotName } from '@bp/frontend/utilities/common';

export const PLATFORM = new InjectionToken<Platform>('PLATFORM');

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService implements IEnvironmentConfig {

	private static __environment: IEnvironmentConfig;

	private static __mainDomain: string;

	private static __checkoutLauncherUrl: string;

	private static readonly __devEnvironmentSlotName = extractDevEnvironmentSlotName(location.hostname);

	private static readonly __isDevEnvironment = !!EnvironmentService.__devEnvironmentSlotName;

	static isHumanManaged = false;

	static init(environment: IEnvironmentConfig): void {
		this.__environment = environment;

		this.__mainDomain = getLocationMainDomain();

		this.isHumanManaged = this.__isHumanManaged();

		this.__setCheckoutLoaderUrlAccordingToHostDomain();
	}

	private static __setCheckoutLoaderUrlAccordingToHostDomain(): void {
		EnvironmentService.__checkoutLauncherUrl = EnvironmentService.__environment.checkoutLauncherUrl
			.replace('{{mainDomain}}', EnvironmentService.__mainDomain);

		if (EnvironmentService.__isDevEnvironment)
			EnvironmentService.__checkoutLauncherUrl = EnvironmentService.__checkoutLauncherUrl.replace('checkout', `checkout-${ EnvironmentService.__devEnvironmentSlotName }`);
	}

	private static __isHumanManaged(): boolean {
		return get(<any>globalThis, 'ScullyIO') !== 'running'
			&& !isbot(navigator.userAgent);
	}

	private readonly __environment = EnvironmentService.__environment;

	readonly api = this.__environment.api;

	readonly apiVersion = this.__environment.apiVersion;

	readonly appVersion = this.__environment.appVersion;

	readonly appVersionLiteral = 'fe-app-version';

	readonly repositorySummary = this.__environment.repositorySummary;

	readonly mainDomain = EnvironmentService.__mainDomain;

	readonly checkoutLauncherUrl = EnvironmentService.__checkoutLauncherUrl;

	readonly bridgerAdminOrigin = this.__environment.bridgerAdminOrigin;

	readonly merchantAdminOrigin = this.__environment.merchantAdminOrigin;

	readonly signupOrigin = this.__environment.signupOrigin;

	readonly name = this.__environment.name;

	readonly environment = Environment.parseStrict(this.name);

	readonly isLocal = this.environment.isLocal;

	readonly isDeployed = this.__environment.isDeployed;

	readonly isStaging = this.environment.isStaging;

	readonly isPullRequestPreview = this.environment.isPullRequestPreview;

	readonly isPrerelease = this.environment.isPrerelease;

	readonly isProduction = this.environment.isProduction;

	readonly isNotProduction = !this.isProduction;

	readonly isStagingOrPreview = this.isStaging || this.isPullRequestPreview;

	readonly isDeployedStagingOrProduction = (this.isStaging || this.isProduction) && !LocalBackendState.isActive;

	readonly isBackoffice = location.hostname.includes('backoffice');

	readonly devEnvironmentSlotName = EnvironmentService.__devEnvironmentSlotName;

	readonly isDevEnvironment = EnvironmentService.__isDevEnvironment;

	private readonly __repositorySummariesPerDeployment$ = new BehaviorSubject<Map<Deployment, IRepositorySummary>>(new Map([
		[ Deployment.frontend, this.repositorySummary ],
	]));

	readonly repositorySummariesPerDeployment$ = this.__repositorySummariesPerDeployment$.asObservable();

	readonly backendDeploymentVersions$ = this.__repositorySummariesPerDeployment$.pipe(
		map(summaryPerDeployment => Object.fromEntries([ ...summaryPerDeployment
			.entries() ]
			.filter(([ deployment ]) => deployment.isBackend)
			.map(([ deployment, summary ]) => [ `${ deployment }BackendVersion`, summary.releaseTitle ]))),
		share(),
	);

	readonly realUserMonitoring = this.__environment.realUserMonitoring;

	// #region Third Party Keys

	readonly sentry = this.__environment.sentry;

	readonly intercom = this.__environment.intercom;

	readonly pardot = this.__environment.pardot;

	readonly logrocket = this.__environment.logrocket;

	readonly googleTagAnalyticsId = this.__environment.googleTagAnalyticsId;

	readonly googleTagTransportUrl = this.__environment.googleTagTransportUrl;

	readonly oauthGoogleClientId = this.__environment.oauthGoogleClientId;

	readonly cloudflareTurnstileSiteKey = this.__environment.cloudflareTurnstileSiteKey;

	// #endregion

	readonly firebaseFunctionSuffix = this.environment.isProduction ? '' : startCase(this.name);

	constructor(@Inject(PLATFORM) public readonly platform: Platform) {
		document.documentElement.classList.add(this.environment.name);
	}

	setDeploymentRepositorySummary(deployment: Deployment, summary: IRepositorySummary): void {
		if (isEqual(summary, this.__repositorySummariesPerDeployment$.value.get(deployment)))
			return;

		this.__repositorySummariesPerDeployment$.next(
			new Map(this.__repositorySummariesPerDeployment$.value)
				.set(deployment, summary),
		);
	}

	 parseAppVersion(appVersion: string): IAppVersion {
		 const segments = appVersion.split('-');
		 const [ release ] = segments;
		 const shortSHA = segments.at(-1) ?? null;
		 const releaseName = segments.slice(1, -1).join('-');

		return { release, releaseName, shortSHA, releaseTitle: appVersion };
	}

}
