import { MetadataEntity, Copyable } from '@bp/shared/models/metadata';

export class CheckoutActivationResponse extends MetadataEntity {

	@Copyable()
	readonly userName!: string;

	@Copyable()
	readonly password!: string;

	@Copyable()
	readonly apiKey!: string;

	@Copyable()
	readonly cashierKey!: string;

	@Copyable()
	readonly apiUrl!: string;

	@Copyable()
	readonly embedUrl!: string;

}
