import { DTO } from '@bp/shared/models/metadata';
import { Dictionary } from '@bp/shared/typings';
import { isEmpty } from '@bp/shared/utilities/core';

import { SubscriptionPlanPrice } from '../subscription-plan-price';
import { SubscriptionPlanChargePeriod } from '../subscription-plan-charge-period.enum';

export type SubscriptionPlanPricePerChargePeriodMap = Map<SubscriptionPlanChargePeriod, SubscriptionPlanPrice>;

export function subscriptionPlanPricePerChargePeriodMapper(
	dtoOrMap: Dictionary<DTO<SubscriptionPlanPrice>> | SubscriptionPlanPricePerChargePeriodMap,
): SubscriptionPlanPricePerChargePeriodMap | null {
	if (dtoOrMap instanceof Map)
		return dtoOrMap;

	if (isEmpty(dtoOrMap))
		return null;

	return new Map(
		Object
			.entries(dtoOrMap)
			.map(([ chargePeriod, priceDto ]) => [
				SubscriptionPlanChargePeriod.parseStrict(chargePeriod),
				new SubscriptionPlanPrice(priceDto),
			]),
	);
}
