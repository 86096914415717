
import { isArray, isNumber, isObjectLike, isString, isSymbol } from 'lodash-es';

import { hasOwnProperty } from './has-own-property';

export function transformMapToObject(map: Map<any, any>) {
	const entries = [ ...map.entries() ];

	assertMapKeysSerializable(entries);

	const mapAsPlainObject = Object.fromEntries(
		entries.map(([ key, value ]) => <const>[ (<Object>key).toString(), value ]),
	);

	assertMapKeysImplementSerializationProtocol(mapAsPlainObject);

	return mapAsPlainObject;
}

function assertMapKeysSerializable(entries: [any, any][]): void {
	const hasInvalidKey = entries.some(([ key ]) => isArray(key)
		|| !isNumber(key)
		&& !isString(key)
		&& !isSymbol(key)
		&& !isObjectLike(key));

	if (hasInvalidKey)
		throw new Error('To transform map to object, the map\'s keys must be Number or String or Object type');
}

function assertMapKeysImplementSerializationProtocol(object: Object): void {
	if (hasOwnProperty(object, '{}'))
		throw new Error('Map object keys must implement toJSON protocol to be properly serialized');
}
