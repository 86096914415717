import { inject, Injectable } from '@angular/core';

import { $ } from '@bp/frontend/utilities/dom';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { ISalesforceTrackingFunction } from '@bp/frontend/features/analytics/models';
import { IUtmTags } from '@bp/frontend/features/analytics/utms-models';
import { RouterService } from '@bp/frontend/services/router';

/**
 * https://developer.salesforce.com/docs/marketing/pardot/guide/tracking-and-consent-use-cases.html
 */
@Injectable({
	providedIn: 'root',
})
export class PardotService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __routerService = inject(RouterService);

	private readonly __enabled = !!this.__environment.pardot;

	private readonly __config = {
		accountId: this.__environment.pardot!,
		campaignId: 3533, // default webtracking campaign
		domainHostName: 'pardot.bridgerpay.com',
	};

	private __inited = false;

	private __currentPardonCampaignId?: number;

	private get __pardot(): ISalesforceTrackingFunction {
		if (window.pdt)
			return window.pdt;

		window.pdt = function() {
			// eslint-disable-next-line prefer-rest-params
			window.pdt!.cq!.push(arguments);
		};

		window.pdt.cq = [];

		return window.pdt;
	}

	init(): void {
		if (this.__inited || !this.__enabled)
			return;

		this.__injectPardotScript();

		this.__initPardotCampaign(this.__config.campaignId);

		this.__observeNavigationAndSetTitleAndUrl();

		this.__inited = true;
	}

	setVisitorEmail(email: string): void {
		this.__pardot('set', 'piEmail', email);

		this.__pardot('sendPageView');
	}

	setUtmTags({ pardotCampaignId, campaign, medium, source, term, refererPageUrl }: IUtmTags): void {
		pardotCampaignId && this.__initPardotCampaign(Number(pardotCampaignId));

		campaign && this.__pardot('set', 'utmCampaign', campaign);

		medium && this.__pardot('set', 'utmMedium', medium);

		source && this.__pardot('set', 'utmSource', source);

		term && this.__pardot('set', 'utmTerm', term);

		refererPageUrl && this.__pardot('set', 'referrer', refererPageUrl);

		this.__pardot('sendPageView');
	}

	setOptIn(value: boolean): void {
		this.__pardot('setOptIn', value);
	}

	private __initPardotCampaign(pardotCampaignId: number): void {
		if (!this.__enabled || this.__currentPardonCampaignId === pardotCampaignId || Number.isNaN(pardotCampaignId) || pardotCampaignId === 0)
			return;

		this.__currentPardonCampaignId = pardotCampaignId;

		this.__pardot('create', this.__config.accountId, pardotCampaignId, this.__config.domainHostName);
	}

	private __observeNavigationAndSetTitleAndUrl(): void {
		this.__routerService
			.sanitizedUrlAndTitleOnNavigationEnd$
			.subscribe(navigationEnd => {
				this.__pardot('set', 'url', navigationEnd.url);

				this.__pardot('set', 'title', document.title);

				this.__pardot('sendPageView');
			});
	}

	private __injectPardotScript(): void {
		document.body.append($.buildAsyncScriptElement({
			src: `https://${ this.__config.domainHostName }/pdt.js`,
		}));
	}
}
