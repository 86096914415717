import { Enumeration } from '@bp/shared/models/core/enum';

export class InviteeStatus extends Enumeration {

	static invited = new InviteeStatus();

	static active = new InviteeStatus();

	get isActive(): boolean {
		return this === InviteeStatus.active;
	}

	get isInvited(): boolean {
		return this === InviteeStatus.invited;
	}

}
