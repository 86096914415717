import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SpinningLogoComponent } from '@bp/frontend/components/spinning-logo';

import { CalendlyDirective } from './directives';
import { CalendlyComponent, CalendlyDialogComponent } from './components';

const EXPORTS = [
	CalendlyComponent,
	CalendlyDirective,
];

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		SharedPipesModule,
		MatIconModule,
		MatButtonModule,

		SpinningLogoComponent,

		SharedDirectivesCoreModule,
	],
	declarations: [
		EXPORTS,
		CalendlyDialogComponent,
	],
	exports: EXPORTS,

})
export class SharedFeaturesCalendlyModule { }
