import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SubscriptionPlansQueryParams, SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';
import { DTO, IEntitiesApiService } from '@bp/shared/models/metadata';
import { RecordsPage } from '@bp/shared/models/common';

import { toHttpParams } from '@bp/frontend/utilities/common';

export const SUBSCRIPTION_PLANS_API_COLLECTION_PATH = new InjectionToken<string>(
	'SUBSCRIPTION_PLANS_API_COLLECTION_PATH',
);

@Injectable({
	providedIn: 'root',
})
export class SubscriptionPlansApiService implements IEntitiesApiService<SubscriptionPlan, SubscriptionPlansQueryParams> {

	constructor(
		@Inject(SUBSCRIPTION_PLANS_API_COLLECTION_PATH)
		public readonly collectionPath: string,
		private readonly _httpClient: HttpClient,
	) { }

	readonly factory = (v: DTO<SubscriptionPlan> | SubscriptionPlan): SubscriptionPlan => v instanceof SubscriptionPlan
		? v
		: new SubscriptionPlan(v);

	getRecordsPage(query?: SubscriptionPlansQueryParams): Observable<RecordsPage<SubscriptionPlan>> {
		return this._httpClient
			.get<RecordsPage<DTO<SubscriptionPlan>>>(
			this.collectionPath,
			{ params: toHttpParams(query) },
		)
			.pipe(map(result => new RecordsPage(query, result, this.factory)));
	}

	get(id: string): Observable<SubscriptionPlan | null> {
		return this._httpClient
			.get<DTO<SubscriptionPlan> | undefined>(`${ this.collectionPath }/${ id }`)
			.pipe(map(v => v ? this.factory(v) : null));
	}

	save(model: SubscriptionPlan): Observable<SubscriptionPlan> {
		return (model.id
			? this._httpClient.put(`${ this.collectionPath }/${ model.id }`, model)
			: this._httpClient.post(`${ this.collectionPath }`, model)
		)
			.pipe(map(this.factory));
	}

	delete(model: SubscriptionPlan): Observable<void> {
		return <Observable<void>> <unknown> this._httpClient
			.delete(`${ this.collectionPath }/${ model.id }`);
	}

}
