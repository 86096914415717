import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AfterViewChecked, Directive, inject, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { fromViewportIntersection } from '@bp/frontend/rxjs';
import { $ } from '@bp/frontend/utilities/dom';

@Directive({
	standalone: true,
	selector: '[bpViewportOverflownObserver]',
})
export class ViewportOverflownObserverDirective extends Destroyable implements AfterViewChecked {

	@Input({
		alias: 'bpViewportOverflownObserverIncludeVerticalScrolling',
		transform: coerceBooleanProperty,
	})
	includeVerticalScrolling = false;

	@Input({
		alias: 'bpViewportOverflownObserverIncludeHorizontalScrolling',
		transform: coerceBooleanProperty,
	})
	includeHorizontalScrolling = true;

	@Output('bpViewportOverflownObserver')
	readonly hasHostElementOverflownViewport = new EventEmitter<boolean>();

	private readonly __$host = <HTMLElement> inject(ElementRef).nativeElement;

	private __isObserving = false;

	ngAfterViewChecked(): void {
		if (!this.__$host.isConnected || this.__isObserving)
			return;

		this.__isObserving = true;

		this.__observeHostElementOverflowingViewport()
			.pipe(takeUntilDestroyed(this))
			.subscribe(
				hasHostElementOverflownViewport => void this.hasHostElementOverflownViewport.emit(hasHostElementOverflownViewport),
			);
	}

	private __observeHostElementOverflowingViewport(): Observable<boolean> {
		return fromViewportIntersection(
			this.__$host,
			{
				root: $.getViewport(this.__$host),
				threshold: 1,
				ignoreHorizontalScrolling: !this.includeHorizontalScrolling,
				ignoreVerticalScrolling: !this.includeVerticalScrolling,
			},
		).pipe(
			filter(this.__isElementInsideViewport),
			map(this.__hasHostElementOverflownViewport),
		);
	}

	private __hasHostElementOverflownViewport(this: void, { intersectionRatio }: IntersectionObserverEntry): boolean {
		return intersectionRatio !== 1;
	}

	private __isElementInsideViewport(this: void, { intersectionRect }: IntersectionObserverEntry): boolean {
		return intersectionRect.width > 0 && intersectionRect.height > 0;
	}

}
