import { Observable, of, timer, merge, EMPTY } from 'rxjs';
import { delay, exhaustMap, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { getMockUsersCredentials, MockUserEmail, MOCK_DEMO_USER_CREDENTIALS } from '@bp/shared/domains/jwt-session';

import { apiResult } from '@bp/frontend/models/common';
import { filterPresent } from '@bp/frontend/rxjs';
import { UserIdleService } from '@bp/frontend/services/core';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { HttpConfigService } from '@bp/frontend/services/http';
import { MockedBackendState, LocalBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { AppStorageService } from '@bp/frontend/services/storage';

import { LayoutFacade } from '@bp/admins-shared/features/layout';
import { Identity, ILoginApiRequest, LOGIN_ROUTE_PATHNAME } from '@bp/admins-shared/domains/identity/models';

import {
	IdentityEffects as IdentityBaseEffects, IDENTITY_STATE_KEY, NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN
} from '@bp/frontend-domains-identity';

import { IdentitySessionIsAboutToExpireDialogComponent } from '../components';
import { IdentityApiService } from '../services';
import { tryCreateIdentityBasedOnLoginQueryParams } from '../utils';

import {
	generateLoginOtpFailure, generateLoginOtpSuccess, generateFeatureAccessOtpFailure, generateFeatureAccessOtpSuccess, refreshTokenFailure, refreshTokenSuccess, featureAccessOtpVerificationFailure, featureAccessOtpVerificationSuccess
} from './identity-api.actions';
import {
	generateLoginOtp, generateFeatureAccessOtp, localStorageIdentityChanged, refreshAccessToken, featureAccessOtpVerification, sessionExpired, setIdentityBasedOnLoginQueryParams, showIdentitySessionIsAboutToExpireDialog, startIdentitySessionExpiryTimer, stopIdentitySessionExpiryTimer,
	updateFeatureAccessExpirationsMap
} from './identity.actions';
import { IdentityFacade } from './identity.facade';
import { FEATURE_STATE_KEY, IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY, IState } from './identity.reducer';

const IDENTITY_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_STATE_KEY }`;
const IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY }`;

@Injectable()
export class IdentityEffects
	extends IdentityBaseEffects<Identity, IState, ILoginApiRequest> {

	protected readonly _identityApiService = inject(IdentityApiService);

	private readonly __nonRedirectedUrlsAfterLogin = inject<string[][]>(NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN);

	private readonly __appStorageService = inject(AppStorageService);

	private readonly __userIdleService = inject(UserIdleService);

	private readonly __layoutFacade = inject(LayoutFacade);

	private readonly __httpConfigService = inject(HttpConfigService);

	private readonly __environment = inject(EnvironmentService);

	onDemoUserLoginReloadInDemoMode$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.login),
			filter(
				({ payload }) => payload.userName === MOCK_DEMO_USER_CREDENTIALS.email
						&& payload.password === MOCK_DEMO_USER_CREDENTIALS.password,
			),
			tap(() => void MockedBackendState.reloadInDemoMode()),
		),
		{ dispatch: false },
	);

	onMockUserLoginReloadInMockMode$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			filter(
				({ result }) => this.__environment.isNotProduction && !!getMockUsersCredentials()[<MockUserEmail>result.email],
			),
			tap(() => void MockedBackendState.reloadInMockMode()),
		),
		{ dispatch: false },
	);

	trySetIdentityBasedOnLoginQueryParams$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.effectsInit),
		mergeMap(() => {
			const identity = tryCreateIdentityBasedOnLoginQueryParams();

			if (window.location.pathname === LOGIN_ROUTE_PATHNAME && identity)
				return of(setIdentityBasedOnLoginQueryParams({ identity }));

			return EMPTY;
		}),
	));

	override onLoginSuccessSetIdentity$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			tap(({ result }) => {
				if (result.isIncomplete)
					this._identityFacade.setIncompleteIdentity(result);
				else
					this._identityFacade.setIdentity(result);
			}),
		),
		{ dispatch: false },
	);

	onLogout$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.logout),
		tap(() => {
			if (!!this._identityFacade.user || !this._router.url.includes(LOGIN_ROUTE_PATHNAME)) {
				MockedBackendState.reloadInNormalMode();

				this._identityFacade.removeIdentity();

				this.__layoutFacade.closeFloatOutlets();

				this._identityFacade.saveUrlForRedirectionAfterLogin(
					this.__nonRedirectedUrlsAfterLogin.flat().some(url => this._router.url.includes(url))
						? '/'
						: this._router.url,
				);

				void this._router.navigateByUrl(LOGIN_ROUTE_PATHNAME);
			}
		}),
		delay(100), /// wait for the logout happening on any other open tab
		map(() => this.actions.logoutComplete()),
	));

	whenIdentityChangedToggleIdentitySessionExpiryTimer$ = createEffect(() => this._identityFacade.user$.pipe(
		map(identity => identity
			? startIdentitySessionExpiryTimer({
				expiresAt: identity.sessionExpiresAt,
			})
			: stopIdentitySessionExpiryTimer()),
	));

	userSessionExpiryTimer$ = createEffect(() => this._actions$.pipe(
		ofType(startIdentitySessionExpiryTimer, stopIdentitySessionExpiryTimer),
		switchMap(action => action.type === startIdentitySessionExpiryTimer.type
			? timer(action.expiresAt.toDate()).pipe(map(() => sessionExpired()))
			: EMPTY),
	));

	whenSessionExpiredRefreshTokenOrLogout$ = createEffect(() => this._actions$.pipe(
		ofType(sessionExpired),
		map(() => (this._identityFacade.user!.refreshToken ? refreshAccessToken() : this.actions.logout())),
	));

	refreshIdentitySessionToken$ = createEffect(() => this._actions$.pipe(
		ofType(refreshAccessToken),
		concatLatestFrom(() => this._identityFacade.userPresent$),
		exhaustMap(([ , identity ]) => this._identityApiService
			.refreshToken(identity)
			.pipe(apiResult(refreshTokenSuccess, refreshTokenFailure))),
	));

	logoutOnRefreshTokenFailure$ = createEffect(() => this._actions$.pipe(
		ofType(refreshTokenFailure),
		map(() => this.actions.logout()),
	));

	whenUserAwayShowSessionIsAboutToExpireDialog = this._identityFacade.user$
		.pipe(switchMap(identity => (identity ? this.__userIdleService.onAway$ : EMPTY)))
		.subscribe(() => void this._identityFacade.showIdentitySessionIsAboutToExpireDialog());

	whenExpireDialogExpiresLogoutUser$ = createEffect(
		() => this._actions$.pipe(
			ofType(showIdentitySessionIsAboutToExpireDialog),
			exhaustMap(() => this.__showIdentitySessionIsAboutToExpireDialogAndObserveContinueWorkingResult()),
			tap(keepWorking => !keepWorking && void this._identityFacade.logout()),
		),
		{ dispatch: false },
	);

	// #region Open tabs synchronization

	storeIdentityToLocalStorageOnChange = this._identityFacade.user$.subscribe(
		identity => void this.__appStorageService.setIfDifferentFromStored(identity, IDENTITY_PATH_IN_STATE),
	);

	/**
	 * When on one tab the user has been changed on
	 * all the other tabs the user will be updated accordingly
	 */
	reflectLocalStorageUserChange$ = createEffect(() => this.__appStorageService.change$.pipe(
		filter(event => event.key === this.__appStorageService.deriveKey(IDENTITY_PATH_IN_STATE) && !LocalBackendState.isActive),
		map(event => (event.newValue && JSON.parse(event.newValue)) ?? null),
		map(localStorageIdentity => (localStorageIdentity ? new Identity(localStorageIdentity) : null)),
		filter(
			localStorageIdentity => localStorageIdentity?.modified?.unix() !== this._identityFacade.user?.modified?.unix(),
		),
		map(localStorageIdentity => localStorageIdentityChanged({ identity: localStorageIdentity })),
	));

	handleLocalStorageUserChange$ = createEffect(
		() => this._actions$.pipe(
			ofType(localStorageIdentityChanged),
			tap(({ identity }) => identity ? void this._identityFacade.setIdentity(identity) : void this._identityFacade.logout()),
		),
		{ dispatch: false },
	);

	navigateToAppOnLoginFromDifferentTab$ = createEffect(() => this._actions$.pipe(
		ofType(localStorageIdentityChanged),
		filter(({ identity }) => !!identity && this._router.url.includes(LOGIN_ROUTE_PATHNAME)),
		withLatestFrom(this._identityFacade.urlForRedirectionAfterLogin$),
		map(([ , urlForRedirectionAfterLogin ]) => this.actions.navigateToApp({ urlForRedirectionAfterLogin })),
	));

	// #endregion Open tabs synchronization

	whenUserChangeSetAuthorizedHeader = merge(
		this._identityFacade.user$,
		this._identityFacade.incompleteIdentity$.pipe(filterPresent),
	)
		.pipe(map(identity => identity?.jwt))
		.subscribe(jwt => jwt ? void this.__httpConfigService.setAuthorizationHeader(jwt) : void this.__httpConfigService.removeAuthorizationHeader());

	whenIdentitySetBasedOnLoginQueryParamsLoginSuccessfully$ = createEffect(() => this._actions$.pipe(
		ofType(setIdentityBasedOnLoginQueryParams),
		// eslint-disable-next-line rxjs/no-unsafe-switchmap
		switchMap(payload => {
			void this._identityFacade.logout(); // logout from any other open tab

			return this._actions$.pipe(
				ofType(this.actions.logoutComplete),
				// eslint-disable-next-line rxjs/no-unsafe-first
				first(),
				map(() => payload),
			);
		}),
		map(({ identity }) => this.actions.api.loginSuccess({ result: identity })),
	));

	generateLoginOtp$ = createEffect(() => this._actions$.pipe(
		ofType(generateLoginOtp),
		exhaustMap(() => this._identityApiService
			.generateLoginOtp()
			.pipe(apiResult(generateLoginOtpSuccess, generateLoginOtpFailure))),
	));

	generateFeatureAccessOtp$ = createEffect(() => this._actions$.pipe(
		ofType(generateFeatureAccessOtp),
		exhaustMap(({ permission }) => this._identityApiService
			.generateFeatureAccessOtp(permission)
			.pipe(apiResult(generateFeatureAccessOtpSuccess, generateFeatureAccessOtpFailure))),
	));

	featureAccessOtpVerification$ = createEffect(() => this._actions$.pipe(
		ofType(featureAccessOtpVerification),
		exhaustMap(payload => this._identityApiService
			.featureAccessOtpVerification(payload)
			.pipe(apiResult(featureAccessOtpVerificationSuccess, featureAccessOtpVerificationFailure))),
	));

	updateFeatureAccessOtpVerificationsMap$ = createEffect(() => this._actions$.pipe(
		ofType(featureAccessOtpVerificationSuccess),
		map(({ result }) => updateFeatureAccessExpirationsMap({
			featureAccessExpirationsMap: new Map([
				...this._identityFacade.featureAccessExpirationsMap,
				[ result.permission, result.expiresAt ],
			]),
		})),
	));

	storeInLocalStorageOnFeatureAccessExpirationsMapChange$ = createEffect(
		() => this._actions$.pipe(
			ofType(updateFeatureAccessExpirationsMap),
			tap(({ featureAccessExpirationsMap }) => void this.__appStorageService.setIfDifferentFromStored(
				[ ...featureAccessExpirationsMap ],
				IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_PATH_IN_STATE,
			)),
		), { dispatch: false },
	);

	constructor(protected override readonly _identityFacade: IdentityFacade) {
		super(_identityFacade);
	}

	private __showIdentitySessionIsAboutToExpireDialogAndObserveContinueWorkingResult(): Observable<boolean> {
		return this._dialog
			.open<IdentitySessionIsAboutToExpireDialogComponent, undefined, boolean>(
			IdentitySessionIsAboutToExpireDialogComponent,
			{
				disableClose: true,
			},
		)
			.afterClosed()
			.pipe(map(keepWorking => !!keepWorking));
	}
}
