export type ErrorsMapKey = keyof typeof ERRORS_MAP;

export const ERRORS_MAP = {
	ccAmount: 'The amount is invalid',
	ccCountry: 'The country not found',
	ccExpireDate: 'The expiry date is invalid e.g.: MM/YY',
	ccExpired: 'The expiry date provided is already expired',
	ccHolder: 'The card holder name includes invalid characters',
	ccNumber: 'The credit card number is incorrect',
	ccVerificationCode: 'The CVV code is incorrect',
	digits: 'The value must contain only digits',
	string: 'The value must be string',
	boolean: 'The value must be boolean',
	validMoment: 'The value must be valid parseable date',
	safeInteger: 'The value must be integer number',
	alphanumeric: 'The value must contain only latin letters or arabic digits',
	email: 'Please enter a valid email address',
	onlyASCIICharacters: 'Only latin letters, numbers, and common punctuation characters are allowed',
	noLeadingOrTrailingSpace: 'Value can’t start or end with a blank space',
	hasDigitCharacter: 'The value must have at least one digit',
	hasLetterCharacter: 'The value must have at least one letter',
	hasUpperCaseAndLowerCaseCharacter: 'The value must have at least one upper and lower case letter',
	hasSpecialCharacter: 'The value must have at least one special character',
	letters: 'The value must not contain any special characters or digits',
	matDatepickerMax: 'The date must be less than or equal to {{max}}',
	matDatepickerParse: 'The date is invalid',
	maximum: 'The value must be less than or equal to {{required}}',
	maxlength: 'The value must be at most {{requiredLength}} characters',
	minimum: 'The value must be greater than or equal to {{required}}',
	greaterThan: 'The value must be greater than {{required}}',
	inList: 'The value is not in allowed values list. Possible values: {{required}}',
	noZero: 'The value can\'t be zero',
	minlength: 'The value must be at least {{requiredLength}} characters',
	fixedLength: 'The value must be exactly {{requiredLength}} characters long',
	'fixedLength.cardBin': 'Only the first {{requiredLength}} digits of the card are accepted',
	pattern: 'The value is invalid',
	phoneParseError: 'The phone number is incorrect',
	phoneTooLong: 'The phone number is too long',
	phoneTooShort: 'The phone number is too short',
	required: 'You must enter a value',
	'required.address': 'Please enter your address',
	'required.amount': 'Please enter the amount',
	'required.city': 'Please enter your city',
	'required.country': 'Please select your country',
	'required.cardCvv': 'Please enter a CVV code',
	'required.default': 'You must enter a value',
	'required.email': 'Please enter your email address',
	'required.cardExpiryDate': 'Expiry Date is required e.g.: MM/YY',
	'required.cardHolderName': 'Please enter the card holder\'s name',
	'required.cardNumber': 'Please enter a credit card number',
	'required.phone': 'Please enter your phone number',
	'required.state': 'Please select your state',
	'required.zipCode': 'Please enter your zip code',
	'required.gender': 'Please select your gender',
	'required.installmentOptionId': 'Please select the payment term',
	'required.firstName': 'Please enter your first name',
	'required.lastName': 'Please enter your last name',
	'required.fullName': 'Please enter your full name',
	'required.companyName': 'Please enter your company name',
	'required.companyWebsite': 'Please enter your company website URL',
	'required.estimatedYearlyVolume': 'Please select from the dropdown',
	stateCode: 'Such state doesn\'t exist',
	unsupportedCardNumber: 'The card number is not supported. Supported brands: {{brands}}',
	countryNotFound: 'Such country doesn\'t exist',
	autocompleteNotFound: 'There is no match on the list',
	minMoreThanOrEqualMax: 'The value must be less than the max value',
	maxLessThanOrEqualMin: 'The value must be greater than the min value',
	valueMoreThanMax: 'The value must be less than or equal to the max value',
	valueLessThanMin: 'The value must be greater than or equal to the min value',
	'password.onlyASCIICharacters': 'Only use latin letters, numbers, and common punctuation characters',
	'password.noLeadingOrTrailingSpace': 'Your password can’t start or end with a blank space',
	'password.hasDigitCharacter': 'The password must contain at least one digit',
	'password.hasLetterCharacter': 'The password must contain at least one letter',
	'password.hasUpperCaseAndLowerCaseCharacter': 'The password must contain at least one upper and lower case letter',
	'password.hasSpecialCharacter': 'The password must contain at least one special character',
	'password.minlength': 'The password has to contain at least {{requiredLength}} characters',
	'password.maxlength': 'The password has to be at most {{requiredLength}} characters',
	passwordConfirm: 'Passwords don\'t match',
	ip: 'The entered IP address is incorrect',
	noUrl: 'String must not contain URL',
	url: 'Please enter a valid URL, must start with http(s)://',
	urlWithOptionalProtocol: 'Please enter a valid website address',
	urlWithoutProtocol: 'Please enter a valid website address',
	pascalCase: 'The value must be in PascalCase',
	uuid: 'Invalid UUID',
	beforeDate: 'The date is after {{required}}',
	afterDate: 'The date is before {{required}}',
	utf8Bmp: 'String must not contain emoji or special symbols',
	xssSafe: 'String must not contain special symbols',
};
