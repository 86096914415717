
import { FiatCurrency, ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, ANY_FIAT_CURRENCY_RESET_OPTION_TEXT } from '@bp/shared/models/currencies';
import { Countries, Country, countriesMapper, countryMapper } from '@bp/shared/models/countries';
import {
	Control, Default, FieldControlType, FieldViewType, Label, MapFromDTO, Mapper,
	View, ViewEmptyValue, DTO, Required, Entity, Secret
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';

import { PspThreeDsCredentialProvider } from './psp-three-ds-credential-provider';

export type PspThreeDsCredentialKeys = NonFunctionPropertyNames<PspThreeDsCredential>;

export class PspThreeDsCredential extends Entity {

	@MapFromDTO()
	override id!: string;

	@Control(FieldControlType.input)
	override name!: string;

	@Control(FieldControlType.switch)
	@Label('Status')
	@View(FieldViewType.booleanCircle)
	@Default(true)
	override isEnabled!: boolean;

	@MapFromDTO()
	merchantPspId!: string;

	@Required()
	@Secret()
	acquirerMerchantId!: string;

	@Control(FieldControlType.country, {
		typeControlOptions: {
			hasWorldwide: false,
		},
	})
	@Mapper(countryMapper)
	@View(FieldViewType.country)
	@Required()
	merchantCountry!: Country;

	@View(FieldViewType.currencyCode)
	@Control(FieldControlType.autocomplete, {
		list: FiatCurrency.list,
	})
	@Mapper(FiatCurrency)
	@Required()
	merchantCurrency!: FiatCurrency;

	@Control(FieldControlType.countryChips, {
		list: Countries.list,
		typeControlOptions: {
			resetOptionText: 'Any country',
		},
	})
	@Mapper(countriesMapper)
	@View(FieldViewType.boxedItems)
	@Default([])
	@Label('Used for these countries')
	@ViewEmptyValue('Any country')
	countries!: Country[];

	@Mapper(FiatCurrency)
	@Default([])
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
			list: FiatCurrency.list,
			typeControlOptions: {
				resetOptionText: ANY_FIAT_CURRENCY_RESET_OPTION_TEXT,
			},
		},
	)
	@ViewEmptyValue(ANY_FIAT_CURRENCY_RESET_OPTION_TEXT)
	@Label('Used for these currencies')
	currencies!: FiatCurrency[];

	@Mapper(PspThreeDsCredentialProvider)
	@Default([])
	providers!: PspThreeDsCredentialProvider[];

	constructor(dto?: DTO<PspThreeDsCredential>) {
		super(dto);
	}

}
