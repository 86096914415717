import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationFreePlanVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoBalloon;

	title = 'HURRAY! you’re all done!';

	subtitle = 'On your <b>FREE</b> account you can:';

	additionalInfo = `Need more? ${ this._buildPromoPricingPageLink('Check out our tailor-made plans') }`;

	features = [
		this._buildPspsFeature(),
		'Create <b>Routes</b> and use <b>Bridger Retry ™</b>',
		'Manage all your payments from <b>one Admin</b>',
		'Get a <b>consultation call</b> with our PSP Experts',
	];

	constructor(subscriptionPlan: SubscriptionPlan) {
		super(subscriptionPlan);
	}
}
