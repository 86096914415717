import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';

@Component({
	selector: 'bp-payment-card-brands-svg-icons-definitions',
	standalone: true,
	templateUrl: './payment-card-brands-svg-icons-definitions.component.html',
	styles: [ ':host { display: none }' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandsSvgIconsDefinitionsComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment
	PaymentCardBrand = PaymentCardBrand;

}
