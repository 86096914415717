import { camelCase, upperFirst } from 'lodash-es';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { DTO, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

export class CheckoutPaymentMethod extends MetadataEntity {

	@MapFromDTO()
	id!: string;

	@MapFromDTO()
	pspId!: string;

	@MapFromDTO()
	merchantId!: string;

	@Mapper(v => upperFirst(camelCase(v)))
	provider!: string;

	@MapFromDTO()
	name!: string | null;

	@MapFromDTO()
	descriptor!: string;

	@MapFromDTO()
	is3dSecure!: boolean;

	@Mapper(PaymentOptionType)
	type!: PaymentOptionType;

	@Mapper(PaymentCardBrand)
	brands?: PaymentCardBrand[];

	@Mapper(FiatCurrency)
	currencies?: FiatCurrency[];

	@MapFromDTO()
	minAmount!: number;

	@MapFromDTO()
	maxAmount!: number;

	@MapFromDTO()
	amount!: number;

	@MapFromDTO()
	processAsCreditCard!: boolean;

	@MapFromDTO()
	readonly defaultAmount: number;

	constructor(dto?: DTO<CheckoutPaymentMethod>) {
		super(dto);

		this.defaultAmount = this.amount;
	}
}
