import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import type { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HttpEvent } from '@angular/common/http';

import { BpError } from '@bp/frontend/models/core';

import { ResourcePaywallDialogComponent, ResourcePaywallDialogData } from '../components';

const PAYWALL_ERROR_TYPE = 'paywall';

@Injectable()
export class HttpResponsePaywallGuardInterceptor implements HttpInterceptor {
	constructor(private readonly _dialog: MatDialog) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: unknown) => {
				this._onPaywallErrorShowPaywall(error);

				// eslint-disable-next-line rxjs/throw-error
				return throwError(() => error);
			}),
		);
	}

	private _onPaywallErrorShowPaywall(error: unknown): void {
		if (error instanceof BpError && error.messages.some(message => message.type === PAYWALL_ERROR_TYPE)) {
			error.messages = error.messages.filter(message => message.type !== PAYWALL_ERROR_TYPE);

			this._showPaywall();
		}
	}

	private _showPaywall(): void {
		this._dialog.open<ResourcePaywallDialogComponent, ResourcePaywallDialogData, boolean>(
			ResourcePaywallDialogComponent,
		);
	}
}
