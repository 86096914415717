import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationGenericVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoBalloon;

	title = 'Congratulations! Welcome to bridger!';

	subtitle = 'On your account you can:';

	additionalInfo = this._buildPromoPricingPageLink('Check out our tailor-made plans');

	features = [
		'Create <b>Routes</b> and use <b>Bridger Retry ™</b>',
		'Manage all your payments from <b>one Admin</b>',
		'Get a <b>consultation call</b> with our PSP Experts',
	];

	constructor() {
		super();
	}
}
