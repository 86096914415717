/// <reference path="../../../../../../typings.d.ts" />

import jwtDecode, { JwtPayload } from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import { camelCase, mapKeys } from 'lodash-es';

export class JwtToken {

	static encode<T extends JwtPayload>(target: T, secret: string = 'secret'): string {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		return <string>jwtEncode(target, secret);
	}

	static decode<T extends JwtPayload>(token: string): T {
		return <T> <unknown> mapKeys(
			jwtDecode<Object>(token),
			(_v, k) => camelCase(k),
		);
	}

}

export { JwtPayload };
