<bp-input
	[pending]="pending"
	[name]="name"
	[nativeAutocomplete]="nativeAutocomplete"
	[color]="color"
	[label]="label"
	[hint]="hint"
	[longHint]="longHint"
	[required]="!!required"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[autocomplete]="autocomplete"
	[throttle]="throttle"
	[debounce]="debounce"
	[appearance]="appearance"
	[hideErrorText]="hideErrorText"
	[hideClearButton]="hideClearButton"
	[floatLabel]="floatLabel"
	[hasSearchIcon]="hasSearchIcon"
	[autoGrow]="autoGrow"
	[bpTooltip]="_hasDescription(value) ? value.description : null"
>
	<bp-input-hint *ngIf="suggestedItem && suggestedItem !== value" @fade>
		<button
			type="button"
			class="link interactive primary-text mat-caption"
			[bpTooltip]="suggestedItemTooltip"
			(click)="setValue(suggestedItem)"
		>
			{{ suggestedItemButtonTextPrefix }}{{ suggestedItem }}
		</button>
	</bp-input-hint>

	<bp-input-suffix *ngIf="hasChevron">
		<bp-chevron-rotatable-180-degrees [rotated]="_isAutocompleteOpen$ | async" />
	</bp-input-suffix>
</bp-input>

<mat-autocomplete #autocomplete="matAutocomplete" [class]="panelClass || ''">
	<div *ngIf="autocomplete.isOpen" @fadeInListStaggered>
		<mat-option *ngIf="!pending && !(filtered$ | async)?.length" disabled>
			{{ 'No items' | bpTranslate | async }}
		</mat-option>

		<div *ngFor="let item of filtered$ | async">
			<mat-option
				*bpDelayedRender="0; allStagger: true"
				bpTooltipShownOnViewportOverflow
				class="autocomplete-option"
				[class.selected]="item === value"
				[class.has-description]="!_customOptionTpl && _hasDescription(item)"
				[value]="item"
				[bpTooltip]="item | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async"
			>
				<div bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
					@if (_customOptionTpl) {
						<ng-container *ngTemplateOutlet="_customOptionTpl; context: { $implicit: item }" />
					} @else {
						{{ item | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async }}
					}
				</div>

				<div *ngIf="!_customOptionTpl && _hasDescription(item)" class="description mat-caption">
					{{ item.description }}
				</div>
			</mat-option>
		</div>
	</div>
	<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
	<mat-option class="hidden" disabled />
</mat-autocomplete>
