import { BehaviorSubject, combineLatest, map, Observable, of, share, switchMap } from 'rxjs';
import { uniq, without } from 'lodash-es';

import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input,
	Output, ViewChild, EventEmitter
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';

import type { Dictionary } from '@bp/shared/typings';

import { FADE } from '@bp/frontend/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { RightDrawersOrchestratorComponent } from '../right-drawers-orchestrator';

export type RightDrawerRouteGroup = {
	groupRoot?: boolean;
	groupName: string;
};

export type RightDrawerRouteConfiguration = {
	drawerWithNavigationLevel?: boolean;
	drawerOccupiesMostOfSpace?: boolean;
	drawerCompact?: boolean;
	drawerMode?: MatDrawerMode;
	headlessDrawer?: boolean;
	drawersGroups?: RightDrawerRouteGroup[];
};

export function rightDrawerRouteDataOptions(options: RightDrawerRouteConfiguration): RightDrawerRouteConfiguration {
	return options;
}

@Component({
	selector: 'bp-right-drawer',
	templateUrl: './right-drawer.component.html',
	styleUrls: [ './right-drawer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class RightDrawerComponent extends Destroyable {

	static extractDrawerIndex(drawerName: string): number | null {
		const [ , index ] = (/\[(-?\d+)\]$/u).exec(drawerName) ?? [];

		return Number.isNaN(Number(index)) ? null : Number(index);
	}

	@Input()
	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;

		this._extractIndexAndSetIndexRelatedProperties(value);
	}

	private _name!: string;

	@Output() readonly closedStart = new EventEmitter();

	readonly closedStart$ = this.closedStart.asObservable();

	@Output() readonly closed = new EventEmitter();

	readonly closed$ = this.closed.asObservable();

	@Output() readonly backdropClick = new EventEmitter();

	readonly backdropClick$ = this.backdropClick.asObservable();

	@ViewChild('sidenav', { read: ElementRef }) sidenavRef?: ElementRef<HTMLElement>;

	animationEnd$ = new BehaviorSubject<void>(undefined);

	private readonly _fullscreen$ = new BehaviorSubject(false);

	fullscreen$ = this._fullscreen$.asObservable();

	get fullscreen(): boolean {
		return this._fullscreen$.value;
	}

	private readonly _hasBackdrop$ = new BehaviorSubject(false);

	hasBackdrop$ = this._hasBackdrop$.asObservable();

	get hasBackdrop(): boolean {
		return this._hasBackdrop$.value;
	}

	isIndexed = false;

	index!: number | null;

	$host: HTMLElement = this._host.nativeElement;

	get routerOutletComponent(): Object | null {
		return this._routerOutlet?.isActivated ? this._routerOutlet.component : null;
	}

	defaultDrawerMode: MatDrawerMode = 'over';

	protected readonly _routerOutletActivatedComponent$ = new BehaviorSubject<Object | null>(null);

	/**
	 * Observable of the instance of the activated component or null if the dynamic outlet is not activated.
	 */
	readonly routerOutletActivatedComponent$ = this._routerOutletActivatedComponent$.asObservable();

	drawerRouteConfig$: Observable<RightDrawerRouteConfiguration> = this.routerOutletActivatedComponent$.pipe(
		switchMap(component => component
			? this._mergeDrawerRouteParamsAndDataConfigs$()
			: of({})),
		share(),
	);

	drawerRouteConfig: RightDrawerRouteConfiguration = {};

	@ViewChild(MatSidenav) private readonly _sidenav?: MatSidenav;

	@ViewChild('outlet', { static: true, read: RouterOutlet })
	protected _routerOutlet?: RouterOutlet;

	private _klass: Dictionary<boolean> = {};

	private readonly _$host = this._host.nativeElement;

	private _canCloseHandlers: (() => Promise<boolean>)[] = [ async () => Promise.resolve(true) ];

	constructor(
		private readonly _rightDrawersOrchestrator: RightDrawersOrchestratorComponent,
		private readonly _cdr: ChangeDetectorRef,
		private readonly _host: ElementRef<HTMLElement>,
	) {
		super();

		void this._rightDrawersOrchestrator.registerRightDrawer(this);

		this._updateDrawerRouteConfigPropertyOnStreamChange();
	}

	registerCanCloseHandler(canCloseHandler: () => Promise<boolean>): () => Promise<boolean> {
		this._canCloseHandlers = uniq([
			...this._canCloseHandlers,
			canCloseHandler,
		]);

		return canCloseHandler;
	}

	unregisterCanCloseHandler(canCloseHandler: () => Promise<boolean>): void {
		this._canCloseHandlers = without(this._canCloseHandlers, canCloseHandler);
	}

	resetCanCloseHandlers(): void {
		this._canCloseHandlers = [ async () => Promise.resolve(true) ];
	}

	async close({ skipCanCloseCheck }: { skipCanCloseCheck?: boolean } = {}): Promise<boolean> {
		if (!skipCanCloseCheck) {
			const canClose = await this._canClose();

			if (!canClose)
				return false;
		}

		this.closedStart.emit();

		void this._sidenav?.close();

		this._cdr.detectChanges();

		return true;
	}

	private async _canClose(): Promise<boolean> {
		const canCloseHandlersResults = await Promise.all(
			this._canCloseHandlers.map(async canCloseHandler => canCloseHandler()),
		);

		return canCloseHandlersResults.every(Boolean);
	}

	focus(): void {
		this.$host.focus();
	}

	setFullscreen(show: boolean): void {
		if (show === this._fullscreen$.value)
			return;

		this._fullscreen$.next(show);
	}

	setBackdrop(showBackdrop: boolean): void {
		if (showBackdrop === this._hasBackdrop$.value)
			return;

		this._hasBackdrop$.next(showBackdrop);
	}

	setClass(value: Dictionary<boolean>): void {
		Object
			.entries(this._klass = {
				...this._klass,
				...value,
			})
			.forEach(([ token, isAdd ]) => this._$host.classList.toggle(token, isAdd));
	}

	onOpenedChange(opened: boolean): void {
		opened && this.animationEnd$.next();
	}

	onTransitionEnd({ target }: TransitionEvent): void {
		if (target !== this.sidenavRef!.nativeElement)
			return;

		this.animationEnd$.next();
	}

	private _extractIndexAndSetIndexRelatedProperties(drawerName: string): void {
		this.index = RightDrawerComponent.extractDrawerIndex(drawerName);

		this.isIndexed = this.index !== null;
	}

	private _updateDrawerRouteConfigPropertyOnStreamChange(): void {
		this.drawerRouteConfig$
			.pipe(takeUntilDestroyed(this))
			.subscribe(config => (this.drawerRouteConfig = config));
	}

	private _mergeDrawerRouteParamsAndDataConfigs$(): Observable<RightDrawerRouteConfiguration> {
		return combineLatest([
			this._routerOutlet!.activatedRoute.params,
			of(this._routerOutlet!.activatedRouteData),
		]).pipe(
			map(([ paramsConfig, dataConfig ]) => ({ ...dataConfig, ...paramsConfig })),
		);
	}
}
