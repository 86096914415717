import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { CongratulationBaseVm, congratulationVmFactory } from '../../models';

@Component({
	selector: 'bp-subscription-plan-congratulation-content',
	templateUrl: './subscription-plan-congratulation-content.component.html',
	styleUrls: [ './subscription-plan-congratulation-content.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPlanCongratulationContentComponent implements OnChanges {

	@Input() subscriptionPlan!: SubscriptionPlan | null;

	@Input() context: 'merchant-admin' | 'wizard' = 'merchant-admin';

	get isWizardContext(): boolean {
		return this.context === 'wizard';
	}

	congratulationVm!: CongratulationBaseVm;

	assetsDir = 'assets/domains/subscription-plans/congratulation/';

	ngOnChanges({ subscriptionPlan }: SimpleChanges<this>): void {
		if (subscriptionPlan)
			this.congratulationVm = congratulationVmFactory(this.subscriptionPlan);
	}
}
