/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { ActionReducer } from '@ngrx/store';
import { INIT } from '@ngrx/store';

import type { Dictionary } from '@bp/shared/typings';

import type { AppStorageService } from '@bp/frontend/services/storage';

export function appStateRehydratorMetaReducerFactory(appStorageService: AppStorageService) {
	return function(reducer: ActionReducer<any>): ActionReducer<any> {
		return function(state, action) {
			const newState = reducer(state, action);

			return action.type === INIT
				? { ...newState, ...extractStateFromStorage(appStorageService) }
				: newState;
		};
	};
}

function extractStateFromStorage(appStorageService: AppStorageService): Object {
	return appStorageService
		.keys()
		// eslint-disable-next-line unicorn/prefer-object-from-entries
		.reduce(
			(state: Dictionary<any>, storageKey) => {
				const stateKeys = storageKey.split('.');

				let currentStateRef = state;

				stateKeys.forEach((key, index) => {
					if (index === stateKeys.length - 1)
						return (currentStateRef[key] = appStorageService.get(storageKey));

					currentStateRef[key] ||= {};

					currentStateRef = currentStateRef[key];
				});

				return state;
			},
			{},
		);
}
