import m from 'moment';

import { DateRange } from './date-range';
import { DescribableEnumeration } from './enums';

export class DateRangeShortcut extends DescribableEnumeration {

	static today = new DateRangeShortcut();

	static thisWeek = new DateRangeShortcut();

	static thisMonth = new DateRangeShortcut();

	static previousMonth = new DateRangeShortcut();

	static threeMonths = new DateRangeShortcut();

	static thisQuarter = new DateRangeShortcut();

	static thisYear = new DateRangeShortcut();

	static currentRangeShortcuts = [
		DateRangeShortcut.thisWeek,
		DateRangeShortcut.thisMonth,
		DateRangeShortcut.thisQuarter,
		DateRangeShortcut.thisYear,
	];

	get dateRange(): DateRange {
		// the moment state can be changed at any time
		// so each request the date shortcut date range should return the last state possible
		// e.g on changing timezone via the UI or the locale setup
		return this._getDateRange()!;
	}

	constructor() {
		super();

		void Promise
			.resolve()
			.then(() => {
				if (DateRangeShortcut.currentRangeShortcuts.includes(this))
					// @ts-expect-error should be readonly
					this.description = `From the beginning of ${ this.displayName.toLowerCase() } to this day`;
			});
	}

	private _getDateRange() {
		const format = 'D MMM YYYY';
		const to = m().endOf('day');

		// eslint-disable-next-line default-case
		switch (this) {
			case DateRangeShortcut.today:
				return new DateRange(
					{
						to,
						from: m().startOf('day'),
					},
					format,
				);

			case DateRangeShortcut.thisWeek:
				return new DateRange(
					{
						to,
						from: m().startOf('week'),
					},
					format,
				);

			case DateRangeShortcut.thisMonth:
				return new DateRange(
					{
						to,
						from: m().startOf('month'),
					},
					format,
				);

			case DateRangeShortcut.threeMonths:
				return new DateRange(
					{
						to,
						from: m()
							.subtract(2, 'month')
							.startOf('month'),
					},
					format,
				);

			case DateRangeShortcut.previousMonth:
				return new DateRange(
					{
						to: m()
							.startOf('month')
							.subtract(1, 'second'),
						from: m()
							.subtract(1, 'month')
							.startOf('month'),
					},
					format,
				);

			case DateRangeShortcut.thisQuarter:
				return new DateRange(
					{
						to,
						from: m().startOf('quarter'),
					},
					format,
				);

			case DateRangeShortcut.thisYear:
				return new DateRange(
					{
						to,
						from: m().startOf('year'),
					},
					format,
				);
		}

		throw new Error('DateRange for the DateRangeShortcut is not implemented');
	}
}
