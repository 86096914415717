import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, Output, OnInit } from '@angular/core';

import { trackById } from '@bp/shared/utilities/core';

import { FADE_IN_SEMI_SLOW } from '@bp/frontend/animations';
import { PspPaymentOptionType } from '@bp/frontend/models/business';
import { BridgerPsp } from '@bp/frontend/domains/bridger-psps/core';

@Component({
	selector: 'bp-bridger-psps-multipicker',
	templateUrl: './bridger-psps-multipicker.component.html',
	styleUrls: [ './bridger-psps-multipicker.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_SEMI_SLOW ],
})
export class BridgerPspsMultipickerComponent implements OnInit {

	// we use IDs instead of objects object references can be different
	private readonly _selected$ = new BehaviorSubject<Set<string>>(new Set<string>());

	@Input()
	get providers() {
		return this._providers;
	}

	set providers(value: BridgerPsp[] | null) {
		this._providers = value || [];
	}

	private _providers!: BridgerPsp[];

	@Input() selectable = true;

	@Input() paymentOptionType!: PspPaymentOptionType;

	@Input() initiallySelected?: BridgerPsp[] | null;

	@Output('selected') readonly selected$ = this._selected$.pipe(skip(1));

	trackById = trackById;

	get selected(): Set<string> {
		return this._selected$.value;
	}

	ngOnInit(): void {
		this._selected$.next(new Set(this.initiallySelected?.map(psp => psp.id!) ?? []));
	}

	toggleProvider(provider: BridgerPsp): void {
		if (this.selected.has(provider.id!))
			this.selected.delete(provider.id!);
		else
			this.selected.add(provider.id!);

		this._selected$.next(new Set(this.selected));
	}

}
