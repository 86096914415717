import { DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class SecurityQuestion extends MetadataEntity {

	@MapFromDTO()
	id!: number;

	@MapFromDTO()
	text!: string;

	constructor(dto: DTO<SecurityQuestion>) {
		super(dto);
	}

	 toJSON(): number {
		return this.id;
	}
}
