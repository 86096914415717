import { isArray, isPresent } from '@bp/shared/utilities/core';

import { Feature } from './feature';
import { FeatureAction } from './feature-actions';
import { FeaturePermission } from './feature-permission';

export type FeaturePermissions<TFeature extends Feature> = Map<FeatureAction | TFeature, FeaturePermission<TFeature>>;

export function featurePermissionsMapFactory<TFeature extends Feature>(
	parser: (claim: string) => TFeature | null,
) {
	return (permissionsInput: FeaturePermissions<TFeature> | string[] | string): FeaturePermissions<TFeature> => {
		if (permissionsInput instanceof Map)
			return permissionsInput;

		const claims = isArray(permissionsInput)
			? permissionsInput
			: [ permissionsInput ];

		return new Map(
			claims
				.map((claim: string): [TFeature | symbol, FeaturePermission<TFeature>] | null => {
					try {
						const featurePermission = new FeaturePermission(claim, parser);

						return [ featurePermission.permission, featurePermission ];
					} catch (error: unknown) {
						console.error(error);

						return null;
					}
				})
				.filter(isPresent),
		);
	};
}

export function featurePermissionFactory<TFeature extends Feature>(
	claim: string,
	parser: (claim: string) => TFeature | null,
): FeaturePermission<TFeature> | null {
	try {
		return new FeaturePermission(claim, parser);
	} catch (error: unknown) {
		console.error(error);

		return null;
	}
}
