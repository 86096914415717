import { delay, distinctUntilChanged, filter, map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Type, Injectable } from '@angular/core';
import { NavigationExtras, Params, ActivatedRoute, NavigationError, NavigationStart, Router, NavigationEnd, NavigationCancel } from '@angular/router';

import { obscureSensitiveDataInUrl } from '@bp/shared/models/core';

import { UrlHelper } from '@bp/frontend/utilities/common';

@Injectable({
	providedIn: 'root',
})
export class RouterService {

	navigationStart$ = this.ngRouter.events.pipe(
		filter((v): v is NavigationStart => v instanceof NavigationStart),
		share(),
	);

	navigationEnd$ = this.ngRouter.events.pipe(
		filter((v): v is NavigationEnd => v instanceof NavigationEnd),
		share(),
	);

	sanitizedUrlAndTitleOnNavigationEnd$ = this.navigationEnd$.pipe(
		delay(25), // time for the title to settle
		map(({ url }) => ({
			url: obscureSensitiveDataInUrl(url),
			title: document.title,
		})),
	);

	private _isNavigationInProcess = false;

	get isNavigationInProcess(): boolean {
		return this._isNavigationInProcess || !!this.ngRouter.getCurrentNavigation();
	}

	constructor(
		public ngRouter: Router,
		public route: ActivatedRoute,
	) {
		this._onNavigationErrorNavigateToErrorPage();

		this._onNavigationFinishResetNavigationInProcessFlag();
	}

	async navigate(
		commands: any[],
		extras: (NavigationExtras & { relativeToCmpt?: Type<any> }) = {},
	): Promise<boolean> {
		const relativeTo = extras.relativeTo
			?? (extras.relativeToCmpt && <ActivatedRoute> UrlHelper.getComponentActivatedRoute(this.route, extras.relativeToCmpt));

		return this.ngRouter.navigate(commands, { ...extras, relativeTo });
	}

	/**
	 * Using router link doesn't respect current matrix params on the url and rewrites the existing
	 * so this method will preserve
	 */
	async changeLastPrimaryRouteMatrixParams(params: Params, relativeTo: ActivatedRoute): Promise<boolean> {
		return this.ngRouter.navigate(
			[ UrlHelper.mergeLastPrimaryRouteSnapshotParamsWithSourceParams(relativeTo, params) ],
			{ relativeTo },
		);
	}

	async closeOutlet(outlet: string): Promise<boolean> {
		return this.ngRouter.navigateByUrl(UrlHelper.buildUrlExcludingOutlet(outlet, this.ngRouter));
	}

	onPrimaryComponentNavigationEnd(routeComponentType: Type<any>): Observable<void> {
		return <Observable<void>><unknown> this.ngRouter.events.pipe(
			filter(navEvent => navEvent instanceof NavigationEnd),
			map(() => UrlHelper.getLastPrimaryRoute(this.route).component),
			distinctUntilChanged(),
			filter(it => it === routeComponentType),
		);
	}

	onNavigationEndToRouteComponent(routeComponentType: Type<any>, navEventId?: number): Observable<ActivatedRoute> {
		return this.ngRouter.events.pipe(
			filter(navEvent => navEvent instanceof NavigationEnd && (navEventId ? navEventId === navEvent.id : true)),
			map(() => <ActivatedRoute | null> UrlHelper.getComponentActivatedRoute(this.route, routeComponentType)),
			distinctUntilChanged((p, q) => p?.routeConfig === q?.routeConfig),
			filter((v): v is ActivatedRoute => v?.component === routeComponentType),
		);
	}

	onNavigationEndFromRouteComponent(routeComponentType: Type<any>): Observable<NavigationEnd> {
		return this.ngRouter.events.pipe(
			filter((navEvent): navEvent is NavigationEnd => navEvent instanceof NavigationEnd
				&& !UrlHelper.getComponentActivatedRoute(this.route, routeComponentType)?.component),
		);
	}

	markAsNavigationInProcess(): void {
		this._isNavigationInProcess = true;
	}

	private _onNavigationErrorNavigateToErrorPage(): void {
		this.ngRouter.events

			/*
			 * The request prop means that an error has occurred on loading a lazy module,
			 * so we just generalize and send to the error page
			 */
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			.pipe(filter(navEvent => navEvent instanceof NavigationError && navEvent.error.request))
			.subscribe(() => void this._navigateToErrorPage());
	}

	private _navigateToErrorPage(): void {
		void this.ngRouter.navigate([ '/error' ], { replaceUrl: false, skipLocationChange: true });
	}

	private _onNavigationFinishResetNavigationInProcessFlag(): void {
		const finishEventClasses = [ NavigationEnd, NavigationError, NavigationCancel ];

		this.ngRouter.events
			.pipe(filter(routerEvent => finishEventClasses.some(eventClass => routerEvent instanceof eventClass)))
			.subscribe(() => (this._isNavigationInProcess = false));
	}

}
