<a
	mat-list-item
	class="nav-item nav-list-item"
	@fadeIn
	[routerLink]="path"
	[routerLinkActive]="path ? 'active' : ''"
	[routerLinkActiveOptions]="{ exact: true }"
	(click)="layoutFacade.closeSidenav(); navigate.emit()"
>
	<bp-svg-icon *ngIf="icon" matListIcon [name]="icon" />

	<span mat-line>
		<ng-content />
	</span>
</a>
