<form *ngIf="form" class="round-controls compact" [formGroup]="form" (ngSubmit)="submit()">
	<button type="submit" class="hidden"></button>

	<bp-alert type="danger" [errors]="errors" />

	<bp-payment-card-holder-input
		*ngIf="metadata.properties.paymentCardHolderName as propertyMetadata"
		[formControlName]="propertyMetadata.property"
		[required]="propertyMetadata.control.required"
		[label]="propertyMetadata.label"
	/>

	<bp-payment-card-number-input
		*ngIf="metadata.properties.paymentCardNumber as propertyMetadata"
		[formControlName]="propertyMetadata.property"
		[required]="propertyMetadata.control.required"
		[label]="propertyMetadata.label"
		(paymentCardBrandChange)="paymentCardBrand = $event"
	/>

	<div class="row">
		<bp-payment-card-expire-input
			*ngIf="metadata.properties.paymentCardExpirationDate as propertyMetadata"
			[formControlName]="propertyMetadata.property"
			[required]="propertyMetadata.control.required"
			[label]="propertyMetadata.label"
		/>

		<bp-payment-card-cvv-input
			*ngIf="metadata.properties.paymentCardCvv as propertyMetadata"
			[paymentCardBrand]="paymentCardBrand"
			[formControlName]="propertyMetadata.property"
			[required]="propertyMetadata.control.required"
			[label]="propertyMetadata.label"
		/>
	</div>

	<bp-property-metadata-controls-section [form]="form" [metadata]="metadata" [sectionScheme]="baseUiScheme" />

	<div class="row">
		<bp-property-metadata-control
			class="w-100"
			[metadata]="metadata.properties.country!"
			[control]="controls!.country"
		/>

		<div>
			<bp-property-metadata-control
				*ngIf="countryHasStates"
				@fade
				[metadata]="metadata.properties.state!"
				[items]="selectedCountry!.states!"
				[control]="$any(controls!.state)"
			/>

			<bp-property-metadata-control
				*ngIf="countryVatConfig"
				@fade
				[metadata]="metadata.properties.vatId!"
				[control]="controls!.vatId"
			/>
		</div>
	</div>

	<p class="privacy mat-caption">
		Your privacy is super important for us - we'll only use your information as described in our
		<a href="https://bridgerpay.com/terms-of-use" class="link" bpTargetBlank>Terms of use</a>
		and
		<a href="https://bridgerpay.com/privacy-policy" class="link" bpTargetBlank>Privacy policy</a>.
	</p>
</form>
