import { Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import {
	HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse, HttpContext, HttpContextToken
} from '@angular/common/http';

import { FIREBASE_SETTINGS } from '@bp/frontend/features/firebase/settings';

import { IdentityFacade, AUTH_PATH_SEGMENT } from '@bp/admins-shared/domains/identity';

const bypassRefreshTokenUpdateContextToken = new HttpContextToken<boolean>(() => false);

export function bypassRefreshToken(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(bypassRefreshTokenUpdateContextToken, true);
}

@Injectable()
export class HttpPostRequestIdentitySessionRefreshInterceptor implements HttpInterceptor {
	constructor(private readonly _identityFacade: IdentityFacade) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const shouldRefreshAccessTokenAfterSuccessPost
			= !request.url.includes(AUTH_PATH_SEGMENT)
			&& !request.url.includes(FIREBASE_SETTINGS.functionsURL)
			&& [ 'DELETE', 'POST' ].includes(request.method)
			&& !request.context.get(bypassRefreshTokenUpdateContextToken);

		return next.handle(request).pipe(
			tap(response => {
				if (shouldRefreshAccessTokenAfterSuccessPost && response instanceof HttpResponse)
					this._identityFacade.refreshAccessToken();
			}),
		);
	}
}
