import sort from 'sort-array';
import { isArray, isBoolean, isNumber } from 'lodash-es';

import { isDictionaryObject } from '@bp/shared/utilities/core';

import { ISortQueryParams } from './query-params';

export function sortArray<T extends Object>(
	array: T[],
	{ sortField, sortDir }: ISortQueryParams,
): T[] {
	if (!sortField)
		return array;

	return sort(array, {
		by: 'computed',
		order: sortDir,
		computed: {
			computed: item => {
				if (isDictionaryObject(item)) {
					const value = item[sortField];

					if (isArray(value))
						return value.length;

					if (isNumber(value))
						return value;

					if (isBoolean(value))
						return value ? 1 : 0;

					return <any>value;
				}

				return <any>item;
			},
		},
	});
}
