import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SpinningLogoComponent } from '@bp/frontend/components/spinning-logo';

import { PaymentCardBrandsComponent } from './payment-card-brands';

const EXPOSED_COMPONENTS = [
	PaymentCardBrandsComponent,
	SpinningLogoComponent,
];

@NgModule({
	imports: [
		EXPOSED_COMPONENTS,

		CommonModule,

		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
		SharedFeaturesTooltipModule,
	],
	exports: EXPOSED_COMPONENTS,
})
export class AdminsSharedCoreComponentsModule { }
