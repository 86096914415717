import {
	Control, Default, EntityBase, FieldControlType, FieldViewType, Label, MapFromDTO,
	View, DTO, Required, Mapper, Validator, Secret
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { Validators } from '@bp/shared/features/validation/models';

export type PspThreeDsCredentialProviderKeys = NonFunctionPropertyNames<PspThreeDsCredentialProvider>;

export class PspThreeDsCredentialProvider extends EntityBase {

	static readonly brands = [
		PaymentCardBrand.visa,
		PaymentCardBrand.masterCard,
		PaymentCardBrand.americanexpress,
		PaymentCardBrand.jcb,
		PaymentCardBrand.discover,
		PaymentCardBrand.unionpay,
		PaymentCardBrand.mir,
		PaymentCardBrand.eftpos,
		PaymentCardBrand.bcard,
	];

	@MapFromDTO()
	override id!: string;

	@Mapper(PaymentCardBrand)
	@Control(FieldControlType.brand, {
		required: true,
		list: PspThreeDsCredentialProvider.brands,
	})
	@Required()
	provider!: PaymentCardBrand;

	@Secret()
    overrideAcquirerMerchantId!: string;

	@Required()
	@Secret()
	acquirerBin!: string;

	@Required()
	@Validator(Validators.maxLength(4))
	acquirerMerchantCategoryCode!: string;

	@Control(FieldControlType.switch)
	@Label('Status')
	@View(FieldViewType.booleanCircle)
	@Default(true)
	override isEnabled!: boolean;

	constructor(dto?: DTO<PspThreeDsCredentialProvider>) {
		super(dto);

		if (dto?.provider)
			this.name = this.provider.displayName;
	}

}
