import { AdminFeature } from '../admin-feature';
import { CommonFeatureActions, EditAndAccessFeatureActions } from '../feature-actions';
import {
	OrganizationsFeatureActions, SupportRequestsFeatureActions, UsersFeatureActions,
	WizardSetupsFeatureActions
} from '../bridger-admin';
import { MembersFeatureActions } from '../merchant-admin';

export class BridgerAdminFeature<T extends CommonFeatureActions = CommonFeatureActions> extends AdminFeature<T> {

	static baBridgerPsps = new BridgerAdminFeature(EditAndAccessFeatureActions);

	static baBridgerPspSystemDetails = new BridgerAdminFeature(EditAndAccessFeatureActions);

	static baSupportRequests = new BridgerAdminFeature(SupportRequestsFeatureActions);

	static baOrganizations = new BridgerAdminFeature(OrganizationsFeatureActions);

	static baWizardSetups = new BridgerAdminFeature(WizardSetupsFeatureActions);

	static baUsers = new BridgerAdminFeature(UsersFeatureActions);

	static baMembers = new BridgerAdminFeature(MembersFeatureActions);

	// region Signup Via Link Workflow

	static baAcceptInvite = new BridgerAdminFeature(CommonFeatureActions);

	static baLoginAfterInvite = new BridgerAdminFeature(CommonFeatureActions);

	static baCreateAccount = new BridgerAdminFeature(CommonFeatureActions);

	// endregion

}
