import { map, startWith } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { FADE, FADE_IN, ROUTE_HIT_ANIMATIONS } from '@bp/frontend/animations';
import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { SupportAgentDetectorService } from '@bp/frontend/services/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { LayoutFacade } from '@bp/admins-shared/features/layout';

import { CurrentOrganizationFacade } from '@bp/merchant-admin/frontend/domains/current-organization';
import { CurrentOrganizationSubscriptionFacade } from '@bp/merchant-admin/frontend/domains/current-organization-subscription';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS, FADE, FADE_IN ],
})
export class RootComponent {

	protected readonly _isSupportAgent = inject(SupportAgentDetectorService).isSupportAgent;

	protected readonly _layoutFacade = inject(LayoutFacade);

	protected readonly _identityFacade = inject(IdentityFacade);

	protected readonly _currentOrganizationFacade = inject(CurrentOrganizationFacade);

	protected readonly _currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	protected readonly _currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	protected _shouldAnimateOutlet!: boolean;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	protected readonly _MerchantAdminFeature = MerchantAdminFeature;

	protected _isMainNavigationEnabled$
		= this._currentOrganizationSubscriptionFacade.isMustPurchaseSubscriptionPlan$.pipe(
			map(isMustPurchaseSubscriptionPlan => !isMustPurchaseSubscriptionPlan),
			startWith(true),
		);

}
