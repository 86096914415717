<mat-sidenav
	#sidenav
	position="end"
	class="right-drawer-sidenav"
	[class.fullscreen]="fullscreen$ | async"
	[class.with-navigation-level]="(drawerRouteConfig$ | async)?.drawerWithNavigationLevel"
	[class.occupies-most-of-space]="(drawerRouteConfig$ | async)?.drawerOccupiesMostOfSpace"
	[class.compact]="(drawerRouteConfig$ | async)?.drawerCompact"
	[class.headless]="(drawerRouteConfig$ | async)?.headlessDrawer"
	[opened]="outlet.isActivated"
	[mode]="(drawerRouteConfig$ | async)?.drawerMode ?? defaultDrawerMode"
	[autoFocus]="true"
	[fixedInViewport]="true"
	[fixedTopGap]="55"
	[disableClose]="true"
	(openedChange)="onOpenedChange($event)"
	(transitionend)="onTransitionEnd($event)"
	(closed)="closed.emit()"
>
	<div *ngIf="hasBackdrop$ | async" class="backdrop" @fade (click)="backdropClick.emit()"></div>

	<button class="btn-icon-close" bpTooltip="Close (ESC)" mat-icon-button tabindex="-1" (click)="close()">
		<bp-svg-icon name="close" />
	</button>

	<router-outlet #outlet="outlet" [name]="name" (activate)="_routerOutletActivatedComponent$.next($event)" />
</mat-sidenav>
