<mat-button-toggle-group
	class="dual-button-toggle-group-rounded"
	[value]="selectedChargePeriod"
	(valueChange)="selectedChargePeriodChange.emit($event)"
>
	<mat-button-toggle *ngFor="let chargePeriod of chargePeriods" [value]="chargePeriod">
		{{ chargePeriod.displayName }}
	</mat-button-toggle>

	<div class="discount">
		<bp-svg-icon name="curly-arrow" class="bp-icon-xl" />
		<div class="tag mat-caption font-weight-extra-bold">
			SAVE UP TO
			{{ annualDiscount | percent }}
		</div>
	</div>
</mat-button-toggle-group>
