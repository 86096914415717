import { ICalendlyOptions } from '@bp/frontend/features/calendly';

import { BookDemoCalendarKind } from './book-demo-calendar-kind.enum';

export const BOOK_DEMO_CALENDAR_OPTIONS_STAGING: ICalendlyOptions = {
	accountId: 'bridgerpaydemo',
	eventId: 'bridgerpay-merchant-admin-demo',
};

export const DEFAULT_BOOK_DEMO_CALENDAR_OPTIONS_PER_KIND = new Map<BookDemoCalendarKind, ICalendlyOptions>([
	[
		BookDemoCalendarKind.bdm,
		{
			accountId: 'd/dps-sc5-5mm',
			eventId: 'bridgerpay-call',
		},
	],
	[
		BookDemoCalendarKind.bdr,
		{
			accountId: 'd/d7g-36g-qvr',
			eventId: 'bridgerpay-demo',
		},
	],
]);
