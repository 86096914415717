import {
	camelCase, forIn, forOwn, isBoolean, isNil, isNumber, isString, kebabCase, lowerCase, snakeCase, upperFirst
} from 'lodash-es';

import { sentenceCase, uuid } from '@bp/shared/utilities/core';

export type GetEnumerationLiterals<T extends typeof Enumeration> = Exclude<
{
	[ K in keyof T ]-?: T[ K ] extends (Function | null | undefined) ? never : K
}[ keyof T ],
'prototype'
>;

export interface IEnumeration {

	name: string;

	displayName: string;

	valueOf: () => boolean | number | string;

}

/*
 * We use static this in this context because we want to use
 * The inherited static this scope,
 */
export class Enumeration implements IEnumeration {

	static parseHook?: (value: any) => Enumeration | null;

	// eslint-disable-next-line @typescript-eslint/prefer-readonly
	private static __list: any[];

	private static __isValue(v: unknown): boolean {
		return isNumber(v) || isBoolean(v);
	}

	static getList<T extends typeof Enumeration>(this: T): InstanceType<T>[] {
		if (isNil(this.__list)) {
			const list: T[] = [];

			forIn(this, (it, key) => {
				if (it instanceof Enumeration && Number.isNaN(Number(key)) && this._shouldList(it))
					list.push(<any> it);
			});

			this.__list = list;
		}

		return this.__list;
	}

	static find<T extends typeof Enumeration>(this: T, value: number | string): InstanceType<T> | null {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (<any> this)[value] || null;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static parse<T extends typeof Enumeration>(this: T, value: any): InstanceType<T> | null {
		if (isNil(value))
			return null;

		if (value instanceof this.prototype.constructor)
			return <InstanceType<T>> value;

		const customParserResult = this.parseHook?.(value);

		if (customParserResult)
			return <InstanceType<T>> customParserResult;

		return this.find(this.__isValue(value) ? value : camelCase(value));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static parseStrict<T extends typeof Enumeration>(this: T, value: any): InstanceType<T> {
		const result = this.parse(value);

		if (!result) {
			throw new Error(`
	Enum type \`${ this.name }\` does not contain value \`${ value }\`.
	Valid values are: ${ this.getList().join(', ') }
			`);
		}

		return result;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static isInstance<T extends typeof Enumeration>(this: T, value: any): value is T {
		return value instanceof this;
	}

	protected static _shouldList(_value: Enumeration): boolean {
		return true;
	}

	get displayName(): string {
		return this.__displayName ?? this.name;
	}

	get titleDisplayName(): string {
		return (this._titleDisplayName ??= this.__hasCustomDisplayName
			? this.displayName
			: sentenceCase(this.displayName));
	}

	private _titleDisplayName?: string;

	get name(): string {
		return this.value.toString();
	}

	get kebabCase(): string {
		return (this.__kebabCase ??= kebabCase(this.name));
	}

	private __kebabCase?: string;

	get snakeCase(): string {
		return (this.__snakeCase ??= snakeCase(this.name));
	}

	private __snakeCase?: string;

	get value(): string {
		return (this.__value ??= this.__getValueName());
	}

	private __value?: string;

	get index(): number {
		return (this.__index ??= (<typeof Enumeration> this.constructor).getList().indexOf(this));
	}

	private __index?: number;

	cssClass!: string;

	private __displayName?: string;

	private readonly __id = `enum_${ uuid({ short: true }) }`;

	private readonly __hasCustomDisplayName: boolean;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	constructor(displayName?: any, ..._args: any[]) {
		this.__assertDisplayName(displayName);

		this.__displayName = displayName;

		this.__hasCustomDisplayName = !isNil(displayName);

		/*
		 * Schedule a microtask at the end of the current event loop
		 * which means that the constructor will have all the enumerations attached to it by the time
		 * the callback is fired, and we are able to find by the id of the enum its name amidst the static properties
		 * PS we can't use native queryMicrotask since it fires the microtask after the dom is rendered.
		 * and we need the enums to be inited before any components are rendered
		 */
		void Promise
			.resolve()
			.then(() => void this._init());
	}

	private __assertDisplayName(displayName: any): asserts displayName is string | undefined {
		if ((displayName !== undefined) && !isString(displayName) || (displayName === ''))
			throw new Error('Invalid displayName argument type, must be non-empty string or undefined');
	}

	valueOf(): string {
		return this.value;
	}

	toString(): string | undefined {
		return this.name;
	}

	toJSON(): string {
		return this.value;
	}

	protected _init(): void {
		this.cssClass = this.__getCssClass();

		this.__displayName ??= upperFirst(lowerCase(this.name));
	}

	private __getCssClass(): string {

		/*
		 * TODO Angular CLI mangles the names of class constructors which is used for generating cssClass, check
		 * somewhere later
		 * return `${kebabCase(this.constructor.name)}-${kebabCase(this.name)}`;
		 */
		return kebabCase(this.name);
	}

	private __getValueName(): string {
		let result = '';

		forOwn(this.constructor, (it, key) => {
			if (it instanceof Enumeration && it.__id === this.__id && Number.isNaN(Number(key))) {
				result = key;

				return false;
			}

			return true;
		});

		return result;
	}
}
