import { camelCase, get } from 'lodash-es';

import type { Feature } from './feature';
import type { FeatureAction } from './feature-actions';
import { ClaimLifetimeType } from './claim-lifetime-type.enum';

export class FeaturePermission<TFeature extends Feature> {

	private static readonly __cache = new Map<string, FeaturePermission<any>>();

	readonly permission!: FeatureAction | TFeature;

	readonly featureName!: string;

	readonly featureDisplayName!: string;

	readonly isOtpRequired: boolean = false;

	readonly lifetimeType: ClaimLifetimeType | null = null;

	readonly isBehindPaywall: boolean = false;

	readonly isAction: boolean = false;

	constructor(

		/**
		 * a string defined by the back looks like `featureName.access` or `featureName.actionName`
		 * or `featureName.access.otp_required__short_ttl` or `featureName.actionName.otp_required__single_use`
		 */
		public readonly claim: string,
		private readonly _featureParser: (featureName: string) => TFeature | null,
	) {
		this.claim = claim.replace(/_access$/ug, '.access'); // handle backend contract breakage

		if (FeaturePermission.__cache.has(claim))
			return FeaturePermission.__cache.get(claim)!;

		const [ featureName, featureActionName, modificatorLiteral ] = <[string, string, string | undefined]> this.claim.split('.');

		const feature = this._featureParser(camelCase(featureName));

		if (feature === null)
			throw new Error(`Unknown feature permission: "${ featureName }"`);

		const actionName = camelCase(featureActionName);
		const action = <FeatureAction | undefined>get(feature.actions, actionName);

		if (action === undefined) {
			throw new Error(
				`Unknown feature action: "${ featureActionName }" of feature: "${ featureName }"`,
			);
		}

		this.isAction = action !== feature.actions.access;

		this.permission = this.isAction ? action : feature;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this.featureDisplayName = this.isAction
			? feature.actions.classMetadata.get(<any>actionName)!.label!
			: feature.displayName.toLowerCase();

		this.featureName = featureName;

		if (modificatorLiteral) {
			const [ modificator, modificatorType ] = modificatorLiteral.split('__');

			this.isBehindPaywall = modificator === 'paywall';

			this.isOtpRequired = modificator === 'otp_required';

			if (this.isOtpRequired)
				this.lifetimeType = ClaimLifetimeType.parseStrict(modificatorType);
		}

		FeaturePermission.__cache.set(claim, this);
	}

	toJSON(): string {
		return this.claim;
	}

	valueOf(): string {
		return this.claim;
	}

	toString(): string {
		return this.claim;
	}
}
