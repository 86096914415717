import { createReducer, on } from '@ngrx/store';

import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { BpError } from '@bp/frontend/models/core';

import { loadAllFailure, loadAllSuccess } from './subscription-plans-shared-api.actions';
import { loadAll, resetState } from './subscription-plans-shared.actions';

export const FEATURE_KEY = '[feature]:subscription-plans-shared';

export interface IState {

	all: SubscriptionPlan[] | null;

	loading: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	all: null,
	loading: false,
	error: null,
};

export const reducer = createReducer(

	initialState,

	on(resetState, (): IState => initialState),

	on(loadAll, (state): IState => ({
		...state,
		loading: true,
	})),

	on(loadAllSuccess, (state, { result }): IState => ({
		...state,
		all: result.records,
		loading: true,
		error: null,
	})),

	on(loadAllFailure, (state, { error }): IState => ({
		...state,
		error,
		loading: false,
	})),

);
