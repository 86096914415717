import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

import { CongratulationBaseVm } from './congratulation-base-vm';
import { LottieAnimationEnum } from './lottie-animation.enum';

export class CongratulationProPlanVm extends CongratulationBaseVm {

	logoLottieAnimation = LottieAnimationEnum.logoCart;

	title = 'WOW! YOU’RE A PRO NOW!';

	subtitle = 'Get ready to rock your payments with:';

	additionalInfo = this._buildPromoPricingPageLink();

	features = [
		this._buildPspsFeature(),
		this._buildCheckoutsFeature(),
		this._buildFreeIntegratedPspsFeature(),
		'Subscriptions and <b>Google Pay/Apple Pay</b>',
		'<b>Rules</b> to optimize payments further',
	];

	constructor(subscriptionPlan: SubscriptionPlan) {
		super(subscriptionPlan);
	}
}
