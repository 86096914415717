import { isNil, mapValues, snakeCase } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JsonNamingStrategy, JSON_NAMING_STRATEGY_HEADER, SORT_FIELD } from '@bp/shared/models/common';

import { takeFirstTruthy, waitUntilNetworkIsOnline$ } from '@bp/frontend/rxjs';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { HttpBaseInterceptorService } from './http-base.interceptor.service';
import { CF_WORKER_PATH_SEGMENT } from './http-config.service';

const bypassAuthorizationHeaderCheckContextToken = new HttpContextToken<boolean>(() => false);

export function bypassAuthorizationHeaderCheck(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(bypassAuthorizationHeaderCheckContextToken, true);
}

const skipAllHttpConfigHeadersContextToken = new HttpContextToken<boolean>(() => false);

export function skipAllHttpConfigHeaders(context?: HttpContext): HttpContext {
	return (context ?? new HttpContext()).set(skipAllHttpConfigHeadersContextToken, true);
}

const jsonNamingStrategyToken = new HttpContextToken<JsonNamingStrategy | null>(() => null);

export function setJsonNamingStrategyHeader(
	jsonNamingStrategy: JsonNamingStrategy | `${ JsonNamingStrategy }`,
	context?: HttpContext,
): HttpContext {
	return (context ?? new HttpContext()).set(jsonNamingStrategyToken, jsonNamingStrategy);
}

@Injectable()
export class HttpRequestInterceptorService extends HttpBaseInterceptorService implements HttpInterceptor {

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return this.__isRequestRequiresAuthorizationHeader(request)
			? this._httpConfigService.hasAuthorizationToken$.pipe(
				switchMap(() => this.__enhanceRequest(request, next)),
			)
			: this.__enhanceRequest(request, next);
	}

	private __isRequestRequiresAuthorizationHeader(request: HttpRequest<unknown>): boolean {
		return !(/.*\.json$/u).test(request.url)
			&& !request.url.includes('auth')
			&& !request.url.startsWith(`${ CF_WORKER_PATH_SEGMENT }/`)
			&& !request.context.get(bypassAuthorizationHeaderCheckContextToken)
			&& !request.context.get(skipAllHttpConfigHeadersContextToken);
	}

	private __enhanceRequest(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const url = this.__buildRequestUrlWithBackendBaseSegment(request);

		const httpConfig = this._httpConfigService.getApiEndpointNamespaceConfig(url);

		return forkJoin([
			MockedBackendState.isNoApiMockPluginsIniting$.pipe(takeFirstTruthy),
			waitUntilNetworkIsOnline$(),
		])
			.pipe(concatMap(() => next.handle(request.clone({
				url,
				body: this._buildHttpEventBody(request, httpConfig),
				params: this.__buildRequestParams(request),
				setHeaders: this.__buildRequestHeaders(request),
			}))));
	}

	private __buildRequestHeaders(
		request: HttpRequest<unknown>,
	): {[name: string]: string } {
		if (request.context.get(skipAllHttpConfigHeadersContextToken))
			return {};

		return {
			...mapValues(
				this._httpConfigService.headers,
				(httpConfigHeaderValue, headerName: string) => request.headers.get(headerName)
				?? httpConfigHeaderValue
				?? '',
			),
			[JSON_NAMING_STRATEGY_HEADER]: request.context.get(jsonNamingStrategyToken)
				?? request.headers.get(JSON_NAMING_STRATEGY_HEADER)
				?? this._httpConfigService.headers[JSON_NAMING_STRATEGY_HEADER]
				?? '',
			'Turnstile-Token': this._turnstileService.token ?? '',
		};
	}

	private __buildRequestUrlWithBackendBaseSegment(request: HttpRequest<unknown>): string {
		return this.__shouldPrependBackendBaseSegmentToHttpRequestUrl(request)
			? `${ this._httpConfigService.backendBaseSegment }/${ request.url.replace(/^\//u, '') }`
			: request.url;
	}

	private __buildRequestParams({ params }: HttpRequest<unknown>): HttpParams {
		if (params.has(SORT_FIELD))
			// bridgerpay backend can digest only if field name in snake case
			params = params.set(SORT_FIELD, snakeCase(params.get(SORT_FIELD)!));

		const httpParamsNames = params instanceof HttpParams
			? params.keys()
			: Object.keys(params);

		const getValueByKey = (k: string): string | null => params instanceof HttpParams
			? params.get(k)
			: params[k];

		return new HttpParams({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			fromObject: Object.fromEntries(
				httpParamsNames
					.map(httpParamName => [
						httpParamName,
						getValueByKey(httpParamName),
					])
					.map(([ httpParamName, httpParamValue ]) => [
						httpParamName,
						isNil(httpParamValue) ? httpParamValue : httpParamValue.toString(),
					])
					.filter(([ , httpParamValue ]) => httpParamValue !== ''
						&& httpParamValue !== 'NaN'
						&& !isNil(httpParamValue)),
			),
		});
	}

	private __shouldPrependBackendBaseSegmentToHttpRequestUrl(request: HttpRequest<unknown>): boolean {
		return !request.url.startsWith('http')
			&& !request.url.includes('assets') // All assets is relative to the origin
			&& !request.url.startsWith('/');
	}

}
