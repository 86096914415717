<bp-svg-shared-filters-definitions />
<bp-svg-shared-icons-definitions />
<bp-svg-admin-shared-icons-definitions />
<bp-svg-shared-transaction-status-icons-definitions />

<mat-sidenav-container
	[hasBackdrop]="(layoutFacade.hasBackdrop$ | async) || (layoutFacade.hasFullpageBackdrop$ | async)"
	[class.fullscreen]="layoutFacade.fullscreen$ | async"
	[class.fullpage-backdrop]="layoutFacade.hasFullpageBackdrop$ | async"
	(backdropClick)="layoutFacade.closeFloatOutlets()"
>
	<ng-content />
</mat-sidenav-container>
