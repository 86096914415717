import { isNil } from 'lodash-es';

import {
	DTO, Entity, Default, Required, Table, Mapper, booleanMapper, MapFromDTO
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';
import { FiatCurrency } from '@bp/shared/models/currencies';

import { SubscriptionPlanFeaturesLimits } from './subscription-plan-features-limits';
import { SubscriptionPlanFeature, SubscriptionPlanLimitedFeature } from './subscription-plan-feature';
import { SubscriptionPlanType } from './subscription-plan-type.enum';
import { SubscriptionPlanChargePeriod } from './subscription-plan-charge-period.enum';
import {
	SubscriptionPlanPricePerChargePeriodMap, subscriptionPlanPricePerChargePeriodMapper
} from './mappers';

export type SubscriptionPlanKeys = NonFunctionPropertyNames<SubscriptionPlan>;

export class SubscriptionPlan extends Entity {

	static {
		this._initClassMetadata();
	}

	@Required()
	@Default(null)
	@Table()
	override name!: string;

	@Required()
	outline!: string;

	@Mapper(SubscriptionPlanType)
	type!: SubscriptionPlanType;

	get isFree(): boolean {
		return this.type === SubscriptionPlanType.free;
	}

	get isPro(): boolean {
		return this.type === SubscriptionPlanType.pro;
	}

	get isGrowth(): boolean {
		return this.type === SubscriptionPlanType.growth;
	}

	get isEnterprise(): boolean {
		return this.type === SubscriptionPlanType.enterprise;
	}

	@Mapper(subscriptionPlanPricePerChargePeriodMapper)
	@Default(null)
	prices!: SubscriptionPlanPricePerChargePeriodMap | null;

	@Mapper(SubscriptionPlanFeaturesLimits)
	limits!: SubscriptionPlanFeaturesLimits;

	@Mapper(SubscriptionPlanFeature)
	@Default([])
	features!: SubscriptionPlanFeature[];

	@Mapper(booleanMapper)
	isMostPopular!: boolean;

	@Default(false)
	@Mapper(booleanMapper)
	includesPreviousPlanFeatures!: boolean;

	@MapFromDTO()
	plansGroupId!: string;

	readonly displayName: string;

	/**
	 * Flat price is a price that is not calculated based on the number of transactions, but rather charged each period.
	 */
	readonly hasFlatPrice: boolean;

	constructor(dto?: DTO<SubscriptionPlan>) {
		super(dto);

		this.name = dto?.name ?? this.type.displayName;

		this.displayName = `${ this.name } plan`;

		this.features = this._prependLimitedFeatures();

		this.hasFlatPrice = this._checkIfHasFlatPrice();

		this.isMostPopular = this.isPro;
	}

	private _checkIfHasFlatPrice(): boolean {
		if (!this.prices)
			return false;

		for (const [ , chargePeriodPrices ] of this.prices) {
			for (const [ , price ] of chargePeriodPrices.pricesPerCurrency) {
				if (price > 0)
					return true;
			}
		}

		return false;
	}

	override valueOf(): string {
		return this.id!;
	}

	getTransactionPriceFor(chargePeriod: SubscriptionPlanChargePeriod, currency: FiatCurrency): number | null {
		return this.prices?.get(chargePeriod)?.getTransactionPriceFor(currency) ?? null;
	}

	getMonthPriceFor(chargePeriod: SubscriptionPlanChargePeriod, currency: FiatCurrency): number {
		if (!this.prices)
			return 0;

		const periodPrice = this.prices.get(chargePeriod)?.getPriceFor(currency);

		if (isNil(periodPrice))
			return 0;

		return periodPrice / chargePeriod.monthsInPeriod;
	}

	private _prependLimitedFeatures(): SubscriptionPlanFeature[] {
		return [
			new SubscriptionPlanLimitedFeature({
				name: 'Users',
				limit: this.limits.users,
				description: 'Number of users',
			}),

			new SubscriptionPlanLimitedFeature({
				name: 'Checkouts',
				limit: this.limits.checkouts,
				description: 'The number of websites you can embed BridgerPay\'s checkout into',
			}),

			new SubscriptionPlanLimitedFeature({
				name: 'Connections',
				limit: this.limits.merchantPsps,
				description: 'Number of PSPs you can connect',
			}),

			...this.features,
		];
	}

}
