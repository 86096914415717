import type { DTO } from '@bp/shared/models/metadata';

import { PspPaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionBaseSummary } from './bridger-psp-payment-option-base-summary';

export class BridgerPspPaymentOptionCreditCardSummary extends BridgerPspPaymentOptionBaseSummary {

	static override readonly type = PspPaymentOptionType.creditCard;

	static {
		this._initClassMetadata();
	}

	readonly type = BridgerPspPaymentOptionCreditCardSummary.type;

	constructor(dto: DTO<BridgerPspPaymentOptionCreditCardSummary>) {
		super(dto);
	}

}
