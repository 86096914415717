import { Directive, HostListener, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ICalendlyOptions } from '../models';
import { CalendlyDialogComponent } from '../components';

import { CalendlyEventsHandlerDirective } from './calendly-events-handler.directive';

@Directive({
	selector: '[bpCalendly]',
})
export class CalendlyDirective extends CalendlyEventsHandlerDirective {

	protected _options!: ICalendlyOptions;

	@Input('bpCalendly')
	get options(): ICalendlyOptions {
		return this._options;
	}

	set options(value: ICalendlyOptions) {
		this._options = value;
	}

	constructor(private readonly _matDialog: MatDialog) {
		super();
	}

	@HostListener('click')
	onClick(): void {
		this._showCalendar();
	}

	private _showCalendar(): void {
		this._matDialog.open<CalendlyDialogComponent, ICalendlyOptions>(CalendlyDialogComponent, {
			data: this.options,
			width: '100vw',
			height: '100vh',
			maxWidth: '100vw', // With lesser width or not set max width a scrollbar appears on small screens
		});
	}

}
