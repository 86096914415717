import type { DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { BridgerPspPaymentOptionApmSummary } from './bridger-psp-payment-option-apm-summary';
import { BridgerPspPaymentOptionCreditCardSummary } from './bridger-psp-payment-option-credit-card-summary';
import { BridgerPspPaymentOptionCryptoSummary } from './bridger-psp-payment-option-crypto-summary';
import { BridgerPspPaymentOptionWalletGooglePaySummary } from './bridger-psp-payment-option-wallet-google-pay-summary';
import { BridgerPspPaymentOptionWalletApplePaySummary } from './bridger-psp-payment-option-wallet-apple-pay-summary';
import { BridgerPspPaymentOptionExternalSummary } from './bridger-psp-payment-option-external-summary';
import { BridgerPspPaymentOptionPosSummary } from './bridger-psp-payment-option-pos-summary';
import { BridgerPspPaymentOptionWalletSummary } from './bridger-psp-payment-option-wallet-summary';

export type BridgerPspPaymentOptionsSummary = BridgerPspPaymentOptionApmSummary | BridgerPspPaymentOptionCreditCardSummary | BridgerPspPaymentOptionCryptoSummary | BridgerPspPaymentOptionExternalSummary | BridgerPspPaymentOptionPosSummary | BridgerPspPaymentOptionWalletApplePaySummary | BridgerPspPaymentOptionWalletGooglePaySummary | BridgerPspPaymentOptionWalletSummary;

const bridgerPspPaymentOptionsSummaryConstructors = [
	BridgerPspPaymentOptionCreditCardSummary,
	BridgerPspPaymentOptionWalletGooglePaySummary,
	BridgerPspPaymentOptionWalletApplePaySummary,
	BridgerPspPaymentOptionWalletSummary,
	BridgerPspPaymentOptionApmSummary,
	BridgerPspPaymentOptionCryptoSummary,
	BridgerPspPaymentOptionExternalSummary,
	BridgerPspPaymentOptionPosSummary,
];

export function bridgerPspPaymentOptionsSummaryFactory(dtos: DTO<BridgerPspPaymentOptionsSummary>[]): BridgerPspPaymentOptionsSummary[] {
	return dtos
		.map(dto => dto.type && bridgerPspPaymentOptionSummaryFactory(dto.type, <any> dto))
		.filter(isPresent);
}

export function bridgerPspPaymentOptionSummaryFactory<T extends BridgerPspPaymentOptionsSummary = BridgerPspPaymentOptionsSummary>(
	type: PaymentOptionType | string,
	dto?: DTO<T>,
): T | null {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	if (paymentOptionType.isDeprecated)
		return null;

	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsSummaryConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);
}
