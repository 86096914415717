import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	Default, DTO, MapFromDTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams, mixinMetadataSortQueryParams
} from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { MerchantPsp, PspStatus } from '@bp/admins-shared/core/models';

type MerchantPspsQueryParamsDTO = DTO<MerchantPspsQueryParams> & IPageQueryParamsDTO & ISortQueryParamsDTO;

export class MerchantPspsQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, MerchantPsp),
) {
	@Default(null)
	@Mapper(PspStatus)
	status!: PspStatus | null;

	@MapFromDTO()
	pspName?: string;

	@Default(null)
	@Mapper(PaymentOptionType)
	type!: PaymentOptionType | null;

	constructor(dto?: MerchantPspsQueryParamsDTO) {
		super(dto);
	}
}
